import {
  ABOUT_ROUTE,
  ELEARN_ROUTE,
  HOME_ROUTE,
  LOGIN_ROUTE,
  SUBMIT_ROUTE
} from "routes";
import { AppLink, Img } from "globalStyles";
import { AppLogo, HamburgerIcon } from "utils/assets";
import {
  HamBurgerBtn,
  HomeLogoContainer,
  HomeNavigationContainer,
  NavLinkCon,
  NavLinkList
} from "./style";
import React, { useEffect, useState } from "react";

import { CATEGORY_ENDPOINT } from "api/ACTION";
import MobileMenu from "components/MobileMenu";
import jmsApp from "api/jms";
import { toast } from "react-hot-toast";
import { useLocation } from "react-router-dom";

// import tw from "twin.macro";

function NavBar() {
  const [journalList, setJournalList] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [activeNav, setActiveNav] = useState(1);
  const [categories, setCategories] = useState([]);
  const [open, setOpen] = useState(false);

  const toggleNav = () => {
    setOpen(!open);
  };

  const journalHandler = () => {
    setJournalList(!journalList);
  };
  const toggleActiveNav = (index) => {
    setActiveNav(index);
  };

  const location = useLocation();
  const { pathname } = location;
  const path = pathname.slice(0);

  const handleCategories = async () => {
    try {
      const { data } = await jmsApp.get(
        CATEGORY_ENDPOINT.GET_ARTICLE_CATEGORY()
      );
      if (data.success) setCategories((categories) => [...data.category]);
    } catch (error) {
      setCategories([]);
      // if (error.response) toast.error(error.response.data.error);
      // else toast.error(error);
    }
  };

  useEffect(() => {
    handleCategories();
  }, []);

  return (
    <>
      {open && <MobileMenu isOpen={open} close={() => toggleNav()} />}
      <HomeNavigationContainer>
        {/* Active state not working fine yet... */}
        <HamBurgerBtn onClick={() => toggleNav()}>
          <Img src={HamburgerIcon} alt="Menu" />
        </HamBurgerBtn>
        <HomeLogoContainer>
          <Img src={AppLogo} alt="LP Logo" />
        </HomeLogoContainer>

        <NavLinkCon>
          <NavLinkList>
            <li className=" transition-all duration-300 ease-in-out transform hover:font-bold hover:scale-110   ">
              <AppLink
                className={path === HOME_ROUTE ? "  scale-110 " : " scale-95"}
                onClick={() => {
                  toggleActiveNav(1);
                }}
                to={HOME_ROUTE}
              >
                <span className=" transition-all duration-300 ease-in-out transform over:font-bold hover:scale-110  h ">
                  Home
                </span>
              </AppLink>
            </li>

            <li className="relative journal_li">
              <div className=" transition-all duration-300 ease-in-out transform  hover:font-bold  hover:scale-110">
                <AppLink
                  className={path === "" ? "  scale-110 " : " scale-95"}
                  onClick={() => {
                    toggleActiveNav(2);
                    journalHandler();
                  }}
                >
                  <span className=" transition-all duration-300 ease-in-out transform  hover:font-bold  hover:scale-110">
                    Journals
                  </span>
                </AppLink>
              </div>

              {journalList && (
                // <JournalListDiv>
                <ul className="absolute mt-5 rounded-lg shadow-inner bg-white text-black/50 text-sm font-medium capitalize  w-max mx-auto p-2 text-center">
                  {categories?.map((category) => (
                    <li
                      key={category.id}
                      className=" p-2 rounded-lg transition-all duration-300 ease-in-out hover:bg-black/50 hover:text-white"
                    >
                      <AppLink to={`/journals/${category.name.toLowerCase()}`}>
                        {category.name}
                      </AppLink>
                    </li>
                  ))}
                </ul>
                // </JournalListDiv>
              )}
            </li>
            {/* <li>
              <AppLink
                
              className={path === SUPPORT_ROUTE ? "  scale-110 " : " scale-95"}
                onClick={() => {
                  toggleActiveNav(3);
                }}
                to={SUPPORT_ROUTE}
              >
                Support
              </AppLink>
            </li> */}
            {/* <li>
              <AppLink
                
              className={path === LOGIN_ROUTE ? "  scale-110 " : " scale-95"}
                onClick={() => {
                  toggleActiveNav(4);
                }}
                to={LOGIN_ROUTE}
              >
                Login
              </AppLink>
            </li> */}
            <li className=" transition-all duration-300 ease-in-out transform  hover:font-bold  hover:scale-110">
              <AppLink
                className={`${
                  path === ELEARN_ROUTE ? "  scale-120 " : " scale-90"
                } whitespace-nowrap transform transition-all duration-300 ease-in-out !hover:text-black/50`}
                onClick={() => {
                  toggleActiveNav(5);
                }}
                // to={DASHBOARD_ROUTE}
                to={ELEARN_ROUTE}
              >
                <span>E-learning</span>
              </AppLink>
            </li>
            <li className=" transition-all duration-300 ease-in-out transform hover:scale-110  hover:font-bold ">
              <AppLink
                className={`${
                  path === ABOUT_ROUTE ? "  scale-120 " : " scale-90"
                } whitespace-nowrap  transform transition-all duration-300 ease-in-out hover:scale-110  hover:font-bold !hover:text-black/50`}
                onClick={() => {
                  toggleActiveNav(6);
                }}
                to={ABOUT_ROUTE}
              >
                <span>About Us</span>
              </AppLink>
            </li>

            {/* <li>
              <AppLink
                
              className={path === BLOG_ROUTE ? '  scale-110 ' : ' scale-95'}
                onClick={() => {
                  toggleActiveNav(7)
                }}
                to={BLOG_ROUTE}
              >
                Blog
              </AppLink>
            </li> */}
          </NavLinkList>
        </NavLinkCon>
        <NavLinkCon>
          <li
            // style={{ margin: "10px", fontSize: "18px", fo2tWeight: "500" 0}
            className=" m-2.5 text-[#46555c] text-lg font-medium transition-all duration-300 ease-in-out border-b border-solid border-transparent hover:border-[#46555c]"
          >
            <AppLink
              className={`${
                path === LOGIN_ROUTE ? "  scale-120 " : " scale-90"
              } whitespace-nowrap transform transition-all duration-300 ease-in-out !hover:text-black/50`}
              onClick={() => {
                toggleActiveNav(4);
              }}
              to={LOGIN_ROUTE}
            >
              Login
            </AppLink>
          </li>

          <button
            disabled={path === SUBMIT_ROUTE}
            className={` disabled:cursor-progress disabled:hover:bg-transparent disabled:hover:text-[#46555c] border border-solid border-[#46555c] p-2.5 rounded-[5px] h-12 transition-colors duration-300 ease-in-out hover:bg-[#46555c] hover:text-white hover:shadow-lg active:shadow-none`}
          >
            <AppLink
              className={`${
                path === HOME_ROUTE ? "  scale-120 " : " scale-90"
              } whitespace-nowrap transform transition-all duration-300 ease-in-out !hover:text-black/50`}
              onClick={() => {
                toggleActiveNav(1);
              }}
              to={SUBMIT_ROUTE}
            >
              Submit Paper
            </AppLink>
          </button>
        </NavLinkCon>
      </HomeNavigationContainer>
    </>
  );
}

export default NavBar;
