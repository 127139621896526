import { ARTICLE_ENDPOINT, CATEGORY_ENDPOINT } from "api/ACTION.js";
import { JournalHead, JournalUploadDiv, TextArea, UploadSelect } from "./style";
import React, { useEffect, useRef, useState } from "react";

import { CONSTANT } from "utils/validation";
import Checkbox from "components/Admin/sharedComponents/CheckBox";
import CustomSelectMain from "components/SelectInput";
import { MainBtn } from "../Hero Section/style";
import TextInput from "components/TextInput";
import jmsApp from "api/jms";
import { toast } from "react-hot-toast";

function JournalSection() {
  const [journalFile, setJournalFile] = useState();
  const [reSubmitting, setResubmitting] = useState(false);
  const [values, setValues] = useState({
    author: "",
    email: "",
    description: "",
    phone: "",
    country: "",
    title: "",
    category: "",
    fileData: "",
    articleId: "",
    format: ".pdf",
    reSubmit: reSubmitting
  });
  const [isUploadComplete, setIsUploadComplete] = useState(false);
  const [categories, setCategories] = useState([]);
  const JournalFileRef = useRef();
  const [active, setActive] = useState(false);
  const [rows, setRows] = useState(2);
  const [step, setStep] = useState(1);

  const [file, setFile] = useState(null);
  const handleJournalFileRef = (e) => {
    JournalFileRef.current.click();
  };
  const handleJournalUpload = (file) => {
    loadFile(file);
    setFile(file);
    // loadFile(e.target.files[0]);
    setIsUploadComplete(true);
  };

  const formRef = useRef(null);

  const clearValues = () => {
    values.author = "";
    values.email = "";
    values.description = "";
    values.phone = "";
    values.country = "";
    values.title = "";
    values.category = "";
    values.fileData = "";
    values.format = "";
    values.articleId = "";
    values.reSubmit = false;
    setIsUploadComplete(false);
  };
  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const loadFile = (file) => {
    if (file) {
      const type = file.type;
      setActive(true);
      if (!CONSTANT.FILE_TYPE.includes(type)) {
        toast.error("Invalid file format");
      } else toast.success("file upload complete");
      values.format = file.type;
      // read the file
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setJournalFile(reader.result);
      };
      setActive(false);
    }
  };
  const uploadFile = (base64EncodedImage) => {
    values.fileData = base64EncodedImage;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // value check
    values.reSubmit = reSubmitting;
    values.fileData = journalFile;
    if (!values.author) {
      toast.error("author name required", {
        className: " capitalize text-xs"
      });
      // to step 1
      setStep(1);
      return;
    } else if (!values.email) {
      toast.error("author email required", {
        className: " capitalize text-xs"
      });
      // to step 1
      setStep(1);
      return;
    } else if (!values.phone) {
      toast.error("author phone number required", {
        className: " capitalize text-xs"
      });
      // to step 1
      setStep(1);
      return;
    } else if (!values.country) {
      toast.error("author country required", {
        className: " capitalize text-xs"
      });
      // to step 1
      setStep(1);
      return;
    } else if (!values.reSubmit && !values.category) {
      toast.error("article category required", {
        className: " capitalize text-xs"
      });
      // to step 3
      setStep(3);
      return;
    } else if (!values.reSubmit && !values.title) {
      toast.error("article title required", {
        className: " capitalize text-xs"
      });
      // to step 3
      setStep(3);
      return;
    } else if (!values.reSubmit && !values.description) {
      toast.error("article description required", {
        className: " capitalize text-xs"
      });
      // to step 3
      setStep(3);
      return;
    } else if (values.reSubmit && !values.articleId) {
      toast.error("article id required for resubmission", {
        className: " capitalize text-xs"
      });
      // to step 3
      setStep(3);
      return;
    } else if (!values.fileData) {
      toast.error("article file is required ", {
        className: " capitalize text-xs"
      });
      // to step 3
      setStep(4);
      return;
    }

    try {
      if (journalFile) {
        //because the file required check if the use selected a file
        uploadFile(journalFile); // set the file data in the state varible
      } else if (!journalFile) {
        return toast.error("select Journal file");
      }
      setActive(true);

      const { data } = await toast.promise(
        jmsApp.post(ARTICLE_ENDPOINT.SEND_ARTICLE(), {
          ...values
        }),
        {
          loading: `Article ${values.reSubmit ? "re-submission" : "submission"} in progress...`,
          success: `Article successfully ${values.reSubmit ? "re-submitted" : "submitted"}.`,
          error: `Article ${values.reSubmit ? "re-submission" : "submission"} failed.`
        },
        {
          className: "text-sm"
        }
      );

      setActive(false);
      if (data.success) {
        setIsUploadComplete(false);
        // toast.success(data.msg);
        // clearValues();
        setResubmitting(false);
        setValues({
          author: "",
          email: "",
          description: "",
          phone: "",
          country: "",
          title: "",
          category: "",
          fileData: "",
          articleId: "",
          format: ".pdf",
          reSubmit: reSubmitting
        });

        setStep(1);
        setFile(null);
        formRef.current.reset();
      }
    } catch (error) {
      setActive(false);
      setIsUploadComplete(false);
      // if (error.response.data) {
      //   toast.error(error.response.data.error);
      // } else toast.error(error.mssage);
    }
  };
  // ! Getting categories for non user
  const handleCategories = async () => {
    try {
      const { data } = await jmsApp.get(
        CATEGORY_ENDPOINT.GET_ARTICLE_CATEGORY()
      );
      if (data.success) setCategories((categories) => [...data.category]);
    } catch (error) {
      setCategories([]);

      // if (error.response) toast.error(error.response.data.error);
      // else toast.error(error);
    }
  };

  useEffect(() => {
    handleCategories();
  }, []);

  return (
    // <Section>
    <form
      ref={formRef}
      action=""
      onSubmit={handleSubmit}
      className=" w-[90%] sm:w-4/5 md:w-3/5  xl:w-2/5 mx-auto py-4 my-8"
    >
      {/* <JournalCon> */}
      <JournalHead>Article Submission</JournalHead>
      {/* Stepper */}
      <div className="my-8 flex items-center justify-center w-full">
        {/* <!-- Stepper --> */}
        {/* <!-- Stepper --> */}
        <ul className="relative flex flex-row items-center justify-center gap-x-2 w-full">
          {/* <!-- Item --> */}
          <li className="shrink basis-0 flex-1 group">
            <div className="min-w-7 min-h-7 w-full inline-flex items-center text-xs align-middle">
              <span
                onClick={() => setStep(1)}
                className={` cursor-pointer size-7 flex justify-center items-center flex-shrink-0 ${step >= 1 ? "bg-blue-700 text-white border-transparent" : " bg-gray-100  text-black/50 border-black/50"} font-medium rounded-full `}
              >
                1
              </span>
              <div
                className={`ms-2 w-full h-px flex-1 ${step > 1 ? "bg-blue-700" : "bg-gray-200"} group-last:hidden `}
              ></div>
            </div>
            <div className="mt-3">
              <span className="block text-xs font-medium text-gray-800   ">
                Author
              </span>
            </div>
          </li>
          {/* <!-- End Item --> */}

          {/* <!-- Item --> */}
          <li className="shrink basis-0 flex-1 group">
            <div className="min-w-7 min-h-7 w-full inline-flex items-center text-xs align-middle">
              <span
                onClick={() => setStep(2)}
                className={` cursor-pointer size-7 flex justify-center items-center flex-shrink-0 ${step >= 2 ? "bg-blue-700 text-white border-transparent" : " bg-gray-100  text-black/50 border-black/50"} font-medium rounded-full `}
              >
                2
              </span>
              <div
                className={`ms-2 w-full h-px flex-1 ${step > 2 ? "bg-blue-700" : "bg-gray-200"} group-last:hidden `}
              ></div>
            </div>
            <div className="mt-3">
              <span className="block text-xs font-medium text-gray-800  ">
                Type
              </span>
            </div>
          </li>
          {/* <!-- End Item --> */}

          {/* <!-- Item --> */}
          <li className="shrink basis-0 flex-1 group">
            <div className="min-w-7 min-h-7 w-full inline-flex items-center text-xs align-middle">
              <span
                onClick={() => setStep(3)}
                className={` cursor-pointer size-7 flex justify-center items-center flex-shrink-0 ${step >= 3 ? "bg-blue-700 text-white border-transparent" : " bg-gray-100  text-black/50 border-black/50"} font-medium rounded-full `}
              >
                3
              </span>
              <div
                className={`ms-2 w-full h-px flex-1 ${step > 3 ? "bg-blue-700" : "bg-gray-200"} group-last:hidden `}
              ></div>
            </div>
            <div className="mt-3">
              <span className="block text-xs font-medium text-gray-800 w-fit ">
                {/* {step === 3 && "Article Details"}
                {step === 4 && "Article ID"} */}
                Article
              </span>
            </div>
          </li>
          {/* <!-- End Item --> */}

          {/* <!-- Item --> */}
          <li className={`shrink basis-0 flex-1 group`}>
            <div className="min-w-7 min-h-7 w-full inline-flex items-center text-xs align-middle">
              <span
                onClick={() => setStep(4)}
                className={` cursor-pointer size-7 flex justify-center items-center flex-shrink-0  ${step === 4 ? "bg-blue-700 text-white border-transparent" : " bg-gray-100 text-black/50 border-black/50"} font-medium  rounded-full  `}
              >
                4
              </span>
              <div className="ms-2 w-full h-px flex-1 bg-gray-200 group-last:hidden "></div>
            </div>
            <div className="mt-3">
              <span className="block text-sm font-medium text-gray-800   w-fit">
                Upload
              </span>
            </div>
          </li>
          {/* <!-- End Item --> */}
        </ul>
        {/* <!-- End Stepper --> */}
      </div>
      {/* first step */}
      {step === 1 && (
        <div className=" my-8">
          <p className=" text-center w-fit font-bold text-xl lg:text-2xl  capitalize">
            Enter your details
          </p>
          <TextInput
            defaultValue={values.author}
            label="Name"
            name="author"
            placeholder="eg. John Doe"
            onChange={handleChange}
            required={true}
          />
          <span>For multiple authors, separate names with comma(,).</span>
          <TextInput
            defaultValue={values.email}
            label="Email"
            name="email"
            placeholder="eg. johndoe@gmail.com"
            type="email"
            required={true}
            onChange={handleChange}
          />
          <TextInput
            label="Phone Number"
            name="phone"
            placeholder="eg. +234(0)811111"
            type="tel"
            required={true}
            onChange={handleChange}
            defaultValue={values.phone}
          />
          <TextInput
            defaultValue={values.country}
            label="Country"
            name="country"
            placeholder="eg. Nigeria"
            type="text"
            required={true}
            onChange={handleChange}
          />
        </div>
      )}
      {/* step 2 */}
      {step === 2 && (
        <div className=" my-8">
          <p className=" text-center w-full font-bold text-xl lg:text-2xl capitalize  ">
            Submission type
          </p>
          <div className=" w-full flex flex-col items-center  justify-start gap-10 py-5">
            <p className=" w-3/5 text-wrap mx-auto font-medium text-center text-lg lg:text-xl">
              Are you submitting this article on this platform for the first
              time ?
            </p>
            <div className=" w-3/5 mx-auto flex items-center justify-between gap-6">
              <button
                type="button"
                className=" w-1/2 !bg-blue-500 !text-white uppercase py-2 px-4 rounded-lg "
                onClick={() => {
                  setResubmitting(false);
                  setStep((val) => val + 1);
                }}
              >
                yes
              </button>
              <button
                onClick={() => {
                  setResubmitting(true);
                  setStep((val) => val + 1);
                }}
                type="button"
                className=" w-1/2 !bg-red-500 !text-white uppercase py-2 px-4 rounded-lg "
              >
                no
              </button>
            </div>
          </div>
        </div>
      )}
      {/* step 3  for first time submission*/}

      {step === 3 && (
        <>
          <div>
            <p className="text-center w-full font-bold text-xl lg:text-2xl capitalize">
              Enter the details of the article your are uploading
            </p>
            <div className=" py-2">
              <CustomSelectMain
                defaultValue={values.category}
                label="Article Category"
                name="category"
                required={true}
                onChange={handleChange}
              >
                <option style={{ color: "grey" }} value="">
                  -Please select-
                </option>
                {categories?.map((cur) => (
                  <option className="active-option" key={cur.id} value={cur.id}>
                    {cur.name}
                  </option>
                ))}
              </CustomSelectMain>
            </div>
            <TextInput
              defaultValue={values.title}
              label="Title of the Paper"
              name="title"
              placeholder="eg. True meaning of life"
              type="text"
              required={true}
              onChange={handleChange}
            />

            <TextArea
              defaultValue={values.description}
              name="description"
              type="text"
              placeholder="Describe your article..."
              onChange={handleChange}
              rows={rows}
              onClick={() => setRows((val) => (val === 5 ? 2 : 5))}
              onBlur={() => {
                if (rows === 2) {
                  return;
                }
                setRows(2);
              }}
              required={true}
            />
          </div>
          {reSubmitting && (
            <div>
              <p className="text-center w-full font-bold text-sm lg:text-lg capitalize">
                Enter the ID of the article you are resubmitting
              </p>
              <TextInput
                defaultValue={values.articleId}
                required={reSubmitting}
                disabled={!reSubmitting}
                label="Article Id"
                name="articleId"
                placeholder="Only for re-submission of article"
                type="text"
                onChange={handleChange}
              />
              <p className=" italic flex-wrap flex text-start text-black/70 py-4">
                Note : The Article ID can be found in the resubmission email
                sent to the email address attached to your first submission
              </p>
            </div>
          )}
        </>
      )}
      {/* step 4 for resubmission */}
      {/* {step === 3 && reSubmitting && (
        <div>
          <p className="text-center w-full font-bold text-xl lg:text-2xl capitalize">
            Enter the ID of the article you are resubmitting
          </p>
          <TextInput
            defaultValue={values.articleId}
            required={reSubmitting}
            disabled={!reSubmitting}
            label="Article Id"
            name="articleId"
            placeholder="Only for re-submission of article"
            type="text"
            onChange={handleChange}
          />
          <p className=" italic flex-wrap flex text-start text-black/70 py-4">
            Note : The Article ID can be found in the resubmission email sent to
            the email address attached to your first submission
          </p>
        </div>
      )} */}
      {/* <div className=" flex  w-full items-center justify-start space-x-4 py-4">
        <Checkbox
          checked={isreSubmit}
          onClick={() => {
            setIsReSubmit((val) => !val);
            setValues({ ...values, reSubmit: !isreSubmit });
          }}
        />
        <p>Re-submission ?</p>
      </div> */}
      {/* step for final step */}
      {step === 4 && (
        <div className=" my-6  flex flex-col items-center justify-start gap-6 w-full">
          <p className="text-center w-full font-bold text-xl lg:text-2xl ">
            Upload a pdf or Microsoft document
          </p>
          <div className="w-full">
            <DragAndDropBox onChange={handleJournalUpload} />
          </div>
          <div className="flex items-center w-full my-8">
            <hr className="flex-1 border-t-[0.2px] border-black/30" />
            <span className="mx-4 text-black/70 italic uppercase">or</span>
            <hr className="flex-1 border-t-[0.2px] border-black/30" />
          </div>
          <label className="block w-full">
            <span className="sr-only">Choose profile photo</span>
            <input
              onChange={(e) => handleJournalUpload(e.target.files[0])}
              type="file"
              multiple={false}
              className="block w-full text-sm text-gray-500
      file:me-4 file:py-2 file:px-4
      file:rounded-lg file:border-0
      file:text-sm file:font-semibold
      file:bg-blue-600 file:text-white
      hover:file:bg-blue-700
      file:disabled:opacity-50 file:disabled:pointer-events-none
      dark:file:bg-blue-500
      dark:hover:file:bg-blue-400
    "
            />
          </label>
          {file && (
            <p className=" text-start text-xl font-bold text-black/70">
              {file.name}
            </p>
          )}
          {/* <input
            ref={JournalFileRef}
            type="file"
            name="file"
            onChange={handleJournalUpload}
            multiple={false}
            style={{ display: "none" }}
          />

          <JournalUploadDiv>
            <UploadSelect
              type="button"
              isUploadComplete={isUploadComplete}
              onClick={handleJournalFileRef}
            >
              {isUploadComplete ? "Upload Complete!" : "Upload"}
            </UploadSelect>
          </JournalUploadDiv> */}
        </div>
      )}
      {/* Buttons */}
      <div className="w-full flex items-center justify-between py-4">
        {step !== 1 && (
          <button
            type="button"
            className={` py-2 px-4 capitalize  border-blue-500 text-blue-600 border font-medium rounded-lg`}
            onClick={() => {
              if (step === 1) return;

              setStep((val) => val - 1);
            }}
          >
            prev
          </button>
        )}
        {step === 4 ? (
          <div type="submit" className=" flex w-full items-center justify-end">
            <MainBtn className="!mt-0" disabled={active}>
              {active ? "Processing..." : " Submit"}
            </MainBtn>
          </div>
        ) : (
          step !== 2 && (
            <button
              type="button"
              className=" py-2 px-4 capitalize border-blue-500 text-blue-600 border  font-medium rounded-lg "
              onClick={() => {
                if (step === 4) return;

                setStep((val) => val + 1);
              }}
            >
              next
            </button>
          )
        )}
      </div>

      {/* </JournalCon> */}
    </form>
    // </Section>
  );
}

export default JournalSection;

const DragAndDropBox = ({ onChange }) => {
  const [dragging, setDragging] = useState(false);
  const [fileName, setFileName] = useState("");

  const handleDragEnter = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragging(false);
    const files = [...e.dataTransfer.files];
    const droppedFile = files[0];
    setFileName(droppedFile.name);
    onChange(droppedFile);
    // Process dropped files here
  };

  return (
    <div
      className={`border-2 border-dashed h-[300px] border-gray-400 rounded-md p-8 text-center transition-all duration-300 ${dragging ? "bg-blue-700/50 text-white" : ""} flex items-center justify-center`}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      style={{
        boxShadow: dragging ? "0px 0px 10px rgba(0, 0, 0, 0.1)" : "none"
      }}
    >
      <div className="text-gray-600">
        {fileName
          ? ` ${fileName}`
          : dragging
            ? "Drop the file here"
            : "Drag and drop file here"}
      </div>
    </div>
  );
};
