// import { FiDownload } from "react-icons/fi";
// import { MdDownload } from "react-icons/md";

import Colors from "utils/colors";
import media from "utils/media";
import styled from "styled-components";

export const Article = styled.table`
  background: white;
  /* min-width: 100%; */
  /* width: 50%; */
  border-collapse: collapse;
  background-color: blue;
  thead {
    /* background: ${Colors.primary}; */
    background: red;
    width: 100vh;

    // max-width: 100%;
    // tr {
    //   text-align: left;
    //   cursor: pointer;
    //   th {
    //     padding: 20px 25px;
    //     color: white;
    //     cursor: pointer;
    //   }
    // }
    tr {
      background: yellow;
    }
    th {
      background: green;
      width: 100%;
    }
  }
  tbody {
    /* min-width: 900px; */
    /* max-width: 100%; */
  }
`;
export const TableData = styled.tr`
  background: ${(props) => (props.id % 2 !== 0 ? "#e5e5e590" : "")};
  td {
    // white-space: nowrap;
    // padding: 22px 25px;
    // border-bottom: 1px solid #e5e5e5;
    // font-size: 15px;
    // color: #000000;
    // text-transform: capitalize;
    // margin: 10px 0;
    // cursor: pointer;
  }
  .remove {
    background: transparent;
    font-weight: 600;
    font-size: 12px;
    color: red;
    padding: 5px;
    border-radius: 4px;

    :hover {
      transition: all 0.2s ease-in-out;
      background: red;
      color: white;
    }
  }
  .actions {
    display: flex;
    gap: 20px;
  }
  .title {
    font-weight: 600;
  }

  :hover {
    background: #e5e5e5;
  }
`;

export const OptCon = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0px 4px;

  .dropDown {
    width: 100px;
    position: relative;
  }
`;

export const Status = styled.span`
  padding: 6px 8px;
  border-radius: 7px;
  color: ${(props) => (props.status ? "white" : "black")};
  font-size: ${(props) => (props.status ? "12px" : "14px")};
  font-weight: ${(props) => (props.status ? "normal" : "500")};
  display: block;
  width: 80px;
  text-align: center;
  cursor: pointer;
  text-transform: capitalize;
  background: ${(props) =>
    props.status === "total"
      ? `${Colors.primary}`
      : props.status === "pending"
      ? `${Colors.black}`
      : props.status === "accepted"
      ? "orange"
      : props.status === "rejected"
      ? "red"
      : props.status === "review"
      ? "gray"
      : props.status === "published"
      ? "green"
      : ""};
`;
export const SearchCont = styled.div`
  width: 100%;

  input {
    max-width: 100%;
    min-width: 450px;
    padding: 20px 23px;
    border-radius: 13px;

    ${media.mobile`
    min-width: 80%;
  `};
  }
`;
export const Move = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  gap: 30px;

  button {
    color: #333333;
    background: unset;
    padding: 10px 16px;
    border-radius: 5px;
    border: 1px solid gray;
    cursor: pointer;
    :disabled {
      color: #33333320;
      border: 1px solid #33333320;
    }
    :hover {
      color: white;
      background: gray;
      transition: 0.3s all ease-in-out;
      :disabled {
        color: #33333320;
        background: unset;
      }
    }
  }
`;
