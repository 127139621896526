import { BiBookBookmark } from "react-icons/bi";
import Colors from "utils/colors";
import media from "utils/media";
import styled from "styled-components/macro";
import tw from "twin.macro";

// import { AuthProp } from "utils/assets";

export const CoursesCont = styled.div`
  background: ${Colors.white};
  /* // padding: 25px 35px; */
  ${tw`py-4 px-6 relative`}
  overflow-x: none;

  .main {
    width: 60%;
    ${media.smallDesktopMinimum`width: 90%;
      margin: auto;
      `}
    ${media.tablet`width: 98%; `}

    p {
      margin-top: 6px;
      color: ${Colors.primary};
      font-size: 17px;
      line-height: 24px;
    }
  }
  .courses {
    display: flex;
    flex-direction: column;
    /* // margin: 30px auto; */

    ${tw`mx-auto my-4`}

    .course {
      display: flex;
      gap: 140px;
      justify-content: space-between;
      align-items: center;
      margin: 50px 0;
      min-width: 300px;
      ${media.smallDesktopMinimum`
	gap:unset;
  flex-direction:column;
  justify-content:center;
  align-items:center;
	`};
      .card {
        max-width: 400px;
        width: 100%;
        height: 350px;
        margin: 15px 0;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          /* z-index: 10; */
          box-shadow: 40px -40px black;
          ${media.mobile`
          box-shadow: 20px -20px black;
	`};
        }
      }
      .content {
        h4 {
          font-size: 20px;
          line-height: 20px;
          margin-bottom: 10px;
        }
        p {
          font-size: 17px;
          line-height: 25px;
        }
        .btn {
          margin-block: 12px;
          display: flex;
          justify-content: flex-end;
          background: unset;
          button {
            color: ${Colors.black};
            /* // padding: 10px 20px; */
            ${tw`px-2 py-1`}
            border: 1px solid ${Colors.primary};
            border-radius: 50px;
            :hover {
              background: ${Colors.primary};
              color: ${Colors.white};
              transition: 0.15s all ease-in-out;
            }
          }
        }
      }
    }
  }
`;

export const Type = styled.div`
  width: 35%;
  height: 100px;
  background: ${Colors.primary};
  color: ${Colors.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  font-weight: bold;
  font-size: 20px;
`;

export const BookIcon = styled(BiBookBookmark)`
  align-self: flex-end;
  color: #999;
`;
export const CourseCont = styled.div`
  width: 100%;
  /* // padding: 25px 35px; */
  .heading {
    margin-top: 40px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: starr;

    align-items: center;
    .btns {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 30px;
      ${media.mobile`
	          gap:10px;
	          `};
      .btn {
        padding: 12px 35px;
        border-radius: 50px;
        font-size: 15px;
        font-weight: 500;
        ${media.mobile`
        padding: 12px 25px;

	`};
      }
      .active {
        background: ${Colors.approvedColor};
        color: white;
      }
      .inactive {
        :hover {
          background: ${Colors.approvedBg};
          transition: all 0.3s ease-in-out;
        }
      }
    }
  }
  .flex {
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 20px;
  }

  .price {
    color: ${Colors.primary};
    font-weight: bold;
  }
  .search {
    background: ${Colors.approvedColor};
  }
  .courses {
    /* // margin: 65px 20px; */
    justify-items: center;
    align-items: center;
    width: 100%;
    /* // padding: 40px; */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* // box-shadow: 1px 1px 3px grey; */
    grid-template-columns: repeat(auto-fill, minmax(min(200px, 100%), 1fr));
    /* // gap: 30px; */
    .eye {
      cursor: pointer;
    }

    .courseCard {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      justify-items: center;
    }

    .course {
      border: 2px;
      width: 300px;
      display: flex;
      margin: 20px;
      align-items: center;
      flex-direction: row;
      gap: 6px;
      background: whitesmoke;
      border-radius: 16px;
      transition: transform 0.2s;

      button {
        margin-top: 10px;
        padding: 6px 10px;
        text-align: center;
        align-self: end;
        border: 1px solid #9c4df4;
        border-radius: 5px;
        width: fit-content;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        text-align: center;
        color: #9c4df4;
        cursor: pointer;
        background: unset;
        :hover {
          background: #9c4df4;
          color: #ffffff;
        }
      }

      .duration {
        display: flex;
        width: 100%;
        justify-content: space-between;
      }

      .catCon {
        display: flex;
        flex-direction: column;
        width: 100%;
        /* // margin: 20px 10px 10px 10px; */
      }

      span {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50px;
        padding: 10px;
        font-size: 24px;
        color: ${Colors.black};
        font-weight: 500;
      }
      .cat {
        font-weight: 500;
        font-size: 17px;
        flex: 1;
        text-align: center;
      }

      :hover {
        transform: scale(1.13);
      }
    }
  }
`;
