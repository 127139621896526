import media from "utils/media";
import styled from "styled-components";
import tw from "twin.macro";

export const NavCon = styled.nav`
  width: 100%;
  height: 100px;
  position: fixed;
  top: 0;
  z-index: 700;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  /* // New styles */

  ${tw`px-2 sm:px-10`}
  /* ${media.smallDesktopMinimum`
  width: 100%;
  height: 100px;
  `}
	${media.mobile`
  width: 100%;
  height: 80px;
  // padding: 0px 20px;
  `} */
  ${media.smallDesktopMinimum`
    height: 100px;
  `}
  ${media.mobile`
    height: 80px;
    // padding: 0px 20px;
  `}
`;

export const HomeNavigationContainer = styled(NavCon)`
  ${tw`px-6 lg:px-14`}
`;

export const NavImgCon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const HomeLogoContainer = styled(NavImgCon)`
  ${tw`w-24 h-20`}
`;

export const NavLinkCon = styled.div`
  /* // margin-right: 8.5%; */
  display: flex;
  /* // flex-direction: column; */
  justify-content: center;

  .journal_li {
    position: relative;
  }
  /* //new style */
  ${media.mobile`
  display:none;
  `}
`;
export const NavLinkList = styled.ul`
  display: flex;

  list-style: none;
  margin: 0;
  padding: 0;
  border: none;
  & > *:not(:last-child) {
    margin-right: 2rem;
  }
  & > a {
    display: block;
    padding: 5px 15px 5px 0.5em;
    width: 100%;
    color: #46555c;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;

    &:hover {
      /* // background: #46555c;
    // color: #ffffff;
    // font-size: 20px; */

      font-weight: bold;
      ${tw` transition-transform duration-500 ease-in-out transform hover:scale-110`}
    }
    & .active__nav {
      /* // background: #46555c; */
      color: #46555c;
      font-weight: bold;
      font-size: 20px;
    }
  }

  /* //new style */
  ${media.mobile`
  display:none;
  `}
  ${media.tablet`
	display: none;
	`}
`;

export const JournalListDiv = styled.div`
  position: absolute;
  /* width: 250px; */
  width: 100%;
  top: 60px;
  padding: 5%;
  background: #ffffff;
  border-radius: 10px;
  /* // box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */

  a {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #46555c;
    margin-top: 10px;
    text-transform: uppercase;
    text-align: center;

    &:hover {
      background-color: rgba(90, 105, 113, 0.5);
      border-radius: 2px;
    }
  }

  /* //new style */
  ${media.mobile`
  display: none;
  `}
  ${media.tablet`
	display: none;
	`}
`;
//deleted

export const HamBurgerBtn = styled.button`
  display: none;
  width: 50px;
  height: 30px;
  outline: none;
  border: none;
  background: transparent;
  padding: 0;
  img {
    width: 100%;
    height: 100%;
  }
  ${media.tablet`
	display: flex;
  position: relative;
	`}
  ${media.mobile`
	display: flex;
	position: relative;
	`}
`;
