import React, { useState } from "react";

import { Link } from "react-router-dom";
import { MdArrowRight } from "react-icons/md";

export default function Author() {
  const [currentTab, setCurrentTab] = useState("overview");
  return (
    <div className="w-full px-6 py-2 flex flex-col items-start justify-start  gap-4">
      <div className="w-full flex items-center justify-start gap-6 text-[#b97868] font-bold uppercase">
        <Link to={"/"}>Home</Link>
        <MdArrowRight size={40} />
        <span>submission procedure</span>
      </div>
      <div className="uppercase text-[#21046e] text-start w-full ">
        submission procedure
      </div>
      <div className="w-full flex flex-col items-start justify-start">
        <div className=" flex items-center justify-start gap-2">
          <button
            onClick={() => setCurrentTab("overview")}
            className={` capitalize p-2 border text-[#22406e] text-xs bg-[#ededed] ${currentTab === "overview" ? "!bg-white" : ""} hover:bg-white`}
          >
            overview
          </button>
          <button
            onClick={() => setCurrentTab("submission")}
            className={` capitalize p-2 border text-[#22406e] text-xs bg-[#ededed] ${currentTab === "submission" ? "!bg-white" : ""} hover:bg-white`}
          >
            new submission
          </button>
          <button
            onClick={() => setCurrentTab("revision")}
            className={` capitalize p-2 border text-[#22406e] text-xs bg-[#ededed] ${currentTab === "revision" ? "!bg-white" : ""} hover:bg-white`}
          >
            revision
          </button>
          <button
            onClick={() => setCurrentTab("resubmission")}
            className={` capitalize p-2 border text-[#22406e] text-xs bg-[#ededed] ${currentTab === "resubmission" ? "!bg-white" : ""} hover:bg-white`}
          >
            resubmission
          </button>
          <button
            onClick={() => setCurrentTab("corrections")}
            className={` capitalize p-2 border text-[#22406e] text-xs bg-[#ededed] ${currentTab === "corrections" ? "!bg-white" : ""} hover:bg-white`}
          >
            corrections
          </button>
        </div>
        <div className="relative border  p-6 w-full ">
          {currentTab === "overview" && (
            <div className="w-full flex flex-col items-start justify-start gap-6 text-xs">
              <h1 className="text-[#8a1f05] font-bold text-lg capitalize">
                overview
              </h1>
              <div className=" w-full divide-y-2  flex flex-col items-start justify-start gap-2 ">
                <h2 className="w-full text-[#8a1e04] capitalize ">
                  introduction
                </h2>

                <p className=" pt-2">
                  Authors are encouraged to submit original manuscripts online,
                  which are not presently under consideration at another
                  journal, in a format defined by the author guidelines.
                  Submitted manuscripts are pre-screened by plagiarism detection
                  software both before and after blind peer review, prior to
                  acceptance. The journal allows the author to track and
                  participate in all activities related to the processing of the
                  manuscript, such as the review process, copy editing, layout
                  editing and proofing of manuscripts, which are all managed
                  online. The publisher utilises multiple communication channels
                  such as email, text messaging and telephone calls, with the
                  intent to provide status updates and action requests for
                  authors. For this purpose, we ask that authors frequently
                  visit their user profile and update it with the latest contact
                  information.
                </p>
              </div>
              <div className=" w-full divide-y-2  flex flex-col items-start justify-start gap-2 ">
                <h2 className="w-full text-[#8a1e04] capitalize ">
                  Publication procedure
                </h2>
                <p className=" pt-2">
                  Upon acceptance of a manuscript for publication by the
                  Editor-in-Chief, the editorial staff will work towards
                  preparing the manuscript for online publication. The first
                  stage involves language editing, after which the manuscript is
                  returned to the corresponding author for review. This is the
                  author’s final opportunity to make text changes to the
                  manuscript and submit a revised version. At a final stage the
                  editorial staff will send the author one set of galley proofs,
                  and the author will have two working days to mark any
                  typographical errors. It may not be possible to incorporate
                  author corrections in the printed version of the manuscript if
                  the author fails to respond to proofreading requests. Authors
                  should visit their personalised homepage frequently to assess
                  the location or stage of the manuscript.
                </p>
              </div>
            </div>
          )}
          {currentTab === "submission" && (
            <div className="w-full flex flex-col items-start justify-start gap-6 text-xs">
              <h1 className="text-[#8a1f05] font-bold text-lg capitalize">
                New Submission
              </h1>
              <div className="w-full  flex flex-col gap-3">
                <p>
                  The manuscripts are uploaded and processed online, so you need
                  to register and login into the journal website. Start the
                  5-step submission process by either:
                </p>
                <ul className="pl-8 flex flex-col gap-2">
                  <li>
                    1. In your My User Home, select New Submission next to
                    Author.
                  </li>
                  <li>
                    2. In your My User Home, select Author, and underneath the
                    heading Start a New Submission select CLICK HERE.
                  </li>
                </ul>
                <p>
                  Make sure you have your cover letter and manuscript body in
                  one document and any supplementary information (e.g.
                  illustrations, legal documents, etc.) in separate documents
                  saved on your computer, ready for upload. Refer to the
                  Submissions Guidelines for more details.
                </p>
              </div>
              <div className=" w-full divide-y-2  flex flex-col items-start justify-start gap-2 ">
                <h2 className="w-full text-[#8a1e04] capitalize ">
                  How to submit a new submission
                </h2>
                <div className="w-full  pt-2 flex flex-col gap-3 ">
                  <p>
                    Each step is on a separate page. The submission process can
                    be interrupted at any time; when you return to the site you
                    can continue from where you left off. You can scroll down
                    and click on the Next button to save your work and advance
                    to the next page:
                  </p>
                  <ul className="w-full pl-8 flex flex-col gap-2 ">
                    <li>
                      {" "}
                      STEP 1: Select the journal section, complete the
                      submission checklist and agree to our copyright and
                      licensing policy. Save and continue.
                    </li>
                    <li>
                      STEP 2: Upload the submission file. Click on the Choose
                      file button and locate the file on your computer. When you
                      have selected the file you wish to upload, click the
                      Upload button. Save and continue.
                    </li>
                    <li>
                      {" "}
                      STEP 3: Insert the manuscript metadata, author(s) details,
                      manuscript title, manuscript abstract and keywords as it
                      is in the manuscript. Save and continue.
                    </li>
                    <li>
                      STEP 4: Upload supplementary files e.g. legal documents
                      and any supplementary information such as large tables and
                      photographs. You can only upload one supplementary file at
                      a time and have a limit of 15MB per single file. Click on
                      the Choose file button and locate the file on your
                      computer. When you have selected the file that you wish to
                      upload, click the Upload button. Repeat the process until
                      all supplementary files have been uploaded. Save and
                      continue.
                    </li>
                    <li>
                      STEP 5: Confirm the files for submission. Select the
                      Finish Submission button. After completing the manuscript
                      submission you will receive a submission confirmation via
                      email. You can also log into the journal website at any
                      time to check the status of your manuscript.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          )}
          {currentTab === "revision" && (
            <div className="w-full flex flex-col items-start justify-start gap-6 text-xs">
              <div className="w-full flex flex-col gap-3">
                <h1 className="text-[#8a1f05] font-bold text-lg capitalize">
                  Revisions
                </h1>
                <p>
                  After formal peer review many manuscripts may be provisionally
                  accepted pending minor revisions, after which authors will
                  submit revised manuscripts. The editor would have gauged the
                  revisions needed as being feasible and capable of being
                  completed within 3 months. Revisions will be requested by the
                  editor through email, with instructions on how to proceed to
                  create and upload a revised submission on the journal website.
                  In the journals’ personalised section your submission will
                  move in the active table from having the status In Review to
                  In Review: Revisions Required.
                </p>
                <p>
                  When you prepare a revised version of your manuscript it is
                  essential that you follow the instructions given in the
                  editor’s letter and the reviewer’s comments (when provided)
                  very carefully.
                </p>
              </div>
              <div className=" w-full divide-y-2  flex flex-col items-start justify-start gap-2 ">
                <h2 className="w-full text-[#8a1e04] capitalize ">
                  Time limits
                </h2>
                <div className="w-full  pt-2 flex flex-col gap-3">
                  <p>Authors must stay within the following time limits:</p>
                  <ol className="w-full pl-8 flex flex-col gap-2 ">
                    <li>
                      Revisions received more than three months after being
                      requested may be sent for another review cycle, at the
                      editor’s discretion.
                    </li>
                    <li>
                      Revisions received more than six months after being
                      requested may be closed and considered withdrawn from
                      consideration.
                    </li>
                  </ol>
                </div>
              </div>
              <div className=" w-full divide-y-2  flex flex-col items-start justify-start gap-2 ">
                <h2 className="w-full text-[#8a1e04] capitalize ">
                  How to submit a revision
                </h2>
                <div className="w-full  pt-2 flex flex-col gap-3 ">
                  <p>Submit your revision by:</p>
                  <ol className="w-full pl-8 flex flex-col gap-2 ">
                    <li>
                      Preparing the required three documents for uploading.
                    </li>
                    <li>Uploading the three documents.</li>
                    <li>
                      Informing the editor via email of your submitted revision.
                    </li>
                  </ol>
                </div>
              </div>
              <div className=" w-full divide-y-2  flex flex-col items-start justify-start gap-2 ">
                <h2 className="w-full text-[#8a1e04] capitalize ">
                  1. Preparing the required three documents for uploading:
                </h2>
                <div className=" w-full  pt-2 flex flex-col gap-3 ">
                  <ol className=" w-full pl-8 flex flex-col gap-2 ">
                    <li>
                      DOCUMENT 1: The feedback summary to the Editor-in-Chief: A
                      complete and concise, point by point response specifying
                      how you have addressed each of the editor’s and reviewer’s
                      comments. Where suggestions have not been followed, you
                      must explain and justify your decision. This should
                      include specific references to sections, pages and
                      paragraph numbers where alterations were made in the text.
                    </li>
                    <li>
                      DOCUMENT 2: Annotated version of the revisions made on the
                      original manuscript: The annotated copy should have
                      highlights of the changes (either by using the track
                      changes function in Microsoft Word or by highlighting or
                      underlining text) with notes in the text referring to the
                      editor’s or reviewer’s query. If references, tables or
                      figures are moved, added or deleted during the revision
                      process, renumber them to reflect such changes so that
                      they continue to be cited in numerical order. Images need
                      only be uploaded if changes have been made to them since
                      the previous version. Revisions with Tracked Changes:
                      Please be sure to blind your edits and comments
                      completely. The following link from Microsoft provides
                      step-by-step directions for blinding your Word document
                      using the track changes feature:
                      https://support.office.com/en-us/article/Change-the-author-name-for-review-comments-cdd4b8ac-fbca-438d-a5b5-a99fb1c750e3
                    </li>
                    <li>
                      DOCUMENT 3: Clean version of the revised manuscript.
                    </li>
                  </ol>
                  <p>
                    Removing Hidden Data and Personal Information by Inspecting
                    Documents: Because hidden information can reveal author
                    identifying information, please be sure to altogether remove
                    all hidden data and personal information in your Word
                    documents before you submit your revised manuscript online.
                  </p>
                </div>
              </div>
              <div className=" w-full divide-y-2  flex flex-col items-start justify-start gap-2 ">
                <h2 className="w-full text-[#8a1e04] capitalize ">
                  2. Uploading the three documents:
                </h2>
                <ol className="w-full pl-8 flex flex-col gap-3 pt-2">
                  <li>
                    STEP 1: When you have addressed the comments and completed
                    your revisions, log into your journal’s personalised section
                    and click on In Review: Revisions Required.
                  </li>
                  <li>
                    STEP 2: Under the Editor Decision, click the bubble icon to
                    view the editor's decision letter. If needed, you may view
                    the original editor and reviewer comments by clicking files
                    linked under the Review Round.
                  </li>
                  <li>
                    STEP 3: Once your revisions are correctly formatted and
                    prepared, click on Browse to begin uploading your revised
                    manuscript from your desktop. Ensure that you upload a clean
                    version, an annotated version and a point by point version
                    as part of your revised manuscript submission.
                  </li>
                </ol>
              </div>
              <div className=" w-full divide-y-2  flex flex-col items-start justify-start gap-2 ">
                <h2 className="w-full text-[#8a1e04] capitalize ">
                  3. Informing the editor via email of your submitted revision:
                </h2>
                <ol className="w-full pl-8 flex flex-col gap-3 pt-2">
                  <li>
                    Once all three documents are uploaded, you will need to
                    inform the editor via email of your submitted revision.
                    Click on the email icon and proceed to type and email the
                    editor.
                  </li>
                </ol>
              </div>
            </div>
          )}
          {currentTab === "resubmission" && (
            <div className="w-full flex flex-col items-start justify-start gap-6 text-xs">
              <div className="w-full flex flex-col items-start justify-start gap-2">
                <h1 className="text-[#8a1f05] font-bold text-lg capitalize">
                  Resubmissions
                </h1>
                <p className="pt-2">
                  Follow the new submission process to submit resubmissions on
                  the journal website. A resubmitted manuscript may fall into
                  one of two categories:
                </p>
              </div>
              <div className=" w-full divide-y-2  flex flex-col items-start justify-start gap-2 ">
                <h2 className="w-full text-[#8a1e04] capitalize ">
                  Invited resubmissions
                </h2>
                <p className=" pt-2">
                  Manuscripts resubmitted in response to encouragement to
                  resubmit following a decision to reject. The author would
                  receive clear guidance on what revisions are required and the
                  conditions under which their manuscript will be reconsidered
                  for possible acceptance. There is thus an agreement framework
                  in place.
                </p>
              </div>
              <div className=" w-full divide-y-2  flex flex-col items-start justify-start gap-2 ">
                <h2 className="w-full text-[#8a1e04] capitalize ">
                  Manuscripts which were previously rejected (which have been
                  resubmitted without this being encouraged or invited)
                </h2>
                <p className=" pt-2">
                  The editor is under no such agreement and may view the
                  manuscript as a completely new submission.
                </p>
              </div>
            </div>
          )}
          {currentTab === "corrections" && (
            <div className="w-full flex flex-col items-start justify-start gap-6 text-xs">
              <h1 className="text-[#8a1f05] font-bold text-lg capitalize">
                Corrections
              </h1>
              <div className="w-full pt-2 flex flex-col gap-3 ">
                <p>
                  Learning Public is committed to preserving the historical
                  accuracy of all its publications. A correction is not intended
                  to replace the original manuscript as it constitutes the
                  final, definitive, and citable version in the scholarly
                  records. If the error was introduced during the publishing
                  process, the Learning Public Publishing Office should be
                  contacted.
                </p>
              </div>
              <div
                className="w-full divide-y-2 flex flex-col items-start justify-start
                gap-2 "
                w-full
              >
                <h2 className="w-full text-[#8a1e04] capitalize ">
                  What to do if you have found an error in your article
                </h2>
                <div className=" w-full  pt-2 flex flex-col gap-3 ">
                  {" "}
                  <p>
                    Authors of the published article must inform Learning Public
                    promptly by submitting a correction online if they become
                    aware of an error needing correcting. If the correction is
                    approved, we will publish its notice and link it to the
                    original article online.
                  </p>
                  <h3>Examples of corrections we would consider including:</h3>
                  <ul className=" w-full pl-8 flex flex-col gap-2 ">
                    <li>Spelling error or factual error in the title</li>
                    <li>Incorrect author list or misspelt name(s)</li>
                    <li>
                      Incorrect, omitted or additional affiliation (only, if
                      this was incorrect at the time of submission)
                    </li>
                    <li>Error in ORCID</li>
                    <li>Error in the Copyright line</li>
                    <li>Error in correspondence details</li>
                    <li>Error in the submitted and accepted dates</li>
                    <li>Error in the article category heading</li>
                    <li>Missing or incorrect citation of the article</li>
                    <li>
                      Correction for missing or misleading text, in an otherwise
                      reliable publication, if the meaning is changed or an
                      error was introduced
                    </li>
                    <li>
                      Error in statistical data that does not alter conclusions
                    </li>
                    <li>
                      Error in medical terms, such as the wrong slide of
                      microscopy provided
                    </li>
                    <li>
                      Amendment to tables, figures, and appendices, if the
                      meaning is changed or an error introduced
                    </li>
                    <li>
                      Figure source, attribution or permission information
                      missing
                    </li>
                    <li>Poor figure quality</li>
                    <li>
                      Missing or incorrect conflict of interest or disclosure
                      statement
                    </li>
                    <li>
                      Omitted or incorrect funding information (e.g. funding
                      number or organization name)
                    </li>
                    <li>Error in online-only supplemental data</li>
                    <li>
                      Incorrect reference, or misspelt author name in the
                      reference list (not additional or updated references or
                      citations)
                    </li>
                    <li>Mislabeled figures or tables.</li>
                  </ul>
                  <p>
                    Although we consider all submitted corrections, we do not
                    publish corrections that would not affect the article in a
                    material way or significantly impair the reader’s
                    understanding of the article.
                  </p>
                </div>
              </div>
              <div
                className=" w-full  divide-y-2 flex flex-col items-start justify-start
                gap-2 "
                w-full
              >
                <h2 className="w-full text-[#8a1e04] capitalize ">
                  How to start the submission process
                </h2>
                <div className=" w-full  pt-2 flex flex-col gap-3 ">
                  <p>
                    The correction is uploaded and processed online, so you need
                    to register and login to the journal website. Start the
                    5-step submission process using one of two methods:
                  </p>
                  <ul className=" w-full pl-8 flex flex-col gap-2 ">
                    <li>
                      In your My User Home, select New Submission next to
                      Author.
                    </li>
                    <li>
                      In your My User Home, select Author, and underneath the
                      heading Start a New Submission, select CLICK HERE.
                    </li>
                  </ul>
                </div>
              </div>
              <div
                className=" w-full  divide-y-2 flex flex-col items-start justify-start
                gap-2 "
                w-full
              >
                <h2 className="w-full text-[#8a1e04] capitalize ">
                  How to submit your correction
                </h2>
                <div className=" w-full  pt-2 flex flex-col gap-3 ">
                  <p>
                    Each step is on a separate page. The submission process can
                    be interrupted at any time; when you return to the site, you
                    can continue from where you left off. You can scroll down
                    and click on the Next button to save your work and advance
                    to the next page:
                  </p>
                  <ol className=" w-full pl-8 flex flex-col gap-2 ">
                    <li>
                      STEP 1: Ensure to select the appropriate journal section,
                      labelled ‘Correction’.
                    </li>
                    <li>
                      STEP 2: Upload the edited correction template and author
                      change request form (if applicable)
                    </li>
                    <li>
                      STEP 3: Insert into the metadata all the authors of the
                      original published article and formulate the title as
                      ‘Corrigendum: Title of original article’.
                    </li>
                    <li>
                      STEP 4: Upload supplementary files (e.g. new table or
                      figure).
                    </li>
                    <li>STEP 5: Confirm files and Finish Submission.</li>
                  </ol>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
