import { BASE_URL } from "services";
import { LOCAL_STORAGE } from "./LOCALSTORAGE";
import authStore from "mobx/AuthStore";
import axios from "axios";
import { handleLogout } from "utils/UserLogout";
import { handleRefreshToken } from "utils/auth";
import toast from "react-hot-toast";

// import authStore from "mobx/AuthStore";
const MAX_RETRIES = 3; // Maximum number of retries
const RETRY_DELAY = 1000; // Retry delay in milliseconds (1 second)
const TIMEOUT_BASE = 40000;
const TIMEOUT_INCREMENT = 10000;

const token = localStorage.getItem("jms-token");

// const [storedValue, setValue, removeValue] = useLocalStorage("jms-token", null);
const jmsApp = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
  timeout: TIMEOUT_BASE
  // headers: {
  //   Authorization: `Bearer ${LOCAL_STORAGE.token()}`
  // }
});

// Request interceptor to add Authorization header
jmsApp.interceptors.request.use((config) => {
  // const { status } = await handleRefreshToken();

  const token = LOCAL_STORAGE.token();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});
function handleShouldRefresh() {
  authStore.setShouldRefresh(true);
}

jmsApp.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    handleShouldRefresh();
    return response;
  },
  async function (error) {
    if (error.message === "Network Error") {
      // toast.error(error.message);
      throw new Error("Network Error");
    }
    const originalResponseConfig = error.config;
    const originalResponse = error.response;
    const originalResponseMessage = originalResponse?.data?.error ?? "";

    if (originalResponse) {
      if (originalResponse.status === 401 && !!originalResponseConfig._retry) {
        originalResponseConfig._retry = true;

        const { status } = await handleRefreshToken();

        if (status) {
          axios.defaults.headers.common["Authorization"] =
            `Bearer ${LOCAL_STORAGE.token()}`;
          return jmsApp(originalResponseConfig);
        } else {
          handleLogout();
        }
      }
      if (
        originalResponse.status === 401 &&
        originalResponseMessage ===
          "You need to login first in order to have access."
      ) {
        handleLogout();
      }
      if (
        originalResponse.status === 500 &&
        originalResponseMessage === "invalid signature"
      ) {
        handleLogout();
      }

      if (
        originalResponse.status === 403 &&
        originalResponseMessage === "Token has expired"
      ) {
        originalResponseConfig._retry = true;

        const { status } = await handleRefreshToken();

        if (status) {
          axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
          return jmsApp(originalResponseConfig);
        } else {
          handleLogout();
        }
      }
      if (error.code === "ECONNABORTED" && error.message === "timeout") {
        return retryRequest(originalResponseConfig);
      }
    }

    //     // Any status codes that falls outside the range of 2xx cause this function to trigger
    //     // Do something with response error
    return Promise.reject(error);
  }
);
async function retryRequest(config, retryCount = 0) {
  if (retryCount >= MAX_RETRIES) {
    // Maximum retries reached, return error
    toast.error("Request timeout");
    return Promise.reject(new Error("Maximum retries exceeded"));
  }

  // Calculate the timeout duration for this retry attempt
  const timeout = TIMEOUT_BASE + retryCount * TIMEOUT_INCREMENT;

  // Set the timeout in the request configuration
  const retryConfig = { ...config, timeout };

  // Delay before retrying
  await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY));

  try {
    // Retry the request with the updated configuration
    const response = await jmsApp(retryConfig);
    return response;
  } catch (error) {
    // Retry failed, try again
    return retryRequest(config, retryCount + 1);
  }
}

export default jmsApp;
