import * as Yup from "yup";

import {
  AuthCard,
  AuthContent,
  Authcontainer,
  CreateAccountButton,
  LoginContainer,
  LoginDiv,
  LoginFormContainer,
  VerifyExpires,
  VerifyResendBtn
} from "../style";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import { AUTH_ENDPOINTS } from "api/ACTION";
import { AppLogo } from "utils/assets";
import { AuthHeader } from "..";
import { ClipLoader } from "react-spinners";
import { Img } from "globalStyles";
import { LOCAL_STORAGE } from "api/LOCALSTORAGE";
import { LOGIN_ROUTE } from "routes";
import TextInput from "components/TextInput";
import authStore from "mobx/AuthStore";
import bg from "../../../assets/images/banner2.jpg";
import jmsApp from "api/jms";
import { observer } from "mobx-react-lite";
import { toast } from "react-hot-toast";
import { useFormik } from "formik";

// import { resendOtpEnd, verifyOtpEmail } from "services/AuthService";

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "white"
};

const VerifyOtp = observer(() => {
  const [loading, setLoading] = useState(false);
  const [countdown, setCountdown] = useState(420); // 7 minutes in seconds
  const [resendDisabled, setResendDisabled] = useState(false);

  const navigate = useNavigate();
  const formik = useFormik({
    enableReinitialize: false,
    initialValues: {
      otp: ""
    },
    onSubmit: async (info, helpers) => {
      authStore.loading();
      setLoading(true);
      authStore.getInitialAuth();
      try {
        const { data } = await jmsApp.post(AUTH_ENDPOINTS.VERIFY_OTP(), info);

        setLoading(false);
        if (data.success) {
          authStore.loading();
          setLoading(false);

          authStore.setCurrentStep("otp");
          helpers.resetForm();
          toast.success(data.msg);
          localStorage.clear();
          navigate(`${LOGIN_ROUTE}`);
        }
      } catch (err) {
        setLoading(false);
        if (err.response.data) {
          if (err.response.data.error.toLowerCase() === "token not found") {
            //route to registration page
            toast.error(`${err.response.data.error}, please register again`);
          } else {
            toast.error(err.response.data.error);
          }
        }
      }
    },
    validationSchema: Yup.object({
      otp: Yup.string().min(4).max(4).required().label("Token")
    })
  });

  const handleResend = async () => {
    setCountdown(420); // Reset the countdown when resending OTP
    setResendDisabled(false); // Enable the resend button
    const email = LOCAL_STORAGE.tempAccountMail();
    // const email = JSON.parse(localStorage.getItem("tempAccountEmail"));
    try {
      authStore.getUserEmail();
      const { data } = await jmsApp.post(
        `/auth/send-otp`,
        { email: email },
        { withCredentials: true }
      );

      if (data.success) {
        authStore.loading();
        authStore.setInitialAuth(data.token);

        authStore.setCurrentStep("re-verify");
        toast.success(`OTP sent successfully to ${email}`);
      }
    } catch (err) {
      if (err.response.data) {
        toast.error(err.response.data.error);
      }
    }
  };
  useEffect(() => {
    let intervalId;

    // Start the countdown when the component mounts
    if (countdown > 0) {
      intervalId = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    } else {
      // If countdown reaches 0, disable the resend button
      setResendDisabled(true);
    }

    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [countdown]);

  return (
    <Authcontainer style={{ backgroundImage: `url(${bg})` }}>
      <AuthContent>
        <Link to="/">
          <Img src={AppLogo} alt="LPJ" />
        </Link>
        <LoginContainer>
          <LoginDiv onSubmit={formik.handleSubmit}>
            <AuthCard>
              <LoginFormContainer>
                <AuthHeader text="Verfiy Email Address" />
                <TextInput
                  label="One Time Password OTP"
                  name="otp"
                  id="otp"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.otp}
                  error={formik.errors.otp}
                  visible={formik.touched.otp}
                  placeholder="****"
                  mt="0.5rem"
                />
                <VerifyExpires>
                  <span className=" mr-3">OTP expires in</span>
                  <span className=" font-bold capitalize">
                    {Math.floor(countdown / 60)} minutes{" "}
                    {`${countdown % 60}`.length > 1
                      ? countdown % 60
                      : `0${countdown % 60}`}{" "}
                    seconds
                  </span>
                </VerifyExpires>
                <VerifyResendBtn
                  className=" cursor-pointer border border-solid border-transparent hover:border-b-current transition-all duration-300 ease-in-out"
                  disabled={authStore.resendingOtp || resendDisabled}
                  type="button"
                  onClick={() => handleResend()}
                >
                  {authStore.resendingOtp ? "Resending Otp..." : "Resend OTP"}
                </VerifyResendBtn>
                <CreateAccountButton
                  type="submit"
                  disabled={loading || countdown === 0}
                  className="bg-black/50"
                >
                  {loading ? (
                    <ClipLoader
                      color="#46555C"
                      loading={loading}
                      cssOverride={override}
                      size={30}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                    />
                  ) : (
                    "Continue"
                  )}
                  {/* {authStore.isLoading ? 'Loading...' : 'Continue'} */}
                </CreateAccountButton>
              </LoginFormContainer>
            </AuthCard>
          </LoginDiv>
        </LoginContainer>
      </AuthContent>
    </Authcontainer>
  );
});

export default VerifyOtp;
