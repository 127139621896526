import {
  Modal,
  Table,
  TableData,
  TableHeader
} from "components/Admin/course/style";
import React, { useState } from "react";

import { AiOutlineClose } from "react-icons/ai";
import Checkbox from "components/Admin/sharedComponents/CheckBox";
import NoCoursesPage from "components/ELearn/NoCoursePg";

const ShowAddCourses = ({ data, onClose, getCoursesId, selCoursesId }) => {
  // const [checked, setChecked] = useState(Array(data.length).fill(false));
  const [coursesId, setCoursesId] = useState([...selCoursesId]);

  // const addChecks = (id) => {
  //   const val = checked[id];
  //   checked.splice(id, 1, !val);
  //   setChecked([...checked]);
  // };

  const addCourse = (courseId) => {
    const isCourseAdded =
      coursesId.filter((course) => course === courseId).length > 0;

    if (isCourseAdded) {
      const newCourses = coursesId.filter((course) => course !== courseId);

      setCoursesId([...newCourses]);
    } else {
      setCoursesId((courses) => [...courses, courseId]);
    }
  };

  return (
    <Modal>
      <div className="container">
        <div className="relative flex items-center justify-center w-full">
          {/* <div className="header"> */}
          <h4 className="capitalize font-bold text-[#333333] text-[24px] ">
            Select resources
          </h4>
          <span className=" absolute top-0 right-0 transform -translate-1/2 mx-6 cursor-pointer transition-colors hover:bg-black/50 duration-300 hover:text-white p-2 rounded-full ">
            <AiOutlineClose className="text-[#333333]" onClick={onClose} />
          </span>
        </div>
        <div className="courses min-w-full overflow-x-auto ">
          {data.length !== 0 ? (
            <div className="w-full py-2">
              <Table>
                <thead>
                  <tr>
                    <TableHeader></TableHeader>
                    <TableHeader>Category</TableHeader>
                    <TableHeader>Title</TableHeader>
                    <TableHeader>Type</TableHeader>
                  </tr>
                </thead>
                <tbody>
                  {data?.map((item, id) => (
                    <tr key={item.id} id={item.id}>
                      <TableData>
                        <Checkbox
                          onClick={() => {
                            // addChecks(id);
                            addCourse(item.courseId);
                          }}
                          // checked={checked[id]}
                          checked={coursesId.find(
                            (course) => course === item.courseId
                          )}
                        />
                      </TableData>
                      <TableData>{item.category}</TableData>
                      <TableData>{item.title}</TableData>
                      <TableData>{item.type}</TableData>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <div className=" w-full  flex items-center justify-center">
                <button
                  type="button"
                  className="btn"
                  onClick={() => {
                    getCoursesId(coursesId);
                    onClose();
                  }}
                >
                  Add Resources
                </button>
              </div>
            </div>
          ) : (
            <NoCoursesPage />
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ShowAddCourses;
