import { CheckoutCont } from "./payment.style";
import {  useContext } from "react";
// import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";
import { BuyCourse } from "components/context/epaymentContext";
import {  Course } from "utils/assets";


function Checkout() {
  const { item } = useContext(BuyCourse);

 

  

  
  return (
    <CheckoutCont>
      <div className='container'>
        <h4>Summary</h4>
        <div className='about'>
          <img src={Course} alt='' />
          <div className='details'>
            <h6>Thank you For choosing this Package!</h6>
            <p>{item.plan}</p>
            <span>₦{item.price}</span>
          </div>
        </div>
        <div className='form'>
          <div>
            <h4>Subtotal</h4>
            <span>₦{item.price}</span>
          </div>
          <div>
            <h4>Coupon Discount</h4>
            <span>0</span>
          </div>
          <div className='total'>
            <h4>Total</h4>
            <span>₦{item.price}</span>
          </div>

          <button
         
          >
            Pay
          </button>
        </div>
      </div>
    </CheckoutCont>
  );
}

export default Checkout;
