import Layout from "components/layout/Layout";
import Checkout from "components/payment/checkout";
import React from "react";

function CheckoutPg() {
  return (
    <Layout>
      <Checkout />
    </Layout>
  );
}

export default CheckoutPg;
