import { AppLink, Img } from "globalStyles";
import React, { useState } from "react";
import { ABOUT_ROUTE, HOME_ROUTE, SUBMIT_ROUTE } from "routes";
import { AppLogo, HamburgerIcon } from "utils/assets";

import { useLocation } from "react-router-dom";
import MobileMenu from "./ArticleViewMobile";
import {
  HamBurgerBtn,
  NavCon,
  NavImgCon,
  NavLinkCon,
  NavLinkList
} from "components/Navbar/style";

function NavBar() {
  const [journalList, setJournalList] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [activeNav, setActiveNav] = useState(1);
  const [open, setOpen] = useState(false);

  const toggleNav = () => {
    setOpen(!open);
  };

  const journalHandler = () => {
    setJournalList(!journalList);
  };
  const toggleActiveNav = (index) => {
    setActiveNav(index);
  };

  const location = useLocation();
  const { pathname } = location;
  const path = pathname.slice(0);

  return (
    <>
      {open && <MobileMenu isOpen={open} close={() => toggleNav()} />}
      <NavCon>
        <HamBurgerBtn onClick={() => toggleNav()}>
          <Img src={HamburgerIcon} alt="Menu" />
        </HamBurgerBtn>

        <AppLink to={HOME_ROUTE}>
          <NavImgCon>
            <Img src={AppLogo} alt="LP Logo" />
          </NavImgCon>
        </AppLink>

        <NavLinkCon>
          <NavLinkList>
            <li>
              <AppLink
                className={path === HOME_ROUTE ? "active__nav" : ""}
                onClick={() => {
                  toggleActiveNav(1);
                }}
                to={HOME_ROUTE}
              >
                Home
              </AppLink>
            </li>
            <li className="journal_li">
              <AppLink
                className={path === "" ? "active__nav" : ""}
                onClick={() => {
                  toggleActiveNav(2);
                  journalHandler();
                }}
                to={ABOUT_ROUTE}
              >
                About
              </AppLink>
            </li>
            <li>
              <AppLink
                className={`${
                  path === HOME_ROUTE ? "active__nav" : ""
                } whitespace-nowrap`}
                onClick={() => {
                  toggleActiveNav(3);
                }}
                to={SUBMIT_ROUTE}
              >
                Submit Article
              </AppLink>
            </li>
          </NavLinkList>
        </NavLinkCon>
      </NavCon>
    </>
  );
}

export default NavBar;
