import { PAYMENT, USER_PLAN } from "api/ACTION";
import React, { Fragment, useEffect } from "react";

import { ClipLoader } from "react-spinners";
import PaymentCard from "./PaymentCard";
import { PaymentCont } from "./payment.style";
import jmsApp from "api/jms";
import { override } from "utils/admin-roles";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

function PaymentComp() {
  const [plan, setPlan] = useState([]);
  const [gettingPlan, setGettingPlan] = useState(false);
  const navigate = useNavigate();

  const handleData = async () => {
    setGettingPlan(true);
    try {
      const { data } = await jmsApp.get(USER_PLAN.GET_PLAN());
      if (data.success) {
        const newPlan = data.plans.filter((x) => x.type !== "free");
        setPlan(newPlan);
      }
    } catch (error) {
      if (error.response?.status === "401") {
        navigate("/login");
      } else return new Error(error);
    } finally {
      setGettingPlan(false);
    }
  };

  function handleRedirect(url) {
    window.location.href = url;
  }
  const checkPlanStatus = async (id) => {
    try {
      const { data: userPlans } = await toast.promise(
        jmsApp.get(USER_PLAN.GET_USER_PLANS()),
        {
          loading: "Please wait while we verify course availability..."
        },
        {
          id: "check-course",
          className: "text-sm"
        }
      );

      const hasCourse = userPlans.plans.map((plan) => plan.planId);

      if (hasCourse) return true.find((plan) => plan === id);
    } catch (err) {
      return false;
    }
  };
  const handler = async (e) => {
    try {
      const planId = e.currentTarget.id;

      const hasCourse = await checkPlanStatus();
      if (hasCourse) {
        toast.success(
          "You already purchased this course, redirecting in 5 seconds...",
          {
            className: "tex-sm text-center"
          }
        );
        setTimeout(() => {
          navigate("/courses");
        }, 5000);
      } else {
        toast.dismiss("check-course");
        const { data } = await toast.promise(
          jmsApp.post(PAYMENT.BUY_PLAN(), {
            planId
          }),
          {
            loading: "Getting course...",
            success: "You are being redirected to the payment page...",
            error: (err) => err.response.data.error || "Purchase failed."
          },
          {
            className: "text-sm"
          }
        );
        if (data.status) {
          handleRedirect(data.data.authorization_url);
        }
      }
    } catch (error) {
      // if (error.response) toast.error(error.response.data.error);
    }
  };

  useEffect(() => {
    handleData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PaymentCont>
      <div className="sub">
        <h4>We create a monthly pricing package for all standard students</h4>
        <p>
          Basically we create this package for those who are really interested
          and get benifited from our courses or books. We want to make a low
          cost package for them. So that they can purchase any courses with the
          package they buy from us. Also will get free books from every
          packages.
        </p>
      </div>
      {gettingPlan ? (
        <ClipLoader
          color="#46555C"
          loading={gettingPlan}
          cssOverride={override}
          size={30}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      ) : (
        <div className="paymentOffers">
          {plan?.map((option, i) => {
            if (option.type !== "FREE")
              return (
                <div
                  // className={`plan ${active === option.paymentPlan ? "active" : ""}`}
                  key={option.planId}
                >
                  <PaymentCard
                    plan={option}
                    major={option.major}
                    id={option.planId}
                    key={option.planId}
                    planId={option.planId}
                    price={option.price}
                    handler={handler}
                    type={option.type}
                  />
                </div>
              );

            return <Fragment key={i}></Fragment>;
          })}
        </div>
      )}
    </PaymentCont>
  );
}

export default PaymentComp;
