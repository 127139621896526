import React from "react";
import { notfound } from "utils/assets";
import { useNavigate } from "react-router-dom";

const styles = {
  main: {
    background: "#e5e5e5",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100vh",
  },
  subText: {
    marginTop: "22px",
  },
  btns: {
    marginTop: "15px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "20px",
    cursor: 'pointer',
  },
  btn: {
    background: "gray",
    color: "white",
    padding: "10px 15px",
    borderRadius: "11px",
    cursor: 'pointer',
  },
};

function PageNotFound() {
  const history = useNavigate();
  return (
    <div style={styles.main}>
      <img src={notfound} alt='' width='400px' />
      <p style={styles.subText}>
        Looks like the page you're looking for does not exist
      </p>
      <div style={styles.btns}>
        <button onClick={() => history(-1)} style={styles.btn}>
          Go Back
        </button>
        <button style={styles.btn} onClick={() => history("/")}>
          Go Home
        </button>
      </div>
    </div>
  );
}

export default PageNotFound;
