import Colors from "utils/colors";
import media from "utils/media";
import styled from "styled-components";

export const Modal = styled.div`
  background: #33333390;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  top: 0;
  left: 0;
  min-height: 100vh;

  overflow-y: auto;
  z-index: 1000;
  padding: 4rem 0;

  .planDuration {
    display: flex;
    align-items: center;
    gap: 50px;
  }

  .btn {
    width: 150px;
    color: ${Colors.primary};
    border: 1px solid ${Colors.primary};
    background: transparent;
    border-radius: 6px;
    display: flex;
    margin: 10px;
    justify-content: center;
    align-items: center;
    padding: 10px 12px;
    font-size: 12px;
    font-weight: 600;
    gap: 6px;

    :hover {
      background: ${Colors.primary};
      transition: background 0.2s ease-in-out;
      border: 1px solid ${Colors.primary};
      color: white;
    }
  }

  .table {
    margin: 20px;
  }

  .container {
    background: white;
    border-radius: 16px;
    max-width: 600px;
    width: 95%;
    display: flex;
    margin: auto;
    flex-direction: column;
    padding: 25px 20px;
    justify-content: center;
    align-items: center;
    /* ${media.mobile`

border-radius:unset;
display: flex;
  justify-content: center;
  ${"" /* align-items: center; */}
	`}; */

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding-top: 10px;
      padding-bottom: 14px;
      cursor: pointer;

      span {
        font-weight: 700;
        font-size: 26px;
      }
    }
  }
`;

export const StaffCont = styled.div`
  background: #e5e5e5;
  width: 100%;
  height: 100%;
  padding: 30px 40px;

  .heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    ${media.mobile`
      flex-direction:column;
      gap:20px;
    justify-content: flex-start;
    align-items: flex-start;
	`};
    button {
      width: 180px;
      color: white;
      background: ${Colors.primary};
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 12px;
      font-size: 16px;
      font-weight: 600;
      border: 1px solid transparent;
      gap: 6px;
      cursor: pointer;
      transition: background 0.2s ease-in-out;

      :hover {
        background: transparent;
        color: ${Colors.primary};
        border-color: ${Colors.primary};
      }
    }
  }
  .search {
    margin: 20px 0;
  }
  .admins {
    overflow: auto;
    margin-top: 25px;
  }
`;
export const VerifyCont = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  background: #e5e5e5;
  flex-direction: column;
  h1 {
    font-size: 20px;
  }
`;

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
`;

export const TableHeader = styled.th`
  border: 1px solid #ddd;
  padding: 8px;
`;

export const TableData = styled.td`
  border: 1px solid #ddd;
  text-overflow: ellipsis;
  padding: 8px 4px;
`;

export const ActionButton = styled.button`
  background-color: #4caf50;
  border: none;
  color: white;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
`;
export const ActionButtonDel = styled.button`
  background-color: red;
  border: none;
  color: white;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
`;
