import PaymentComp from "components/payment/PaymentComp";
import Layout from "components/layout/Layout";

function PaymentPg() {
  return (
    <Layout>
      <PaymentComp />
    </Layout>
  );
}

export default PaymentPg;
