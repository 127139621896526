import React, { useEffect, useRef, useState } from "react";

import { ADMIN_ROLES } from "utils/admin-roles";
import { ARTICLE_ENDPOINT } from "api/ACTION";
import { AuthenticateUser } from "api/CheckUser";
import { CONSTANT } from "utils/validation";
import { DOWNLOAD_ENDPOINT } from "api/ACTION";
import { IoClose } from "react-icons/io5";
import { LOCAL_STORAGE } from "api/LOCALSTORAGE";
import { TextArea } from "components/TextArea/style";
import { articleData } from "../sharedComponents/articleData";
import jmsApp from "api/jms";
import styled from "styled-components";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

// Styled component for the upload button
const UploadButton = styled.label`
  display: inline-block;
  padding: 10px 20px;
  /* background-color: #e5e5e5; */
  background-color: ${(props) =>
    props.isUploadComplete ? "green" : "#46555C"};
  transition: background-color 0.3s ease-in-out;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 4px;
  border-radius: 10%;

  /* cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")}; */
  &:hover {
    background-color: ${(props) =>
      props.isUploadComplete ? "green" : "darkblue"};
  }
  &:disabled {
    cursor: not-allowed;
    /* pointer-events: none; */
  }
`;

const Button = styled.button`
  padding: 10px 20px;
  background-color: ${(props) =>
    props.isSubmitComplete ? "green" : "blue"} !important;
  color: white;
  font-size: 16px;
  margin: 4px;
  border: none;
  cursor: pointer;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
  box-shadow: none;

  &:hover {
    background-color: ${(props) =>
      props.isSubmitComplete ? "green" : "darkblue"};
    box-shadow: 2px 2px 2px 2px black;
    color: white;
  }
  &:active {
    box-shadow: none;
  }
  &:disabled {
    cursor: not-allowed;
    /* pointer-events: none; */
  }
`;

const UploadInput = styled.input`
  display: none;
`;

const UploadFileComp = ({ isDownloaded, refreshData }) => {
  const fileInputRef = useRef(null);
  const currentArticle = LOCAL_STORAGE.articleId();
  // const currentArticle = localStorage.getItem("articleId");
  const userRole = LOCAL_STORAGE.user()?.type ?? "";
  const [rows, setRows] = useState(2);

  const [isActive, setIsActive] = useState(false);
  const [commentActive, setCommentActive] = useState(false);

  const [isSubmitComplete, setIsSubmitComplete] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [active, setActive] = useState(false);
  const [journalFile, setJournalFile] = useState();
  const [isUploadComplete, setIsUploadComplete] = useState(false);
  const [editorComment, setEditorComment] = useState("");

  const [submitting, setSubmitting] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [values, setValues] = useState({
    fileData: "",
    format: ".pdf"
  });
  useEffect(() => {
    async function checkArticle(articleId) {
      try {
        await jmsApp.get(DOWNLOAD_ENDPOINT.GET_DOWNLOADED_ARTICLE_BY_ID(), {
          params: {
            articleId
          }
        });
        setIsActive(true);
      } catch (err) {
        // console.log("Article check error : ");
      }
    }
    if (currentArticle !== null) {
      checkArticle(currentArticle);
    }
  }, [currentArticle]);
  const handleUpload = (e) => {
    // Handle file upload logic here
    // console.log(e.target.files[0], " : is the file to be uploaded");
    loadFile(e.target.files[0]);
    setIsUploadComplete(true);
  };

  const loadFile = (file) => {
    if (file) {
      const type = file.type;
      // setActive(true)
      if (!CONSTANT.FILE_TYPE.includes(type)) {
        toast.error("Invalid file format");
      } else toast.success("file upload complete");
      values.format = file.type;
      // read the file
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setJournalFile(reader.result);
      };
      setIsUploadComplete(false);
      setActive(false);
    }
  };

  const navigate = useNavigate();

  const uploadFile = (base64EncodedImage) => {
    values.fileData = base64EncodedImage;
  };

  const openFilePicker = async () => {
    const articleId = LOCAL_STORAGE.articleId();
    if (!articleId) {
      toast.error("You need to select an article first", {
        className: "text-[12px]",
        duration: 2000
      });
      return;
    }
    const loadingToast = toast.loading("verifying article download status");
    const isVerified = await verifyDownloads(articleId);
    if (!isVerified && userRole !== ADMIN_ROLES.SUPER_ADMIN) {
      toast.dismiss(loadingToast);
      toast.error("You need to download the file first before uploading", {
        className: "text-[12px]",
        duration: 2000
      });
      return;
    }
    toast.dismiss(loadingToast);
    toast.success("Please proceed to upload your file.");
    fileInputRef.current.click();

    // if (
    //   userRole !== ADMIN_ROLES.SUPER_ADMIN &&
    //   (!isActive || isDownloaded === currentArticle)
    // ) {
    //   toast.error("You need to download the file first before uploading", {
    //     className: "text-[12px]",
    //     duration: 2000
    //   });
    //   return;
    // }
    // fileInputRef.current.click();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    try {
      const articleId = LOCAL_STORAGE.articleId();

      await verifyDownloads(articleId);

      if (journalFile) {
        //because the file required check if the use selected a file
        uploadFile(journalFile); // set the file data in the state varible
      } else if (!journalFile) {
        return toast.error("select Journal file");
      }
      setActive(true);

      const { data } = await toast.promise(
        jmsApp.patch(ARTICLE_ENDPOINT.REUPLOAD_ARTICLE(), {
          articleId,
          ...values,
          comment: editorComment
        }),
        {
          loading: "Submitting...",
          success: "Submission successful",
          error: (err) =>
            ` ${(err.response.data.error || err.mssage).toString()}`
        }
      );

      setActive(false);

      if (data.success) {
        // toast.success("Upload Successful");
        refreshData();
        setIsSubmitComplete(true);
        setIsUploadComplete(false);
        localStorage.setItem("articleId", JSON.stringify(null));
        setCommentActive(false);
      }
      setIsSubmitComplete(false);

      setIsUploadComplete(false);
    } catch (error) {
      setActive(false);
      setIsUploadComplete(false);

      if (error.response) {
        // toast.error(error.response.data.error);
        setActive(false);
        setIsUploadComplete(false);
        if (error.response?.status === 401) {
          navigate("/login");
        }
      } else {
        // toast.error(error.mssage);
      }
    } finally {
      setSubmitting(false);
    }
  };

  const verifyDownloads = async (articleId) => {
    // if (!articleId) {
    //   return false;
    // }
    try {
      const { data } = await jmsApp.get(
        DOWNLOAD_ENDPOINT.GET_DOWNLOADED_ARTICLE_BY_ID(),
        {
          params: {
            articleId
          }
        }
      );
      if (data.success) {
        return true;
      }
    } catch (error) {
      if (error) return false;
    }
  };

  // function handleEditorProceed() {
  //
  // }
  useEffect(() => {
    AuthenticateUser();
  }, []);

  return (
    <div>
      <form
        onSubmit={handleSubmit}
        className=" flex items-end justify-end w-full sm:space-x-6 py-4"
      >
        <UploadButton
          // className={`${
          //   isActive ? "" : "!cursor-not-allowed pointer-events-none"
          // } `}
          // disabled={!isActive}
          onClick={openFilePicker}
          isUploadComplete={isUploadComplete}
        >
          {isUploadComplete ? "Upload Complete!" : "Upload File"}
        </UploadButton>
        <UploadInput type="file" ref={fileInputRef} onChange={handleUpload} />

        {/* <Button
          type="button"
          onClick={() => setCommentActive(true)}
          disabled={!isUploadComplete}
        >
          proceed
        </Button> */}
        {userRole === ADMIN_ROLES.EDITOR ? (
          <Button
            type="button"
            onClick={() => {
              if (!isUploadComplete) {
                toast.error(
                  "You need to upload the edited article in order to proceed.",
                  {
                    className: "text-sm",
                    duration: 2000
                  }
                );
                return;
              }
              setCommentActive(true);
            }}
            // disabled={!isUploadComplete}
          >
            proceed
          </Button>
        ) : (
          <Button
            className=" disabled:!cursor-not-allowed"
            type="submit"
            isSubmitComplete={isSubmitComplete}
            disabled={!isUploadComplete || submitting}
          >
            {isSubmitComplete ? "Submit Complete!" : "Submit"}
          </Button>
        )}
        {commentActive && (
          <div className=" fixed top-0 left-0 backdrop-blur-sm flex items-center justify-center h-screen w-full shadow-2xl bg-slate-400 bg-opacity-15">
            <div className="relative w-4/5 sm:w-3/5 flex flex-col bg-white rounded-lg p-4 space-y-4 ">
              <button
                className="  absolute top-0 right-0 sm:mr-4 sm:mt-4 p-2 rounded-full text-black hover:bg-black hover:text-white cursor-pointer "
                onClick={() => setCommentActive(false)}
              >
                <IoClose />
              </button>

              <h1 className=" font-bold text-sm xl:text-2xl uppercase text-center">
                Add a comment on the article
              </h1>
              <div className=" w-4/5 mx-auto">
                <TextArea
                  name="description"
                  type="text"
                  placeholder="Write a brief review for the article..."
                  onChange={(e) => setEditorComment(e.target.value)}
                  rows={rows}
                  onClick={() => setRows((val) => (val === 5 ? 2 : 5))}
                  onBlur={() => {
                    if (rows === 2) {
                      return;
                    }
                    setRows(2);
                  }}
                  required={true}
                />
              </div>
              <div
                className={`w-4/5 mx-auto flex items-end justify-end ${
                  editorComment === "" && "cursor-not-allowed"
                } `}
              >
                <Button
                  className=" disabled:!cursor-not-allowed !py-[5px] !px-[10px] xxs:!py-[10px] xxs:!px-[20px]"
                  type="submit"
                  isSubmitComplete={isSubmitComplete}
                  disabled={submitting}
                >
                  {isSubmitComplete ? "Submit Complete!" : "Submit"}
                </Button>
              </div>
            </div>
          </div>
        )}
      </form>
    </div>
  );
};

export default UploadFileComp;
