import { useLocation, useNavigate } from "react-router-dom";

import { AuthButton } from "components/Auth";
import { PASSWORD_RECOVERY_ENDPOINT } from "api/ACTION";
import { RESET_PASSWORD_ROUTE } from "routes";
import { VerifyCont } from "./style";
import jmsApp from "api/jms";
import { toast } from "react-hot-toast";
import { useState } from "react";

function VerifyRecovery() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const search = useLocation().search;
  const id = new URLSearchParams(search).get("id");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const { data } = await jmsApp.get(
        PASSWORD_RECOVERY_ENDPOINT.VERIFY_PASSWORD(),
        {
          params: { id: id }
        }
      );
      setLoading(false);
      if (data.success) {
        localStorage.setItem("recoveryID", JSON.stringify(id));
        toast.success(data.msg);
        navigate(RESET_PASSWORD_ROUTE);
      }
    } catch (err) {
      setLoading(false);
      if (err.response) {
        // toast.error(err.response.data.error);
      }
    }
  };

  return (
    <VerifyCont>
      <h1>Please verify you received a mail to change your password.</h1>
      <form onSubmit={handleSubmit}>
        <AuthButton title="Verify" loading={loading} />
      </form>
    </VerifyCont>
  );
}

export default VerifyRecovery;
