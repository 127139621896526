import * as Yup from "yup";

import { AuthBottomLink, AuthButton, AuthHeader } from "components/Auth";
import {
  AuthCard,
  AuthContent,
  Authcontainer,
  EmailCreateText,
  LoginContainer,
  LoginDiv,
  LoginFormContainer
} from "./style";
import React, { useState } from "react";

import { ADMIN_ENDPOINTS } from "api/ACTION";
import { AppLogo } from "utils/assets";
import { Img } from "globalStyles";
import { LOCAL_STORAGE } from "api/LOCALSTORAGE";
import { LOGIN_ROUTE } from "routes";
import { Link } from "react-router-dom";
import TextInput from "components/TextInput";
import authStore from "mobx/AuthStore";
import jmsApp from "api/jms";
import toast from "react-hot-toast";
import { useFormik } from "formik";
import { useNavigate } from "react-router";

function RegisterAdmin() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const id = LOCAL_STORAGE.verifyId();
  const formik = useFormik({
    enableReinitialize: false,
    initialValues: {
      name: "",
      username: "",
      email: "",
      password: "",
      phone: ""
    },

    onSubmit: async (info, helpers) => {
      setLoading(true);

      try {
        const { data } = await toast.promise(
          jmsApp.post(
            ADMIN_ENDPOINTS.REGISTER_ADMIN(),
            { ...info },
            { params: { id: id } }
          ),
          {
            loading: "Please wait while we get you registered...",
            success: "You have been successfully registered",
            error: (err) =>
              `${!String(err.response?.data.error).toLowerCase().includes("id") ? err.response?.data.error : "Error logging in, please try again later."}`
          },
          {
            duration: 2000,
            className: "text-sm"
          }
        );

        if (data.success) {
          setLoading(false);

          authStore.loading();
          // authStore.setCurrentStep("verify");
          // authStore.setUserEmail(data.tempAccount.email);
          // localStorage.setItem(
          //   'tempAccountEmail',
          //   JSON.stringify(data.tempAccount.email)
          // )
          // authStore.setInitialAuth(data.authorization);
          helpers.resetForm();
          navigate(`${LOGIN_ROUTE}`); //push this let me merge
        }
      } catch (err) {
        setLoading(false);
        // if (err.response.data) {
        //   toast.error(err.response.data.error);
        // }
      }
    },

    validationSchema: Yup.object({
      name: Yup.string().max(50).required().label("Full Name"),
      username: Yup.string().max(50).required().label("Username"),
      email: Yup.string().max(255).email().required().label("Email"),
      phone: Yup.string().max(15).required().label("Phone"),
      password: Yup.string()
        .min(8)
        .required("Password is required.")
        .label("Password")
    })
  });
  return (
    <Authcontainer>
      <AuthContent>
        <Link to="/">
          <Img src={AppLogo} alt="LP" />
        </Link>
        <LoginContainer>
          <LoginDiv onSubmit={formik.handleSubmit}>
            <AuthCard>
              <LoginFormContainer>
                <AuthHeader text="Create  Account" />
                <EmailCreateText>
                  {" "}
                  We will send a one time password to this email adress{" "}
                </EmailCreateText>
                <TextInput
                  label="Full Name"
                  name="name"
                  id="name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                  error={formik.errors.name}
                  placeholder="Enter your full name"
                />
                <TextInput
                  label="Username"
                  name="username"
                  id="username"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.username}
                  error={formik.errors.username}
                  placeholder="Provide a username"
                  mt="0.5rem"
                />
                <TextInput
                  label="Email Address"
                  name="email"
                  type="email"
                  id="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  error={formik.errors.email}
                  placeholder="Email Address"
                  mt="0.5rem"
                />
                <TextInput
                  label="Phone Number"
                  name="phone"
                  type="tel"
                  id="phone"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.phone}
                  error={formik.errors.phone}
                  placeholder="Phone Number"
                  mt="0.5rem"
                />
                <TextInput
                  label="Password"
                  name="password"
                  type="password"
                  id="password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  error={formik.errors.password}
                  placeholder="Password"
                  mt="0.5rem"
                />
                <AuthButton
                  type="submit"
                  disabled={authStore.isLoading ? true : false}
                  title={authStore.isLoading ? "Loading..." : "Submit"}
                  loading={loading}
                />

                <AuthBottomLink link={LOGIN_ROUTE} />
              </LoginFormContainer>
            </AuthCard>
          </LoginDiv>
        </LoginContainer>
      </AuthContent>
    </Authcontainer>
  );
}

export default RegisterAdmin;
