import { useCallback, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { ADMIN_ENDPOINTS } from "api/ACTION";
import { ADMIN_SIGNUP_ROUTE } from "routes";
import { AuthButton } from "components/Auth";
import { ClipLoader } from "react-spinners";
import { VerifyCont } from "./style";
import jmsApp from "api/jms";
import { toast } from "react-hot-toast";

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "white"
};

function VerifyInvite() {
  const navigate = useNavigate();
  const { id } = useParams();
  const idSlice = id.split("=")[1];

  const verifyInvite = useCallback(async () => {
    try {
      const { data } = await jmsApp.post(ADMIN_ENDPOINTS.VERIFY_INVITE(), {
        id: idSlice
      });

      if (data.success) {
        localStorage.setItem("verifyId", JSON.stringify(idSlice));
        navigate(`${ADMIN_SIGNUP_ROUTE}`);
      }
    } catch (err) {
      if (err.response) {
        toast.error(err.response.data.error);
      }
    }
  }, [idSlice, navigate]);

  useEffect(() => {
    verifyInvite();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <VerifyCont>
      <ClipLoader
        color="#46555C"
        cssOverride={override}
        size={30}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
      <form>
        <AuthButton title="Please Wait..." />
      </form>
    </VerifyCont>
  );
}

export default VerifyInvite;
