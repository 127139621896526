import { configure, makeAutoObservable, observable } from "mobx";

import { LOCAL_STORAGE } from "api/LOCALSTORAGE";

// import { UserDpName } from "components/AuthNav/SideBar/style";

// import { setJwt } from "services/httpService"
// import { setJwt } from "services/httpService"

// import jwt_decode from "jwt-decode"
configure({
  enforceActions: "never"
});

class AuthStore {
  isLoading = false;
  isSending = false;
  resendingOtp = false;
  isAuth = false;
  userId = "";
  userRole = "";
  currentStep = "";
  userEmail = "";
  currentUser = {};
  openAssignModal = false;
  currentArticle = null;
  shouldRefresh = false;
  publishedArticles = [];
  reviewedArticles = [];
  constructor() {
    makeAutoObservable(this, {
      isAuth: observable,
      isLoading: observable,
      isSending: observable,
      resendingOtp: observable,
      currentUser: observable,
      userId: observable,
      currentStep: observable,
      userRole: observable,
      openAssignModal: observable,
      currentArticle: observable,
      shouldRefresh: observable,
      publishedArticles: observable,
      reviewedArticles: observable
    });
  }
  updateReviewedArticles(data) {
    this.reviewedArticles = data || [];
  }
  updatePublishedArticles(data) {
    this.publishedArticles = data || [];
  }
  loading() {
    this.isLoading = !this.isLoading;
  }
  sendMessage() {
    this.isSending = !this.isSending;
  }

  resending_otp() {
    this.resendingOtp = !this.resendingOtp;
  }

  setInitialAuth(payload) {
    localStorage.setItem("jms-token", JSON.stringify(payload));
    this.getInitialAuth();
  }
  setShouldRefresh(payload) {
    this.shouldRefresh = payload;
  }

  getInitialAuth() {
    const auth = LOCAL_STORAGE.token();
    // const auth = localStorage.getItem("jms-token");
    // eslint-disable-next-line no-unused-vars
    const initAuth = auth ? auth : "";
    // setJwt(initAuth)
  }
  setUserRole(payload) {
    this.userRole = payload.userRole;
  }
  toggleAssignModal(article) {
    if (!this.openAssignModal && article) {
      this.currentArticle = article;
      this.openAssignModal = true;
    } else {
      this.currentArticle = null;
      this.openAssignModal = false;
    }
  }

  // loggedInStatus() {
  // 	const userStatus = sessionStorage.getItem("token")
  // 	// userStatus ? setJwt(userStatus) : setJwt(userStatus)
  // 	this.isAuth = userStatus ? true : false
  // 	return userStatus
  // }

  // logoutUser() {
  // 	sessionStorage.removeItem("token")
  // 	this.isAuth = false
  // 	this.userId = ""
  // 	this.currentUser = {}
  // }

  setCurrentUser(payload) {
    this.currentUser = payload;
  }
  // getUserToken = () => {
  // 	const token = sessionStorage.getItem("token")

  // 	if (!token) return null
  // 	return token
  // }

  setCurrentStep(payload) {
    localStorage.setItem("auth-state", JSON.stringify(payload));
    this.getCurrentStep();
  }

  getCurrentStep() {
    const status = LOCAL_STORAGE.auth_state();
    // const status = localStorage.getItem("auth-state");
    this.currentStep = status ? status : "";
  }

  setUserEmail(payload) {
    localStorage.setItem("auth-email", JSON.stringify(payload));
    this.getUserEmail();
  }

  getUserEmail() {
    const email = LOCAL_STORAGE.auth_email();
    // const email = localStorage.getItem("auth-email");
    this.userEmail = email ? email : "";
  }
  setRefreshToken(payload) {
    localStorage.setItem("jwt", JSON.stringify(payload));
  }
  getRefreshToken() {
    return LOCAL_STORAGE.jwt();
    // return localStorage.getItem("jwt");
  }
  getUserRole() {
    return this.userRole;
  }
  getCurrentArticle() {
    if (this.currentArticle) {
      return this.currentArticle;
    } else {
      this.openAssignModal = false;
    }
  }
  getOpenModalState() {
    return this.openAssignModal;
  }
}

const authStore = new AuthStore();

export default authStore;
