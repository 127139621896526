import * as Yup from "yup";

import { COURSE, USER_PLAN, USER_TYPE } from "api/ACTION";
import React, { useEffect, useState } from "react";

import { AiOutlineClose } from "react-icons/ai";
import { AuthButton } from "components/Auth";
import { Modal } from "../../course/style";
import ShowAddCourses from "./ShowAddCourses";
import { TextArea } from "components/TextArea/style";
import TextInput from "components/TextInput";
import jmsApp from "api/jms";
import { toast } from "react-hot-toast";
import { useFormik } from "formik";
import { useNavigate } from "react-router";

export const ShowAddPlan = ({ onClose, setUpdated }) => {
  const [addCourses, setAddCourses] = useState(false);
  const [courses, setCourses] = useState([]);

  const [loading, setLoading] = useState(false);

  const [selCourses, setSelCourses] = useState([]);

  const [courseDescription, setCourseDescription] = useState("");

  const navigate = useNavigate();
  useEffect(() => {
    if (addCourses) {
      toast.loading("Adding resources...", { id: "adding-resources" });
    } else {
      toast.dismiss("adding-resources");
    }
    return () => {
      toast.dismiss("adding-resources");
    };
  });
  useEffect(() => {
    handleData();
  }, []);

  const handleData = async () => {
    try {
      if (USER_TYPE.SUPER() === "super") {
        const { data } = await jmsApp.get(COURSE.GET_COURSE());
        if (data.success) {
          setCourses(data.course);
        }
      }
    } catch (error) {
      // toast.error("error");
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      price: "",
      // duration: "",
      type: "",
      major: "",
      modules: 0
    },

    onSubmit: async (info) => {
      setLoading(true);

      if (info.modules === 0) {
        toast.error("Please choose a new course module");
        setLoading(false);
        return;
      }
      if (selCourses.length === 0) {
        toast.error("Please add at least a course to the new module");
        setLoading(false);
        return;
      }
      try {
        const { data } = await toast.promise(
          jmsApp.post(USER_PLAN.CREATE_PLAN(), {
            description: courseDescription,
            courses: selCourses,

            ...info
          }),
          {
            loading: "Adding a new course...",
            success: (data) => `${data.data.msg}`,
            error: (err) => `${err.message}`
          }
        );
        // setActive(false)
        if (data.success) {
          // setIsUploadComplete(false)
          setLoading(false);
          setUpdated(true);
          // toast.success("Sucessfully added");
          onClose();
          // helpers.resetForm()
        }
      } catch (error) {
        setLoading(false);
        if (error.response?.status === "401") {
          navigate("/login");
        }
        // toast.error(error);
      }
    },

    validationSchema: Yup.object({
      price: Yup.string().max(255).required().label("Price"),

      type: Yup.string().max(255).required().label("Type"),
      major: Yup.string().max(255).required().label("Major"),
      modules: Yup.number().min(0).required().label("Module")
    })
  });

  return (
    <Modal>
      <div className="container">
        <div className="relative flex items-center justify-center w-full">
          {/* <div className="header"> */}
          <h4 className="capitalize font-bold text-[#333333] text-[24px] ">
            Add Course
          </h4>
          <button
            onClick={onClose}
            className=" absolute top-0 right-0 transform -translate-1/2 mx-6 cursor-pointer transition-colors hover:bg-black/50 duration-300 text-[#333333] hover:text-white p-2 rounded-full "
          >
            <AiOutlineClose />
          </button>
        </div>
        <form onSubmit={formik.handleSubmit} className=" w-4/5 mx-auto">
          <TextInput
            label="Type"
            name="type"
            type="text"
            id="type"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.type}
            error={formik.errors.type}
            visible={formik.touched.type}
            placeholder="course type e.g. JAMB | WAEC | FREE | DUAL | combined"
            mt="0.5rem"
            required={true}
          />
          <TextInput
            label="Major"
            name="major"
            type="text"
            id="Major"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.major}
            error={formik.errors.major}
            visible={formik.touched.major}
            placeholder="Major e.g art | science | combined"
            mt="0.5rem"
            required={true}
          />
          <div className=" w-full mx-auto">
            <TextArea
              className="!h-max w-full "
              name="description"
              type="text"
              placeholder="Write a description for the course..."
              onChange={(e) => setCourseDescription(e.target.value)}
              // rows={2}
              maxLength={150}
              required={true}
            />
            <p className="text-gray-500 text-right">
              {150 - courseDescription.length} characters remaining.
            </p>
          </div>
          <TextInput
            label="Module"
            name="modules"
            type="number"
            id="Module"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.modules}
            error={formik.errors.modules}
            visible={formik.touched.modules}
            placeholder="Module e.g 1,2,3"
            mt="0.5rem"
            required={true}
          />

          <div>
            {addCourses && (
              <div className="table absolute">
                <ShowAddCourses
                  data={courses}
                  onClose={() => setAddCourses(false)}
                  getCoursesId={(coursesId) => {
                    setSelCourses(coursesId);
                  }}
                  selCoursesId={selCourses}
                />
              </div>
            )}
            <div className="w-full flex items-center justify-start space-x-6 my-4">
              {/* <div className="planDuration"> */}
              <button
                className="btn !ml-0"
                onClick={() => setAddCourses(true)}
                type="button"
              >
                Add Resources
              </button>
              {selCourses.length}
            </div>
          </div>

          <TextInput
            label="Price"
            name="price"
            type="text"
            id="price"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.price}
            error={formik.errors.price}
            visible={formik.touched.price}
            placeholder="Price"
            mt="0.5rem"
            required={true}
          />
          <AuthButton
            loading={loading}
            type="submit"
            // disabled={authStore.isLoading ? true : false}
            title={"Add"}
          />
        </form>
      </div>
    </Modal>
  );
};
