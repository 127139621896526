export function convertUTCToLocalTime(utcDateString) {
  // Create a Date object from the UTC string
  const utcDate = new Date(utcDateString);

  // Get the user's local time zone offset in minutes
  const timeZoneOffset = utcDate.getTimezoneOffset();

  // Create a new Date object adjusted for the local time zone
  const localDate = new Date(utcDate.getTime() - timeZoneOffset * 60 * 1000);

  // Format the local date according to your desired format
  const options = {
    year: "numeric",
    month: "long", // You can use 'numeric' for month as a number (1-12)
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true // Use 'hour24' for 24-hour format
  };

  return localDate.toLocaleDateString("en-US", options); // Adjust 'en-US' for your locale preference
}
