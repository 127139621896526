import { ADMIN_ROLES, override } from "utils/admin-roles";
import { ARTICLE_ENDPOINT, DOWNLOAD_ENDPOINT } from "api/ACTION";
import {
  AllArticles,
  ArticleCont
} from "components/Admin/Articles/Articles.style";
import ArticleAssign, { handleAssign } from "../Dashboard/article-asign";
import { useEffect, useMemo, useState } from "react";

import ArticlesTable from "./articles-table";
import { ClipLoader } from "react-spinners";
import { Cloudinary } from "@cloudinary/url-gen";
import { FcDeleteDatabase } from "react-icons/fc";
import { LOCAL_STORAGE } from "api/LOCALSTORAGE";
import Search from "../sharedComponents/Search";
import UploadFileComp from "./UploadFileComp";
import authStore from "mobx/AuthStore";
import axios from "axios";
import { heading } from "../sharedComponents/articleData";
import jmsApp from "api/jms";
import { observer } from "mobx-react-lite";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

// import Table from "../sharedComponents/Table-v2";

const ArticlesComp = observer(() => {
  const activeStatus = [
    { status: "all", access: ["super", "editor", "formatter", "sub"] },
    { status: "approved", access: ["super"] },
    { status: "review", access: ["super"] },
    { status: "published", access: ["super"] },
    { status: "rejected", access: ["super"] },
    { status: "pending", access: ["super"] },
    { status: "downloaded", access: ["super", "editor", "formatter", "sub "] },
    { status: "formatted", access: ["super", "editor", "formatter", "sub "] },
    { status: "reviewed", access: ["super", "editor", "formatter", "sub"] },
    { status: "assigned", access: ["super", "editor", "formatter", "sub"] }
  ];
  const isAssignModalOpen = authStore.getOpenModalState();

  const currentUser = LOCAL_STORAGE.user();
  const userRole = currentUser?.type ?? "";
  const [data, setData] = useState(useMemo(() => [], []));
  const columns = useMemo(() => heading, []);
  const [active, setActive] = useState("all");
  const [value, setValue] = useState("");
  const [isDownloaded, setIsDownloaded] = useState(null);
  const [isFetchingArticles, setIsFetchingArticles] = useState(false);
  const [downloadList, setDownloadList] = useState();
  const [isDeletingArticle, setIsDeletingArticle] = useState(null);
  // useEffect(() => {
  //   const { data } = jmsApp.get(DOWNLOAD_ENDPOINT.DOWNLOADED_ARTICLE());
  //   console.log(data);
  //   setIsDownloaded();
  // }, []);

  const navigate = useNavigate();

  const filterArticles = (status) => {
    if (status === "all") {
      return data;
    }
    if (status === "downloaded") {
      // console.log(downloadList);
    }
    return data.filter((article) => article.status === status);
  };

  const handleData = async () => {
    try {
      setIsFetchingArticles(true);
      const { data } = await jmsApp.get(ARTICLE_ENDPOINT.GET_ARTICLE());
      if (data.success) {
        setData(data.article);
        setIsFetchingArticles(false);
        const { data: downloads } = await jmsApp.get(
          DOWNLOAD_ENDPOINT.DOWNLOADED_ARTICLE()
        );
        setDownloadList([]);
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        navigate("/login");
      }
      if (error?.response) {
        //toast.error(error.response.data.error);
      } else {
        //toast.error(error.message)
      }
    } finally {
      setIsFetchingArticles(false);
    }
  };

  useEffect(() => {
    handleData();
    // AuthenticateUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const cld = new Cloudinary({
    cloud: {
      cloudName: process.env.REACT_APP_CLOUDINARY_NAME,
      apiKey: process.env.REACT_APP_CLOUDINARY_API_KEY,
      apiSecret: process.env.REACT_APP_CLOUDINARY_API_SECRET
    }
  });
  const downloadFile = async (articleURL, title, articleId) => {
    try {
      await jmsApp.post(DOWNLOAD_ENDPOINT.DOWNLOADED_ARTICLE(), {
        articleId: articleId
      });
      const myImage = cld.image(articleURL);
      const image = await axios({
        url: myImage.publicID, //your url
        method: "GET",
        responseType: "blob" // important
      });

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(image.data);
      link.setAttribute("download", `${title}.pdf`);
      document.body.appendChild(link);
      link.click();
    } catch (err) {
      if (err.response.status === 401) {
        navigate("/login");
      }
      if (err.response) {
        Promise.reject(err.response.data.error);
        // toast.error(err.response.data.error);
      }
    }
  };
  const handleUpdateData = (updatedData) => {
    setData([...updatedData]);
  };

  async function handleDownload(article) {
    const { articleUrl, title, articleId } = article;
    if (LOCAL_STORAGE.articleId() !== articleId) {
      toast.error("Select the article to be downloaded first");
      return;
    }
    try {
      await toast.promise(downloadFile(articleUrl, title, articleId), {
        loading: "Downloading...",
        success: "Download successful successful",
        error: "Download failed"
      });
      setIsDownloaded(articleId);
    } catch (err) {
      setIsDownloaded(null);
    }
  }
  function handleView(article) {
    navigate(`/admin/articles/${article.articleId}`);
  }
  async function handleDelete(article) {
    try {
      await toast.promise(
        jmsApp.delete(ARTICLE_ENDPOINT.DELETE_ARTICLE(), {
          params: {
            articleId: article.articleId
          }
        }),
        {
          loading: `Deleting ${article.title}...`,
          error: `${article.title} deletion failed.`,
          success: `${article.title} successfully deleted.`
        }
      );
      setIsDeletingArticle(null);
      await handleData();
    } catch (err) {}
    // const {}
  }
  const actions =
    userRole === ADMIN_ROLES.SUPER_ADMIN
      ? [
          { name: "download", action: handleDownload },
          { name: "view", action: handleView },

          {
            name: "delete",
            action: (article) => setIsDeletingArticle(article)
          },
          { name: "assign", action: handleAssign }
        ]
      : [
          { name: "download", action: handleDownload },
          { name: "view", action: handleView },

          { name: "assign", action: handleAssign }
        ];
  // console.log(filterArticles(active));
  return (
    <>
      <ArticleCont>
        <div className="heading">
          <div className=" w-full flex flex-col items-start xl:flex-row gap-2 xl:items-center justify-between py-4 px-6 ">
            <h1 className=" text-lg  font-bold uppercase">All Articles</h1>
            {currentUser.name && (
              <div className=" flex text-sm lg:text-lg  font-bold uppercase space-x-4 items-center justify-end ">
                <span>Welcome</span>
                <span className=" ">{currentUser?.name}</span>
              </div>
            )}
          </div>
          <div className="search">
            <Search
              value={value}
              setValue={setValue}
              placeholder="Search Articles..."
            />
          </div>
        </div>
        <div className="active-status overflow-x-scroll  space-x-4 py-8">
          <>
            {activeStatus?.map((status, id) => {
              if (!status.access.includes(userRole)) return null;
              return (
                <div
                  key={id}
                  className={`${
                    active === status.status ? "active" : ""
                  } w-fit whitespace-nowrap h-fit px-2.5 py-1.5 rounded-lg border border-[#808080] border-solid cursor-pointer hover:bg-[#46555c] hover:text-white transition-colors duration-100 ease-out capitalize`}
                  onClick={() => setActive(status.status)}
                >
                  {status.status}
                </div>
              );
            })}
          </>
        </div>

        {data.length === 0 ? (
          !isFetchingArticles ? (
            <div className="bg-transparent capitalize font-bold text-2xl font-primary text-center w-full py-10 text-black/50 flex flex-col space-y-4 items-center  justify-center">
              <span>
                <FcDeleteDatabase size={40} />
              </span>
              <p>No Articles found</p>
            </div>
          ) : (
            <div className="fixed top-0 left-0 w-full h-screen flex items-center justify-center bg-gray-100 bg-opacity-75 !mt-0 backdrop:blur-md">
              <ClipLoader
                color="#333333"
                loading={isFetchingArticles}
                cssOverride={override}
                size={40}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
            // <div className="empty">
            //   <span>
            //     <FcDeleteDatabase />
            //   </span>
            //   <div className="bg-transparent capitalize font-bold text-2xl font-primary text-center w-full py-4 text-[#46555C] flex items-center  justify-center">
            //     <p>No data found</p>
            //   </div>
            // </div>
          )
        ) : filterArticles(active).length > 0 ? (
          <>
            <AllArticles>
              <ArticlesTable
                user={userRole}
                data={filterArticles(active)}
                columns={columns}
                hasStatus={true}
                value={value}
                handleUpdateData={handleUpdateData}
                actions={actions}
                isPagination={true}
                // actions={[
                //   { name: "download", action: handleDownload },
                //   { name: "view", action: handleView },
                //   { name: "delete", action: handleDelete },
                //   { name: "assign", action: handleAssign }
                // ]}
              />
            </AllArticles>
            <div className="">
              {/* <UploadFileComp /> */}
              <UploadFileComp
                isDownloaded={isDownloaded}
                refreshData={handleData}
              />
            </div>
          </>
        ) : (
          <div className="bg-transparent capitalize font-bold text-xl font-primary text-center w-full  text-black/50 py-10 flex flex-col space-y-4 items-center  justify-center">
            <span>
              <FcDeleteDatabase size={40} />
            </span>
            <p>No articles found</p>
          </div>
        )}

        {/* {data.length !== 0 &&
          (filterArticles(active).length > 0 ? (
            <AllArticles>
              <ArticlesTable
                user={userRole}
                data={filterArticles(active)}
                columns={columns}
                hasStatus={true}
                value={value}
                handleUpdateData={handleUpdateData}
                actions={actions}
                // actions={[
                //   { name: "download", action: handleDownload },
                //   { name: "view", action: handleView },
                //   { name: "delete", action: handleDelete },
                //   { name: "assign", action: handleAssign }
                // ]}
              />
            </AllArticles>
          ) : (
            <div className="bg-transparent capitalize font-bold text-xl font-primary text-center w-full  text-black/50 py-10 flex flex-col space-y-4 items-center  justify-center">
              <span>
                <FcDeleteDatabase size={40} />
              </span>
              <p>No articles found</p>
            </div>
          ))} */}
        {/* <div className="">
         
          <UploadFileComp isDownloaded={isDownloaded} />
        </div> */}
        {isAssignModalOpen && <ArticleAssign refreshData={handleData} />}
        {isDeletingArticle && (
          <div className="min-h-screen  bg-black/70 bg-opacity-50 backdrop-blur-sm fixed w-full h-full top-0 left-0 flex items-center justify-center">
            <div
              className=" min-w-[250px] max-w-[400px] h-[250px] flex flex-col bg-white rounded-lg items-center justify-center gap-6
           p-6"
            >
              <p className=" font-medium text-center text-xl ">
                Do you want to remove{" "}
                <strong className="text-red-600 italic underline block">
                  {isDeletingArticle.title}
                </strong>{" "}
                <span className=" block">from the shelf ?</span>
              </p>
              <div className=" w-full items-center justify-between flex gap-6">
                <button
                  onClick={() => setIsDeletingArticle(null)}
                  className=" w-full p-2 rounded-md border border-red-500 text-red-500 transition-all duration-300 ease-in-out hover:bg-red-500 hover:text-white capitalize font-bold text-lg "
                >
                  no
                </button>
                <button
                  onClick={() => handleDelete(isDeletingArticle)}
                  className=" w-full p-2 rounded-md border border-blue-500 text-blue-500 transition-all duration-300 ease-in-out hover:bg-blue-500 hover:text-white capitalize font-bold text-lg "
                >
                  yes
                </button>
              </div>
            </div>
          </div>
        )}
        {/* <div className="col-start-1 text-end">
          <div className="hs-tooltip inline-block [--placement:left]">
            <button
              type="button"
              className="hs-tooltip-toggle w-10 h-10 inline-flex justify-center items-center gap-2 rounded-full bg-gray-50 border border-gray-200 text-gray-600 hover:bg-blue-50 hover:border-blue-200 hover:text-blue-600 "
            >

              <span
                className="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 transition-opacity inline-block absolute invisible z-10 py-1 px-2 bg-gray-900 text-xs font-medium text-white rounded shadow-sm "
                role="tooltip"
              >
                Assign
              </span>
            </button>
          </div> 
        </div>*/}
      </ArticleCont>
    </>
  );
});

export default ArticlesComp;
