import Colors from "utils/colors";
import bg from "../../assets/images/banner.jpg";
import { keyframes } from "styled-components";
import media from "utils/media";
import styled from "styled-components/macro";
import tw from "twin.macro";

// import { AuthProp } from "utils/assets";

const fadeInAnimation = keyframes`
   0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const Authcontainer = styled.div`
  width: 100%;
  height: 110vh;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  /* // background: url(${bg});
  // animation: ${fadeInAnimation} 5s ease-in-out infinite;
  // background: ${Colors.white}; */
`;
export const AuthContent = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);

  position: relative;

  /* // animation: ${fadeInAnimation} 10s ease-out infinite;
  // background: url(${bg}) center center no-repeat; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const AuthCard = styled.div`
  width: 100%;
  background: ${Colors.white};
  /* // background: rgba(255, 255, 255, 0.2); */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.6);
  border-radius: 35px;
  margin-top: 1rem;
  padding: 1rem 1rem;
  ${media.mobile`
	padding: 2rem 1rem;
	`}
  ${media.smallMobile`
	box-shadow:unset;
	/* padding: unset; */
  border-radius:10px;
  width:90%
	`}
`;
export const AuthText = styled.h3`
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  text-align: center;
  line-height: 103%;
  color: ${Colors.primary};
  ${"" /* margin-top: -8px; */}
  margin-bottom: 1px;
  ${media.mobile`
	font-size: 22px;

	`}
`;
export const Authbtn = styled.button`
  ${"" /* width: 30%; */}
  height: 40px;
  border: none;
  outline: none;
  border-radius: 10px;
  background-color: ${Colors.primary} !important;
  color: ${Colors.white};
  padding: 5px 35px;
  margin-top: 20px;
  cursor: pointer;
  /* New style */
  border: 1px solid ${Colors.primary};
  transition: all 0.2s ease-in-out;
  /* color: white; */
  /* background: ${Colors.primary}; */
  width: 100%;
  :hover {
    color: ${Colors.primary};
    background: transparent !important;
    box-shadow: 1px 2px 2px 2px #6a757a;
    /* border: 1px solid ${Colors.primary}; */
    /* transition: all 0.2s ease-in-out; */
  }
  :active {
    box-shadow: none;
  }
  :disabled {
    pointer-events: none;
    background: ${Colors.primary}!important;
    color: ${Colors.white}!important;
    border: none;
  }
`;
export const AuthBottomDIv = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-top: 8px;
  justify-content: center;
  flex-direction: row;

  ${tw`space-y-0 space-x-4`}
  .auth--bottom-link {
    color: ${Colors.light};
    font-size: 14px;
  }
  .link-bottom {
    margin-left: 5px;
    font-size: 12px;
    text-decoration: none;
    font-weight: 700;
    color: ${Colors.primary};

    border: 1px solid transparent;
    transition: all 0.5s ease-in-out;
    ${tw`hover:border-b-[#46555C]`}
  }

  @media only screen and (max-width: 400px) {
    ${tw`flex-col items-center space-x-0 text-center mx-auto space-y-2  w-full rounded-sm `}
  }
`;

export const CreateContainer = styled.div`
  width: 70%;
`;
export const CreateAccountButton = styled.button`
  width: 100%;
  /* height: 35px; */
  border: none;
  outline: none;
  border-radius: 10px;
  background: ${Colors.gradient} !important;
  color: ${Colors.white};
  padding: 5px 20px;
  margin-top: 20px;
  cursor: pointer;
`;
export const SetPasswordButton = styled.button`
  width: 40%;
  height: 35px;
  border: none;
  outline: none;
  border-radius: 10px;
  background: ${Colors.gradient};
  color: ${Colors.white};
  padding: 5px 20px;
  margin-top: 20px;
  cursor: pointer;
`;
export const AuthContentcreate = styled.div`
  width: 100%;
  height: 100%;

  background-repeat: no-repeat;
  background-position: -14% 130%;
  background-size: 400px 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${media.mobile`
	background-position: -40.5% 110%;
	background-size: 200px 200px;
	`}
`;
export const AuthSectionUnique = styled.div`
  width: 100%;
  background: ${Colors.gradient};
`;
export const EmailCreateText = styled.p`
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.005em;
  text-transform: capitalize;
  color: ${Colors.black};
`;

export const VerifyResendBtn = styled.button`
  background: transparent;
  outline: none;
  border: 1px solid transparent;
  font-weight: 700;
  margin-left: -10px;
  color: ${Colors.primaryLight};
  width: 100px;
  margin-top: 10px;
  cursor: pointer;

  :hover {
    border-bottom-color: ${Colors.gradient};
  }
`;

export const VerifyExpires = styled.p`
  font-size: 12px;
  color: ${Colors.primaryLight};
  margin-top: 20px;
`;

export const LoginContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const LoginDiv = styled.form`
  width: 35%;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${media.smallDesktopMinimum`
	width:50%;
	`}
  ${media.tablet`
	width:70%;
	`}
  ${media.mobile`
	width:85%;
	`}
  ${media.smallMobile`
	width:90%;
	`}
`;
export const LoginFormContainer = styled.div`
  width: 82%;
  display: flex;
  margin: auto;
  flex-direction: column;
  ${media.mobile`
width:100%;
	`}
`;
export const LoginCont = styled.div`
  width: 82%;
  display: flex;
  margin: auto;
  flex-direction: column;
  justify-content: center;

  ${media.mobile`
width:100%;
	`}
`;
export const ForgotDiv = styled.div`
  margin-top: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  input {
    margin-right: 3px;
  }

  .forgot,
  span {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
  }
`;
export const CreateAcct = styled.div`
  display: flex;
  margin-top: 6px;
  justify-content: center;
  align-items: center;

  span {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: ${Colors.approvedColor};
  }
`;
export const ForgotPasswordDiv = styled.div`
  width: 100%;
`;

export const ClearSessionCont = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  /* // background: ${Colors.primary}; */
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;

  .modal {
    padding: 20px;
    border-radius: 11px;
    background: white;
    display: flex;
    flex-direction: column;
    ${tw` w-4/5 sm:w-3/5 md:w-2/5 lg:w-1/3 `}
    ${
      "" /* justify-content: center;
    align-items: center; */
    }
    ${"" /* width: 60%; */}
    .menu {
      padding: 20px;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
    }
    h3 {
      color: ${Colors.primary};
    }

    span {
      color: white;
      font-size: 20px;
    }
  }
`;
export const VerifyCont = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  background: #e5e5e5;
  flex-direction: column;
  h1 {
    font-size: 20px;
    font-weight: 600;
  }
`;
