import React, { useState } from "react";

import PropTypes from "prop-types";

const Tooltip = ({ children, text }) => {
  const [isTooltipVisible, setTooltipVisible] = useState(false);

  const handleMouseEnter = () => {
    setTooltipVisible(true);
  };

  const handleMouseLeave = () => {
    setTooltipVisible(false);
  };

  return (
    <div
      className="relative inline-block"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {isTooltipVisible && (
        <div className="absolute z-10 bg-gray-800 text-white text-xs p-2 rounded-md shadow-md top-0 left-1/2 transform -translate-x-1/2 -translate-y-full mb-4 capitalize">
          Click to {text}
        </div>
      )}
      {children}
    </div>
  );
};

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
  text: PropTypes.string.isRequired
};

export default Tooltip;
