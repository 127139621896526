import { useEffect, useState } from "react";

import { Pay } from "utils/assets";
import { PaymentCont } from "./payment.style";
import { TiTick } from "react-icons/ti";

const paymentOptions = [
  {
    offers: [
      "3 HD video lessons & tutorials",
      "1 Official exam",
      "100 Practice questions",
      "1 Month subscriptions",
      "1 Free book"
    ]
  }
];

export default function PaymentCard({
  price,
  type,
  major,
  planId,
  handler,
  url,
  plan
}) {
  // const [loadingPlan, setLoadingPlan] = useState(true);
  // useEffect(() => {
  //   const loadingTimeout = setTimeout(() => {
  //     setLoadingPlan(false);
  //   }, 2000);
  //   return () => {
  //     clearTimeout(loadingTimeout);
  //   };
  // });
  const noOfCourses = plan.courses.length;
  const noOfVideos = plan.courses.filter(
    (course) => course.category.toLowerCase() === "video"
  ).length;
  const noOfBooks = plan.courses.filter(
    (course) => course.category.toLowerCase() === "book"
  ).length;
  const noOfModules = plan.courses.reduce((maxModule, obj) => {
    return obj.module > maxModule ? obj.module : maxModule;
  }, -Infinity);

  return (
    <div>
      <PaymentCont>
        <div className="paymentOffers">
          {
            /* The code snippet is using the `map` function to iterate over the `paymentOptions` array
           and render a set of elements for each option. */
            paymentOptions?.map((option, index) => (
              <div className={`plan `} key={index}>
                <div className="head">
                  <img src={Pay} alt="" />
                  <h5 className="type">
                    {type}-<span>{major}</span>{" "}
                  </h5>
                  <p>{plan.description || ""}</p>
                </div>
                <div className="main">
                  {/* {option.offers?.map((offer, index) => (
                    
                  ))} */}

                  <div>
                    {noOfCourses > 0 && (
                      <PlanDetailItem
                        text={getRandomMediaMessage(noOfCourses, "courses")}
                      />
                    )}
                    {noOfModules > 0 && (
                      <PlanDetailItem
                        text={getRandomMediaMessage(noOfModules, "modules")}
                      />
                    )}
                    {noOfVideos > 0 && (
                      <PlanDetailItem
                        text={getRandomMediaMessage(noOfVideos, "videos")}
                      />
                    )}
                    {noOfBooks > 0 && (
                      <PlanDetailItem
                        text={getRandomMediaMessage(noOfBooks, "books")}
                      />
                    )}
                  </div>
                  <span className="price">₦{price}</span>
                  <div>
                    <button
                      className="pay"
                      id={planId}
                      onClick={(e) => handler(e)}
                      type="button"
                      // url={url}
                    >
                      Purchase Course
                    </button>
                  </div>
                </div>
              </div>
            ))
            // ) : (
            //   <div className="bg-gray-200 h-[400px] min-w-[300px] flex flex-col p-6 justify-between items-center pt-10 rounded-lg shadow-lg">
            //     <div className="w-full h-10 rounded-lg bg-gray-300/50 animate-pulse"></div>

            //     <div className="w-full h-10 rounded-lg bg-gray-300/50 animate-pulse"></div>

            //     <div className="w-full h-10 rounded-lg bg-gray-300/50 animate-pulse"></div>

            //     <div className="w-full h-10 rounded-lg bg-gray-300/50 animate-pulse"></div>

            //     <div className="w-full h-10 rounded-lg bg-gray-300/50 animate-pulse"></div>
            //   </div>
          }
        </div>
      </PaymentCont>
    </div>
  );
}
function PlanDetailItem({ text }) {
  return (
    <p className=" tracking-wider">
      <span>
        <TiTick />
      </span>

      {text}
    </p>
  );
}
function getRandomMediaMessage(count, mediaType) {
  const messages = [
    `Explore  ${count} ${mediaType} in your plan!`,
    `Dive into  ${count} ${mediaType} available for you!`,
    `${count} ${mediaType} waiting for you in your plan!`,
    `Unlock the power of  ${count} ${mediaType} in your learning journey!`,
    `Embark on a learning adventure with  ${count} ${mediaType} in your plan!`,
    `Discover knowledge through  ${count} engaging ${mediaType} in your plan!`
  ];

  const randomIndex = Math.floor(Math.random() * messages.length);
  return messages[randomIndex];
}
