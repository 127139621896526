import Mainlayout from 'layout/MainLayout'
import React from 'react'
import JournalComp from '../components/JournalComp'

function JournalPage() {
  return (
    <Mainlayout>
      <JournalComp />
    </Mainlayout>
  )
}

export default JournalPage
