import { SearchCont } from "./style";

function Search({ placeholder, value, setValue }) {
  return (
    <SearchCont>
      <input
        type="search"
        name=""
        id=""
        value={value}
        placeholder={placeholder}
        onChange={(e) => setValue(e.target.value)}
      />
    </SearchCont>
  );
}

export default Search;
