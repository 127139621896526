import media from "utils/media";
import styled from "styled-components/macro";

export const LayoutCont = styled.div`
  display: flex;
  ${media.tablet`flex-direction:column;
	`};

  .kids {
    padding-left: 280px;
    width: 100%;
    min-height: 100vh;
    ${media.smallDesktopMinimum`
	padding-left:80px;
	`};
    ${media.tablet`
	padding-left:0px;
    padding-top:80px;
	`};
  }
`;
