import { COURSES_ROUTE } from "routes";
import DashboardLayout from "../shared/DashboardLayout";
import { LayoutCont } from "./layout.style";
import React from "react";
import { dashboardLinks } from "./link";
import { useLocation } from "react-router-dom";

function Layout({ children }) {
  let location = useLocation();
  const path = location.pathname;

  const displaypath =
    path === "/dashboard" ||
    path === "/courses" ||
    path === "/courses/waec" ||
    path === "/courses/jamb" ||
    path === "/payment" ||
    path === "/payment/checkout" ||
    path === "/admin" ||
    path === "/account" ||
    path === COURSES_ROUTE ||
    path === "/articles" ||
    path === "/account";
  return (
    <LayoutCont>
      {displaypath && <DashboardLayout links={dashboardLinks} />}
      <div className="kids">{children}</div>
    </LayoutCont>
  );
}

export default Layout;
