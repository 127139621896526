// import { Navigate, useNavigate } from "react-router-dom";

import { AUTH_ENDPOINTS } from "./ACTION";
import { LOCAL_STORAGE } from "./LOCALSTORAGE";
import { handleLogout } from "utils/UserLogout";
import jmsApp from "./jms";

export const checkUser = async () => {
  try {
    const token = LOCAL_STORAGE.token();
    const { data } = await jmsApp.post(AUTH_ENDPOINTS.CHECK_USER(), {
      token
    });

    if (!data) return false;
    if (data.success) return data;
  } catch (error) {
    handleLogout();
    if (error.response) return error;
  }
};
