import { COURSE, USER_PLAN } from "api/ACTION";
import {
  WelcomeCard,
  WelcomeMessage
} from "components/Dashboard/dashboardStyle";
import { useEffect, useState } from "react";

import { AiFillEye } from "react-icons/ai";
import { BiArrowBack } from "react-icons/bi";
import ClipLoader from "react-spinners/ClipLoader";
import CourseCard from "./CourseCard";
import { CourseCont } from "./courses.style";
import { IconSize } from "components/Admin/Articles/Articles.style";
import NoCoursesPage from "components/ELearn/NoCoursePg";
import SearchBox from "./SearchBox";
import Tooltip from "utils/tooltip";
import jmsApp from "api/jms";
import { override } from "utils/admin-roles";
import styled from "styled-components";
import textBanner from "../../assets/images/bgBanner.png";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

function Courses() {
  const [plans, setPlans] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [plan, setPlan] = useState(null);
  const [active, setActive] = useState("All");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPlan, setIsLoadingPlan] = useState(false);
  const [checkingCourse, setCheckingCourse] = useState({
    id: "",
    module: ""
  });
  const [courseOnVIew, setCourseOnView] = useState(null);
  const [courseOnModule, setCourseOnModule] = useState(1);
  const navigate = useNavigate();

  useEffect(() => {
    handleData();
    if (plans.length > 0) {
      getPlan(plans[0].planId);
      setActive(plans[0].type);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleData = async () => {
    setIsLoading(true);
    try {
      const { data } = await jmsApp.get(USER_PLAN.GET_USER_PLANS());
      // console.log(data);
      if (data) {
        setPlans(data.plans);
        await getPlan(data.plans[0].planId);
        setActive(data.plans[0].type);
        setIsLoading(false);
      }
    } catch (error) {
      if (error.response?.data?.error === "Course Not Found") {
        setPlans([]);
        setPlan([]);
        // setIsLoadingPlan(false);
        setIsLoading(false);
      }
      setIsLoading(false);
      if (error.response?.status === "401") {
        navigate("/login");
      }
    }
  };

  const getPlan = async (planId) => {
    setIsLoadingPlan(true);

    try {
      const { data } = await jmsApp.get(USER_PLAN.GET_PLAN_BY_ID(), {
        params: { planId }
      });

      if (data.success) {
        setPlan(data.plans);
      }
    } catch (error) {
      // toast.error("error");
      if (error.response?.status === "401") {
        navigate("/login");
      }
    } finally {
      setIsLoadingPlan(false);
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handler = async ({ course, planId }) => {
    setCheckingCourse({ id: course.id, module: course.module });
    try {
      // console.log({
      //   courseId: course.id,
      //   modules: course.module,
      //   planId: planId
      // });
      await toast.promise(
        jmsApp.get(COURSE.GET_COURSE_BY_ID(), {
          params: {
            courseId: course.id,
            modules: course.module,
            planId: planId
          }
        }),
        {
          loading: "verifying course...",
          error: "Error verifying course.",
          success: "Verification successful."
        }
      );
      navigate(`/course/${course.id}?module=${course.module}&planId=${planId}`);
    } catch (err) {
      if (err.response?.status === 403) {
        toast.error(
          `You need to complete module ${
            Number(course.module) - 1
          } to access module ${Number(course.module)}`,
          {
            className: "w-max"
          }
        );
        setCheckingCourse({
          id: "",
          module: ""
        });
        return;
      }
    } finally {
      setCheckingCourse({
        id: "",
        module: ""
      });
    }
  };
  function handleViewCourse(course) {
    setCourseOnView(course);
    // console.log("Viewing course : ", course);
  }
  const isModuleComplete = (module) => {
    const moduleComplete = courseOnVIew?.courses
      .filter((course) => course.module === module)
      ?.map((course) => course.completed)
      .every((val) => val === true);
    return moduleComplete;
  };

  return (
    <div className=" relative">
      {(isLoading || isLoadingPlan) && (
        <div className=" w-full  flex items-center justify-center  py-10 absolute top-0 left-0 min-h-screen z-50  bg-slate-100 backdrop-blur-sm bg-opacity-20 ">
          <ClipLoader
            color="#46555C"
            loading={isLoading || isLoadingPlan}
            cssOverride={override}
            size={30}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      )}
      <WelcomeCard style={{ backgroundImage: `url(${textBanner})` }}>
        <WelcomeMessage>Enjoy your courses</WelcomeMessage>
      </WelcomeCard>
      <CourseCont className=" pb-8 px-6 ">
        <div className="heading ">
          <SearchBox
            value={searchTerm}
            onChange={handleSearch}
            title={"your courses"}
          />

          {/* {!isLoading && plans.length > 1 && (
            <div className="btns !overflow-x-scroll grid grid-cols-[repeat(auto-fill, minmax(100px,1fr))] justify-stretch items-stretch  no-scrollbar !max-w-md  !my-6  ">
              {plans.map((e, i) => (
                <Card
                  key={i}
                  onClick={async () => {
                    if (e.type === active) return;
                    setActive(e.type);
                    getPlan(e.planId);
                  }}
                  className={`w-max  rounded-xl cursor-pointer uppercase transition-colors duration-500 ease-in-out border border-[#9C4DF4] hover:bg-[#9C4DF4] hover:text-white ${
                    active === e.type
                      ? " bg-[#9C4DF4] text-white"
                      : " text-[#9C4DF4]"
                  }`}
                >
                  <Title>{e.type}</Title>
                </Card>
              ))}
            </div>
          )} */}
        </div>

        {plan?.courses?.length > 0 ? (
          // <div className="courses">
          //   <div className="flex py-6">
          //     Price: ₦{plan?.price}
          //     <span className="price"> Duration: {plan?.duration}</span>
          //   </div>

          //   <div className="courseCard">
          //     {plan.courses.map((course, idx) => {
          //       return (
          //         <CourseCard
          //           checkingCourse={checkingCourse}
          //           plan={plan}
          //           key={`${course.id}--${idx}`}
          //           course={course}
          //           type={plan.type}
          //           title={course.title ? course.title : "Title"}
          //           buttonText={<AiFillEye className="eye" />}
          //           category={course.category}
          //           handler={() => handler({ course, planId: plan.planId })}
          //         />
          //       );
          //     })}
          //   </div>
          // </div>

          <div>
            {!courseOnVIew ? (
              <div className="flex flex-col">
                <div className="-m-1.5 overflow-x-auto">
                  <div className="p-1.5 min-w-full inline-block align-middle">
                    <div className="overflow-hidden">
                      <table className="min-w-full divide-y divide-gray-200 ">
                        <thead>
                          <tr>
                            <th
                              scope="col"
                              className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase"
                            >
                              Type
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase"
                            >
                              Major
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase"
                            >
                              Description
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-3  text-xs font-medium text-gray-500 uppercase text-center"
                            >
                              No. of modules
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-3 text-end text-xs font-medium text-gray-500 uppercase"
                            >
                              &nbsp;{" "}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {plans.map((plan, idx) => {
                            return (
                              <tr
                                key={`${plan.planId}--${idx}`}
                                className="odd:bg-white even:bg-gray-100 hover:bg-gray-100  "
                              >
                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 ">
                                  {plan.type}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 ">
                                  {plan.major}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 ">
                                  {plan.description}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 text-center">
                                  {
                                    [
                                      ...new Set(
                                        plan.courses.map(
                                          (course) => course.module
                                        )
                                      )
                                    ].length
                                  }
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-end text-sm font-medium">
                                  <button
                                    type="button"
                                    onClick={() => handleViewCourse(plan)}
                                    className="inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-blue-600 hover:text-blue-800 disabled:opacity-50 disabled:pointer-events-none "
                                  >
                                    view
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className=" grid grid-rows-[max-content_1fr]">
                <div className="h-[50px] flex items-center !justify-start my-2 w-full">
                  <IconSize>
                    <div
                      className=" text-[12px]  sm:text-lg lg:text-xl border border-solid rounded-full transition-all duration-300 ease-in-out hover:bg-black/70 hover:text-white absolute w-max p-3 "
                      onClick={(e) => setCourseOnView(null)}
                    >
                      <BiArrowBack />
                    </div>
                  </IconSize>
                </div>
                <div className=" grid grid-rows-[max-content_1fr]">
                  <h1 className=" text-xl font-bold py-5 w-full  text-center flex flex-col items-center justify-start">
                    <span className=" flex items-center justify-center ">
                      <span>{courseOnVIew.type}</span>-
                      <span>{courseOnVIew.major}</span>
                    </span>
                    <span>{courseOnVIew.description}</span>
                  </h1>
                  <div className=" grid grid-cols-[200px_1fr] gap-2 border border-solid border-black/50 divide-x divide-black/50">
                    <div className="w-full  flex flex-col items-center justify-start space-y-2 py-2">
                      {[
                        ...new Set(
                          courseOnVIew.courses.map((course) => course.module)
                        )
                      ]
                        .sort((a, b) => a - b)
                        .map((val) => (
                          <button
                            key={val}
                            onClick={() => setCourseOnModule(val)}
                            className={` flex items-center justify-start space-x-2 px-4 transform capitalize transition-all ease-in-out duration-500 ${val === courseOnModule ? "  translate-x-4 text-blue-600 " : " translate-x-0"} `}
                          >
                            <Tooltip
                              text={`${courseOnVIew.courses.map((course) => course.completed || false) ? "completed" : "un-completed"}`}
                            >
                              <span
                                className={`block h-4 w-4  rounded-full border border-solid  ${val === courseOnModule ? " border-blue-800 " : " border-black/50"} p-2 ${
                                  isModuleComplete(val)
                                    ? " bg-[radial-gradient(circle_at_bottom_left,_var(--tw-gradient-stops))] from-blue-600 via-blue-600 to-transparent"
                                    : "bg-transparent"
                                }`}
                              ></span>
                            </Tooltip>
                            module {val}
                          </button>
                        ))}
                    </div>
                    <div className=" w-full ">
                      <div className="flex flex-col">
                        <div className="-m-1.5 overflow-x-auto">
                          <div className="p-1.5 min-w-full inline-block align-middle">
                            <div className="border rounded-lg  overflow-hidden ">
                              <table className="min-w-full divide-y divide-gray-200 ">
                                <tbody className="divide-y divide-gray-200 relative ">
                                  {courseOnVIew.courses
                                    .filter(
                                      (course) =>
                                        course.module === courseOnModule
                                    )
                                    .map((course, idx) => {
                                      // console.log(course);
                                      return (
                                        <>
                                          {checkingCourse.id === course.id &&
                                            checkingCourse.module ===
                                              course.module && (
                                              <div className="absolute w-full h-full top-0 left-0 flex items-center justify-center bg-slate-200/50 backdrop-blur-sm z-50">
                                                <ClipLoader
                                                  color="#46555C"
                                                  loading={
                                                    checkingCourse.id ===
                                                      course.id &&
                                                    checkingCourse.module ===
                                                      course.module
                                                  }
                                                  cssOverride={override}
                                                  size={30}
                                                  aria-label="Loading Spinner"
                                                  data-testid="loader"
                                                />
                                              </div>
                                            )}
                                          <tr
                                            key={`${course.id}--${idx}`}
                                            className="odd:bg-white even:bg-gray-100 hover:bg-gray-100  "
                                          >
                                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 ">
                                              <span
                                                className={` h-4 w-4 block  rounded-full border border-solid p-1  ${course.module === courseOnModule ? " border-blue-800 " : " border-black/50"} ${
                                                  course.completed
                                                    ? " bg-[radial-gradient(circle_at_bottom_left,_var(--tw-gradient-stops))] from-blue-600 via-blue-600 to-transparent"
                                                    : "bg-transparent"
                                                }

                                                `}
                                              ></span>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 ">
                                              {course.type}
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 ">
                                              {course.title}
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 ">
                                              {course.category}
                                            </td>

                                            <td className="px-6 py-4 whitespace-nowrap text-end text-sm font-medium">
                                              <button
                                                onClick={() =>
                                                  handler({
                                                    course,
                                                    planId: plan.planId
                                                  })
                                                }
                                                type="button"
                                                className="inline-flex uppercase items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-blue-600 hover:text-blue-800 disabled:opacity-50 disabled:pointer-events-none"
                                              >
                                                start
                                              </button>
                                            </td>
                                          </tr>
                                        </>
                                      );
                                    })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div>
            {(!isLoading || !isLoadingPlan) && (
              <div className="courses">
                <NoCoursesPage />
              </div>
            )}
          </div>
        )}
      </CourseCont>
    </div>
  );
}

const Card = styled.div`
  border: 1px solid #ddd;
  padding: 5px 16px;
  border-radius: 8px;
  display: flex-column;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.h4`
  margin: 0;
`;

export default Courses;
