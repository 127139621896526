import { courseInfoWaec } from "../components/courses/courseInfo";
import Layout from "../components/layout/Layout";
import CoursesInfo from "../components/courses/CoursesInfo";

function WaecCorsesPg() {
  return (
    <Layout>
      <CoursesInfo data={courseInfoWaec} />
    </Layout>
  );
}

export default WaecCorsesPg;
