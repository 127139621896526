import React, { useEffect, useState } from "react";

import { ARTICLE_ENDPOINT } from "api/ACTION";
import { ClipLoader } from "react-spinners";
import { Link } from "react-router-dom";
import { MdArrowRight } from "react-icons/md";
import { convertUTCToLocalTime } from "utils/utc-to-date";
import jmsApp from "api/jms";
import { override } from "utils/admin-roles";
// import { testArticles } from "..";
import { useLocation } from "react-router-dom";

export default function ArticlesSort() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  // Get a specific query string parameter
  const by = searchParams.get("by");

  const [isFetchingArticles, setIsFetchingArticles] = useState(false);
  const [articles, setArticles] = useState([]);
  useEffect(() => {
    getAllArticles();
  }, []);
  const getAllArticles = async () => {
    try {
      setIsFetchingArticles(true);

      const { data } = await jmsApp.get(ARTICLE_ENDPOINT.ARTICLE_PUBLISHED());
      if (data.success) {
        // console.log(data);
        setArticles(data.article);
      }
    } catch (err) {
      // console.log(err);
    } finally {
      setIsFetchingArticles(false);
    }
  };

  if (isFetchingArticles) {
    return (
      <div className=" w-full h-full flex  items-center justify-center">
        <ClipLoader
          color="#46555C"
          loading={isFetchingArticles}
          cssOverride={override}
          size={30}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    );
  }
  const sortedArticles = [...articles].sort((a, b) =>
    a[by.toLowerCase()].localeCompare(b[by.toLowerCase()])
  );
  // console.log(articles);
  return (
    <div className="w-full px-6 py-2 flex flex-col items-start justify-start  gap-4">
      <div className="w-full flex items-center justify-start gap-6 text-[#b97868] font-bold uppercase">
        <Link to={"/"}>Home</Link>
        <MdArrowRight size={40} />
        <span>SEARCH</span>
        <MdArrowRight size={40} />
        <span>{by} index</span>
      </div>
      <div className="uppercase text-[#21046e] text-start w-full ">
        Browse {by} index
      </div>
      <div className=" w-full border rounded-md shadow-2xl p-6">
        {by.toLowerCase() === "title" ? (
          <div className="flex flex-col">
            <div className="-m-1.5 overflow-x-auto">
              <div className="p-1.5 min-w-full inline-block align-middle">
                <div className="overflow-hidden">
                  <table className="min-w-full divide-y divide-gray-200 ">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase"
                        >
                          title
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase"
                        >
                          description
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase"
                        >
                          author
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase"
                        >
                          country
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase"
                        >
                          date
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 ">
                      {sortedArticles.map((article, idx) => (
                        <tr key={idx}>
                          <td className="px-6 py-4 whitespace-nowrap   text-sm font-medium text-gray-800 ">
                            {article.title}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 ">
                            {article.description}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 ">
                            {article.author}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 ">
                            {article.country}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 ">
                            {convertUTCToLocalTime(article.createdAt)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex flex-col">
            <div className="-m-1.5 overflow-x-auto">
              <div className="p-1.5 min-w-full inline-block align-middle">
                <div className="overflow-hidden">
                  <table className="min-w-full divide-y divide-gray-200 ">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase"
                        >
                          Author
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase"
                        >
                          description
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase"
                        >
                          title
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase"
                        >
                          country
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase"
                        >
                          date
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 ">
                      {sortedArticles.map((article, idx) => (
                        <tr key={idx}>
                          <td className="px-6 py-4 whitespace-nowrap   text-sm font-medium text-gray-800 ">
                            {article.author}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 ">
                            {article.description}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 ">
                            {article.title}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 ">
                            {article.country}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 ">
                            {convertUTCToLocalTime(article.createdAt)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
