import { ADMIN_ROLES, override } from "utils/admin-roles";
import { Detail, IconSize } from "./Articles.style";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { ARTICLE_ENDPOINT } from "api/ACTION";
import { BiArrowBack } from "react-icons/bi";
import ClipLoader from "react-spinners/ClipLoader";
import { LOCAL_STORAGE } from "api/LOCALSTORAGE";
import jmsApp from "api/jms";
import { toast } from "react-hot-toast";

function ArticleComp({ articleId }) {
  const { id } = useParams();
  const navigate = useNavigate();
  const [article, setArticle] = useState([]);
  const [isFetchingArticle, setIsFetchingArticle] = useState(true);
  const [isSendingArticle, setIsSendingArticle] = useState(false);
  const userRole = LOCAL_STORAGE.user().type;
  const userId = LOCAL_STORAGE.user().id;
  const handleData = async () => {
    let articleId = id;
    try {
      const { data } = await jmsApp.get(ARTICLE_ENDPOINT.GET_ARTICLE_BY_ID(), {
        params: { articleId: articleId }
      });
      if (data.success) {
        setArticle(data.article);
      }
    } catch (error) {
      if (error.response?.status === 401) {
        navigate("/login");
      }
      if (error.response) {
        toast.error(error.response.data.error);
      } else {
        toast.error(error.mssage);
      }
      navigate(-1);
    } finally {
      setIsFetchingArticle(false);
    }
    return;
  };

  async function handleSendReviewToAuthor(editor) {
    try {
      setIsSendingArticle(true);
      await toast.promise(
        jmsApp.post(ARTICLE_ENDPOINT.SEND_REVIEW(), {
          articleId: article.articleId,
          editorId: editor
        }),
        {
          loading: "Sending article review to author....",
          error: (err) =>
            err?.response?.data?.error ??
            "Error encountered while sending review...",
          success: "Article review sent successfully"
        }
      );
      // console.log(data);
    } catch (err) {
      // console.log(err);
    } finally {
      setIsSendingArticle(false);
    }
  }
  useEffect(() => {
    handleData(articleId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isFetchingArticle) {
    return (
      <div className="fixed top-0 left-0 w-full h-screen flex items-center justify-center bg-gray-100 bg-opacity-75 !mt-0 backdrop:blur-md">
        <ClipLoader
          color="#333333"
          loading={isFetchingArticle}
          cssOverride={override}
          size={40}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    );
  }

  return (
    <div className="relative w-full min-h-screen flex items-start justify-center py-10">
      {isSendingArticle && (
        <div className="fixed top-0 left-0 w-full h-screen flex items-center justify-center bg-gray-100 bg-opacity-75 !mt-0 backdrop:blur-md">
          <ClipLoader
            color="#333333"
            loading={isSendingArticle}
            cssOverride={override}
            size={40}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      )}
      <IconSize>
        <div
          className=" text-[12px]  sm:text-lg lg:text-xl border border-solid rounded-full transition-all duration-300 ease-in-out hover:bg-black/70 hover:text-white absolute left-0 top-0 transform p-3 translate-x-1/2 translate-y-1/2"
          onClick={(e) => navigate(-1)}
        >
          <BiArrowBack />
        </div>
      </IconSize>
      <div className=" w-4/5 mx-auto h-full ">
        <div className="  w-full">
          <div className=" px-6 py-3  font-medium text-black uppercase text-center text-2xl">
            {article.title}
          </div>
        </div>
        <div className=" w-full">
          <div className=" px-6 py-3  font-medium text-black/70 uppercase text-center text-lg ">
            {article.description}
          </div>
        </div>
        <div className=" w-full flex flex-col sm:flex-row items-center justify-center sm:gap-4  overflow-x-auto">
          <div className=" px-6 py-3 text-center text-sm font-medium text-black/70 uppercase">
            {article.author}
          </div>
          {userRole === ADMIN_ROLES.SUPER_ADMIN && (
            <div className=" px-6 py-3 text-center text-sm font-medium text-black/70 uppercase">
              {article.email}
            </div>
          )}
        </div>
        <div
          className={` w-full grid ${userRole === ADMIN_ROLES.SUPER_ADMIN ? " grid-cols-3" : " grid-cols-2"}  divide-x divide-black/50 overflow-x-auto`}
        >
          {userRole === ADMIN_ROLES.SUPER_ADMIN && (
            <div className=" px-6 py-3 text-center text-[10px] sm:text-sm font-medium text-black/70 uppercase">
              {article.phone}
            </div>
          )}
          <div className=" px-6 py-3 text-center text-[10px] sm:text-sm font-medium text-black/70 uppercase">
            {article.country}
          </div>
          <div className=" px-6 py-3 text-center text-[10px] sm:text-sm font-medium text-black/70 uppercase">
            <a href={article.articleUrl}>Link</a>
          </div>
        </div>
        {userRole === ADMIN_ROLES.SUPER_ADMIN && (
          <div className="  w-full">
            <div className=" px-6 py-3  font-medium text-black/70 uppercase text-center text-xl">
              Assigned Admin
            </div>
          </div>
        )}
        {userRole === ADMIN_ROLES.SUPER_ADMIN && article.editors.length > 0 ? (
          <div className="-m-1.5 overflow-auto">
            <div className="p-1.5 min-w-full inline-block align-middle">
              <div className="overflow-hidden">
                <div className="table border-collapse table-auto w-full divide-y divide-gray-200">
                  <div className="table-row-group divide-y divide-gray-200 bg-white ">
                    <div className="table-row uppercase">
                      <div className="table-cell px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800">
                        Name
                      </div>
                      <div className="table-cell px-6 py-4 whitespace-nowrap text-sm text-gray-800 ">
                        Email
                      </div>
                      <div className="table-cell px-6 py-4 whitespace-nowrap text-sm text-gray-800 ">
                        Phone
                      </div>
                      <div className="table-cell px-6 py-4 whitespace-nowrap text-sm text-gray-800 ">
                        Type
                      </div>
                      <div className="table-cell px-6 py-4 whitespace-nowrap text-sm text-gray-800 ">
                        Comment
                      </div>
                      {article.status === "reviewed" && (
                        <div className="table-cell px-6 py-4 whitespace-nowrap text-sm text-gray-800 ">
                          To the author
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="table-row-group divide-y divide-gray-200 bg-white ">
                    {/* <!-- Additional rows for each editor --> */}
                    {article.editors.map((editor) => (
                      <div className="table-row" key={editor.id}>
                        <div className="table-cell px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 ">
                          {editor.name}
                        </div>
                        <div className="table-cell px-6 py-4 whitespace-nowrap text-sm text-gray-800 ">
                          {editor.email}
                        </div>
                        <div className="table-cell px-6 py-4 whitespace-nowrap text-sm text-gray-800 ">
                          {editor.phone}
                        </div>
                        <div className="table-cell px-6 py-4 whitespace-nowrap text-sm text-gray-800  capitalize">
                          {editor.type}
                        </div>
                        <div className="table-cell px-6 py-4 whitespace-nowrap text-sm text-gray-800 ">
                          <p className="w-[400px] text-wrap">
                            {editor.comment}
                          </p>
                        </div>
                        {article.status === "reviewed" && (
                          <div className="table-cell px-6 py-4 whitespace-nowrap text-sm text-gray-800 ">
                            <button
                              disabled={isSendingArticle}
                              onClick={() =>
                                handleSendReviewToAuthor(editor.id)
                              }
                              className=" p-2 uppercase font-bold bg-blue-600 text-white rounded-lg transition-all duration-300 ease-in-out hover:shadow-lg active:shadow-none shadow-blue-900"
                            >
                              forward
                            </button>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className=" w-full py-10 h-10  text-center text-black/50 ">
            {article?.editors?.filter((editor) => editor.id === userId).length >
            0
              ? article.editors
                  .filter((editor) => editor.id === userId)
                  .map((ed) => (
                    <div
                      className="w-4/5 mx-auto flex flex-col items-center justify-start space-y-2"
                      key={ed.id}
                    >
                      <p className=" underline capitalize ">Your comment</p>
                      <p className="w-full italic text-wrap">
                        {ed.comment || "No Comment yet"}
                      </p>
                    </div>
                  ))
              : article.status}
          </div>
        )}
      </div>
    </div>
  );
  // return (
  //   <div className="">
  //     <Detail>
  //       <div className=" w-4/5 sm:w-3/5 lg:w-2/5  bg-black/50 rounded-lg shadow-2xl mx-auto p-4 font-medium text-white text-lg ">
  //         <IconSize>
  //           <div
  //             className=" text-[12px]  sm:text-lg lg:text-xl"
  //             onClick={(e) => navigate(-1)}
  //           >
  //             <BiArrowBack />
  //           </div>
  //         </IconSize>
  //         <div className=" flex flex-col items-start w-4/5 mx-auto justify-start space-y-6 ">
  //           <div className="flex w-full items-center justify-center text-center">
  //             {/* <h4>Book Title</h4> */}
  //             <span className=" uppercase text-sm  sm:text-lg lg:text-2xl ">
  //               {article?.title}
  //             </span>
  //           </div>
  //           <div className="description text-[10px]  sm:text-sm lg:text-lg">
  //             <h4 className=" capitalize underline ">Description</h4>
  //             <span>{article?.description}</span>
  //           </div>
  //           <div className="w-full text-[10px]  sm:text-sm lg:text-lg">
  //             <h4 className=" capitalize underline ">email</h4>
  //             <p className="break-words w-full ">{article?.email}</p>
  //           </div>
  //           <div className=" flex item-start space-y-4 sm:space-y-0 sm:items-center flex-col sm:flex-row justify-between w-full sm:w-4/5 lg:w-3/5 text-[10px]  sm:text-sm lg:text-lg">
  //             <div className="country">
  //               <h4 className=" capitalize underline ">Country</h4>
  //               <span className=" uppercase ">{article?.country}</span>
  //             </div>
  //             <div className="status">
  //               <h4 className=" capitalize underline ">Status</h4>
  //               <span className=" uppercase ">{article?.status}</span>
  //             </div>
  //           </div>
  //         </div>
  //         {article.comment.length > 0 &&
  //           article.comment.map((comment, idx) => (
  //             <div key={idx}>
  //               <span>comment written by : {comment?.author ?? ""}</span>
  //               <span>comment : {comment?.comment ?? ""}</span>
  //             </div>
  //           ))}
  //         <div className="flex items-center w-full justify-end space-x-6 font-semibold tracking-wider leading-tight text-[10px] sm:text-sm  lg:text-xl pt-10">
  //           {/* <h4>Author:</h4> */}
  //           <span className=" uppercase ">{article?.author}</span>
  //         </div>
  //       </div>
  //     </Detail>
  //   </div>
  // );
}

export default ArticleComp;
