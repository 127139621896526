import Colors from "utils/colors";
import media from "utils/media";
import styled from "styled-components";

export const Modal = styled.div`
  background: #33333390;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .container {
    background: white;
    border-radius: 16px;
    max-width: 600px;
    width: 100%;
    display: flex;
    margin: auto;
    flex-direction: column;
    padding: 25px 20px;
    ${media.mobile`
height:100vh;
border-radius:unset;
display: flex;
  justify-content: center;
  ${"" /* align-items: center; */}
	`};

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding-top: 10px;
      padding-bottom: 14px;
      cursor: pointer;

      span {
        font-weight: 700;
        font-size: 26px;
      }
    }
  }
`;

export const StaffCont = styled.div`
  background: #e5e5e5;
  padding: 25px 40px;
  width: 100%;
  min-height: 100vh;

  ${media.mobile`
  padding: 25px 20px;
`};

  .heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    ${media.mobile`
      flex-direction:column;
      gap:20px;
    justify-content: flex-start;
    align-items: flex-start;
	`};
    button {
      width: 180px;
      color: white;
      background: ${Colors.primary};
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 12px;
      font-size: 16px;
      font-weight: 600;
      gap: 6px;
      cursor: pointer;
      border: 1px solid transparent;
      transition: background 0.2s ease-in-out;

      :hover {
        background: transparent;
        color: ${Colors.primary};
        border-color: ${Colors.primary};
      }
    }
  }
  .search {
    margin: 20px 0;
  }
  .admins {
    /* overflow: auto; */
    margin-top: 25px;
  }
`;
export const VerifyCont = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  background: #e5e5e5;
  flex-direction: column;
  h1 {
    font-size: 20px;
  }
`;
