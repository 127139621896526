import React from 'react'
import { AgricDiv, AgricContainer } from './style'

function ManagementPage() {
  return (
    <div>
      <AgricDiv>
        <AgricContainer>ManagementPage</AgricContainer>
      </AgricDiv>
    </div>
  )
}

export default ManagementPage