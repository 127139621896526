import { ARTICLE_ENDPOINT, AUTH_ENDPOINTS } from "api/ACTION";
import React, { useEffect, useState } from "react";

import Checkbox from "../sharedComponents/CheckBox";
import ClipLoader from "react-spinners/ClipLoader";
import Dropdown from "../sharedComponents/DropDown";
import { IoClose } from "react-icons/io5";
import authStore from "mobx/AuthStore";
import jmsApp from "api/jms";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import toast from "react-hot-toast";

// import CustomDropdown from "../sharedComponents/drop-down/drop-down";

export function handleAssign(article) {
  authStore.toggleAssignModal(article.articleId);
}
const StyledStatus = styled.span`
  background-color: ${(props) =>
    String(props.status).toLowerCase() === "verified" ? "green" : "red"};
  color: white;
  text-transform: uppercase;
  padding: 10px;
  border-radius: 10px;
`;
const ArticleAssign = observer(({ refreshData }) => {
  // useObserver;
  const [adminRole, setAdminRole] = useState("");
  const [roleAdmins, setRoleAdmins] = useState([]);
  const articleId = authStore.getCurrentArticle();
  const [checkedAdmins, setCheckedAdmins] = useState([]);
  const [article, setArticle] = useState(null);
  const [isFetchingArticle, setIsFetchingArticle] = useState(true);
  const [isGettingAdmins, setIsGettingAdmins] = useState(false);
  const [isAssigning, setIsAssigning] = useState(false);
  const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "black"
  };

  useEffect(() => {
    async function getCurrentArticle(articleId = "") {
      try {
        const { data } = await jmsApp.get(
          ARTICLE_ENDPOINT.GET_ARTICLE_BY_ID(),
          {
            params: { articleId }
          }
        );
        setArticle(data.article);
        setCheckedAdmins(data.article.editors.map((editor) => editor.id));
      } catch (err) {
        authStore.toggleAssignModal();
      } finally {
        setIsFetchingArticle(false);
      }
    }
    getCurrentArticle(articleId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    async function getAdminsByRole(role) {
      setIsGettingAdmins(true);
      try {
        const { data } = await toast.promise(
          jmsApp.get(AUTH_ENDPOINTS.GET_ADMIN_ACCOUNTS_BY_ROLE(), {
            params: {
              role: role
            }
          }),
          {
            loading: `Getting ${role} admins`,
            success: (data) => `${data.data.account.length || 0} admins found.`,
            error: () => `Admin search failed`
          }
        );
        setRoleAdmins(data.account);
      } catch (err) {}finally{
        
        setIsGettingAdmins(false);
      }
    }
    if (adminRole !== "") getAdminsByRole(adminRole);
  }, [adminRole]);
  function handleCheckVal(admin) {
    const adminChecked = checkedAdmins.includes(admin);
    if (adminChecked) {
      setCheckedAdmins((arr) => [...arr.filter((val) => val !== admin)]);
    } else {
      setCheckedAdmins((arr) => [...arr, admin]);
    }
  }
  async function handleAssignment() {
    try {
      setIsAssigning(true);
      await toast.promise(
        jmsApp.post(ARTICLE_ENDPOINT.ASSIGN_ARTICLE(), {
          articleId: article.articleId,
          editors: checkedAdmins
        }),
        {
          loading: `Article assignment in progress...`,
          success: (data) => {
            authStore.toggleAssignModal();

            return `${data.msg || "Article assignment successful."}`;
          },
          error: (err) => `${err.response.data.error}`
        }
      );
      setIsAssigning(false);
      refreshData();
      // toast.success(data.msg);
    } catch (err) {
    } finally {
      setIsAssigning(false);
    }
  }
  // if (!isModalOpen) {
  //   // setCheckedAdmins([]);
  //   // setAdminRole("");
  //   return;
  // }
  if (isFetchingArticle) {
    return (
      <div className="fixed top-0 left-0 w-full h-screen flex items-center justify-center bg-gray-100 bg-opacity-75 !mt-0 backdrop:blur-md">
        <ClipLoader
          color="#333333"
          loading={isFetchingArticle}
          cssOverride={override}
          size={40}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    );
  }
  return (
    <div className=" fixed top-0 left-0 w-full min-h-screen bg-slate-400/50 backdrop-blur-sm flex flex-col items-center justify-center">
      <div className="relative p-6 w-4/5 md:w-3/5 lg:w-1/2 bg-white flex flex-col items-center justify-center space-y-4  rounded-lg shadow-xl">
        <button
          className=" absolute top-0 right-0 mr-4 mt-4 p-2 rounded-full text-black hover:bg-black hover:text-white cursor-pointer "
          onClick={() => authStore.toggleAssignModal()}
        >
          <IoClose />
        </button>
        <h1 className=" font-bold text-2xl uppercase text-center">
          Assign Journal
        </h1>
        <p className=" font-semibold text-xs uppercase text-center text-black/50 italic">
          {article?.title ?? ""}
        </p>
        <p className=" font-semibold text-xs uppercase text-center text-black/50 italic">
          By : {article?.author ?? ""}
        </p>
        <div className="flex items-center flex-col sm:flex-row justify-center space-y-2 sm:space-y-0 sm:space-x-4 w-full  ">
          <p className=" ">Assign to :</p>
          <div className="relative flex items-start justify-start sm:self-start">
            <Dropdown
              additionalClass="!m-0 !min-w-[200px]"
              label="Select Admin Role"
              options={["super", "formatter", "sub", "editor", "e-learning"]}
              lblList={["Super", "formatter", "Sub", "Editor", "E-learning"]}
              selectedOption={adminRole}
              onSelectOption={(option) => {
                setAdminRole(option.val);
              }}
            />
            {/* <CustomDropdown
              options={[
                { key: "super", value: "SUPER ADMIN" },
                { key: "third", value: "THIRD ADMIN" },
                { key: "sub", value: "SUB ADMIN" },
                { key: "editor", value: "EDITOR" }
              ]}
              defaultValue={{ key: "super", value: "SUPER ADMIN" }}
              onSelect={(option) => {
                setAdminRole(option.val);
              }}
              extendedClassNames
              title={"Select Admin Role"}
            /> */}
          </div>
        </div>
        {isGettingAdmins && (
          <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-gray-100 bg-opacity-75 !mt-0 backdrop:blur-md">
            <ClipLoader
              color="#333333"
              loading={isGettingAdmins}
              cssOverride={override}
              size={40}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        )}
        {roleAdmins.length > 0 ? (
          <div className="w-full flex flex-col ">
            <div className="flex flex-col">
              <div className="-m-1.5 overflow-x-auto w-full">
                <div className="p-1.5 min-w-full overflow-x-auto inline-block align-middle">
                  <div className="border rounded-lg shadow overflow-hidden ">
                    <table className="min-w-full divide-y divide-gray-200 ">
                      <thead className="bg-gray-200 ">
                        <tr>
                          <th
                            scope="col"
                            className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase "
                          >
                            select
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase "
                          >
                            name
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase "
                          >
                            email
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-end text-xs font-medium text-gray-500 uppercase "
                          >
                            Status
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 ">
                        {roleAdmins.map((admin, idx) => (
                          <tr key={`${idx}-${admin.name}`}>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 ">
                              <Checkbox
                                classNames={`${
                                  checkedAdmins.includes(admin.id)
                                    ? "!bg-blue-400"
                                    : ""
                                }`}
                                disabled={
                                  String(admin.status).toLowerCase() !==
                                  "verified"
                                }
                                onClick={() => handleCheckVal(admin.id)}
                                checked={checkedAdmins.includes(admin.id)}
                              />
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 ">
                              {admin.name}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 ">
                              {admin.email}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-end text-sm font-medium">
                              <StyledStatus status={admin.status}>
                                {admin.status}
                              </StyledStatus>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className=" flex w-full items-end justify-end my-4">
              <button
                disabled={checkedAdmins.length === 0}
                onClick={handleAssignment}
                className="relative disabled:cursor-not-allowed disabled:hover:bg-transparent disabled:hover:border-black/50 disabled:hover:text-black/50 uppercase font-primary text-lg px-4 py-2 rounded-lg text-white sm:text-black/50 border border-solid bg-blue-400 sm:bg-transparent border-transparent  sm:border-black/50 hover:bg-blue-400 hover:border-transparent hover:text-white transition-all duration-300 ease-in-out  "
              >
                {isAssigning && (
                  <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-gray-100 bg-opacity-75 !mt-0 backdrop:blur-md">
                    <ClipLoader
                      color="#333333"
                      loading={isAssigning}
                      cssOverride={override}
                      size={40}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                    />
                  </div>
                )}
                <span>{isAssigning ? "assigning..." : "submit"}</span>
              </button>
            </div>
          </div>
        ) : (
          <p>No admin account found!</p>
        )}
      </div>
    </div>
  );
});
export default ArticleAssign;
