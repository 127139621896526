import * as Yup from "yup";

import { AuthBottomLink, AuthButton, AuthHeader } from ".";
import {
  AuthCard,
  AuthContent,
  Authcontainer,
  LoginCont,
  LoginContainer,
  LoginDiv
} from "./style";
import { Div, Img } from "globalStyles";

import { AppLogo } from "utils/assets";
import { LOGIN_ROUTE } from "routes";
import { PASSWORD_RECOVERY_ENDPOINT } from "api/ACTION";
import TextInput from "components/TextInput";
import authStore from "mobx/AuthStore";
import bg from "../../assets/images/banner2.jpg";
import jmsApp from "api/jms";
import { sendRecoveryMail } from "utils/UserLogout";
import { toast } from "react-hot-toast";
import { useFormik } from "formik";
import { useState } from "react";

// import { sendEmail } from "services/AuthService";
// import { useNavigate } from "react-router-dom";

// import { VERIFY_RECOVERY_ROUTE } from "routes";

function ForgotPassword() {
  const [loading, setLoading] = useState(false);
  // const navigate = useNavigate();
  const formik = useFormik({
    enableReinitialize: false,
    initialValues: {
      email: ""
    },
    onSubmit: async (info, helpers) => {
      setLoading(true);
      // const checkInfo = () => {
      //   if (info.email.includes("@")) {
      //     return "email";
      //   } else return "username";
      // };
      try {
        // const { data } = await jmsApp.get(
        //   `${PASSWORD_RECOVERY_ENDPOINT.FORGOT_PASSWORD()}${checkInfo()}=${
        //     info.email
        //   }`
        // );
        const { data } = await jmsApp.get(
          PASSWORD_RECOVERY_ENDPOINT.FORGOT_PASSWORD(),
          {
            params: {
              email: info.email
            }
          }
        );
        if (data.success) {
          const { user } = data;
          sendRecoveryMail(user.userId, user.email).then((data) => {
            localStorage.setItem("recoveryID", JSON.stringify(data.id));
            setLoading(false);
            helpers.resetForm();
          });
        }
      } catch (err) {
        setLoading(false);
        if (err.response.data) {
          // toast.error(err.response.data.error);
        } else {
          // toast.error(err.message);
        }
      }
    },
    validationSchema: Yup.object({
      email: Yup.string().max(50).required().label("Username/Email")
    })
  });

  return (
    <Authcontainer style={{ backgroundImage: `url(${bg})` }}>
      <AuthContent>
        <Img src={AppLogo} alt="LP" />
        <LoginContainer>
          <LoginDiv onSubmit={formik.handleSubmit}>
            <AuthCard>
              <LoginCont>
                <AuthHeader text="Don’t panic, we’ll just reset your password" />
                <TextInput
                  label="Username/Email"
                  name="email"
                  id="email"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  error={formik.errors.email}
                  visible={formik.touched.email}
                  placeholder="Enter your username or email"
                  mt="2.5rem"
                />
                <Div width="100%" display="flex" justify="flex-end">
                  <AuthBottomLink link={LOGIN_ROUTE} text="" linktext="Login" />
                </Div>
                <AuthButton
                  type="submit"
                  disabled={authStore.isLoading ? true : false}
                  title={authStore.isLoading ? "Loading..." : "Continue"}
                  loading={loading}
                />
              </LoginCont>
            </AuthCard>
          </LoginDiv>
        </LoginContainer>
      </AuthContent>
    </Authcontainer>
  );
}

export default ForgotPassword;
