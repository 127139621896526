import { AuthBottomDIv, AuthText, Authbtn, Authcontainer } from "./style";

import { AppLogo } from "utils/assets";
import { ClipLoader } from "react-spinners";
import { Img } from "globalStyles";
import { Link } from "react-router-dom";
import React from "react";

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "white",
};

function AuthComponent({ children }) {
  return (
    <Authcontainer>
      <Img src={AppLogo} alt='BSL' />
      {/* <AuthContent>{children}</AuthContent> */}
    </Authcontainer>
  );
}

export default AuthComponent;

export const AuthHeader = ({ text }) => {
  return <AuthText>{text}</AuthText>;
};
export const AuthButton = ({ title = "Continue", loading, ...rest }) => {
  return (
    <Authbtn {...rest} disabled={loading === true}>
      {loading ? (
        <ClipLoader
          color='#46555C'
          loading={loading}
          cssOverride={override}
          size={30}
          aria-label='Loading Spinner'
          data-testid='loader'
        />
      ) : (
        title
      )}
    </Authbtn>
  );
};

export const AuthBottomLink = ({
  text = "Already have an account?",
  link,
  linktext = "Log in",
}) => {
  return (
    <AuthBottomDIv>
      <p className='auth--bottom-link'>{text}</p>{" "}
      <Link className='link-bottom' to={link}>
        {linktext}
      </Link>
    </AuthBottomDIv>
  );
};
