// import UserLoginComp from 'components/Home/Admin Login/userLogin'

import "./Elearn.css";

import React, { useEffect, useState } from "react";

import { ArtHead } from "components/Home/Article Section/style";
import { BlogHeadCon } from "components/Home/Blog Section/style";
import Mainlayout from "layout/MainLayout";
import PaymentCard from "components/payment/PaymentCard";
import { PaymentCont } from "components/payment/payment.style";
import { TextBody } from "./style";
import { USER_PLAN } from "api/ACTION";
import jmsApp from "api/jms";
import styled from "styled-components";
import textBanner from "../../assets/images/bgBanner.png";
import { toast } from "react-hot-toast";
import tw from "twin.macro";
import { useNavigate } from "react-router-dom";

// import PaymentCard from 'components/payment/PaymentCard'

// import SupportPricing from "components/SupportPricing";

const AboutSection = styled.section`
  /* // padding: 170px 8.5% 0 8.5%; */
  background-color: #f2f2f2;
  padding: 0;
  /* ${tw`sm:pt-4 pt-[5rem]`} */
`;

export default function Elearn() {
  const [plan, setPlan] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleData = async () => {
    try {
      setIsLoading(true);
      const { data } = await jmsApp.get(USER_PLAN.GET_PLAN());

      if (data.success) {
        setPlan(data.plans);
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);

      // toast.error("error");
    }
  };

  const handler = async (_e) => {
    navigate("/create-account");
  };

  useEffect(() => {
    handleData();
  }, []);
  return (
    <div>
      <Mainlayout>
        <AboutSection className="  ">
          <BlogHeadCon
            style={{ backgroundImage: `url(${textBanner})`, height: "100px" }}
          >
            <ArtHead style={{ color: "#ffffff" }}>
              JMS learning Platform
            </ArtHead>
          </BlogHeadCon>
          {/* <AboutSection>
          
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              paddingTop: "40px",
              paddingBottom: "40px",
            }}
          > */}
          <TextBody>
            <p
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                paddingTop: "40px",
                paddingBottom: "40px"
              }}
            >
              A learning platform is a digital tool or software that facilitates
              the creation, delivery, management, and assessment of educational
              content and experiences. It provides a comprehensive learning
              environment that allows educators to design and deliver online
              courses, interactive tutorials, and other educational materials to
              learners anywhere, at any time.
            </p>
          </TextBody>
        </AboutSection>

        <section style={{ margin: "auto", maxWidth: "" }}>
          <PaymentCont>
            <div className="sub">
              <h4>
                We create a monthly pricing package for all standard students
              </h4>
              <p>
                Basically we create this package for those who are really
                interested and get benifited from our courses or books. We want
                to make a low cost package for them. So that they can purchase
                any courses with the package they buy from us. Also will get
                free books from every packages.
              </p>
            </div>
            {isLoading === false && (
              <>
                {" "}
                <div className="paymentOffers mx-auto">
                  {plan?.map((option, i) => (
                    <div key={option.planId}>
                      <PaymentCard
                        id={option.planId}
                        key={option.planId}
                        planId={option.planId}
                        price={option.price}
                        handler={handler}
                        type={option.type}
                        plan={option}
                      />
                    </div>
                  ))}
                </div>
              </>
            )}
            {isLoading === true && (
              <>
                <div className="paymentOffers mx-auto py-10">
                  <div className="w-60  h-96  bg-white shadow-md  overflow-hidden animate-pulse pr-6">
                    <div className="ms-4 mt-2 w-full">
                      <div
                        className="h-4 bg-gray-200 rounded-full "
                        style={{
                          width: "40%"
                        }}
                      ></div>

                      <ul className="mt-5 space-y-3">
                        <li className="w-full h-4 bg-gray-200 rounded-full "></li>
                        <li className="w-full h-4 bg-gray-200 rounded-full "></li>
                        <li className="w-full h-4 bg-gray-200 rounded-full "></li>
                        <li className="w-full h-4 bg-gray-200 rounded-full "></li>
                      </ul>
                    </div>
                  </div>
                  <div className="w-60  h-96  bg-white shadow-md  overflow-hidden animate-pulse pr-6">
                    <div className="ms-4 mt-2 w-full">
                      <div
                        className="h-4 bg-gray-200 rounded-full "
                        style={{
                          width: "40%"
                        }}
                      ></div>

                      <ul className="mt-5 space-y-3">
                        <li className="w-full h-4 bg-gray-200 rounded-full "></li>
                        <li className="w-full h-4 bg-gray-200 rounded-full "></li>
                        <li className="w-full h-4 bg-gray-200 rounded-full "></li>
                        <li className="w-full h-4 bg-gray-200 rounded-full "></li>
                      </ul>
                    </div>
                  </div>
                  <div className="w-60  h-96  bg-white shadow-md  overflow-hidden animate-pulse pr-6">
                    <div className="ms-4 mt-2 w-full">
                      <div
                        className="h-4 bg-gray-200 rounded-full "
                        style={{
                          width: "40%"
                        }}
                      ></div>

                      <ul className="mt-5 space-y-3">
                        <li className="w-full h-4 bg-gray-200 rounded-full "></li>
                        <li className="w-full h-4 bg-gray-200 rounded-full "></li>
                        <li className="w-full h-4 bg-gray-200 rounded-full "></li>
                        <li className="w-full h-4 bg-gray-200 rounded-full "></li>
                      </ul>
                    </div>
                  </div>
                  <div className="w-60  h-96 bg-white shadow-md  overflow-hidden animate-pulse pr-6">
                    <div className="ms-4 mt-2 w-full">
                      <div
                        className="h-4 bg-gray-200 rounded-full "
                        style={{
                          width: "40%"
                        }}
                      ></div>

                      <ul className="mt-5 space-y-3">
                        <li className="w-full h-4 bg-gray-200 rounded-full "></li>
                        <li className="w-full h-4 bg-gray-200 rounded-full "></li>
                        <li className="w-full h-4 bg-gray-200 rounded-full "></li>
                        <li className="w-full h-4 bg-gray-200 rounded-full "></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </>
            )}
          </PaymentCont>
        </section>

        {/* <SupportPricing /> */}
      </Mainlayout>
    </div>
  );
}
