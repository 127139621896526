import RegisterAdmin from "components/Admin/Auth/AdminRegister";
import React from "react";

function AdminRegisterPg() {
  return (
    <div>
      <RegisterAdmin />
    </div>
  );
}

export default AdminRegisterPg;
