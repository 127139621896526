import * as Yup from "yup";

import React, { useState } from "react";

import { ADMIN_ENDPOINTS } from "api/ACTION";
import { AiOutlineClose } from "react-icons/ai";
import { AuthButton } from "components/Auth";
import Dropdown from "../sharedComponents/DropDown";
import { Modal } from "./style";
import TextInput from "components/TextInput";
import authStore from "mobx/AuthStore";
import jmsApp from "api/jms";
import { toast } from "react-hot-toast";
import { useFormik } from "formik";

function AddAdmin({ onClose }) {
  const [loading, setLoading] = useState(false);
  const [adminRole, setAdminRole] = useState("");

  const formik = useFormik({
    enableReinitialize: false,
    initialValues: {
      email: "",
      role: ""
    },

    onSubmit: async (info, helpers) => {
      setLoading(true);
      try {
        info.role = adminRole.toLowerCase();

        const { data } = await toast.promise(
          jmsApp.post(ADMIN_ENDPOINTS.ADD_ADMIN(), info),
          {
            loading: `Sending invite to ${info.email}...`,
            success: (data) => data.data.msg,
            error: (err) => err.response.data.error
          },
          {
            loading: {
              className: "text-sm"
            }
          }
        );
        setLoading(false);
        if (data.sucess) {
          authStore.loading();
          helpers.resetForm();
          // toast.success(data.msg);
          onClose();
        }
      } catch (err) {
        setLoading(false);
        if (err.response.data) {
          // toast.error(err.response.data.error);
        } else {
          // toast.error(err.message);
        }
      }
    },

    validationSchema: Yup.object({
      email: Yup.string().max(255).email().required().label("Email")
    })
  });

  return (
    <Modal>
      <div className="container">
        <div className="header">
          <h4 className="font-bold text-[#333333] text-[24px]">Add Admin</h4>
          <span>
            <AiOutlineClose className="text-[#333333]" onClick={onClose} />
          </span>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <TextInput
            label="Email Address"
            name="email"
            type="email"
            id="email"
            // onChange={(e) => setValues({ ...values, email: e.target.value })}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            // value={values.email}
            value={formik.values.email}
            error={formik.errors.email}
            visible={formik.touched.email}
            placeholder="Email Address"
            mt="0.5rem"
          />
          <Dropdown
            label="Select Admin Role"
            options={["Super", "Formatter", "Sub", "Editor", "E-learning"]}
            lblList={["Super", "Formatter", "Sub", "Editor", "E-learning"]}
            selectedOption={adminRole}
            onSelectOption={(option) => {
              setAdminRole(option.val);
            }}
          />
          <AuthButton
            type="submit"
            disabled={authStore.isLoading ? true : false}
            title="Add"
            loading={loading}
          />
        </form>
      </div>
    </Modal>
  );
}

export default AddAdmin;
