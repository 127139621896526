import styled from "styled-components/macro";
import tw from "twin.macro";
import Colors from "utils/colors";
import media from "utils/media";

export const PaymentCont = styled.div`
  /* padding: 25px 0 40px 0; */
  width: 100%;
  height: 100%;
  // background: #e5e5e5;
  overflow-x: none;
  // margin-right: 5%;
  ${media.tablet`
    padding:unset;
	`};

  .heading {
    width: 100%;
    background: #efebf5;
    border-radius: 20px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 20px;
    h1 {
      font-style: normal;
      font-weight: 600;
      font-size: 30px;
      line-height: 50px;
      color: #0a033c;
      span {
        display: block;
      }
    }
  }
  .sub {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 0;
    margin: auto;
    width: 60%;
    ${media.smallDesktopMinimum`
    width: 80%;
	`};
    ${media.mobile`
    width: 95%;
`};

    h4 {
      font-style: normal;
      font-weight: 700;
      font-size: 30px;
      line-height: 40px;
      color: #0a033c;
      text-align: center;
      margin-bottom: 10px;
    }
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 30px;
      color: #5d5a6f;
      text-align: center;
    }
  }
  .paymentOffers {
    ${tw`flex items-center flex-wrap gap-4 sm:gap-8 mx-auto justify-center`}
    ${media.tablet`
    grid-template-columns: repeat(auto-fill, minmax(min(300px, 100%), 1fr));
    // margin: 0 30px 20px 30px;
	`};
    ${media.mobile`
    grid-template-columns: repeat(auto-fill, minmax(min(250px, 100%), 1fr));
    // margin: 0 30px;
	`};

    .plan {
      background: #ffffff;
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 280px;
      margin: 30px auto;
      padding: 15px 20px;
      ${tw`shadow-lg`}
      ${media.mobile`
      width: 230px;


	`};

      .head {
        width: 100%;
        padding: 10px 0;
        border-bottom: 1px solid rgba(10, 3, 60, 0.15);

        h5 {
          margin-top: 10px;
          ont-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 30px;
          color: #0a033c;
          text-transform: uppercase;
        }
      }
      .main {
        margin-top: 24px;

        p {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          font-style: normal;
          font-weight: 500;
          font-size: 15px;
          line-height: 16px;
          color: #5d5a6f;
          gap: 6px;
          margin: 16px 0;

          span {
            display: flex;
            width: 20px;
            height: 20px;
            ${'' /* flex-direction: column; */}
            justify-content: center;
            align-items: center;
            border: 1px solid #00df76;
            border-radius: 50%;
            color: #00df76;
            font-weight: 200;
          }
        }
        .price {
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 20px;
          margin-top: 18px;
          color: #0a033c;
        }

        .pay {
          margin-top: 22px;
          padding: 10px 0;
          text-align: center;
          border: 1px solid #9c4df4;
          border-radius: 5px;
          width: 100%;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          text-align: center;
          color: #9c4df4;
          cursor: pointer;
          background: unset;
          :hover {
            background: #9c4df4;
            color: #ffffff;
            transition: all 0.2s ease-in-out;
          }
        }
      }
      :hover {
        transform: scale(1.05);
        transition: transform 0.5s ease-in-out;
      }
    }
  }
`
export const CheckoutCont = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #e5e5e5;
  .container {
    width: 60%;
    background: ${Colors.white};
    border-radius: 20px;
    padding: 20px;
    ${media.smallDesktopMinimum`
    width: 75%;
	`};
    ${media.mobile`
    width: 90%;
	`};
    h4 {
      font-weight: 400;
      font-size: 24px;
      color: #252641;
      margin: 0px 0 40px 0;
    }
    .about {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      gap: 15px;

      ${media.mobile`
    flex-direction:column;
	`};

      h6,
      span {
        font-weight: 400;
        font-size: 16px;
      }
      p {
        font-weight: 400;
        font-size: 18px;
        line-height: 180%;
        letter-spacing: 0.02em;
        color: #5b5b5b;
      }
    }
    .form {
      ${media.mobile`
    margin-top:25px;
	`};
      div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #5b5b5b;
        padding: 20px 0;

        h4,
        span {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #5b5b5b;
          margin: unset;
        }
      }
      .total {
        h4,
        span {
          color: #000000;
        }
      }

      button {
        width: 80px;
        margin: 20px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background: ${Colors.primary};
        border-radius: 4px;
        padding: 10px 5px;
        color: white;
        cursor:pointer;
        :hover {
          background: ${Colors.white};
          border: 1px solid ${Colors.primary};
          color: ${Colors.primary};
          transition: all 0.2s ease-in-out;
        }
      }
    }
  }
`;
