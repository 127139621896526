import { ADMIN_ROLES, override } from "utils/admin-roles";
import { useEffect, useMemo, useState } from "react";

import { AUTH_ENDPOINTS } from "api/ACTION";
import AddAdmin from "./AddAdmin";
import ClipLoader from "react-spinners/ClipLoader";
import { FcDeleteDatabase } from "react-icons/fc";
import { HiPlus } from "react-icons/hi";
import { LOCAL_STORAGE } from "api/LOCALSTORAGE";
import Search from "../sharedComponents/Search";
import { StaffCont } from "./style";
import Table from "../sharedComponents/Table-v2";
import jmsApp from "api/jms";
import { staffHeading } from "../sharedComponents/articleData";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

// import authStore from "mobx/AuthStore";

function Staffs() {
  const [openModal, setOpenModal] = useState(false);
  const [value, setValue] = useState("");
  const [data, setData] = useState([]);
  const [fetchingStaff, setFetchingStaff] = useState(false);
  const columns = useMemo(() => staffHeading, []);
  const navigate = useNavigate();
  const currentUser = LOCAL_STORAGE.user();
  const userRole = currentUser?.type ?? "";
  const [ìsSackingStaff, setIsSackingStaff] = useState(null);

  // const currentADMIN = ADMIN_ROLES.SUPER;
  const handleData = async () => {
    setFetchingStaff(true);
    try {
      const { data } = await jmsApp.get(AUTH_ENDPOINTS.GET_ADMIN_ACCOUNTS());
      if (data.success) {
        setData(data.account);
      }
    } catch (error) {
      if (error.response?.status === "401") {
        navigate("/login");
      } else return new Error(error);
    } finally {
      setFetchingStaff(false);
    }
  };
  async function handleDelete(staff) {
    try {
      await toast.promise(
        jmsApp.delete(AUTH_ENDPOINTS.DELETE_USER_ACCOUNT(), {
          params: {
            email: staff.email
          }
        }),
        {
          loading: `Deleting ${staff.name} from the staff list...`,
          success: `${staff.name} successfully removed from the staff list.`,
          error: `${staff.name} removal process failed, please retry later.`
        },
        {
          className: " text-xs"
        }
      );
      setIsSackingStaff(null);
      await handleData();
    } catch (err) {}
    // const {}
  }

  useEffect(() => {
    handleData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StaffCont>
      <div className="heading">
        <div className=" w-full flex items-center justify-between py-4 px-6 ">
          <h1 className=" text-xl font-bold uppercase">all admins</h1>
          {currentUser.name && (
            <div className=" flex text-xs lg:text-xl font-bold uppercase space-x-4 items-center justify-end ">
              <span>Welcome</span>
              <span className=" ">{currentUser?.name}</span>
            </div>
          )}
        </div>

        {userRole === ADMIN_ROLES.SUPER_ADMIN && (
          <button onClick={() => setOpenModal(true)}>
            <span>
              <HiPlus size={24} />
            </span>
            Add Admin
          </button>
        )}
      </div>
      <div className="search">
        <Search
          value={value}
          setValue={setValue}
          placeholder="Search Admins..."
        />
      </div>
      <div className="admins">
        {data.length !== 0 ? (
          userRole === ADMIN_ROLES.SUPER_ADMIN ? (
            <Table
              // data={filterArticles(active)}
              data={data}
              columns={columns}
              color="grey"
              value={value}
              user={userRole}
              actions={[
                { name: "delete", action: (staff) => setIsSackingStaff(staff) }
              ]}
            />
          ) : (
            <Table
              // data={filterArticles(active)}
              data={data}
              columns={columns}
              color="grey"
              value={value}
              user={userRole}
            />
          )
        ) : !fetchingStaff ? (
          <div className="bg-transparent capitalize font-bold text-xl font-primary text-center w-full py-4 text-[#46555C] flex flex-col space-y-4 items-center  justify-center">
            <span>
              <FcDeleteDatabase size={40} />
            </span>
            <p>No staff found</p>
          </div>
        ) : (
          <div className=" w-full h-full flex  items-center justify-center py-10">
            <ClipLoader
              color="#46555C"
              loading={fetchingStaff}
              cssOverride={override}
              size={30}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        )}
      </div>
      {openModal && <AddAdmin onClose={() => setOpenModal(false)} />}
      {ìsSackingStaff && (
        <div className=" bg-black/70 bg-opacity-50 backdrop-blur-sm absolute w-full h-full top-0 left-0 flex items-center justify-center">
          <div
            className="max-w-[400px] h-[250px] flex flex-col bg-white rounded-lg items-center justify-center gap-6
           p-6"
          >
            <p className=" font-medium text-center text-xl ">
              Do you want to remove{" "}
              <strong className="text-red-600 italic underline">
                {ìsSackingStaff.name}
              </strong>{" "}
              from the staff list ?
            </p>
            <div className=" w-full items-center justify-between flex gap-6">
              <button
                onClick={() => setIsSackingStaff(null)}
                className=" w-full p-2 rounded-md border border-red-500 text-red-500 transition-all duration-300 ease-in-out hover:bg-red-500 hover:text-white capitalize font-bold text-lg "
              >
                no
              </button>
              <button
                onClick={() => handleDelete(ìsSackingStaff)}
                className=" w-full p-2 rounded-md border border-blue-500 text-blue-500 transition-all duration-300 ease-in-out hover:bg-blue-500 hover:text-white capitalize font-bold text-lg "
              >
                yes
              </button>
            </div>
          </div>
        </div>
      )}
    </StaffCont>
  );
}

export default Staffs;
