import React from 'react'
import { AgricDiv, AgricContainer } from './style'

function HumanSocialPage() {
  return (
    <div>
      <AgricDiv>
        <AgricContainer>HumanSocialPage</AgricContainer>
      </AgricDiv>
    </div>
  )
}

export default HumanSocialPage