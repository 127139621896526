import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 100%;
  .flex {
    display: flex;
    width: 100%;
    align-content: center;
  }
`;

export const VideoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 500px;
  margin-bottom: 20px;
`;

export const Video = styled.video`
  max-width: 100%;
  max-height: 100%;
  type: "video/mp4";
`;

export const PdfContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-bottom: 20px;
  width: max-content;
`;

export const Pdf = styled.iframe`
  width: 100%;
  height: 500px;
  border: none;
`;

export const Title = styled.p`
  font-size: 20px;
  line-height: 1.5;
  margin-bottom: 20px;
  justify-self: center;
  flex: 1;
  text-align: center;
  font-weight: bold;
`;

export const Duration = styled.p`
  font-size: 14px;
  color: #666;
`;
