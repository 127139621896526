import * as Yup from "yup";

import { AuthButton, AuthHeader } from ".";
import {
  AuthCard,
  AuthContent,
  Authcontainer,
  LoginContainer,
  LoginDiv,
  LoginFormContainer
} from "./style";
import { Link, useNavigate } from "react-router-dom";

import { AppLogo } from "utils/assets";
import { Img } from "globalStyles";
import { LOCAL_STORAGE } from "api/LOCALSTORAGE";
import { LOGIN_ROUTE } from "routes";
import { PASSWORD_RECOVERY_ENDPOINT } from "api/ACTION";
import TextInput from "components/TextInput";
import jmsApp from "api/jms";
import { observer } from "mobx-react-lite";
import { toast } from "react-hot-toast";
import { useFormik } from "formik";
import { useState } from "react";

function ResetPassword() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const formik = useFormik({
    enableReinitialize: false,
    initialValues: {
      //   currentPassword: "",
      id: LOCAL_STORAGE.recoveryId(),
      // id: JSON.parse(localStorage.getItem("recoveryID")),
      newPassword: ""
    },
    onSubmit: async (info, helpers) => {
      setLoading(true);
      try {
        const { data } = await jmsApp.post(
          PASSWORD_RECOVERY_ENDPOINT.RESET_PASSWORD(),
          info
        );
        setLoading(false);
        if (data.success) {
          helpers.resetForm();
          toast.success(data.msg);
          navigate(LOGIN_ROUTE);
          localStorage.removeItem("recoveryID");
        }
      } catch (err) {
        setLoading(false);
        if (err.response) {
          // toast.error(err.response.data.error);
        } else {
        }
      }
    },
    validationSchema: Yup.object({
      newPassword: Yup.string()
        .min(8)
        .required("Password is required.")
        .label("New Password")
      //   newPassword: Yup.string()
      //     .oneOf(
      //       [Yup.ref("currentPassword"), null],
      //       "Confirm Password must match Password"
      //     )
      //     .required("Confirm Password is required."),
    })
    // .label("Confirm Password"),
  });

  return (
    <Authcontainer>
      <AuthContent>
        <Link to="/">
          <Img src={AppLogo} alt="LPJ" />
        </Link>
        <LoginContainer>
          <LoginDiv onSubmit={formik.handleSubmit}>
            <AuthCard>
              <LoginFormContainer>
                <AuthHeader text="Reset Your Password" />
                <TextInput
                  label="New Password"
                  name="newPassword"
                  type="password"
                  id="newPassword"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.newPassword}
                  error={formik.errors.newPassword}
                  visible={formik.touched.newPassword}
                  placeholder="New Password"
                  mt="0.5rem"
                />
                {/* <TextInput
                  label='Confirm Password'
                  name='newPassword'
                  type='password'
                  id='newPassword'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.newPassword}
                  error={formik.errors.newPassword}
                  visible={formik.touched.newPassword}
                  placeholder='Confirm Password'
                /> */}
                <AuthButton type="submit" title="Reset" loading={loading} />
              </LoginFormContainer>
            </AuthCard>
          </LoginDiv>
        </LoginContainer>
      </AuthContent>
    </Authcontainer>
  );
}

export default observer(ResetPassword);
