import { Img } from 'globalStyles'
import React from 'react'
import { SocialDiv } from './style'

function Socials({
  icon,
  link = 'https://play.google.com/store/apps',
  title = 'twitter',
}) {
  return (
    <SocialDiv>
      <a href={link} rel="noopener noreferrer" target="_blank">
        <Img src={icon} alt={title} />
      </a>
    </SocialDiv>
  )
}

export default Socials
