export function dateStringToSeconds(dateString) {
  const dateObject = new Date(dateString);

  if (isNaN(dateObject.getTime())) {
    return null;
  }

  const seconds = dateObject.getTime() / 1000;

  return seconds;
}
