import { COURSE, USER_PLAN } from "api/ACTION";
import { Container, Title, VideoContainer } from "./courseStyle";
import { Document, Page, pdfjs } from "react-pdf";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { BiArrowBack } from "react-icons/bi";
import ClipLoader from "react-spinners/ClipLoader";
import { IconSize } from "components/Admin/Articles/Articles.style";
import { IoReload } from "react-icons/io5";
import ReactPlayer from "react-player";
import { handleRefreshToken } from "utils/auth";
import jmsApp from "api/jms";
import { override } from "utils/admin-roles";
import toast from "react-hot-toast";
import { useDebounce } from "hooks/use-debounce";
import useResizeObserver from "use-resize-observer";

// import { useScreen } from "hooks/use-window-size";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const CourseComp = () => {
  const navigate = useNavigate();

  // Access the location object to get query parameters
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const { id } = useParams();

  const planId = searchParams.get("planId") || "";

  const module = searchParams.get("module");
  const [numPages, setNumPages] = useState(null);
  const [gettingCourseData, setGettingCourseData] = useState(false);
  const [gettingNewCourseData, setGettingNewCourseData] = useState(false);
  const [courseData, setCourseData] = useState(null);
  const [completing, setCompleting] = useState(false);
  const [courseCompleted, setCourseCompleted] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [showNext, setShowNext] = useState(false);
  const [nextCourse, setNextCourse] = useState([]);
  const [completedId, setCompletedId] = useState([id]);
  const [loadError, setLoadError] = useState("");
  const [timer, setTimer] = useState(60000);
  useEffect(() => {
    if (timer === 0) return;
    const interval = setInterval(() => {
      // Decrease timer by 1000 milliseconds (1 second) every second
      setTimer((prevTimer) => prevTimer - 1000);
    }, 1000); // Run the interval every second (1000 milliseconds)

    // Cleanup function to clear the interval when the component unmounts or timer reaches zero
    return () => clearInterval(interval);
  }, [timer]);

  useEffect(() => {
    if (!courseCompleted) return;
    const getPlan = async (planId) => {
      try {
        const { data } = await jmsApp.get(USER_PLAN.GET_PLAN_BY_ID(), {
          params: { planId }
        });
        return data.plans;
      } catch (error) {
        return null;
      }
    };
    const handleModuleComplete = async () => {
      setCompleting(true);

      try {
        const isComplete = jmsApp.patch(COURSE.COMPLETE_COURSE(), {
          courseId: courseData.id,
          planId: planId,
          modules: Number(courseData.module)
        });

        await toast.promise(isComplete, {
          loading: "Please wait...",
          success: `Course ${courseData?.category} completed`,
          error: `Course ${courseData?.category} completion failed`
        });
        // await isComplete;
        // toast.success(`Course ${courseData?.category} completed`);
        setCourseCompleted(false);
        const plan = await getPlan(planId);

        const availableCourses = plan.courses
          .map((course) => {
            return {
              id: course.id,
              module: Number(course.module),
              completed: course.completed || false
            };
          })
          .filter(
            (course) => !course.completed && !completedId.includes(course.id)
          );

        if (availableCourses?.length > 0) {
          setNextCourse(availableCourses);
          setShowNext(true);
        } else {
          toast.error("All courses for this plan has been completed.");
        }

        // navigate("/courses");
      } catch (err) {
        toast.error("Completion failed, try again later.");
      } finally {
        setCompleting(false);
      }
    };
    if (courseCompleted) {
      handleModuleComplete();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseCompleted]);
  useEffect(() => {
    async function getData() {
      if (!Number(module)) {
        navigate("/courses");
      }
      setGettingCourseData(true);
      try {
        const courseData = await jmsApp.get(COURSE.GET_COURSE_BY_ID(), {
          params: {
            courseId: id,
            modules: Number(module),
            planId
          }
        });

        setCourseData(courseData?.data.course);
      } catch (err) {
        if (err?.response?.status === 403) {
          navigate(-1);
        }
      } finally {
        setGettingCourseData(false);
      }
    }

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (currentPage + 1 > numPages && numPages > 0) {
      setCourseCompleted(true);
    }
  }, [currentPage, numPages]);
  // const [videoCompleted, setVideoCompleted] = useState(false);

  // const [pageNumber] = useState(1);

  const { ref, width = 1 } = useResizeObserver();
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };
  const onDocumentLoadFailed = ({ err }) => {
    setLoadError(err);
    setNumPages(null);
  };
  const handleVideoWatched = (arg) => {
    // console.log("Video completed : ", arg);
    setCourseCompleted(true);
  };

  const debouncedWidth = useDebounce(width, 300);

  function keepAuthAlive() {
    if (timer !== 0) return;
    try {
      handleRefreshToken();
      setTimer(60000);
    } catch (err) {}
  }
  if (gettingCourseData) {
    return (
      <div className="fixed top-0 left-0 w-full h-screen flex items-center justify-center bg-gray-100 bg-opacity-75 !mt-0 backdrop:blur-md">
        <ClipLoader
          color="#333333"
          loading={gettingCourseData}
          cssOverride={override}
          size={40}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    );
  }
  if (loadError) {
    <div className=" flex flex-col items-center justify-center space-y-6 w-full">
      <p className=" font-bold text-xl text-center">Error loading file </p>
      <button
        className=" flex items-center justify-center border border-solid border-[#9C4DF4] rounded-lg "
        onClick={() => window.location.reload()}
      >
        <IoReload />
        reload
      </button>
    </div>;
  }
  async function handleGetNewData(courses, index = 0) {
    if (index >= courses.length) {
      // All courses failed, navigate away or handle the situation
      Promise.reject();
      setShowNext(false);
      // navigate("/courses");
      return;
    }

    const course = courses[index];

    if (!Number(course.module)) {
      // Skip to the next course if current course is invalid
      handleGetNewData(courses, index + 1);
      return;
    }

    setGettingNewCourseData(true);

    try {
      const courseData = await jmsApp.get(COURSE.GET_COURSE_BY_ID(), {
        params: {
          courseId: course.id,
          modules: Number(course.module),
          planId: planId
        }
      });
      if (courseData?.data.course.completed) {
        throw new Error("This course has already been completed");
      }
      Promise.resolve();
      setCourseData(courseData?.data.course);
      window.history.replaceState(
        "",
        "",
        `/course/${course.id}?module=${course.module}&planId=${planId}`
      );
      setCompletedId((val) => [...val, courseData?.data.course.id]);
      setCurrentPage(1);
    } catch (err) {
      if (err?.response?.status === 403) {
        navigate("/courses");
        return;
      }

      // Retry with the next course
      handleGetNewData(courses, index + 1);
    } finally {
      setShowNext(false);
      setCourseCompleted(false);
      setCompleting(false);
      setGettingNewCourseData(false);
    }
  }

  return (
    <Container className=" relative">
      {/* // <div> */}
      {completing ||
        (gettingNewCourseData && (
          <div className="fixed z-20 top-0 left-0 w-full h-full  flex items-center justify-center bg-gray-100 bg-opacity-75 !mt-0 backdrop:blur-md">
            <ClipLoader
              color="#333333"
              loading={completing || gettingNewCourseData}
              cssOverride={override}
              size={40}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        ))}
      <div className="flex w-full items-center justify-center mx-auto">
        <IconSize>
          <div className="" onClick={() => navigate("/courses")}>
            <BiArrowBack />
          </div>
        </IconSize>
        <Title>{courseData?.title}</Title>
      </div>
      {String(courseData?.category).toLowerCase() === "video" && (
        <VideoContainer>
          <ReactPlayer
            url={courseData?.resourceUrl}
            controls
            width="100%"
            height="100%"
            onEnded={handleVideoWatched}
            onError={onDocumentLoadFailed}
            loop={false}
            onProgress={keepAuthAlive}
          />
        </VideoContainer>
      )}
      {String(courseData?.category).toLowerCase() === "book" && (
        // <PdfContainer>
        <div className=" flex flex-col items-center space-y-4" ref={ref}>
          <Document
            file={courseData?.resourceUrl}
            onLoadSuccess={onDocumentLoadSuccess}
            onLoadError={onDocumentLoadFailed}
          >
            {/* <div className=" flex w-full items-center justify-center py-10"> */}
            <Page
              width={debouncedWidth || undefined}
              renderAnnotationLayer={false}
              renderTextLayer={false}
              // className={` w-full `}
              pageNumber={currentPage}
              error={
                <p className=" capitalize font-bold text-2xl">No file found</p>
              }
              loading={
                <p className=" capitalize font-bold text-2xl">Loading....</p>
              }
            />
            {/* </div> */}
          </Document>
          <div className="w-full flex items-center justify-center space-x-8 py-4">
            <button
              onClick={() => {
                keepAuthAlive();
                if (currentPage - 1 === 0) return;
                setCurrentPage((page) => page - 1);
              }}
              className=" p-2 border border-solid border-blue-300 text-blue-400 rounded-lg transition-all duration-300 hover:bg-blue-400 hover:text-white disabled:cursor-not-allowed "
              disabled={currentPage - 1 === 0}
            >
              Previous
            </button>
            <p>
              Page {currentPage}&nbsp;of&nbsp;&nbsp;{numPages}
            </p>
            <button
              onClick={() => {
                keepAuthAlive();
                if (currentPage + 1 > numPages) return;
                setCurrentPage((page) => page + 1);
              }}
              disabled={currentPage + 1 > numPages}
              className=" disabled:cursor-not-allowed p-2 border border-solid border-blue-300 text-blue-400 rounded-lg transition-all duration-300 hover:bg-blue-400 hover:text-white "
            >
              Next
            </button>
          </div>
        </div>
        // </PdfContainer>
      )}

      {/* <Duration>{category}</Duration> */}
      {/* </div> */}
      {showNext && !completing && (
        <div className="w-full flex items-center justify-end ">
          <button
            onClick={() => {
              const gettingNewCourse = handleGetNewData(nextCourse);
              toast.promise(gettingNewCourse, {
                loading: "Please wait...",
                success: `New course found`,
                error: `Try again later`
              });
            }}
            className="relative p-2 px-6 rounded-lg bg-blue-500 text-white uppercase transition-all duration-300 ease-in-out"
          >
            {completing && (
              <div className="absolute w-full h-full top-0 left-0 flex items-center justify-center bg-slate-200/50 backdrop-blur-sm">
                <ClipLoader
                  color="#46555C"
                  loading={completing}
                  cssOverride={override}
                  size={30}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </div>
            )}
            <span>Next</span>
          </button>
        </div>
      )}
      {/* {courseData?.category.toLowerCase() === "video" && videoCompleted && (
        <div className="w-full flex items-center justify-end ">
          <button
            // onClick={()}
            // onClick={handleModuleComplete}
            className="relative p-2 rounded-lg bg-blue-500 text-white uppercase transition-all duration-300 ease-in-out"
          >
            {completing && (
              <div className="absolute w-full h-full top-0 left-0 flex items-center justify-center bg-slate-200/50 backdrop-blur-sm">
                <ClipLoader
                  color="#46555C"
                  loading={completing}
                  cssOverride={override}
                  size={30}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </div>
            )}
            <span> Video completed</span>
          </button>
        </div>
      )} */}
    </Container>
  );
};

export default CourseComp;
