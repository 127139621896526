import JournalSection from "components/Home/Journal Section";
import Mainlayout from "layout/MainLayout";
import React from "react";

export default function SubmitPage() {
  return (
    <Mainlayout>
      <div className="pt-20 sm:pt-0">
      <JournalSection />
      </div>
    </Mainlayout>
  );
}
