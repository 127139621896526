import React from "react";
import { useNavigate } from "react-router-dom";
function Unauthorized() {
  const history = useNavigate();
  return (
    <div
      style={{
        background: "#e5e5e5",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh"
      }}
    >
      <p>You are an unauthorized user. </p>
      <button
        onClick={() => history(-1)}
        style={{
          border: "1px solid gray",
          borderRadius: "11px",
          padding: "10px 12px",
          marginTop: "10px"
        }}
      >
        Go Back
      </button>
    </div>
  );
}

export default Unauthorized;
