import { ContactTextInputContainer, GeneralTypeSelect } from "./style";

import { ArrowSelectDown } from "utils/assets";
import { ErrorMsgSpan } from "components/TextInput/style";
import { Img } from "globalStyles";
import React from "react";

function CustomSelectMain({
  children,
  value,
  onChange,
  label,
  name,
  mt = "",
  width = "100%",
  error,
  visible,
  ErrorMt,
  defaultValue,
  ...rest
}) {
  return (
    <ContactTextInputContainer mt={mt} width={width}>
      <label htmlFor={name}>{label}</label>
      <GeneralTypeSelect>
        <select
          value={value || defaultValue}
          onChange={onChange}
          name={name}
          {...rest}
        >
          {children}
        </select>
        <div>
          <Img width="12px" height="12px" src={ArrowSelectDown} alt="select" />
        </div>
      </GeneralTypeSelect>
      {visible && error ? (
        <ErrorMsgSpan ErrorMt={`${mt}`}>{error}</ErrorMsgSpan>
      ) : null}
    </ContactTextInputContainer>
  );
}

export default CustomSelectMain;
