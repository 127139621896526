import { AUTH_ENDPOINTS, USER } from "api/ACTION";
import {
  Button,
  ButtonContainer,
  Container,
  Header,
  Icon,
  Title,
  UpdateCon
} from "components/Account/accountStyle";
import React, { useEffect, useState } from "react";

import { BiEdit } from "react-icons/bi";
import { ClipLoader } from "react-spinners";
import DeleteModal from "components/Account/modal/deleteModal";
import { FiTrash2 } from "react-icons/fi";
import { LOCAL_STORAGE } from "api/LOCALSTORAGE";
import Layout from "components/Admin/DashboardLayout/Layout";
import jmsApp from "api/jms";
import { toast } from "react-hot-toast";

export default function AdminSettings() {
  // const user = LOCAL_STORAGE.user();
  // console.log(user);
  const [user, setUser] = useState(null);
  const [name, setName] = useState(null);
  const [nameEdt, setNameEdt] = useState(true);
  const [phoneEdt, setPhoneEdt] = useState(true);
  const [phone, setPhone] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [hasMounted, setHasMounted] = useState(false);
  const [isFetchingUser, setIsFetchingUser] = useState(true);
  useEffect(() => {
    setHasMounted(true);
    handleData();
  }, []);

  async function handleData() {
    try {
      setIsFetchingUser(true);
      const { data: user } = await jmsApp.get(AUTH_ENDPOINTS.USER_INFO());
      // console.log(user, user);
      setUser(user.user);
    } catch (err) {
    } finally {
      setIsFetchingUser(false);
    }
  }

  const handleNameChange = (event) => {
    setName(event?.target?.value);
  };

  const handlePhoneChange = (event) => {
    setPhone(event?.target?.value);
  };

  const handleUpdateUser = async () => {
    setLoading(true);

    try {
      const { data } = await toast.promise(
        jmsApp.patch(USER.UPDATE_USER(), {
          name: name,
          phone: phone
        }),
        {
          loading: "Updating your details...",
          success: "Details successfully updated.",
          error: "Update filed, please try again later"
        }
      );
      if (data.success) {
        setLoading(false);
        setNameEdt(true);
        setPhoneEdt(true);
        // toast.success("Updated Successfullly");
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "white"
  };
  if (!hasMounted) {
    return (
      <div className=" w-full h-full flex  items-center justify-center">
        <ClipLoader
          color="#46555C"
          loading={!hasMounted}
          cssOverride={override}
          size={30}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    );
  }

  return (
    <Layout>
      {isFetchingUser && (
        <div className="fixed top-0 left-0 w-full h-screen flex items-center justify-center bg-gray-100 bg-opacity-75 !mt-0 backdrop:blur-md">
          <ClipLoader
            color="#333333"
            loading={isFetchingUser}
            cssOverride={override}
            size={40}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      )}
      <Container>
        <Header>
          <div className=" w-full flex items-center justify-between py-4 px-6 ">
            <div className=" text-xl font-bold upprecase">
              {" "}
              <Title>Admin Settings</Title>
            </div>

            {user?.name && (
              <div className=" flex text-xs lg:text-xl font-bold uppercase space-x-4 items-center justify-end ">
                <span>Welcome</span>
                <span className=" ">{user?.name}</span>
              </div>
            )}
          </div>
          {/* <ButtonContainer> */}
          <div
            onClick={() => setShowDelete(true)}
            className="flex items-center justify-center gap-x-2 bg-red-300 text-black rounded-lg px-2 py-1 cursor-pointer hover:bg-red-500 hover:text-black"
          >
            <FiTrash2 />{" "}
            <span className=" whitespace-nowrap">Delete Account</span>
          </div>
          {/* </ButtonContainer> */}
        </Header>

        <UpdateCon>
          <div className="info">User Information</div>
          <span className=" w-4/5 xl:w-3/5 text-center">
            Manage your profile Information which includes your name, phone
            number
          </span>
          <div className="flex flex-col items-stretch justify-start space-y-10">
            {/* <div className="boxCon"> */}
            {/* <TxtBoxCon>
            <div className="lbl">Email: </div>
            <Input
              disabled
              type="email"
              placeholder="Email Address"
              value={user.email}
            />
          </TxtBoxCon> */}
            <CustomInput
              title={"email"}
              disabled={true}
              type="email"
              placeholder="Email Address"
              defaultValue={user?.email}
            />
            {/* <TxtBoxCon>
            <div className="lbl">Name: </div>
            <Input
              type="name"
              placeholder="Name"
              value={name}
              disabled={nameEdt}
              onChange={handleNameChange}
            />
            <Icon onClick={() => setNameEdt(!nameEdt)}>
              <BiEdit />
            </Icon>
          </TxtBoxCon> */}
            <CustomInput
              title={"name"}
              type="name"
              placeholder="Name"
              value={name || (user?.name ?? "")}
              disabled={nameEdt}
              onChange={handleNameChange}
            >
              <Icon onClick={() => setNameEdt(!nameEdt)}>
                <BiEdit />
              </Icon>
            </CustomInput>
            {/* <TxtBoxCon>
            <div className="lbl">UserName: </div>
            <Input
              type="username"
              disabled
              placeholder="Username"
              value={user.username}
            />
          </TxtBoxCon> */}
            <CustomInput
              title={"username"}
              type="username"
              disabled
              placeholder="Username"
              defaultValue={user?.username}
            />
            {/* <TxtBoxCon>
            <div className="lbl">Phone: </div>
            <Input
              type="phone"
              placeholder="Enter Number"
              value={phone}
              disabled={phoneEdt}
              onChange={handlePhoneChange}
            />
            <Icon onClick={() => setPhoneEdt(!phoneEdt)}>
              <BiEdit />
            </Icon>
          </TxtBoxCon> */}
            <CustomInput
              title={"phone"}
              type="phone"
              placeholder="Enter Number"
              value={phone || user?.phone || ""}
              disabled={phoneEdt}
              onChange={handlePhoneChange}
            >
              <Icon onClick={() => setPhoneEdt(!phoneEdt)}>
                <BiEdit />
              </Icon>
            </CustomInput>
            <div className=" w-full flex items-center justify-center ">
              <Button
                name="update"
                onClick={handleUpdateUser}
                disabled={phoneEdt && nameEdt}
              >
                {loading ? (
                  <ClipLoader
                    color="#46555C"
                    loading={loading}
                    cssOverride={override}
                    size={20}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                ) : (
                  <>Update</>
                )}
              </Button>
            </div>
          </div>
        </UpdateCon>
        {showDelete && <DeleteModal onClose={() => setShowDelete(false)} />}
      </Container>
    </Layout>
  );
}

function CustomInput({
  type,
  title,
  children,
  onChange,
  disabled,
  value = "",
  placeholder,
  defaultValue = ""
}) {
  return (
    <div>
      <div className="w-full flex rounded-lg shadow-sm">
        <span className=" capitalize px-4 inline-flex items-center min-w-fit rounded-s-md border border-e-0 border-gray-200 bg-gray-50 text-sm text-gray-500 ">
          {title}
        </span>
        <input
          defaultValue={defaultValue}
          type={type}
          value={value || defaultValue}
          disabled={disabled}
          onChange={onChange}
          placeholder={placeholder}
          className={`disabled:cursor-not-allowed disabled:bg-opacity-75  py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm ${
            !children ? "rounded-e-lg" : ""
          }  text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none `}
        />
        {children && (
          <div className=" px-4 inline-flex items-center min-w-fit rounded-e-md border border-e-0 border-gray-200 bg-gray-50 text-sm text-gray-500 0">
            {children}
          </div>
        )}
      </div>
    </div>
  );
}
