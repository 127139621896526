import Courses from "components/courses/Courses";
import Layout from "components/layout/Layout";
import { Outlet } from "react-router-dom";
// import WaecCorsesPg from "./WaecCorsesPg";
// import JambCoursesPg from "./JambCoursesPg";
// import { WAEC_ROUTE, JAMB_ROUTE } from "routes";

function CoursesPg() {
  return (
    <Layout>
      <Courses />
      <Outlet />
    </Layout>
  );
}

export default CoursesPg;
