import {
  ABOUT_ROUTE,
  BLOG_ROUTE,
  ELEARN_ROUTE,
  HOME_ROUTE,
  JOURNAL_ROUTE,
  SUBMIT_ROUTE,
  SUPPORT_ROUTE,
} from 'routes'
import { AppLink, Img } from 'globalStyles'
import {
  AppLogo,
  FBicon,
  IGicon,
  LinkedInIcon,
  LocationIcon,
  TwitterIcon,
  email,
  phone,
} from 'utils/assets'
import { FooterUl, SocialDivFooter } from './style'

import Colors from 'utils/colors'
import FooterItem from './FooterItem'
import React from 'react'
import Socials from './Socials'
import media from 'utils/media'
import styled from 'styled-components'
import tw from 'twin.macro'

const FootCon = styled.div`
  /* margin-top: 2rem; */
  width: 100%;
  background: #46555c;
  min-height: 400px;
  // padding: 50px 0px;

  ${tw`py-12`}
`
const FootDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  /* max-width: 1200px; */
  width: 100%;
  margin: 0 auto;

  ${tw`px-6 sm:px-12 items-center`}

  ${media.mobile`
    flex-direction: column;
    /* align-items: flex-start; */
  `}
`
const FootLeft = styled.div`
  display: flex;
  flex-direction: column;
  /* 
  new style */
  text-align: center;
  align-items: center;
`
const FootMid = styled.div`
  display: flex;
  flex-direction: column;
  /* 
  new style */
  align-items: center;
`
const FootRight = styled.div`
  display: flex;
  flex-direction: column;
  /* 
  new style */
  align-items: center;
`
const CopyrightText = styled.p`
  color: ${Colors.white};
  font-size: 14px;
  margin-top: 50px;
`
const FootHeadText = styled.h1`
  color: ${Colors.white};
  font-size: 24px;
  margin-bottom: 10px;
  text-align: center;
`

function Footer() {
  return (
    <FootCon>
      <FootDiv>
        <FootLeft>
          <div>
            <Img className="app_logo" src={AppLogo} alt="LP logo" />
          </div>
          <FooterUl>
            <li className="transition-all duration-300 ease-in-out border-b border-solid border-transparent hover:border-white">
              <AppLink className="links-footer" to={HOME_ROUTE}>
                Home
              </AppLink>
            </li>

            <li className="transition-all duration-300 ease-in-out border-b border-solid border-transparent hover:border-white">
              <AppLink className="links-footer" to={JOURNAL_ROUTE}>
                Explore Journals
              </AppLink>
            </li>

            <li className="transition-all duration-300 ease-in-out border-b border-solid border-transparent hover:border-white">
              <AppLink className="links-footer" to={ELEARN_ROUTE}>
                Visit our E-learning portal
              </AppLink>
            </li>
            <li className="transition-all duration-300 ease-in-out border-b border-solid border-transparent hover:border-white">
              <AppLink className="links-footer" to={SUBMIT_ROUTE}>
                Submit Your Articles
              </AppLink>
            </li>
          </FooterUl>
        </FootLeft>

        <FootMid>
          <FootHeadText>Our Organization</FootHeadText>

          <FooterUl>
            <li className="transition-all duration-300 ease-in-out border-b border-solid border-transparent hover:border-white">
              <AppLink className="links-footer" to={ABOUT_ROUTE}>
                Know About Us
              </AppLink>
            </li>
            <li className="transition-all duration-300 ease-in-out border-b border-solid border-transparent hover:border-white">
              <AppLink className="links-footer" to={SUPPORT_ROUTE}>
                Our Support team
              </AppLink>
            </li>

            <li className="transition-all duration-300 ease-in-out border-b border-solid border-transparent hover:border-white">
              <AppLink className="links-footer" to={BLOG_ROUTE}>
                Read about us
              </AppLink>
            </li>
          </FooterUl>
        </FootMid>

        <FootRight>
          <FootHeadText>Contact Information</FootHeadText>

          <FooterItem
            icon={LocationIcon}
            text="LP Headquaters, 8126 Birch Bay Drive Unit 101 Blaine WA 98230 USA"/>
          <FooterItem icon={email} text="info@learningpublics.com" />
          <FooterItem icon={phone} text="+13608207328" />
        </FootRight>
      </FootDiv>

      <div className=" mx-auto w-fit  my-2">
        <FootHeadText>Our Socials</FootHeadText>
        <SocialDivFooter>
          <Socials
            icon={TwitterIcon}
            title="twitter"
            link="http://facebook.com"
          />
          <Socials icon={FBicon} title="facebook" link="http://facebook.com" />
          <Socials icon={IGicon} title="Instagram" link="http://facebook.com" />
          <Socials
            icon={LinkedInIcon}
            title="linkedin"
            link="http://facebook.com"
          />
        </SocialDivFooter>

        <CopyrightText>© Copyright 2023 | Learning Publics</CopyrightText>
      </div>
    </FootCon>
  )
}

export default Footer
