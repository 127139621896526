import media from "utils/media";
import styled from "styled-components";

export const BlogSecCon = styled.div`
  // background-color: red;
  // padding:0 8.5% 0 8.5%;
  display: flex;
  align-items: center;
  // gap: 4%;

  ${media.mobile`
flex-direction: column;
// padding-left: 0%;
// padding: 0 8.5%;
  `}
`;
export const BlogHeadCon = styled.div`
  // padding-left: 8.5%;
  //  padding: 25px 0 40px 8.5%;
`;
