import Layout from "components/Admin/DashboardLayout/Layout";
import ArticlesComp from "components/Admin/Articles/ArticlesComp";

function ArticlesPg() {
  return (
    <Layout>
      <ArticlesComp />
    </Layout>
  );
}

export default ArticlesPg;
