import JournalsComp from 'components/Journals/JournalsComp'
import ArticleViewLayout from 'layout/ArticleViewLayout'
import React from 'react'

function JournalsViewPage() {
  return (
    <ArticleViewLayout>
      <JournalsComp />
    </ArticleViewLayout>
  )
}

export default JournalsViewPage
