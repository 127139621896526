import { ARTICLE_ENDPOINT } from "api/ACTION";
import authStore from "mobx/AuthStore";
import { handleRefreshToken } from "utils/auth";
import jmsApp from "api/jms";

// import toast from "react-hot-toast";

export const handleArticleReview = async () => {
  try {
    const { data } = await jmsApp.get(ARTICLE_ENDPOINT.ARTICLE_IN_REVIEW());
    if (data.success) {
      authStore.updateReviewedArticles(data.article);
    }
  } catch (error) {
    if (error.response?.status === 401) {
      await handleRefreshToken();
      // await handleArticleReview()
    } else if (error.response) {
      //   toast.error(error.response.data.error);
    }
  }
};
export const handleArticlePublished = async () => {
  try {
    const { data } = await jmsApp.get(ARTICLE_ENDPOINT.ARTICLE_PUBLISHED());
    if (data.success) {
      authStore.updatePublishedArticles(data.article);
    }
  } catch (error) {
    if (error.response?.status === 401) {
      await handleRefreshToken();
      // await handleArticlePublished()
    } else if (error.response) {
      //   toast.error(error.response.data.error);
    }
  }
};
