import styled from 'styled-components'

export const AgricDiv = styled.div`
  background-color: ;
  margin: auto;
  margin-top: 5rem;
  width: 80%;
`
export const AgricContainer = styled.div`
  /* background-color: red; */
  margin: auto;
  /* margin-top: 5rem; */
  /* width:80% */
  border-radius: 10px;
  padding: 10px;
  color: gray;
`
