// import React from "react";

import { AUTH_ENDPOINTS, PASSWORD_RECOVERY_ENDPOINT } from "api/ACTION";

import jmsApp from "api/jms";
import { toast } from "react-hot-toast";

export const handleLogout = async () => {
  try {
    const { data } = await jmsApp.post(AUTH_ENDPOINTS.LOGOUT());

    if (data.success) {
      // localStorage.removeItem("isUserDetails");
      // localStorage.removeItem("jms-token");
      // localStorage.removeItem("jmsRefreshToken");
      // localStorage.removeItem("course");
      // localStorage.removeItem("auth-email");
      // localStorage.removeItem("auth-state");

      localStorage.clear();

      window.location.replace("/");
      return data;
    }
  } catch (err) {
    // Clear everything in the local storage
    localStorage.clear();

    window.location.replace("/");
  }
};

export const sendRecoveryMail = async (id, email) => {
  try {
    const { data } = await jmsApp.post(
      PASSWORD_RECOVERY_ENDPOINT.RECOVER_LINK(),
      null,
      {
        params: {
          userId: id,
          email: email
        }
      }
    );

    if (data.success) {
      toast.success(data.msg);
      return data;
    }
  } catch (err) {
    if (err.response.data) {
      // toast.error(err.response.data.error);
    } else {
    
    }
  }
};
