import Colors from "utils/colors";
import media from "utils/media";
import styled from "styled-components";

export const AdminDashboardCont = styled.div`
  background: #e5e5e5;
  padding: 25px 40px;
  width: 100%;
  min-height: 100vh;

  ${media.mobile`
  padding: 25px 20px;
`};
`;
export const CardCont = styled.div`
  padding: 35px 0;
  display: grid;
  justify-items: center;
  align-items: center;
  grid-template-columns: repeat(auto-fill, minmax(min(250px, 100%), 1fr));
  gap: 50px;

  ${media.tablet`
  grid-template-columns: repeat(auto-fill, minmax(min(200px, 100%), 1fr));
	gap:25px;
	`};
  ${media.mobile`
  grid-template-columns: repeat(auto-fill, minmax(min(150px, 100%), 1fr));
	gap:15px;
	`};
  .card {
    width: 250px;
    background: ${Colors.white};
    padding: 25px 10px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 7px;
    border-radius: 11px;
    ${media.tablet`
  width:200px;
	`};
    ${media.mobile`
  width:150px;
  flex-direction:column;
  justify-content: center;
  align-items: center;

	`};
    .content {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      text-transform: uppercase;
      align-items: flex-start;
      ${media.mobile`
      justify-content: center;
      align-items: center;
	`};
      h4 {
        font-weight: 500;
        font-size: 17px;
        line-height: 18px;
        color: #262626;
      }
      span {
        font-weight: 600;
        font-size: 22px;
        line-height: 33px;
        color: #262626;
      }
    }
  }
`;
export const StatusProp = styled.p`
  font-size: 26px;
  padding: 6px;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) =>
    props.status === "total"
      ? `${Colors.primary}`
      : props.status === "pending"
      ? `${Colors.black}`
      : props.status === "accepted"
      ? "orange"
      : props.status === "rejected"
      ? "red"
      : props.status === "review"
      ? "gray"
      : props.status === "published"
      ? "green"
      : ""};
`;
export const ArticleCont = styled.div`
  h3 {
    margin: 13px 0;
  }
  .recent {
    padding: 20px 25px;
    background: white;
    min-height: max-content;
    /* min-height: 58vh; */
    max-width: 100%;
    border-radius: 11px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    overflow: auto;
    .all {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 20px;
      button {
        width: 180px;
        background: #e5e5e590;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px 15px;
        font-size: 16px;
        font-weight: 600;
        gap: 6px;
        :hover {
          background: #e5e5e5;
          transition: background 0.2s ease-in-out;
        }
        span {
          font-size: 26px;
          font-weight: 600;
        }
      }
    }
    .article {
      margin-top: 15px;
      min-width: 100%;
      border-collapse: collapse;
      thead {
        tr {
          text-align: left;
          padding: 10px;
          th {
            padding: 10px 25px;
          }
        }
      }
      tbody {
        min-width: 900px;
        max-width: 100%;
      }
    }
  }
`;
