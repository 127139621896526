import { HeroImg, HeroPenIcon } from "utils/assets";

import media from "utils/media";
import styled from "styled-components";
import tw from "twin.macro";

export const HeroCon = styled.div`
  /* // background: url(${HeroImg}); */
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 500px;
  /* margin-top: 4rem; */
  /* // border: 2px solid red; */
  ${media.mobile`
  width: 100%;
  height: 450px;
  /* background-position: 30% 5%; */
  /* background-size:30% 30%; */
  /* background: red */
  `}
`;
export const HeroOverlay = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;

  ${media.mobile`
  flex-direction: column;
  /* background: red; */
  `}
`;
export const HeroContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  /* // background-image: url(${HeroPenIcon});
  // background-repeat: no-repeat;
  // background-position: 30% 5%;
  // background-size:60% 60%;
  // padding-left: 8.5%; */

  ${media.mobile`
  flex-direction: column;
  /* background: red; */
  background-position: 40% 0%;
  background-size:30% 30%;
  width: 100%;
  margin-left: 0%;
  // padding: 0 8.5%;
  `}
`;

export const ContentHeader = styled.h1`
  /* // font-family: 'Montserrat';
  // width: 70%; */

  font-family: "Roboto Slab", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 28px;

  /* line-height: 59px;
  // color: #ffffff; */

  ${media.mobile`
  /* // padding-bottom: 10px; */
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;
  `}
`;

export const HomePageTypography = styled(ContentHeader)`
  ${tw`mx-auto  max-w-lg text-center  text-white font-bold`}
`;

export const ContentPara = styled.p`
  width: 45%;
  font-weight: 400;
  font-size: 18px;
  line-height: 150.02%;
  /* // letter-spacing: 1px; */
  font-family: "Roboto Slab", sans-serif;
  color: #ffffff;

  ${media.mobile`
  width: 100%;
  font-weight: 400;
  font-size: 18px;
  line-height: 120%;
 
  `}
`;
export const SearchDiv = styled.div`
  position: relative;
  background-color: white;
  width: 49%;
  margin-left: 4px;
  padding: 10px 15px;

  h2 {
    text-decoration: underline;
    color: blue;
  }
`;

export const SearchBtn = styled.button`
  display: flex;
  flex-direction: center;
  justify-content: center;
  background-color: #d86d5e;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  padding: 5px;
  position: absolute;
  color: white;
  font-weight: 700;
  margin-top: -7px;
  margin-left: 23rem;
`;

export const MainBtn = styled.button`
  width: 150px;
  height: 45px;
  margin-top: 20px;
  background: #46555c;

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
  cursor: pointer;
  border-radius: 5px;
  transition: 0.5s;

  :hover {
    background: transparent;
    border: 2px solid #46555c;
    color: #46555c;
    /* // front: blod; */
    font-weight: bold;
    font-size: 20px;
  }
  ${media.mobile`
  width: 150px;
  height: 40px;
  
  `}
`;
