import DashboardComp from "components/Dashboard/Dashboard";
import React from "react";
import Layout from "../components/layout/Layout";

function DashboardPg() {
  return (
    <div>
      <Layout>
        <DashboardComp />
      </Layout>
    </div>
  );
}

export default DashboardPg;
