import {
  MdOutlineAppRegistration,
  MdOutlineArticle,
  MdOutlineCancelPresentation,
  MdOutlinePendingActions,
  MdRateReview
} from "react-icons/md";

import { SiSimpleanalytics } from "react-icons/si";

export const adminCards = [
  {
    name: "Total Articles",
    value: 20,
    icon: <MdOutlineArticle />,
    status: "total"
  },
  {
    name: "Accepted Articles",
    value: 3,
    icon: <MdOutlineAppRegistration />,
    status: "accepted"
  },
  {
    name: "Pending Articles",
    value: 7,
    icon: <MdOutlinePendingActions />,
    status: "pending"
  },
  {
    name: "Reviewed Articles",
    value: 7,
    icon: <MdRateReview />,
    status: "review"
  },
  {
    name: "Rejected Articles",
    value: 4,
    icon: <MdOutlineCancelPresentation />,
    status: "rejected"
  },

  {
    name: "Published Articles",
    value: 6,
    icon: <SiSimpleanalytics />,
    status: "published"
  }
];

export const heading = [
  { Header: "Author", accessor: "author" },
  { Header: "Title", accessor: "title" },
  { Header: "Email Address", accessor: "email" },
  { Header: "Status", accessor: "status" }
  // { Header: "Status", accessor: "download" },
  // { Header: "Status", accessor: "download" },
];

export const courseHeading = [
  { Header: "Type", accessor: "type" },
  { Header: "Title", accessor: "title" },
  { Header: "Category", accessor: "category" },
  { Header: "URL", accessor: "resourceUrl" },
  { Header: "Actions", accessor: "" }
  // { Header: "Actions", Taccessor: "" },
];

export const PlanHeading = [
  { Header: "Type", accessor: "type" },
  { Header: "Courses(No)", accessor: "courses" },
  // { Header: "Duration", accessor: "duration" },
  { Header: "Major", accessor: "major" },
  { Header: "Price", accessor: "price" },
  { Header: "Actions", accessor: "" }
];

export const staffHeading = [
  { Header: "Name", accessor: "name" },
  { Header: "Phone Number", accessor: "phone" },
  { Header: "Email Address", accessor: "email" },
  { Header: "Type", accessor: "type" }
];
export const articleData = [
  {
    author: "Price Kuro Benjamin",
    email: "PriceKuro@gmail.com",
    title: "Finding Kuro",
    status: "pending"
  },
  {
    author: "Praise Kuro Benjamin",
    email: "PriceKuro@gmail.com",
    title: "Finding Kuro",
    status: "pending"
  },
  {
    author: "Praise Kuro Benjamin",
    email: "PriceKuro@gmail.com",
    title: "Finding Kuro",
    status: "accepted"
  },
  {
    author: "Praise Kuro Benjamin",
    email: "PriceKuro@gmail.com",
    title: "Finding Kuro",
    status: "accepted"
  },
  {
    author: "Praise Kuro Benjamin",
    email: "PriceKuro@gmail.com",
    title: "Finding Kuro",
    status: "accepted"
  },
  {
    author: "Peace Kuro Benjamin",
    email: "PriceKuro@gmail.com",
    title: "Finding Kuro",
    status: "pending"
  },
  {
    author: "Arkad Kuro Benjamin",
    email: "PriceKuro@gmail.com",
    title: "Finding Kuro",
    status: "published"
  },
  {
    author: "Faith Kuro Benjamin",
    email: "PriceKuro@gmail.com",
    title: "Eating Fish",
    status: "rejected"
  },
  {
    author: "Price Kuro Benjamin",
    email: "PriceKuro@gmail.com",
    title: "Finding Kuro",
    status: "published"
  },
  {
    author: "Price Kuro Benjamin",
    email: "PriceKuro@gmail.com",
    title: "Finding Kuro",
    status: "rejected"
  },
  {
    author: "Price Kuro Benjamin",
    email: "PriceKuro@gmail.com",
    title: "Finding Kuro",
    status: "published"
  },
  {
    author: "Price Kuro Benjamin",
    email: "PriceKuro@gmail.com",
    title: "Finding Kuro",
    status: "published"
  },
  {
    author: "Price Kuro Benjamin",
    email: "PriceKuro@gmail.com",
    title: "Finding Kuro",
    status: "published"
  },
  {
    author: "Price Kuro Benjamin",
    email: "PriceKuro@gmail.com",
    title: "Finding Kuro",
    status: "accepted"
  },
  {
    author: "Price Kuro Benjamin",
    email: "PriceKuro@gmail.com",
    title: "Finding Kuro",
    status: "accepted"
  },
  {
    author: "Price Kuro Benjamin",
    email: "PriceKuro@gmail.com",
    title: "Finding Kuro",
    status: "pending"
  },
  {
    author: "Price Kuro Benjamin",
    email: "PriceKuro@gmail.com",
    title: "Finding Kuro",
    status: "accepted"
  },
  {
    author: "Price Kuro Benjamin",
    email: "PriceKuro@gmail.com",
    title: "Finding Kuro",
    status: "accepted"
  },
  {
    author: "Price Kuro Benjamin",
    email: "PriceKuro@gmail.com",
    title: "Finding Kuro",
    status: "published"
  },
  {
    author: "Price Kuro Benjamin",
    email: "PriceKuro@gmail.com",
    title: "Finding Kuro",
    status: "published"
  },
  {
    author: "Price Kuro Benjamin",
    email: "PriceKuro@gmail.com",
    title: "Finding Kuro",
    status: "pending"
  },
  {
    author: "Price Kuro Benjamin",
    email: "PriceKuro@gmail.com",
    title: "Finding Kuro",
    status: "accepted"
  },
  {
    author: "Price Kuro Benjamin",
    email: "PriceKuro@gmail.com",
    title: "Finding Kuro",
    status: "accepted"
  },
  {
    author: "Price Kuro Benjamin",
    email: "PriceKuro@gmail.com",
    title: "Finding Kuro",
    status: "accepted"
  },

  {
    author: "Price Kuro Benjamin",
    email: "PriceKuro@gmail.com",
    title: "Finding Kuro",
    status: "pending"
  },
  {
    author: "Price Kuro Benjamin",
    email: "PriceKuro@gmail.com",
    title: "Finding Kuro",
    status: "pending"
  }
];
export const adminInfo = [
  {
    name: "Linus Mba",
    email: "Linus@gmail.com",
    phone: "+234 812 048 4386",
    country: "Iraq"
  },
  {
    name: "Linus Mba",
    email: "Linus@gmail.com",
    phone: "+234 812 048 4386",
    country: "Iraq"
  },
  {
    name: "Linus Mba",
    email: "Linus@gmail.com",
    phone: "+234 812 048 4386",
    country: "Iraq"
  },
  {
    name: "Linus Mba",
    email: "Linus@gmail.com",
    phone: "+234 812 048 4386",
    country: "Iraq"
  },
  {
    name: "Linus Mba",
    email: "Linus@gmail.com",
    phone: "+234 812 048 4386",
    country: "Iraq"
  },
  {
    name: "Linus Mba",
    email: "Linus@gmail.com",
    phone: "+234 812 048 4386",
    country: "Iraq"
  },
  {
    name: "Linus Mba",
    email: "Linus@gmail.com",
    phone: "+234 812 048 4386",
    country: "Iraq"
  },
  {
    name: "Linus Mba",
    email: "Linus@gmail.com",
    phone: "+234 812 048 4386",
    country: "Iraq"
  },
  {
    name: "Linus Mba",
    email: "Linus@gmail.com",
    phone: "+234 812 048 4386",
    country: "Iraq"
  },
  {
    name: "Linus Mba",
    email: "Linus@gmail.com",
    phone: "+234 812 048 4386",
    country: "Iraq"
  },
  {
    name: "Linus Mba",
    email: "Linus@gmail.com",
    phone: "+234 812 048 4386",
    country: "Iraq"
  },
  {
    name: "Linus Mba",
    email: "Linus@gmail.com",
    phone: "+234 812 048 4386",
    country: "Iraq"
  },
  {
    name: "Linus Mba",
    email: "Linus@gmail.com",
    phone: "+234 812 048 4386",
    country: "Iraq"
  },
  {
    name: "Linus Mba",
    email: "Linus@gmail.com",
    phone: "+234 812 048 4386",
    country: "Iraq"
  },
  {
    name: "Linus Mba",
    email: "Linus@gmail.com",
    phone: "+234 812 048 4386",
    country: "Iraq"
  },
  {
    name: "Linus Mba",
    email: "Linus@gmail.com",
    phone: "+234 812 048 4386",
    country: "Iraq"
  },
  {
    name: "Irom Mba",
    email: "Linus@gmail.com",
    phone: "+234 812 048 4386",
    country: "Iraq"
  },
  {
    name: "Linus Mba",
    email: "Linus@gmail.com",
    phone: "+234 812 048 4386",
    country: "Iraq"
  },
  {
    name: "Linus Mba",
    email: "Linus@gmail.com",
    phone: "+234 812 048 4386",
    country: "Iraq"
  },
  {
    name: "Linus Mba",
    email: "Linus@gmail.com",
    phone: "+234 812 048 4386",
    country: "Iraq"
  },
  {
    name: "Linus Mba",
    email: "Linus@gmail.com",
    phone: "+234 812 048 4386",
    country: "Iraq"
  },
  {
    name: "Linus Mba",
    email: "Linus@gmail.com",
    phone: "+234 812 048 4386",
    country: "Iraq"
  },
  {
    name: "Linus Mba",
    email: "Linus@gmail.com",
    phone: "+234 812 048 4386",
    country: "Iraq"
  },
  {
    name: "Linus Mba",
    email: "Linus@gmail.com",
    phone: "+234 812 048 4386",
    country: "Iraq"
  },
  {
    name: "Linus Mba",
    email: "Linus@gmail.com",
    phone: "+234 812 048 4386",
    country: "Iraq"
  },
  {
    name: "Linus Mba",
    email: "Linus@gmail.com",
    phone: "+234 812 048 4386",
    country: "Iraq"
  },
  {
    name: "Linus Mba",
    email: "Linus@gmail.com",
    phone: "+234 812 048 4386",
    country: "Iraq"
  },
  {
    name: "Linus Mba",
    email: "Linus@gmail.com",
    phone: "+234 812 048 4386",
    country: "Iraq"
  },
  {
    name: "Linus Mba",
    email: "Linus@gmail.com",
    phone: "+234 812 048 4386",
    country: "Iraq"
  },
  {
    name: "Linus Mba",
    email: "Linus@gmail.com",
    phone: "+234 812 048 4386",
    country: "Iraq"
  },
  {
    name: "Linus Mba",
    email: "Linus@gmail.com",
    phone: "+234 812 048 4386",
    country: "Iraq"
  },
  {
    name: "Linus Mba",
    email: "Linus@gmail.com",
    phone: "+234 812 048 4386",
    country: "Iraq"
  },
  {
    name: "Linus Mba",
    email: "Linus@gmail.com",
    phone: "+234 812 048 4386",
    country: "Iraq"
  },
  {
    name: "Linus Mba",
    email: "Linus@gmail.com",
    phone: "+234 812 048 4386",
    country: "Iraq"
  },
  {
    name: "Linus Mba",
    email: "Linus@gmail.com",
    phone: "+234 812 048 4386",
    country: "Iraq"
  },
  {
    name: "Linus Mba",
    email: "Linus@gmail.com",
    phone: "+234 812 048 4386",
    country: "Iraq"
  },
  {
    name: "Linus Mba",
    email: "Linus@gmail.com",
    phone: "+234 812 048 4386",
    country: "Iraq"
  },
  {
    name: "Linus Mba",
    email: "Linus@gmail.com",
    phone: "+234 812 048 4386",
    country: "Iraq"
  },
  {
    name: "Linus Mba",
    email: "Linus@gmail.com",
    phone: "+234 812 048 4386",
    country: "Iraq"
  },
  {
    name: "Linus Mba",
    email: "Linus@gmail.com",
    phone: "+234 812 048 4386",
    country: "Iraq"
  },
  {
    name: "Linus Mba",
    email: "Linus@gmail.com",
    phone: "+234 812 048 4386",
    country: "Iraq"
  },
  {
    name: "Linus Mba",
    email: "Linus@gmail.com",
    phone: "+234 812 048 4386",
    country: "Iraq"
  },
  {
    name: "Linus Mba",
    email: "Linus@gmail.com",
    phone: "+234 812 048 4386",
    country: "Iraq"
  },
  {
    name: "Linus Mba",
    email: "Linus@gmail.com",
    phone: "+234 812 048 4386",
    country: "Iraq"
  }
];
