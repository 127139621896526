import { FcDeleteDatabase } from "react-icons/fc";
import React from "react";

// import styled from "styled-components";

// const Container = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   height: 100vh;
// `;

// const Title = styled.h1`
//   font-size: 2rem;
//   margin-bottom: 2rem;
// `;

// const Subtitle = styled.h2`
//   font-size: 1.5rem;
// `;

const NoCoursesPage = () => {
  return (
    <div className=" flex flex-col w-full items-center justify-center space-y-8 capitalize font-bold text-2xl py-4">
      <span>
        <FcDeleteDatabase size={40} />
      </span>
      <h1>No resource available</h1>
      <p>Check back later for more resources!</p>
      <div className="flex items-center !w-1/2 mx-auto my-8">
        <hr className="flex-1 border-t-[0.2px] border-black/30" />
        <span className="mx-4 text-black/70 italic uppercase">or</span>
        <hr className="flex-1 border-t-[0.2px] border-black/30" />
      </div>
      <div>
        <button
          className=" capitalize py-1 bg-black/80 text-white rounded-lg px-2 font-light text-sm"
          onClick={() => window.location.reload()}
        >
          retry
        </button>
      </div>
    </div>
  );
};

export default NoCoursesPage;
