export const LOCAL_STORAGE = {
  user: () => JSON.parse(localStorage.getItem("isUserDetails")),
  token: () => JSON.parse(localStorage.getItem("jms-token")),
  refreshToken: () => JSON.parse(localStorage.getItem("jmsRefreshToken")),
  adminId: () => JSON.parse(localStorage.getItem("adminId")),
  articleId: () => JSON.parse(localStorage.getItem("articleId")),
  verifyId: () => JSON.parse(localStorage.getItem("verifyId")),
  jwt: () => JSON.parse(localStorage.getItem("jwt")),
  recoveryId: () => JSON.parse(localStorage.getItem("recoveryID")),
  tempAccountMail: () => JSON.parse(localStorage.getItem("tempAccountEmail")),
  course: () => JSON.parse(localStorage.getItem("course")),
  categoryId: () => JSON.parse(localStorage.getItem("categoryId")),
 
  auth_email: () => JSON.parse(localStorage.getItem("auth-email")),
  auth_state: () => JSON.parse(localStorage.getItem("auth-state"))
};
