import { Img } from "globalStyles";
import React from "react";
import { FooterItemDiv } from "./style";

function FooterItem({ icon, text = "phone" }) {
  return (
    <FooterItemDiv>
      <Img src={icon} alt={text} />
      <p className="text-contact">{text}</p>
    </FooterItemDiv>
  );
}

export default FooterItem;
