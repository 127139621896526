export const HOME_ROUTE = "/";
export const LOGIN_ROUTE = "/login";
export const ELEARN_ROUTE = "/elearn";
export const LOGIN_ADMIN_ROUTE = "/admin/login";
export const SIGNUP_ROUTE = "/signup";
export const ADMIN_SIGNUP_ROUTE = "/admin-register";
export const JOURNAL_ROUTE = "/journals";

export const COMP_ROUTE = "/journals/computer-science";
export const ENGINE_ROUTE = "/journals/engineering";
export const AGRIC_ROUTE = "/journals/agric";
export const SFR_ROUTE = "/journals/science-frontier-research";
export const MGT_ROUTE = "/journals/management";
export const HSS_ROUTE = "/journals/human-social-science";
export const MED_ROUTE = "/journals/medical-research";
export const SIDEBAR_AUTHOR_ROUTE = "author/submission-procedure";
export const SIDEBAR_ARTICLE_ROUTE = "/articles-sort";
export const SIDEBAR_EDITORIAL_ROUTE = "/editorial-team";
// export const
export const SUPPORT_ROUTE = "/support";
export const SUBMIT_ROUTE = "/submit";
export const ABOUT_ROUTE = "/about";
export const FAQ_ROUTE = "/faqs";
export const E_LEARNING_ROUTE = "/e-learning";
export const BLOG_ROUTE = "/blog";
export const FORGOT_PASSWORD_ROUTE = "/forgot-password";
export const VERIFY_RECOVERY_ROUTE = "/user/verify-reset";
export const RESET_PASSWORD_ROUTE = "/reset-password";
export const DASHBOARD_ROUTE = "/dashboard";
export const DASHBOARD_PROFILEROUTE = "/dashboard/profile";
export const DASHBOARD_PAYMENT_DETAILS_ROUTE = "/dashboard/payment-details";
export const DASHBOARD_APPLY_ROUTE = "/dashboard/apply";
export const DASHBOARD_COURSES_ROUTE = "/dashboard/courses";
export const DASHBOARD_APPLY_UNDERGRAD_ROUTE = "/dashboard/apply/undergraduate";
export const DASHBOARD_APPLY_POSTGRAD_ROUTE = "/dashboard/apply/postgraduate";
export const DASHBOARD_APPLY_PROFESSIONAL_ROUTE =
  "/dashboard/apply/professional";
export const DASHBOARD_SETTINGS_ROUTE = "/dashboard/settings";
export const DASHBOARD_LOGOUT_ROUTE = "/dashboard/logout";
export const CREATE_ACCOUNT_ROUTE = "/create-account";
export const VERIFY_OTP_ROUTE = "/verify-otp";
export const COURSES_ROUTE = "courses";
export const ACCOUNT_ROUTE = "/account";
export const COURSE_ROUTE = "/course/:id";
export const JAMB_ROUTE = "/courses/jamb";
export const WAEC_ROUTE = "/courses/waec";
export const PAYMENT_ROUTE = "/payment";
export const CHECKOUT_ROUTE = "/payment/checkout";
export const ADMIN_DASHBOARD_ROUTE = "/admin/dashboard";
export const VERIFY_INVITE_ROUTE = "/invite/:id";
export const VERIFY_ARTICLE_ROUTE = "/article";
export const ARTICLES_ROUTE = "/admin/articles";
export const ARTICLE_ROUTE = "/admin/articles/:id";
export const STAFF_ROUTE = "/admin/staffs";
export const ADMIN_COURSE_ROUTE = "/admin/course";
export const ADMIN_PLAN_ROUTE = "/admin/plan";
export const ADMIN_SETTINGS = "/admin/settings";
export const UNAUTHORIZED_ROUTE = "/unauthorized";
