import { FiDownload, FiEye } from "react-icons/fi";
import { Menu, Transition } from "@headlessui/react";

import { ARTICLE_ENDPOINT } from "api/ACTION";
import { AiFillDelete } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import { Fragment } from "react";
import Tooltip from "utils/tooltip";
import jmsApp from "api/jms";
import styled from "styled-components";
import { toast } from "react-hot-toast";
import tw from "twin.macro";

const StyledSpan = styled.span`
  background-color: ${(props) => props.color};
  ${tw`w-full text-center mx-auto  inline-block uppercase px-6 py-2 rounded-lg whitespace-nowrap text-sm font-medium text-white`}
`;

const activeStatus = ["approved", "review", "published", "rejected", "pending"];

function Table({
  data,

  columns,
  user,
  actions = [],
  hasStatus = false,
  handleUpdateData
}) {
  const TableActions = {
    edit: ActionEdit,
    download: ActionDownload,
    delete: ActionDelete,
    view: ActionView
  };

  const handleStatus = async (articleId, status, id) => {
    try {
      const { data: updateData } = await jmsApp.patch(
        ARTICLE_ENDPOINT.ARTICLE_STATUS(),
        {
          articleId: articleId,
          status: status
        }
      );

      if (updateData.success) {
        const newDeets = [...data];
        const newDataIdx = newDeets.findIndex((item, idx) => idx === id);

        if (newDataIdx >= 0) {
          newDeets[newDataIdx].status = status;
        }

        handleUpdateData([...newDeets]);

        // defaultStat.splice(id, 1, status);
        // setdefaultStat([...defaultStat]);
      }
    } catch (error) {
      if (error.response) {
        //toast.error(error.response.data.error)
      }
    }
  };

  function ActionView({ action, item }) {
    return (
      <Tooltip text="view">
        <button onClick={() => action(item)}>
          <FiEye size={25} />
        </button>
      </Tooltip>
    );
  }
  function ActionDownload({ action, item }) {
    return (
      <Tooltip text="download">
        <button
          onClick={() => action(item)}
          disabled={item.status === "pending" || item.status === "rejected"}
          className=" disabled:curso r-not-allowed"
        >
          <FiDownload size={25} />
        </button>
      </Tooltip>
    );
  }
  function ActionDelete({ action, item }) {
    return (
      <Tooltip text="delete">
        <button onClick={() => action(item)}>
          <AiFillDelete size={25} />
        </button>
      </Tooltip>
    );
  }
  function ActionEdit({ action, item }) {
    return (
      <Tooltip text="edit">
        <button onClick={() => action(item)}>
          <BiEdit size={25} />
        </button>
      </Tooltip>
    );
  }

  function RenderAction({ actionType, action, item, key }) {
    const ActionComponent = TableActions[actionType];

    if (ActionComponent) {
      return <ActionComponent action={action} item={item} key={key} />;
    } else {
      return null; // or render a default action or handle unknown action types
    }
  }
  const statusColors = {
    default: "rgb(129 140 248)",
    pending: "rgb(167 139 250)",
    review: "rgb(254 240 138)",
    approved: "rgb(96 165 250)",
    published: "rgb(74 222 128)",
    rejected: "rgb(248 113 113)"
  };

  return (
    <div className="flex flex-col">
      <div className="-m-1.5 overflow-x-auto w-full">
        <div className="p-1.5 sm:p-6 min-w-full inline-block align-middle">
          <div className="overflow-hidden">
            <table className="min-w-full divide-y divide-gray-200 ">
              <thead>
                <tr>
                  {columns.map((column) => {
                    if (column.accessor === "") {
                      return null;
                    }
                    return (
                      <th
                        key={column.accessor}
                        scope="col"
                        className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase"
                      >
                        {column.Header}
                      </th>
                    );
                  })}

                  {actions.length > 0 && (
                    <th
                      scope="col"
                      className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase"
                    >
                      actions
                    </th>
                  )}
                </tr>
              </thead>

              <tbody>
                {data.map((item, dataIdx) => (
                  <tr
                    key={`${dataIdx}--%`}
                    // className="odd:bg-white even:bg-gray-100 hover:bg-gray-100 "
                    className="odd:bg-white even:bg-gray-100 hover:bg-gray-100 "
                  >
                    {columns.map((col, idx) => {
                      if (col.accessor === "") {
                        return null;
                      }
                      if (col.accessor === "resourceUrl") {
                        return (
                          <td
                            key={idx}
                            className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 "
                          >
                            <a
                              href={`${item[col.accessor]}`}
                              className=" uppercase transition-all  duration-300 ease-in-out  hover:underline"
                            >
                              link
                            </a>
                          </td>
                        );
                      }

                      if (item.status) {
                        return (
                          <td
                            key={`${idx}-${item.status || "nil"}`}
                            className={` px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 `}
                          >
                            {col.accessor === "status" ? (
                              <Menu
                                as="div"
                                className="relative uppercase inline-block text-left"
                              >
                                <div>
                                  <Menu.Button className="inline-flex w-full justify-center rounded-md  px-4 py-2 text-sm font-medium text-white  focus:outline-none">
                                    <StyledSpan
                                      color={
                                        statusColors[
                                          `${item.status}`.toLowerCase()
                                        ]
                                      }
                                    >
                                      {Array.isArray(item[col.accessor])
                                        ? item[col.accessor].length
                                        : item[col.accessor] || "Nil"}
                                    </StyledSpan>
                                  </Menu.Button>
                                </div>

                                <Transition
                                  as={Fragment}
                                  enter="transition ease-out duration-100"
                                  enterFrom="transform opacity-0 scale-95"
                                  enterTo="transform opacity-100 scale-100"
                                  leave="transition ease-in duration-75"
                                  leaveFrom="transform opacity-100 scale-100"
                                  leaveTo="transform opacity-0 scale-95"
                                >
                                  <Menu.Items className="absolute z-50 -left-6 -top-16 mt-2 w-56 origin-top-right divide-y divide-gray-100 min-h-[4rem] rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                                    <div className="px-1 py-1 flex flex-col items-center gap-2">
                                      {activeStatus.map((status, idx) => {
                                        return (
                                          <Menu.Item
                                            key={`status--${idx}`}
                                            id={status}
                                            onClick={() => {
                                              handleStatus(
                                                item.articleId,
                                                status,
                                                dataIdx
                                              );
                                            }}
                                          >
                                            {({ active }) => {
                                              return (
                                                <button className="bg-transparent">
                                                  {" "}
                                                  {active ? (
                                                    <StyledSpan
                                                      color={
                                                        statusColors[
                                                          `${status}`.toLowerCase()
                                                        ]
                                                      }
                                                    >
                                                      {status}
                                                    </StyledSpan>
                                                  ) : (
                                                    <span className="text-gray-900  block px-4 py-2 text-sm">
                                                      {status}
                                                    </span>
                                                  )}
                                                </button>
                                              );
                                            }}
                                          </Menu.Item>
                                        );
                                      })}
                                    </div>
                                  </Menu.Items>
                                </Transition>
                              </Menu>
                            ) : Array.isArray(item[col.accessor]) ? (
                              item[col.accessor].length
                            ) : (
                              item[col.accessor] || "Nil"
                            )}
                          </td>
                        );
                      } else {
                        return (
                          <td
                            key={idx}
                            className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 "
                          >
                            {Array.isArray(item[col.accessor])
                              ? item[col.accessor].length
                              : item[col.accessor] || "Nil"}
                          </td>
                        );
                      }
                    })}
                    {actions.length > 0 && (
                      <td
                        className={`px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800  grid grid-rows-1 grid-flow-col gap-2 place-items-center`}
                      >
                        {actions.map((action, idx) =>
                          RenderAction({
                            key: idx,
                            item: item,
                            action: action.action,
                            actionType: action.name
                          })
                        )}
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Table;
