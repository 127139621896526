import Layout from "components/Admin/DashboardLayout/Layout";
import Courses from "components/Admin/course/Courses";
import React from "react";

function AdminCoursePg() {
  return (
    <Layout>
      <Courses />
    </Layout>
  );
}

export default AdminCoursePg;
