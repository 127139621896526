export const ADMIN_ROLES = {
  SUPER_ADMIN: "super",
  FORMATTER: "formatter",
  SUB_ADMIN: "sub",
  E_LEARNING: "e-learning",
  EDITOR: "editor",
  USER: "user"
};
export const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "black"
};
