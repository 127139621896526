import Mainlayout from 'layout/MainLayout'
import React from 'react'
import  SupportComp  from '../components/SupportComp'

function SupportPage() {
  return (
    <Mainlayout>
      <SupportComp />
    </Mainlayout>
  )
}

export default SupportPage
