import styled from 'styled-components'
import media from 'utils/media'

//Home component design

export const HomeDiv = styled.div`
  background-color: red;
  color: white;
`
export const JournalDiv = styled.div`
  background-color: blue;
  color: white;
`
export const BlogDiv = styled.div`
  background-color: red;
  color: white;
  
`

export const LineBreak = styled.hr`
  width: 704px;
  margin-left: 8.5%;
  border: 3px solid #d5d5d5;
 
  ${media.mobile`
  width: 70%;
  `}
`
