import styled, { createGlobalStyle } from "styled-components/macro";

import { Link } from "react-router-dom";
import tw from "twin.macro";

export const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@400;500;600;700;900&display=swap');


* {
  border: 0;
  padding: 0;
  margin: 0;
  outline: 0;
  list-style: none;
  box-sizing: border-box;
  text-decoration: none;
  /* SCROLL BAR */
  scrollbar-width: thin;
  scrollbar-color: #999 transparent;
}
body {
  margin: 0;
  font-family: "Roboto Slab",  -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Tahoma', 'Montserrat', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue' ,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
 
}


::-webkit-scrollbar {
  width: 8px; 
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #999; /* Set the color of the scrollbar thumb */
  border-radius: 4px; /* Set the border radius for rounded edges */
}



[class^="number-slide"],
[class*=" number-slide"] {
	background: grey;
	display: flex;
	align-items: center;
	color: #fff;
	height: 300px;
	max-height: 100vh;
}

h1, h2, h3, h4, h5 {

	font-family: 'Roboto Slab';
  	font-style: normal;
}

p{
	font-family: "Roboto Slab";
		font-style: normal;
}

`;
export const Img = styled.img`
  width: ${({ w }) => (w ? w : null)};
  height: ${({ h }) => (h ? h : null)};
  margin: ${({ m }) => (m ? m : null)};
  margin-left: ${({ ml }) => (ml ? ml : null)};
  padding: ${({ p }) => (p ? p : null)};
  border-radius: ${({ br }) => (br ? br : null)};
  align-self: ${({ align }) => (align ? align : null)};
`;
export const MainContainer = styled.main`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px 0px;
  overflow: hidden;
  flex-wrap: wrap;
  min-height: calc(100vh - 75px);
`;

export const AppLink = styled(Link)`
  text-decoration: none;
  cursor: pointer;
  :hover {
    color: "white";
  }
`;
export const Div = styled.div`
  width: ${({ width }) => (width ? width : null)};
  height: ${({ height }) => (height ? height : null)};
  margin: ${({ margin }) => (margin ? margin : null)};
  padding: ${({ padding }) => (padding ? padding : null)};
  display: ${({ display }) => display};
  align-items: ${({ alignI }) => alignI};
  flex: ${({ fg }) => (fg ? fg : null)};
  align-self: ${({ align }) => (align ? align : null)};
  margin: ${({ margin }) => (margin ? margin : null)};
  margin-top: ${({ mt }) => (mt ? mt : null)};
  margin-left: ${({ ml }) => (ml ? ml : null)};
  margin-right: ${({ ml }) => (ml ? ml : null)};
  margin-bottom: ${({ mb }) => (mb ? mb : null)};
  display: ${({ display }) => display || null};
  flex-direction: ${({ fd }) => fd || null};
  flex-wrap: ${({ wrap }) => wrap || null};
  justify-content: ${({ justify }) => (justify ? justify : null)};
  background-color: ${({ bc }) => (bc ? bc : null)};
  background-image: ${({ image }) => (image ? `url(${image})` : null)};
  box-shadow: ${({ boxShadow }) => (boxShadow ? boxShadow : null)};
  box-sizing: ${({ boxSizing }) => (boxSizing ? boxSizing : null)};
  background-size: cover;
  cursor: ${({ cursor }) => (cursor ? cursor : null)};
  background-repeat: no-repeat;
  border: ${({ border }) => (border ? border : null)};
  border-radius: ${({ br }) => (br ? br : null)};
  top: ${({ top }) => (top ? top : null)};
  color: ${({ color }) => (color ? color : null)};
  position: ${({ position }) => (position ? position : "")};
`;

export const Section = styled.section`
  /* // margin-top: 2rem; 
  // padding: 20px 0px;*/

  ${tw`px-6 sm:px-12 `}
`;
