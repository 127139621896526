import styled from "styled-components/macro";
import Colors from "utils/colors";
// import media from "utils/media";

export const Heading = styled.div`
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 50px;
    color: ${Colors.primary};
    margin: 5px;
    span {
      display: block;
    
  }
`

export const TextBody = styled.div`
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 30px;
        margin: 20px Opx;
    padding : 10px 8.5%;
    color: #0a033c;
   
`

