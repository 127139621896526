import CourseComp from "components/courses/Course";
import React from "react";

const CoursePg = () => {
  return (
    <div className=" w-[90%] sm:w-4/5 mx-auto flex items-center min-h-screen">
      <CourseComp />
    </div>
  );
};

export default CoursePg;
