import "./CategoryTable.css";

import React, { useEffect, useState } from "react";

import AddModal from "./AddModal";
import { AiOutlineDelete } from "react-icons/ai";
import { CATEGORY_ENDPOINT } from "api/ACTION";
import { FiEdit2 } from "react-icons/fi";
import { HiPlus } from "react-icons/hi";
import { LOCAL_STORAGE } from "api/LOCALSTORAGE";
import UpdateModal from "./UpdateModal";
import jmsApp from "api/jms";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

export default function AddCategories() {
  const [openModal, setOpenModal] = useState(false);
  const [openModal2, setOpenModal2] = useState(false);
  const [category, setCategory] = useState([]);
  const user = JSON.parse(localStorage.getItem("isUserDetails"));
  const [categoryId, setCategoryId] = useState("");
  const [updated, setUpdated] = useState(true);

  const handleCategory = async () => {
    try {
      const { data } = await jmsApp.get(CATEGORY_ENDPOINT.GET_CATEGORY(), {
        refreshToken: LOCAL_STORAGE.refreshToken()
      });
      if (data.success) {
        setCategory(data.category);
        setUpdated(false);
      }
    } catch (error) {
      // toast.error(error.response.data.error);
    }
  };

  const navigate = useNavigate();

  const handleDelete = async (catId, catName) => {
    setCategoryId(catId);
    try {
      const { data } = await toast.promise(
        jmsApp.delete(
          CATEGORY_ENDPOINT.DELETE_CATEGORY(catId)
          // { params: { categoryId: catId } }
        ),
        {
          loading: `Deleting ${catName} category...`,
          success: `${catName} category successfully deleted.`,
          error: `Failed to delete ${catName} category`
        },
        {
          className: "text-[12px]"
        }
      );
      if (data.success) {
        handleCategory();
      }
    } catch (error) {
      if (error.response?.status === 401) {
        navigate("/login");
      }
      // toast.error(error.response.data.error);
    }
  };

  const handleSelect = (e) => {
    setCategoryId(e.currentTarget.id);
    setOpenModal2(true);
  };

  useEffect(() => {
    if (updated === true) {
      handleCategory();
    }
  }, [updated]);

  const handleClose = () => {
    handleCategory();
    setOpenModal(false);
  };

  return (
    <div className=" flex flex-col items-center w-full justify-between space-y-10">
      <section className=" w-4/5  mx-auto grid grid-cols-2 place-items-center">
        {openModal && <AddModal open={openModal} onClose={handleClose} />}

        {openModal2 && (
          <UpdateModal
            // open={openModal2}
            categoryId={categoryId}
            category={
              category.filter((category) => category.id === categoryId)[0]
            }
            setUpdated={setUpdated}
            onClose={() => setOpenModal2(false)}
          />
        )}
      </section>

      <section className=" flex flex-col items-center w-full justify-start space-y-8">
        <div className="flex items-center justify-between my-4 w-full">
          <h1 className="text-black text-lg sm:text-3xl font-bold">
            Categories{" "}
          </h1>

          <div className="my-4">
            {user.type === "super" && (
              <button
                className="flex items-center g2ap-x-1 bg-black/50 text-white cursor-pointer rounded-lg py-2 px-4 w-fit h-fit transition-colors duration-300 ease-in-out hover:bg-white/50 hover:text-black/50 hover:shadow-lg active:shadow-none "
                onClick={() => setOpenModal(true)}
              >
                <span className="whitespace-nowrap">Add Category</span>
                <span>
                  <HiPlus size={24} />
                </span>
              </button>
            )}
          </div>
        </div>

        <table className="styled-table ">
          <thead>
            <tr>
              <th className="px-4 py-2 uppercase"> Name</th>
              <th className="px-4 py-2 uppercase flex items-center justify-center">
                <span>Actions</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {category?.map((item, index) => (
              <tr id={item.id} key={index}>
                <td className="border px-4 py-2 capitalize">{item?.name}</td>
                <td className="grid grid-cols-2 place-items-center">
                  <button
                    className="flex items-center justify-center space-x-4 w-full text-blue-400"
                    id={item.id}
                    open={openModal2}
                    onClick={(e) => handleSelect(e)}
                  >
                    <FiEdit2 />
                    <span>Edit</span>
                  </button>

                  <button
                    className="flex items-center justify-center space-x-4 w-full text-red-400"
                    id={item.id}
                    onClick={() => handleDelete(item.id, item.name)}
                  >
                    <AiOutlineDelete />
                    <span>Delete</span>
                  </button>
                </td>
              </tr>
            ))}
            {openModal2 && (
              <UpdateModal
                // open={openModal2}
                category={
                  category.filter((category) => category.id === categoryId)[0]
                }
                categoryId={categoryId}
                setUpdated={setUpdated}
                onClose={() => setOpenModal2(false)}
              />
            )}
          </tbody>
        </table>
      </section>
    </div>
  );
}
