import React from 'react'
import tw from 'twin.macro';
import styled from 'styled-components'
import NavBar from './ArticleViewSidebar'
import Footer from 'components/Footer'


const ArtViewCon = styled.div`
  /* display: flex; */
  width: 100%;
`
const ArtViewContent = styled.div`
  width: 100%;
  ${tw`pt-12 sm:pt-16 pb-[50px]`}
`

function ArticleViewLayout(props) {
  return (
    <ArtViewCon>
      <NavBar />
      <ArtViewContent>{props.children}</ArtViewContent>
      <Footer />
    </ArtViewCon>
  )
}

export default ArticleViewLayout
