import styled, { keyframes } from "styled-components";

import Colors from "utils/colors";
import { Link } from "react-router-dom";
import media from "utils/media";

export const SlideInPopUp = keyframes`
from {
    opacity: 0
}
to{
    opacity: 1
}
`;
export const MenuOverlay = styled.div`
  position: fixed;
  display: none;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  z-index: 800;
  background: ${Colors.whiteGradient};
  display: ${({ open }) => (open ? "flex" : "none")};
  transition: all 0.5s ease-out;
  animation-name: ${SlideInPopUp};
  animation-duration: 0.5s;
  backdrop-filter: blur(10px);
  ${media.tablet`
    display:flex;
    `}
  ${media.mobile`
    display:flex;
    
    `}
`;
export const MenuContainer = styled.div`
  padding-top: 3rem;
  position: relative;
  background: ${Colors.white};
  display: flex;
  height: 60vh;
  width: 100%;
  /* margin-top: 20%; */
  flex-direction: column;
  align-items: center;
`;
export const CloseMenuContainer = styled.div`
  width: calc(100% - 40px);
  height: 23px;
  padding: 0px 20px;
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  button {
    background: transparent;
    outline: none;
    border: none;
  }
`;
export const MobileNavLink = styled(Link)`
  text-decoration: none;
`;
export const MobileMenuBtn = styled.button`
  width: 100%;
  height: 35px;
  outline: none;
  border: none;
  background: transparent;
  margin: 5px 0px;
  padding: 0px 20px;
  margin-top: ${({ mt }) => (mt ? mt : null)};
  font-size: 18px;
  font-weight: bold;
`;
export const AuthButtonDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  /* margin-top: 20px; */
  /* padding: 0px 20px; */
`;

export const JoinBtnMobile = styled.button`
  width: 50%;
  height: 40px;
  outline: none;
  border: none;
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 700;
  margin-left: 15px;
  font-size: 12px;
  line-height: 12px;
  padding: 0px 20px;
  letter-spacing: 0.025em;
  text-transform: capitalize;
  background: ${Colors.gradient};
  color: ${Colors.white};
  border-radius: 10px;
  cursor: pointer;
`;
