import { createContext, useEffect, useState } from "react";

import { LOCAL_STORAGE } from "api/LOCALSTORAGE";

export const BuyCourse = createContext({});

function BuyCourseProvider({ children }) {
  function getInitialState() {
    const course = LOCAL_STORAGE.course() || { plan: "", price: null };
    // const course = localStorage.getItem("course");
    // return course ? JSON.parse(course) : { plan: "", price: null };
    return course;
  }
  const [item, setItem] = useState(getInitialState);

  useEffect(() => {
    localStorage.setItem("course", JSON.stringify(item));
  }, [item]);

  return (
    <BuyCourse.Provider value={{ item, setItem }}>
      {children}
    </BuyCourse.Provider>
  );
}
export default BuyCourseProvider;
