import { ADMIN_ROLES, override } from "utils/admin-roles";
import {
  ARTICLE_ENDPOINT,
  COURSE,
  DOWNLOAD_ENDPOINT,
  USER_PLAN
} from "api/ACTION";
import {
  AdminDashboardCont,
  ArticleCont,
  CardCont,
  StatusProp
} from "components/Admin/Dashboard/Dashboard.style";
import ArticleAssign, { handleAssign } from "./article-asign";
import {
  PlanHeading,
  courseHeading,
  heading
} from "../sharedComponents/articleData";
import {
  handleArticlePublished,
  handleArticleReview
} from "./admin-article-count";
import { useEffect, useMemo, useState } from "react";

import AddCategoreis from "../categories/AddCategoreis";
import ArticlesTable from "../Articles/articles-table";
import { ClipLoader } from "react-spinners";
import { Cloudinary } from "@cloudinary/url-gen";
import { HiViewGridAdd } from "react-icons/hi";
import { LOCAL_STORAGE } from "api/LOCALSTORAGE";
import { MdRateReview } from "react-icons/md";
import { SiSimpleanalytics } from "react-icons/si";
import Table from "../sharedComponents/Table-v2";
import authStore from "mobx/AuthStore";
import axios from "axios";
import { handleRefreshToken } from "utils/auth";
import jmsApp from "api/jms";
import { observer } from "mobx-react-lite";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

// import Table from "../sharedComponents/Table";

// import Table from "../sharedComponents/Table";

const AdminDashboard = observer(() => {
  const isAssignModalOpen = authStore.getOpenModalState();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [isFetchingData, setIsFetchingData] = useState(false);
  const columns = useMemo(() => heading, []);
  const courseColumns = useMemo(() => PlanHeading, []);
  const resourcesColumns = useMemo(() => courseHeading, []);
  const currentUser = LOCAL_STORAGE.user();
  const userRole = currentUser?.type ?? "";
  const handleData = async () => {
    try {
      const { data } = await jmsApp.get(ARTICLE_ENDPOINT.GET_ARTICLE());
      if (data?.success) {
        setData(data.article);
      }
    } catch (error) {
      if (error.response?.status === 401) {
        await handleRefreshToken();
        // await handleData()
      } else if (error.response) {
        // toast.error(error.response.data.error);
      }
    }
  };
  const handleLearning = async () => {
    setIsFetchingData(true);
    try {
      const { data: resources } = await jmsApp.get(COURSE.GET_COURSE());
      const { data: courses } = await jmsApp.get(USER_PLAN.GET_PLAN());
      setData({ resources, courses: courses });
    } catch (error) {
      if (error.response?.status === 401) {
        await handleRefreshToken();
        // await handleData()
      } else if (error.response) {
        // toast.error(error.response.data.error);
      }
    } finally {
      setIsFetchingData(false);
    }
  };

  const handleUpdateData = (updatedData) => {
    setData([...updatedData]);
  };

  useEffect(() => {
    if (userRole !== ADMIN_ROLES.E_LEARNING) {
      handleData();
      handleArticlePublished();
      handleArticleReview();
    } else {
      handleLearning();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cld = new Cloudinary({
    cloud: {
      cloudName: process.env.REACT_APP_CLOUDINARY_NAME,
      apiKey: process.env.REACT_APP_CLOUDINARY_API_KEY,
      apiSecret: process.env.REACT_APP_CLOUDINARY_API_SECRET
    }
  });
  const downloadFile = async (articleURL, title, articleId) => {
    const myImage = cld.image(articleURL);
    await axios({
      url: myImage.publicID, //your url
      method: "GET",
      responseType: "blob" // important
    }).then((res) => {
      jmsApp
        .post(DOWNLOAD_ENDPOINT.DOWNLOADED_ARTICLE(), { articleId: articleId })
        .then(() => {
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(res.data);
          link.setAttribute("download", `${title}.pdf`);
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => {
          if (err.response?.status === 401) {
            handleRefreshToken();
          } else if (err.response) {
            // toast.error(err.response.data.error);
          }
        });
    });
  };

  function handleDownload(article) {
    const { articleUrl, title, articleId } = article;

    downloadFile(articleUrl, title, articleId);
  }
  function handleView(article) {
    navigate(`/admin/articles/${article.articleId}`);
  }

  return userRole !== ADMIN_ROLES.E_LEARNING ? (
    <AdminDashboardCont>
      <div className=" w-full flex items-center justify-between py-4 px-6 ">
        <h1 className=" text-xl font-bold uppercase">DashBoard</h1>
        {currentUser.name && (
          <div className=" flex text-xs lg:text-xl font-bold uppercase space-x-4 items-center justify-end ">
            <span>Welcome</span>
            <span className=" ">{currentUser?.name}</span>
          </div>
        )}
      </div>

      <CardCont>
        <div className="card" key={authStore.publishedArticles?.articleId}>
          <StatusProp status={"published"}>
            {authStore.publishedArticles?.icon}
            <SiSimpleanalytics />
          </StatusProp>
          <div className="content">
            <h4>PUBLISHED</h4>
            <span> {authStore.publishedArticles?.length}</span>
          </div>
        </div>
        <div className="card" key={authStore.reviewedArticles?.articleId}>
          <StatusProp status={"review"}>
            {authStore.reviewedArticles.review?.icon}
            <MdRateReview />
          </StatusProp>
          <div className="content">
            <h4>REVIEW</h4>
            <span> {authStore.reviewedArticles.review?.length}</span>
          </div>
        </div>
      </CardCont>

      <ArticleCont>
        <h3>Recent Articles</h3>
        <div className="recent">
          <div className="all">
            <button
              onClick={() => navigate("/admin/articles")}
              className=" transition-colors duration-300 ease-in-out hover:shadow-lg active:shadow-none "
            >
              <span>
                <HiViewGridAdd />
              </span>
              View All
            </button>
          </div>
          {data.length === 0 && (
            <div className=" w-full  flex items-center justify-center h-full font-bold text-3xl opacity-20 uppercase">
              No Article To Show
            </div>
          )}
          {data.length > 0 && (
            // <Table
            //   data={data.slice(0, 6)}
            //   columns={columns}
            //   color="none"
            //   heading={heading}
            //   handleUpdateData={handleUpdateData}
            //   hasStatus={true}
            //   actions={[
            //     { name: "download", action: handleDownload },
            //     { name: "view", action: handleView }
            //     // { name: "edit", action: handleEdit }
            //   ]}
            //   value=""
            //   user="article"
            // />
            <ArticlesTable
              data={data.slice(0, 6)}
              columns={columns}
              hasStatus={true}
              heading={heading}
              value=""
              handleUpdateData={handleUpdateData}
              // actions={[
              //   { name: "download", action: handleDownload },
              //   { name: "view", action: handleView },
              //   { name: "assign", action: handleAssign }
              // ]}
              user={userRole}
              // user={ADMIN_ROLES.SUPER}
            />
          )}
        </div>
        {userRole === ADMIN_ROLES.SUPER_ADMIN && <AddCategoreis />}
        {isAssignModalOpen && <ArticleAssign />}
      </ArticleCont>
    </AdminDashboardCont>
  ) : (
    <AdminDashboardCont>
      <div className=" w-full flex items-center justify-between py-4 px-6 ">
        <h1 className=" text-xl font-bold uppercase">DashBoard</h1>
        {currentUser.name && (
          <div className=" flex text-xs lg:text-xl font-bold uppercase space-x-4 items-center justify-end ">
            <span>Welcome</span>
            <span className=" ">{currentUser?.name}</span>
          </div>
        )}
      </div>
      <div>
        <CardCont>
          <div className="card" key={authStore.publishedArticles?.articleId}>
            <StatusProp status={"published"}>
              {authStore.publishedArticles?.icon}
              <SiSimpleanalytics />
            </StatusProp>
            <div className="">
              <h4>Resources</h4>
              <span> {data?.resources?.course?.length}</span>
            </div>
          </div>
          <div className="card" key={authStore.reviewedArticles?.articleId}>
            <StatusProp status={"review"}>
              {authStore.reviewedArticles.review?.icon}
              <MdRateReview />
            </StatusProp>
            <div className="">
              <h4>Courses</h4>
              <span> {data?.courses?.plans?.length}</span>
            </div>
          </div>
        </CardCont>
        <div>
          {data?.resources?.course?.length > 0 && (
            <div>
              <h1 className=" w-full text-center font-bold text-2xl underline">
                Resources
              </h1>
              <Table
                data={data?.resources?.course.slice(0, 5)}
                columns={resourcesColumns}
                color="grey"
                heading={courseHeading}
                user={userRole}
                // handleDelete={(id) => handleDelete(id)}
                // viewCourses={(courses) => handleCourse(courses)}
                // handleEdit={(plan) => handleEdit(plan)}
              />
            </div>
          )}
          {data?.courses?.plans?.length > 0 && (
            <div>
              <h1 className=" w-full text-center font-bold text-2xl underline">
                Courses
              </h1>
              <Table
                data={data?.courses.plans.slice(0, 5)}
                columns={courseColumns}
                color="grey"
                heading={PlanHeading}
                user={userRole}
                // handleDelete={(id) => handleDelete(id)}
                // viewCourses={(courses) => handleCourse(courses)}
                // handleEdit={(plan) => handleEdit(plan)}
              />
            </div>
          )}
          {!data && !isFetchingData && (
            <div className=" w-full  flex items-center justify-center h-full font-bold text-3xl opacity-20 uppercase">
              No Data To Show
            </div>
          )}
          {isFetchingData && (
            <div className="fixed top-0 left-0 w-full h-screen flex items-center justify-center bg-gray-100 bg-opacity-75 !mt-0 backdrop:blur-md">
              <ClipLoader
                color="#333333"
                loading={isFetchingData}
                cssOverride={override}
                size={40}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
          )}
        </div>
      </div>
    </AdminDashboardCont>
  );
});

export default AdminDashboard;
