import Colors from "utils/colors";
import media from "utils/media";
import styled from "styled-components";
import tw from "twin.macro";

export const FooterContainer = styled.div`
  width: 100%;
  height: 350px;
  display: flex;
  // margin-top: 2rem;
  padding: 40px 8.5%;
  /* height: 350px; */
  background: #46555c;
  ${media.mobile`
  height: 100%;
  .app_logo{
    display: none;
  }
  `}
`;

export const FooterDiv = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  // padding: 30px 60px;
  justify-content: center;

  ${media.mobile`
  flex-direction: column-reverse;
  margin-top: 40px;
  width: 100%;
  padding: 40px 10px;

  `}
`;
export const FooterLeft = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  .copyright-text {
    // font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    margin-top: 2rem;
    font-size: 16px;
    line-height: 12px;
    letter-spacing: 0.025em;
    text-transform: capitalize;
    color: ${Colors.white};
  }
  .desc-text {
    max-width: 70%;
    // font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: ${Colors.white};
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding-top: 20px;
  }

  ${media.mobile`
  width:100%;
.desc-text {
    max-width: 100%;
    font-size: 12px;
    line-height: 22px;
  }

  `}
`;
export const FooterRight = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  // align-items: center;

  ${media.mobile`
  width: 100%;
  height: 100%;
  margin-bottom:30px;
  
  `}
`;

export const FooterUl = styled.ul`
  list-style-type: none;
  /* display: flex; */
  margin-bottom: 20px;
  //  background-color: red;
  /* new styles  */
  ${tw` flex flex-col items-center justify-start space-y-2`}

  .links-footer {
    // margin-right: 20px;
    //  margin-top: -10px;
    /* margin-bottom: 5px; */
    color: ${Colors.white};
    text-decoration: none;

    ${media.mobile`
  // display:none;
  // margin-right: 5px;
  font-size:12px;
  
  `}
  }
  .bottom-link-start {
    color: ${Colors.white};
    margin-right: 20px;
  }
  ${media.mobile`
  // display:none;
  // margin-right: 0px;
  
  `}
`;

export const SocialDivFooter = styled.div`
  display: flex;
  // align-items: center;

  margin: 1rem;
  // padding : 10px;
  // background-color: red;

  ${media.mobile`
  /* flex-direction: column; */
  `}
`;
export const SocialDiv = styled.div`
  margin: 0px 10px;
`;
export const FooterItemDiv = styled.div`
  width: 100%;
  // background-color: red;
  display: flex;
  align-items: center;
  // border: 1px solid red;
  // justify-content: center;
  // margin-left: 1.5rem;
  .text-contact {
    margin-left: 5px;
    letter-spacing: 0.0075em;
    // justify-content: start;
    // font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;

    color: ${Colors.white};
  }

  img {
    width: 20px;
    height: 20px;
  }
`;

export const FootFirstDiv = styled.div`
  display: flex;
  flex-direction: column;
`;
