import Layout from 'components/Admin/DashboardLayout/Layout'
import PlanComp from 'components/Admin/Plan/PlanComp'
import React from 'react'

const PlanPg = () => {
  return (
    <Layout>
      <PlanComp />
    </Layout>
  )
}

export default PlanPg