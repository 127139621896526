import * as Yup from "yup";

import { AuthContent, ClearSessionCont } from "./style";
import React, { useState } from "react";

import { AUTH_ENDPOINTS } from "api/ACTION";
import { AuthButton } from ".";
import { GrClose } from "react-icons/gr";
import TextInput from "components/TextInput";
import bg from "../../assets/images/banner2.jpg";
import jmsApp from "api/jms";
import { toast } from "react-hot-toast";
import { useFormik } from "formik";

function ClearSessionModal({ onclose }) {
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    enableReinitialize: false,
    initialValues: {
      username: "",
      password: ""
    },
    onSubmit: async (info, helpers) => {
      setLoading(true);
      try {
        const { data } = await jmsApp.post(AUTH_ENDPOINTS.LOGOUT_ALL(), info);
        if (data.success) {
          onclose();
          localStorage.removeItem("isUserDetails");
          localStorage.removeItem("token");
          setLoading(false);
          helpers.resetForm();
          toast.success(data.msg);
        }
      } catch (err) {
        setLoading(false);
        onclose();
        if (err.response) {
          // toast.error(err.response.data.error);
          // if (err.response.status === 400) {
          // }
        } else {
          // toast.error(err.message);
        }
      }
    },
    validationSchema: Yup.object({
      username: Yup.string().max(50).required().label("Username"),
      password: Yup.string()
        .min(8)
        .required("Password is required.")
        .label("Password")
    })
  });
  return (
    <ClearSessionCont style={{ backgroundImage: `url(${bg})` }}>
      <AuthContent>
        <div className="modal relative">
          <div className="absolute top-0 right-0  m-2" onClick={onclose}>
            <div className=" cursor-pointer transition-all duration-300 ease-in-out  rounded-full p-2  hover:bg-[#46555c] hover:text-white">
              <GrClose size={24} className="text-[#46555c] hover:text-white" />
            </div>
          </div>
          <h3 className=" text-center">You have an active session</h3>
          <div className="container">
            <div onSubmit={formik.handleSubmit}>
              <div>
                <form>
                  <TextInput
                    label="Username"
                    name="username"
                    id="username"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.username}
                    error={formik.errors.username}
                    visible={formik.touched.username}
                    placeholder="e.g iamprincekuro1"
                    mt="0.5rem"
                  />
                  <TextInput
                    label="Password"
                    type="password"
                    name="password"
                    id="password"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                    error={formik.errors.password}
                    visible={formik.touched.password}
                    placeholder="Enter your password"
                  />
                  <div className="btns w-full">
                    <AuthButton title="Logout All" loading={loading} />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </AuthContent>
    </ClearSessionCont>
  );
}

export default ClearSessionModal;
