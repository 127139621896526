import { ArtHead } from "components/Home/Article Section/style";
import { BlogHeadCon } from "components/Home/Blog Section/style";
import Mainlayout from "layout/MainLayout";
import React from "react";
import avater1 from "../assets/images/avater1.jpg";
import avater2 from "../assets/images/avater2.jpg";
import avater3 from "../assets/images/avater3.jpg";
import styled from "styled-components";
import textBanner from "../assets/images/bgBanner.png";
import tw from "twin.macro";

// import SupportPricing from 'components/SupportPricing';

// Styled components
const AboutSection = styled.section`
  // padding: 170px 8.5% 0 8.5%;
  background-color: #f2f2f2;
  // padding-top: 150px;

  /* ${tw`sm:pt-[6.25rem] pt-[5rem]`}// @media (max-width: 768px) { */
  //   padding-top: 80px;
  // }
`;

// eslint-disable-next-line no-unused-vars
const Title = styled.h1`
  font-size: 32px;
  color: #333333;
  margin-bottom: 20px;
`;

const Description = styled.p`
  font-size: 18px;
  color: #666666;
  line-height: 1.5;
  padding-left: 8.5%;
  padding-right: 8.5%;
`;

// const TeamContainer = styled.div`
//   display: grid;
//   grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
//   gap: 20px;
//   // margin-top: 40px;
//   padding-left: 8.5%;
//   padding-right: 8.5%;
//   padding-top: 40px;
//   padding-bottom: 40px;
// `;

const TeamMember = styled.div`
  text-align: center;
  // display: flex;
  // box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
  // padding: 20px;
  // border: 2px solid red;

  ${tw` rounded shadow-xl  p-2 sm:p-4`}
`;

const Avatar = styled.img`
  width: 200px;
  height: 200px;
  border-radius: 20px;
  object-fit: cover;
  object-position: top;
  margin-bottom: 10px;
`;

const Name = styled.h3`
  font-size: 18px;
  color: #333333;
`;

const Role = styled.p`
  font-size: 14px;
  // border-radius: 20px;
  color: #666666;
`;

// About Us page component
const AboutPage = () => {
  return (
    <>
      <Mainlayout>
        <AboutSection>
          <BlogHeadCon
            style={{ backgroundImage: `url(${textBanner})` }}
            className="h-20 sm:h-auto"
          >
            <ArtHead className="!text-white">About us</ArtHead>
          </BlogHeadCon>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              paddingTop: "40px",
              paddingBottom: "40px"
            }}
          ></div>

          <div className=" flex flex-col items-center justify-start space-y-10 w-full p-4 pb-10">
            <div className=" flex flex-col-reverse items-center justify-center w-4/5 sm:w-full lg:w-4/5 mx-auto sm:grid grid-cols-2 place-items-center gap-4">
              <Description>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                euismod ipsum a quam ultrices consectetur. Proin eget lacinia
                ex. Nulla quis tempus tellus, eget maximus metus. Integer vel
                mauris ut erat tincidunt malesuada. Vivamus non ultricies
                turpis, eget aliquet mi.
              </Description>
              <TeamMember
                className=" transform transition duration-500 ease-in-out cursor-pointer hover:animate-pulse hover:scale-110"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <Avatar src={avater3} alt="Team Member 1" />
                <Name>John Doe</Name>
                <Role>CEO</Role>
              </TeamMember>
            </div>
            <div className=" flex flex-col items-center justify-center w-4/5 sm:w-full lg:w-4/5 mx-auto sm:grid grid-cols-2 place-items-center gap-4 ">
              <TeamMember
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center"
                }}
                className=" transform transition duration-500 ease-in-out cursor-pointer hover:animate-pulse hover:scale-110"
              >
                <Avatar src={avater2} alt="Team Member 2" />
                <Name>Jane Smith</Name>
                <Role>CTO</Role>
              </TeamMember>
              <Description>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                euismod ipsum a quam ultrices consectetur. Proin eget lacinia
                ex. Nulla quis tempus tellus, eget maximus metus. Integer vel
                mauris ut erat tincidunt malesuada. Vivamus non ultricies
                turpis, eget aliquet mi.
              </Description>
            </div>
            <div className=" flex flex-col-reverse items-center justify-center w-4/5 sm:w-full lg:w-4/5 mx-auto sm:grid grid-cols-2 place-items-center gap-4">
              <Description>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                euismod ipsum a quam ultrices consectetur. Proin eget lacinia
                ex. Nulla quis tempus tellus, eget maximus metus. Integer vel
                mauris ut erat tincidunt malesuada. Vivamus non ultricies
                turpis, eget aliquet mi.
              </Description>
              <TeamMember
                className=" transform transition duration-500 ease-in-out cursor-pointer hover:animate-pulse hover:scale-110"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <Avatar src={avater1} alt="Team Member 3" />
                <Name>Mike Johnson</Name>
                <Role>Designer</Role>
              </TeamMember>
            </div>
          </div>
          {/* <SupportPricing  /> */}
        </AboutSection>
      </Mainlayout>
    </>
  );
};

export default AboutPage;
