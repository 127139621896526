import Layout from "components/Admin/DashboardLayout/Layout";
import Staffs from "components/Admin/Staff/Staffs";
import React from "react";

function StaffsPg() {
  return (
    <Layout>
      <Staffs />
    </Layout>
  );
}

export default StaffsPg;
