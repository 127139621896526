import React, { useRef, useState } from "react";

import ClipLoader from "react-spinners/ClipLoader";
import { LogoutModalCont } from "./style";
import { handleLogout } from "utils/UserLogout";

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "black"
};
function LogoutModal({ setOpenModal }) {
  const [loading, setLoading] = useState(false);
  const logout = () => {
    setLoading(true);

    handleLogout().then((data) => {
      setLoading(false);
      setOpenModal(false);
      window.location.replace("/login");
    });
  };
  const overlayRef = useRef();

  function handleOutsideClose(e) {
    if (e.target !== overlayRef.current) return;
    setOpenModal(false);
  }

  return (
    <LogoutModalCont onClick={handleOutsideClose} ref={overlayRef}>
      <div className="overflow-hidden  flex flex-col items-center justify-center bg-white p-4 sm:p-10 rounded-lg shadow-lg gap-y-4 sm:gap-y-8 w-fit sm:w-72 mx-auto">
        <span className=" w-full text-center">Do you want to logout ?</span>
        <div className=" flex items-center justify-between gap-x-8">
          <button
            onClick={() => setOpenModal(false)}
            className=" p-1 sm:p-2  rounded-lg uppercase font-primary font-bold border border-solid border-black/50 transition-colors duration-300 ease-in-out hover:bg-red-500 hover:border-transparent hover:text-white  text-sm"
          >
            cancel
          </button>
          <button
            onClick={() => logout()}
            className="p-1 sm:p-2 rounded-lg uppercase text-sm font-primary font-bold bg-black/50 transition-colors duration-300 ease-in-out hover:bg-black text-white hover:shadow-lg active:shadow-none "
          >
            confirm
          </button>
        </div>
        {loading && (
          <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-gray-100 bg-opacity-75 !mt-0 backdrop:blur-md">
            <ClipLoader
              color="#333333"
              loading={loading}
              cssOverride={override}
              size={40}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        )}
      </div>
    </LogoutModalCont>
  );
}

export default LogoutModal;
