import Colors from "utils/colors";
import media from "utils/media";
import styled from "styled-components";
import tw from "twin.macro";

export const ArticleCont = styled.div`
  background: #e5e5e5;
  padding: 25px 40px;
  width: 100%;
  min-height: 100vh;
  .heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    ${media.tablet`
      flex-direction:column;
      gap:20px;
    justify-content: flex-start;
    align-items: flex-start;
	`};
  }
  .active-status {
    /* padding: 40px 0 20px 0; */
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* gap: 15px; */
    /* overflow: auto; */
    button {
      background: transparent;
      border: 1px solid gray;
      padding: 10px 12px;
      display: block;
      width: 80px;
      text-align: center;
      border-radius: 11px;
      text-transform: capitalize;
      :hover {
        background: ${Colors.primary};
        color: white;
        transition: all 0.2s ease-in-out;
      }
    }
    .active {
      background: ${Colors.primary};
      color: white;
    }
  }
  .empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* // align-items: center; */
    ${tw`items-center`}
    min-height: 80vh;
    span {
      font-size: 200px;
    }
    h1 {
      margin-top: -30px;
      font-size: 30px;
    }
  }
`;
export const AllArticles = styled.div`
  margin-top: 25px;
  /* overflow: auto; */
  background: white;
  cursor: pointer;
  /* position: relative; */
`;
export const TableData = styled.div``;
export const Status = styled.div``;

export const Detail = styled.div`
  background: #e5e5e5;
  padding: 40px 25px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  cursor: pointer;

  .content {
    margin: auto;
    max-width: 700px;
    padding: 40px 20px;
    width: 100%;
    display: flex;
    gap: 20px;
    flex-direction: column;
    justify-content: space-between;
    ${"" /* border: 1px solid grey; */}
    ${"" /* border-radius: 7px; */}

    .author {
      display: flex;
      gap: 20px;
      justify-content: space-between;
      div {
        flex: 0.4;
        padding: 20px;
        background: white;
        border-radius: 7px;
      }
    }
  }
`;
export const IconSize = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 30px;
  cursor: pointer;
`;
