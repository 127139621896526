import React from 'react'
import {AgricDiv, AgricContainer} from './style'

function EnginePage() {
  return (
    <div>
      <AgricDiv>
      <AgricContainer>

      EnginePage
      </AgricContainer>
      </AgricDiv>
      </div>
  )
}

export default EnginePage