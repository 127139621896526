import * as Yup from "yup";

import {
  ADMIN_DASHBOARD_ROUTE,
  CREATE_ACCOUNT_ROUTE,
  DASHBOARD_ROUTE,
  FORGOT_PASSWORD_ROUTE
} from "routes";
import { AuthBottomLink, AuthButton, AuthHeader } from ".";
import {
  AuthCard,
  AuthContent,
  Authcontainer,
  LoginContainer,
  LoginDiv,
  LoginFormContainer
} from "./style";
import { Div, Img } from "globalStyles";
import { Link, useNavigate } from "react-router-dom";

import { AUTH_ENDPOINTS } from "api/ACTION";
import { AppLogo } from "utils/assets";
import ClearSessionModal from "./ClearSessionModal";
import TextInput from "components/TextInput";
import authStore from "mobx/AuthStore";
import bg from "../../assets/images/banner2.jpg";
import jmsApp from "api/jms";
import { observer } from "mobx-react-lite";
import { toast } from "react-hot-toast";
import { useFormik } from "formik";
import { useState } from "react";

// import axios from "axios";

function LoginComponent() {
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const setStorageItem = async (item) => {
    return new Promise((resolve, reject) => {
      try {
        localStorage.setItem(item.name, JSON.stringify(item.value));
        resolve(); // Resolve the promise if setItem is successful
      } catch (error) {
        reject(error); // Reject the promise if there is an error
      }
    });
  };
  const formik = useFormik({
    enableReinitialize: false,
    initialValues: {
      username: "",
      password: ""
    },
    onSubmit: async (info, helpers) => {
      setLoading(true);
      try {
        const { data } = await toast.promise(
          jmsApp.post(AUTH_ENDPOINTS.LOGIN(), info),
          {
            loading: "Please  wait, while we log you in...",
            error: (err) =>
              err.response?.data.error ?? "Login failed, try again later.",
            success: "You have been successfully logged in."
          }
        );

        if (data.success) {
          const { token, refreshToken } = data;

          setLoading(false);
          authStore.setCurrentStep("login");
          authStore.setUserEmail(data.user.email);

          const { type } = data.user;
          authStore.setUserRole({ userRole: type });

          authStore.setCurrentUser(data.user);

          await setStorageItem({ name: "jms-token", value: token });
          await setStorageItem({ name: "isUserDetails", value: data.user });
          await setStorageItem({
            name: "jmsRefreshToken",
            value: refreshToken
          });

          type === "user"
            ? navigate(`${DASHBOARD_ROUTE}`)
            : navigate(`${ADMIN_DASHBOARD_ROUTE}`);

          // authStore.setInitialAuth(data.authorization);
          helpers.resetForm();
        }
      } catch (err) {
        setLoading(false);
        if (err.response.data) {
          // toast.error(err.response.data.error);
          if (
            err.response.status === 400 &&
            err.response.data.error.includes("active session")
          ) {
            setShowModal(true);
          }
        } else {
          // toast.error(err.message);
        }
      }
    },
    validationSchema: Yup.object({
      username: Yup.string().max(50).required().label("Username"),
      password: Yup.string()
        .min(8)
        .required("Password is required.")
        .label("Password")
    })
  });

  return (
    <Authcontainer style={{ backgroundImage: `url(${bg})` }}>
      <AuthContent>
        <Link to="/">
          <Img src={AppLogo} alt="LPJ" />
        </Link>
        <LoginContainer>
          <LoginDiv onSubmit={formik.handleSubmit}>
            <AuthCard>
              <LoginFormContainer>
                <AuthHeader text="Log in" />
                <TextInput
                  label="Username"
                  name="username"
                  id="username"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.username}
                  error={formik.errors.username}
                  visible={formik.touched.username}
                  placeholder="e.g iamprincekuro1"
                  mt="0.5rem"
                />
                <TextInput
                  label="Password"
                  type="password"
                  name="password"
                  id="password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  error={formik.errors.password}
                  visible={formik.touched.password}
                  placeholder="Enter your password"
                />
                <Div width="100%" display="flex" justify="flex-end">
                  <AuthBottomLink
                    link={FORGOT_PASSWORD_ROUTE}
                    text=""
                    linktext="Forgot Password"
                  />
                </Div>
                <AuthButton type="submit" title="Login" loading={loading} />
                {/* <AuthButton title={authStore.isLoading ? "Loading..." : "Login"} /> */}
                <AuthBottomLink
                  link={CREATE_ACCOUNT_ROUTE}
                  text="Don't have an account?"
                  linktext="sign up"
                />
              </LoginFormContainer>
            </AuthCard>
          </LoginDiv>
        </LoginContainer>
      </AuthContent>
      {showModal && <ClearSessionModal onclose={() => setShowModal(false)} />}
    </Authcontainer>
  );
}

export default observer(LoginComponent);
