import { AUTH_ENDPOINTS } from "api/ACTION";
import { LOCAL_STORAGE } from "api/LOCALSTORAGE";
import authStore from "mobx/AuthStore";
import jmsApp from "api/jms";

export const handleRefreshToken = async () => {
  try {
    const response = await jmsApp.post(AUTH_ENDPOINTS.REFRESH_TOKEN(), {
      refreshToken: LOCAL_STORAGE.refreshToken()
    });
    authStore.setShouldRefresh(false);

    if (response.data.success) {
      const { token, refreshToken: newRefreshToken } = response.data;
      localStorage.setItem("jms-token", JSON.stringify(token));
      localStorage.setItem("jmsRefreshToken", JSON.stringify(newRefreshToken));

      return { status: true };
    } else {
      throw new Error("Refresh token failed with status : ", response?.status);

      // handleLogout();
      // window.location("/login");
      // return { status: false };
    }
  } catch (err) {
    // handleLogout();

    ////console.error("Error refreshing token:", err);
    // window.location("/login");
    return { status: false };
  }
};
