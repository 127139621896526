import { USER_PLAN, USER_TYPE } from "api/ACTION";
import { useEffect, useState } from "react";

import { AiFillEye } from "react-icons/ai";
import { CourseCont } from "./courses.style";
import NoCoursesPage from "components/ELearn/NoCoursePg";
import SearchBox from "./SearchBox";
import jmsApp from "api/jms";
import styled from "styled-components";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

function CoursesInfo() {
  const [plans, setPlans] = useState([]);
  const [plan, setPlan] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    handleData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleData = async () => {
    try {
      const { data } = await jmsApp.get(USER_PLAN.GET_USER_PLANS());

      if (data) {
        setPlans(data.plans);

        getPlan(data.plans[0].plan.planId);
        setActive(data.plans[0].plan.type);
      } else {
      }
    } catch (error) {
      // toast.error("error");
    }
  };

  const getPlan = async (planId) => {
    try {
      if (USER_TYPE.SUPER() === "super") {
        const { data } = await jmsApp.get(USER_PLAN.GET_PLAN_BY_ID(), {
          params: { planId }
        });
        if (data.success) {
          setPlan(data.plans);
        }
      }
    } catch (error) {
      // toast.error("error");
    }
  };

  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const [active, setActive] = useState("All");

  return (
    <CourseCont>
      <div className="heading">
        <SearchBox value={searchTerm} onChange={handleSearch} />
        <div className="btns">
          {plans.length > 0 &&
            plans.map((e, i) => (
              <Card
                key={i}
                onClick={() => {
                  setActive(e.plan.type);
                  getPlan(e.plan.planId);
                }}
                className={`btn ${
                  active === e.plan.type ? "active" : "inactive"
                }`}
              >
                <Title>{e.plan.type}</Title>
              </Card>
            ))}
        </div>
      </div>
      {plan && (
        <div className="courses">
          <div className="course">
            {plan.courses.length > 0 ? (
              plan.courses.map((e, i) => (
                <div key={i}>
                  <span>{e.title ? e.title : "Tittle"}</span>
                  <div className="catCon">
                    <p className="cat">{e.category}</p>
                    <AiFillEye
                      className="eye"
                      onClick={() => navigate(`/course/${e._id}`)}
                    />
                  </div>
                </div>
              ))
            ) : (
              <NoCoursesPage />
            )}

            <div className="duration">
              <div>price: ₦{plan.price}</div>
              <div>duration: {plan.duration}</div>
            </div>
          </div>
        </div>
      )}
    </CourseCont>
  );
}

const Card = styled.div`
  border: 1px solid #ddd;
  padding: 5px 16px;
  border-radius: 8px;
  display: flex-column;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.h4`
  margin: 0;
`;

// const Count = styled.span`
//   font-size: 15px;
//   font-weight: bold;
// `;

export default CoursesInfo;
