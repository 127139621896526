import ArticlesComp from 'components/Articles/ArticlesComp'
import ArticleViewLayout from 'layout/ArticleViewLayout'
import React from 'react'

function ArticlesViewPage() {
  return (
    <ArticleViewLayout>
      <ArticlesComp />
    </ArticleViewLayout>
  )
}

export default ArticlesViewPage
