import { ArticleCon, ButtonComp, Container } from "./style";
import { Link, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";

import { ARTICLE_ENDPOINT } from "api/ACTION";
import { ArtHead } from "components/Home/Article Section/style";
import { BlogHeadCon } from "components/Home/Blog Section/style";
import book from "../../assets/images/books.png";
import jmsApp from "api/jms";
import styled from "styled-components";
import textBanner from "../../assets/images/bgBanner.png";
import { toast } from "react-hot-toast";
import tw from "twin.macro";

function ArticlesComp({ ArtCategory = "" }) {
  const params = useParams();
  const { id } = params;

  const [article, setArticle] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const { data: articlesData } = await jmsApp.get(
          ARTICLE_ENDPOINT.GET_ARTICLE_BY_ID(),
          {
            params: {
              articleId: id
            }
          }
        );

        setIsLoading(false);
        // Sort articles based on relevance to the search query

        setArticle(articlesData.article);
      } catch (error) {
        setIsLoading(false);
        toast.error("Failed to fetch data");
      }
    };

    fetchData();
  }, [id]);

  return (
    <ArticleCon>
      <Container>
        <BlogHeadCon
          style={{ backgroundImage: `url(${textBanner})`, height: "100px" }}
        >
          <ArtHead style={{ color: "#ffffff" }}>Browse journals</ArtHead>
        </BlogHeadCon>
        <div className="w-full p-2 sm:p-44 flex items-center justify-center overflow-hidden">
          {isLoading === false && !!article && (
            <>
              {" "}
              <div className="mx-auto p-2 sm:p-4 w-full">
                <Card key={article?.articleId}>
                  <div className=" flex  w-full h-full items-center gap-x-4 sm:gap-x-8 ">
                    <div className=" h-full min-w-[6rem] max-w-[7rem] w-28 sm:w-32 sm:max-w-[8rem] rounded-md overflow-hidden">
                      <img
                        src={book}
                        alt="img"
                        className=" !w-full !h-full object-cover"
                      />
                    </div>
                    <div className=" truncate">
                      <h2 className="truncate px-1 ">{article?.title}</h2>
                      <p className="author truncate">{article?.author}</p>
                      <p className="desc truncate">{article?.description}</p>
                      <Link to={`${article?.articleUrl}`} target="_blank">
                        <ButtonComp>Read paper</ButtonComp>
                      </Link>
                    </div>
                  </div>
                </Card>
              </div>
            </>
          )}

          {isLoading === true && (article === null || !article) && (
            <>
              <div className="mx-auto p-2 sm:p-4 w-full ">
                <div
                  role="status"
                  className="space-y-8 my-2 animate-pulse md:space-y-0 md:space-x-8 rtl:space-x-reverse md:flex md:items-center"
                >
                  <div className="flex items-center justify-center w-full h-48 bg-gray-300 rounded sm:w-96 ">
                    <svg
                      className="w-10 h-10 text-gray-200 "
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 20 18"
                    >
                      <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
                    </svg>
                  </div>
                  <div className="w-full">
                    <div className="h-2.5 bg-gray-200 rounded-full  w-48 mb-4"></div>
                    <div className="h-2 bg-gray-200 rounded-full  max-w-[480px] mb-2.5"></div>
                    <div className="h-2 bg-gray-200 rounded-full  mb-2.5"></div>
                    <div className="h-2 bg-gray-200 rounded-full  max-w-[440px] mb-2.5"></div>
                    <div className="h-2 bg-gray-200 rounded-full  max-w-[460px] mb-2.5"></div>
                    <div className="h-2 bg-gray-200 rounded-full  max-w-[360px]"></div>
                  </div>
                  <span className="sr-only">Loading...</span>
                </div>
                <div
                  role="status"
                  className="space-y-10 my-2 animate-pulse md:space-y-0 md:space-x-8 rtl:space-x-reverse md:flex md:items-center"
                >
                  <div className="flex items-center justify-center w-full h-48 bg-gray-300 rounded sm:w-96 ">
                    <svg
                      className="w-10 h-10 text-gray-200 "
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 20 18"
                    >
                      <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
                    </svg>
                  </div>
                  <div className="w-full">
                    <div className="h-2.5 bg-gray-200 rounded-full  w-48 mb-4"></div>
                    <div className="h-2 bg-gray-200 rounded-full  max-w-[480px] mb-2.5"></div>
                    <div className="h-2 bg-gray-200 rounded-full  mb-2.5"></div>
                    <div className="h-2 bg-gray-200 rounded-full  max-w-[440px] mb-2.5"></div>
                    <div className="h-2 bg-gray-200 rounded-full  max-w-[460px] mb-2.5"></div>
                    <div className="h-2 bg-gray-200 rounded-full  max-w-[360px]"></div>
                  </div>
                  <span className="sr-only">Loading...</span>
                </div>
                <div
                  role="status"
                  className="space-y-8 my-2 animate-pulse md:space-y-0 md:space-x-8 rtl:space-x-reverse md:flex md:items-center"
                >
                  <div className="flex items-center justify-center w-full h-48 bg-gray-300 rounded sm:w-96 ">
                    <svg
                      className="w-10 h-10 text-gray-200 "
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 20 18"
                    >
                      <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
                    </svg>
                  </div>
                  <div className="w-full">
                    <div className="h-2.5 bg-gray-200 rounded-full  w-48 mb-4"></div>
                    <div className="h-2 bg-gray-200 rounded-full  max-w-[480px] mb-2.5"></div>
                    <div className="h-2 bg-gray-200 rounded-full  mb-2.5"></div>
                    <div className="h-2 bg-gray-200 rounded-full  max-w-[440px] mb-2.5"></div>
                    <div className="h-2 bg-gray-200 rounded-full  max-w-[460px] mb-2.5"></div>
                    <div className="h-2 bg-gray-200 rounded-full  max-w-[360px]"></div>
                  </div>
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </>
          )}

          {isLoading === false && (article === null || !article) && (
            <div className="mx-auto p-2 sm:p-4 w-full">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%"
                }}
              >
                <div className="  w-full h-full bg-opacity-50 flex items-center justify-center ">
                  <h2 className=" text-lg  sm:text-2xl font-bold capitalize pt-8 sm:pt-0">
                    No articles found
                  </h2>
                </div>
              </div>
            </div>
          )}
        </div>
      </Container>
    </ArticleCon>
  );
}

export default ArticlesComp;

const Card = styled.div`
  display: block;
  /* // margin:20px 8.5% 0 8.5%; */
  /* // gap: 2rem;
  // width: 100%; */
  min-height: 180px;
  ${tw`mx-auto my-1.5 sm:my-2.5 sm:space-y-2 w-[90%] sm:w-[40rem] p-1 sm:p-2 h-40 sm:h-auto`}
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  .desc {
    color: brown;
    /* // font-size: 16px; */

    ${tw`text-sm sm:text-base`}
  }
`;
