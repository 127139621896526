const Colors = {
	primary: "#46555C",
	primaryLight: "#6D0062",
	secondary: "#EEBC1D",
	black: "#000000",
	white: "#FFFFFF",
	muted: "#D9D9D9",
	light: "#A7A7A7",
	gradient: "linear-gradient(73.6deg, rgba(57, 26, 96, 0.97) 4.94%, rgba(109, 0, 98, 0.97) 71.35%)",
	whiteGradient: "rgba(0,0,0, 0.5)",
	pendingBg: "#FEECD7",
	pendingColor: "#F6A74B",
	approvedBg: "#D2FED2",
	approvedColor: "#205E20",
	rejectedBg: "#FDD6D6",
	rejectedColor: "#FF3B3B",
	disbursedBg: "#D2FED2",
	disbursedColor: "#205E20",
	dueBg: "#FF9C00",
	dueColor: "#000000"
}
export default Colors

// W1)qLDTEYLA.T47f
