import React, { useState } from "react";

import Colors from "utils/colors";
import { FiChevronDown } from "react-icons/fi";
import styled from "styled-components";

const DropdownContainer = styled.div`
  position: relative;
  width: 100%;
  /* width: 500px; */
  border: 1px solid ${Colors.primary};
  border-radius: 10px;
  margin-top: 20px;
  /*// margin-bottom: 10px;*/
`;

const DropdownHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 5px;
  cursor: pointer;
  border-radius: 10px;
`;

const DropdownList = styled.ul`
  position: absolute;
  top: 1005;
  z-index: 10;
  left: 0;
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 0;
  margin: 0;
  list-style: none;
`;

const DropdownItem = styled.li`
  padding: 10px;
  cursor: pointer;

  &:hover {
    background-color: #f5f5f5;
  }
`;

const Dropdown = ({
  label,
  options,
  selectedOption,
  lblList,
  onSelectOption,
  additionalClass
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    onSelectOption(option);
    setIsOpen(false);
  };

  return (
    <DropdownContainer className={additionalClass}>
      <DropdownHeader
        className="!text-sm lg:!text-md"
        onClick={() => toggleDropdown()}
      >
        {selectedOption
          ? lblList[
              [...options].findIndex((option) => option === selectedOption)
            ]
          : label}
        <FiChevronDown />
      </DropdownHeader>
      {isOpen && (
        <DropdownList>
          {options.length > 0 &&
            options.map((e, i) => (
              <DropdownItem
                key={i}
                onClick={() => handleOptionClick({ val: e, lbl: lblList[i] })}
              >
                {lblList[i]}
              </DropdownItem>
            ))}
        </DropdownList>
      )}
    </DropdownContainer>
  );
};

export default Dropdown;
