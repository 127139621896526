import { Img } from 'globalStyles'
import React from 'react'
import {
  ABOUT_ROUTE,
  HOME_ROUTE,
  JOURNAL_ROUTE,
} from 'routes'
import { CloseIcon } from 'utils/assets'

import NavButtonMobile from 'components/MobileMenu/NavButtonMobile'
import { CloseMenuContainer, MenuContainer, MenuOverlay } from 'components/MobileMenu/style'

function MobileMenu({ isOpen, close }) {
  return (
    <MenuOverlay open={isOpen} onClick={close}>
      <MenuContainer>
        <CloseMenuContainer>
          <button onClick={close}>
            <Img src={CloseIcon} alt={'Close Menu'} />
          </button>
        </CloseMenuContainer>
        <NavButtonMobile to={HOME_ROUTE} title="Home" mt="20px" />
        <NavButtonMobile to={ABOUT_ROUTE} title="About" />
        <NavButtonMobile to={JOURNAL_ROUTE} title="Submit Article" />
        
      </MenuContainer>
    </MenuOverlay>
  )
}

export default MobileMenu
