import * as Yup from "yup";

import { COURSE, USER_PLAN } from "api/ACTION";
import React, { useEffect, useState } from "react";

import { AiOutlineClose } from "react-icons/ai";
import { AuthButton } from "components/Auth";
import ClipLoader from "react-spinners/ClipLoader";
import Dropdown from "components/Admin/sharedComponents/DropDown";
import { Modal } from "../../course/style";
import ShowAddCourses from "./ShowAddCourses";
import { TextArea } from "components/TextArea/style";
import TextInput from "components/TextInput";
import jmsApp from "api/jms";
import { override } from "utils/admin-roles";
import { toast } from "react-hot-toast";
import { uniqueArray } from "utils/unique-array";
import { useFormik } from "formik";
import { useNavigate } from "react-router";

export const ShowUpdatePlan = ({ onClose, plan, setUpdated }) => {
  const [addCourses, setAddCourses] = useState(false);
  const [loading, setLoading] = useState(false);
  const [courses, setCourses] = useState([]);
  const [planData, setPlanData] = useState(null);
  const [fetchingData, setFetchingData] = useState(false);
  const [currentModule, setCurrentModule] = useState(0);
  const [addNewModule, setAddNewModule] = useState(false);
  const [inputModule, setInputModule] = useState(0);
  const [deletingModule, setDeletingModule] = useState(false);

  useEffect(() => {
    if (!planData?.courses) return;

    if (currentModule === 0) {
      setSelCourses([...(planData?.courses ?? [])].map((course) => course.id));
    } else {
      setSelCourses(
        [...planData.courses]
          .map((course) => course.module === currentModule && course.id)
          .filter((val) => typeof val === "string")
      );
    }
  }, [currentModule, planData?.courses]);
  useEffect(() => {
    if (addCourses) {
      toast.loading("Editing resources...", { id: "editing-resources" });
    } else {
      toast.dismiss("editing-resources");
    }
    return () => {
      toast.dismiss("editing-resources");
    };
  });
  useEffect(() => {
    if (addNewModule) {
      setInputModule(
        Math.max(
          1,
          uniqueArray(
            (planData?.courses ?? []).map((course) => course.module)
          ).at(-1) + 1
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addNewModule, planData?.courses]);

  const [selCourses, setSelCourses] = useState([]);
  const [courseDescription, setCourseDescription] = useState("");

  const { navigate } = useNavigate();

  useEffect(() => {
    handleData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectCourse = (courseId) => {
    selCourses.splice(0);
    selCourses.push(...courseId);
  };
  const handleData = async () => {
    setFetchingData(true);
    try {
      const { data } = await jmsApp.get(COURSE.GET_COURSE());
      const { data: plans } = await jmsApp.get(USER_PLAN.GET_PLAN_BY_ID(), {
        params: {
          planId: plan.planId
        }
      });

      if (data.success) {
        setCourses(data.course);
        setPlanData(plans.plans);
        setSelCourses([...plans.plans.courses].map((course) => course.id));
      }
    } catch (error) {
      // toast.error("error");
    } finally {
      setFetchingData(false);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      price: plan.price,
      // duration: "",
      type: plan.type,
      major: plan.major,
      modules: plan.modules
    },

    onSubmit: async (info, helpers) => {
      try {
        if (!addNewModule) {
          info.modules = Number(currentModule);
        } else {
          info.modules = Number(inputModule);
        }
        if (info.modules === 0) {
          toast.error("Please select a course module");
          return;
        }
        if (selCourses.length === 0) {
          toast.error("Please add at least a course to the new module");
          return;
        }
        const details = {
          description: courseDescription,
          courses: selCourses,

          ...info
        };

        const { data } = await toast.promise(
          jmsApp.put(
            USER_PLAN.UPDATE_PLAN_COURSE(),
            {
              ...details
            },
            {
              params: {
                planId: plan.planId
              }
            }
          ),
          {
            loading: "Updating course....",
            success: (data) => `${data.data.msg}`,
            error: (err) => `${err.message}`
          }
        );
        // setActive(false)
        if (data.success) {
          // setIsUploadComplete(false)
          setLoading(false);
          setUpdated(true);
          // toast.success(data.msg);
          onClose();
          // helpers.resetForm()
        }
      } catch (error) {
        setLoading(false);
        if (error.response) {
          if (error.response?.status === 401) {
            navigate("/login");
          } else {
            // toast.error(error.response.data.error);
          }
        } else {
          // toast.error(error); // send FE customer error
        }
      }
    },

    validationSchema: Yup.object({
      price: Yup.string().max(255).required().label("Price"),

      type: Yup.string().max(255).required().label("Type"),
      major: Yup.string().max(255).required().label("Major")
    })
  });

  async function handleDeleteModule() {
    toast.promise(
      jmsApp.put(
        USER_PLAN.REMOVE_MODULE({
          planId: plan.planId,
          module: Number(currentModule)
        })
      ),
      {
        loading: "Deleting module...",
        error: "Module delete failed.",
        success: `Module ${currentModule} successfully deleted.`
      }
    );
    handleData();

    setDeletingModule(false);
  }

  if (fetchingData) {
    return (
      <div className="fixed top-0 left-0 w-full h-screen flex items-center justify-center bg-gray-100 bg-opacity-75 !mt-0 backdrop:blur-md">
        <ClipLoader
          color="#333333"
          loading={fetchingData}
          cssOverride={override}
          size={40}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    );
  }
  return (
    // <Modal className=" min-h-screen ">
    <div className=" absolute top-0 left-0 w-full min-h-screen overflow-y-scroll flex items-center justify-center py-16 z-[1000] bg-black/50 bg-opacity-50 backdrop-blur-sm">
      {/* <div className=" bg-red-500 h-[900px] w-1/2 mx-auto"></div> */}
      <div className="relative flex flex-col w-[90%] sm:w-4/5 lg:w-3/5 xl:w-2/5 py-8 rounded-lg items-center justify-start bg-white">
        <div className=" flex items-center justify-center w-full">
          <h4 className="capitalize font-bold text-[#333333] text-lg xl:text-[24px] ">
            Update Course
          </h4>
          <span
            onClick={onClose}
            className=" absolute top-0 right-0 lg:transform lg:-translate-1/2 lg:mx-6 lg:my-6 cursor-pointer transition-colors hover:bg-black/50 duration-300 text-[#333333] hover:text-white p-2 rounded-full "
          >
            <AiOutlineClose />
          </span>
        </div>

        <form
          onSubmit={formik.handleSubmit}
          className=" flex flex-col items-center justify-start space-y-4 w-4/5"
        >
          <TextInput
            label="Type"
            name="type"
            type="text"
            id="type"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.type}
            error={formik.errors.type}
            visible={formik.touched.type}
            placeholder="course type e.g. JAMB | WAEC | FREE | DUAL | combined"
            mt="0.5rem"
            required={true}
          />
          <TextInput
            label="Major"
            name="major"
            type="text"
            id="Major"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.major}
            error={formik.errors.major}
            visible={formik.touched.major}
            placeholder="Major e.g art | science | combined"
            mt="0.5rem"
            required={true}
          />
          <div className=" w-full mx-auto">
            <TextArea
              className="!h-max w-full "
              name="description"
              type="text"
              placeholder="Write a description for the course..."
              onChange={(e) => setCourseDescription(e.target.value)}
              defaultValue={plan.description}
              // rows={2}
              maxLength={150}
              required={true}
            />
            <p className="text-gray-500 text-right">
              {150 - courseDescription.length} characters remaining.
            </p>
          </div>
          <div className=" w-full grid grid-cols-2 ">
            <div className=" w-full ">
              {addNewModule ? (
                <TextInput
                  min={
                    Number(
                      uniqueArray(
                        (planData?.courses ?? []).map((course) => course.module)
                      ).at(-1)
                    ) + 1
                  }
                  label="Module"
                  name="modules"
                  type="number"
                  id="Module"
                  onChange={(e) => setInputModule(e.target.value)}
                  value={Math.max(
                    Number(
                      uniqueArray(
                        (planData?.courses ?? []).map((course) => course.module)
                      ).at(-1)
                    ) + 1
                  )}
                  placeholder="Module e.g 1,2,3"
                  mt="0.5rem"
                  required={addNewModule}
                />
              ) : (
                <div className=" flex flex-col items-start justify-center space-y-0">
                  <label>Module</label>
                  <Dropdown
                    additionalClass={`!p-1`}
                    label="Select a module"
                    options={[
                      ...uniqueArray(
                        (planData?.courses ?? []).map((course) => course.module)
                      )
                    ]}
                    lblList={[
                      ...uniqueArray(
                        (planData?.courses ?? []).map((course) => course.module)
                      )
                    ]}
                    selectedOption={currentModule}
                    onSelectOption={(option) => {
                      setCurrentModule(Number(option.val));
                    }}
                  />
                </div>
              )}
            </div>
            <div className=" w-full flex items-center justify-end px-2 gap-2">
              <button
                className={`w-1/2 text-sm ${
                  !addNewModule ? "!bg-green-400" : "!bg-red-400"
                } p-2 rounded-lg text-white transform translate-y-1/2 `}
                type="button"
                onClick={() => {
                  if (!addNewModule) {
                    setSelCourses([]);
                  } else {
                    currentModule === 0
                      ? setSelCourses([...planData.courses])
                      : setSelCourses(
                          [...planData.courses]
                            .map(
                              (course) =>
                                course.module === currentModule && course.id
                            )
                            .filter((val) => typeof val === "string")
                        );
                  }
                  setAddNewModule((val) => !val);
                }}
              >
                {addNewModule ? "cancel" : "Add"}
              </button>
              <button
                className={`w-1/2 hover:!bg-blue-400 !bg-slate-300 p-2 rounded-lg transform translate-y-1/2  text-white`}
                type="button"
                onClick={() => {
                  if (currentModule <= 0) {
                    toast.error("You need to select a module to be deleted.");
                  } else {
                    setDeletingModule((val) => !val);
                  }
                }}
              >
                Delete
              </button>
            </div>
          </div>
          {addCourses && (
            <div className="table absolute">
              <ShowAddCourses
                data={courses}
                onClose={() => setAddCourses(false)}
                getCoursesId={(coursesId) => {
                  handleSelectCourse(coursesId);
                }}
                selCoursesId={selCourses}
              />
            </div>
          )}
          {deletingModule && (
            <div className="fixed w-full top-0 left-0 h-full flex items-center justify-center z-[200] bg-black/20 backdrop-blur-md bg-opacity-20 ">
              <div className="w-[250px] h-[200px] text-center flex flex-col items-center justify-center p-2 mx-auto rounded-lg bg-white text-black/70 gap-4">
                <p className=" text-black/70 font-bold w-4/5 mx-auto">
                  Do you want to delete module{" "}
                  <strong className="text-red-700 font-bold">
                    {currentModule}
                  </strong>{" "}
                  of this course ?{" "}
                </p>
                <div className=" w-4/5 mx-auto flex items-center justify-center space-x-3">
                  <button
                    type="button"
                    onClick={() => setDeletingModule(false)}
                    className=" py-2 border border-solid border-red-400 text-red-500 hover:bg-red-500 hover:text-white transition-colors duration-300 ease-in-out capitalize px-4 w-1/2 rounded-lg"
                  >
                    No
                  </button>
                  <button
                    type="button"
                    onClick={handleDeleteModule}
                    className=" py-2 border border-solid border-green-400 text-green-500 hover:bg-green-500 hover:text-white transition-colors duration-300 ease-in-out capitalize w-1/2 px-4 rounded-lg"
                  >
                    yes
                  </button>
                </div>
              </div>
            </div>
          )}
          <div className="w-full flex items-center justify-start space-x-6 my-4">
            <button
              type="button"
              className="btn !ml-0 border rounded-lg px-4 transition-all duration-300 ease-in-out hover:shadow-lg active:shadow-none border-solid p-2"
              onClick={() => setAddCourses(true)}
            >
              Edit Resources
            </button>
            <span>{selCourses.length}</span>
          </div>

          <TextInput
            label="Price"
            name="price"
            type="price"
            id="price"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.price}
            error={formik.errors.price}
            visible={formik.touched.price}
            placeholder="Price"
            mt="0.5rem"
          />
          <AuthButton
            loading={loading}
            type="submit"
            // disabled={authStore.isLoading ? true : false}
            title={"Update"}
          />
        </form>
      </div>
    </div>
    // </Modal>
  );
};
