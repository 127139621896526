import {
  Card,
  CardContainer,
  Container,
  Value,
  WelcomeCard,
  WelcomeMessage
} from "./dashboardStyle";
import React, { useEffect, useState } from "react";

import { LOCAL_STORAGE } from "api/LOCALSTORAGE";
import { USER_PLAN } from "api/ACTION";
import { chooseRandomItems } from "utils";
import jmsApp from "api/jms";
import textBanner from "../../assets/images/bgBanner.png";
import { useNavigate } from "react-router";

const DashboardComp = ({ courses, courseStats, events }) => {
  const user = LOCAL_STORAGE.user();

  // const welcomeMessage = `Welcome ${user.name}★`;

  const [plans, setPlans] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const [isHovered, setIsHovered] = useState(false);

  const buttonStyle = {
    backgroundColor: isHovered ? "transparent" : "#46555c",
    border: isHovered ? "2px solid #46555c" : "2px solid transparent",
    color: isHovered ? "#46555c" : "#fff",
    padding: "10px 20px",
    cursor: "pointer",
    transition: "background-color 0.3s, border 0.3s"
  };

  useEffect(() => {
    handleData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleData = async () => {
    setIsLoading(true);
    try {
      const { data } = await jmsApp.get(USER_PLAN.GET_USER_PLANS());

      setIsLoading(false);
      if (data) {
        setPlans(data.plans);
        // setSuccess(true);
      }
    } catch (error) {
      setIsLoading(false);
      if (error.response?.status === "401") {
        navigate("/login");
      }
    }
  };

  const allCourses = plans.map((item) => item.courses).flat();
  // const allCourses = plans
  //   .map((item) => item.courses)
  //   .map((course) => {
  //     // const course = item.plan.courses;
  //     // course.courseType = item.type;
  //     return course;
  //   })
  //   .flat();
  const randomCourses =
    allCourses.length > 5 ? chooseRandomItems(allCourses, 5) : allCourses;

  return (
    <Container>
      <WelcomeCard style={{ backgroundImage: `url(${textBanner})` }}>
        <WelcomeMessage>Welcome {user.name} ★</WelcomeMessage>
      </WelcomeCard>
      {plans?.length > 0 && isLoading === false ? (
        <CardContainer>
          <div className="text-lg py-4 font-bold ">
            Your have{" "}
            {plans.reduce((acc, item) => acc + item.courses.length, 0)} Courses{" "}
          </div>
          <div
            className="w-full grid grid-cols-[repeat(auto-fill, minmax(300px, 1fr))]   gap-6 "
            style={{
              gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))"
            }}
          >
            {allCourses.length > 0 ? (
              randomCourses.map((course, idx) => (
                <Card key={idx} className="min-w-[300px]">
                  <div className="text-[10px]  absolute  right-0 bg-[#9C4DF4]/50 items-center justify-center overflow-wrap font-bold py-[2px] px-2 w-[10rem] text-center text-white  uppercase transform translate-x-[25%] translate-y-[15%] rotate-45 origin-center ">
                    <p className=" w-1/2 mx-auto text-wrap text-center">
                      {course.type}
                    </p>
                  </div>
                  <Value>{course.title}</Value>
                  <Value>{course.type}</Value>
                  <div className=" self-end">module {course.module} </div>
                </Card>
              ))
            ) : (
              <div className="">
                <p>No Subscription found</p>
              </div>
            )}
          </div>
          <button
            onClick={() => navigate("/courses")}
            type="button"
            style={buttonStyle}
            onMouseOver={() => setIsHovered(true)}
            onMouseOut={() => setIsHovered(false)}
          >
            View More
          </button>
        </CardContainer>
      ) : null}

      {plans?.length <= 0 && isLoading === true ? (
        <CardContainer>
          <div className=" my-2 h-6 w-28 bg-gray-200 rounded-full animate-pulse"></div>
          {/* <div className="innerCon"> */}
          <div
            className="w-full grid grid-cols-[repeat(auto-fill, minmax(200px, 1fr))]   gap-6 "
            style={{
              gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))"
            }}
          >
            <ul className="m-1 space-y-3 w-40 bg-gray-100/70 rounded p-4  ">
              <li className="w-full h-4 bg-gray-200 rounded-full animate-pulse"></li>
              <li className="w-full h-4 bg-gray-200 rounded-full animate-pulse"></li>
              <li className="w-full h-4 bg-gray-200 rounded-full animate-pulse"></li>
              <li className="w-full h-4 bg-gray-200 rounded-full animate-pulse"></li>
            </ul>
            <ul className="m-1 space-y-3 w-40 bg-gray-100/70 rounded p-4">
              <li className="w-full h-4 bg-gray-200 rounded-full animate-pulse"></li>
              <li className="w-full h-4 bg-gray-200 rounded-full animate-pulse"></li>
              <li className="w-full h-4 bg-gray-200 rounded-full animate-pulse"></li>
              <li className="w-full h-4 bg-gray-200 rounded-full animate-pulse"></li>
            </ul>
            <ul className="m-1 space-y-3 w-40 bg-gray-100/70 rounded p-4">
              <li className="w-full h-4 bg-gray-200 rounded-full animate-pulse"></li>
              <li className="w-full h-4 bg-gray-200 rounded-full animate-pulse"></li>
              <li className="w-full h-4 bg-gray-200 rounded-full animate-pulse"></li>
              <li className="w-full h-4 bg-gray-200 rounded-full animate-pulse"></li>
            </ul>
            <ul className="m-1 space-y-3 w-40 bg-gray-100/70 rounded p-4">
              <li className="w-full h-4 bg-gray-200 rounded-full animate-pulse"></li>
              <li className="w-full h-4 bg-gray-200 rounded-full animate-pulse"></li>
              <li className="w-full h-4 bg-gray-200 rounded-full animate-pulse"></li>
              <li className="w-full h-4 bg-gray-200 rounded-full animate-pulse"></li>
            </ul>
            <ul className="m-1 space-y-3 w-40 bg-gray-100/70 rounded p-4">
              <li className="w-full h-4 bg-gray-200 rounded-full animate-pulse"></li>
              <li className="w-full h-4 bg-gray-200 rounded-full animate-pulse"></li>
              <li className="w-full h-4 bg-gray-200 rounded-full animate-pulse"></li>
              <li className="w-full h-4 bg-gray-200 rounded-full animate-pulse"></li>
            </ul>
            <ul className="m-1 space-y-3 w-40 bg-gray-100/70 rounded p-4">
              <li className="w-full h-4 bg-gray-200 rounded-full animate-pulse"></li>
              <li className="w-full h-4 bg-gray-200 rounded-full animate-pulse"></li>
              <li className="w-full h-4 bg-gray-200 rounded-full animate-pulse"></li>
              <li className="w-full h-4 bg-gray-200 rounded-full animate-pulse"></li>
            </ul>
          </div>
        </CardContainer>
      ) : null}

      {plans?.length <= 0 && isLoading === false ? (
        <div className="">
          <p>No Plans found</p>
        </div>
      ) : null}
    </Container>
  );
};
export default DashboardComp;
