import { MdGroups2, MdOutlineModeEdit } from "react-icons/md";
import React, { useState } from "react";

import { FaHome } from "react-icons/fa";
import Footer from "components/Footer";
import { IoIosPaper } from "react-icons/io";
import { Link } from "react-router-dom";
import { MainContainer } from "globalStyles";
import NavBar from "components/Navbar";

const Mainlayout = (props) => {
  return (
    <div className="body-bg">
      <NavBar />
      <div className="pt-[100px] flex flex-col sm:grid grid-cols-[25%_75%] xl:grid-cols-[15%_85%] divide-x-2">
        <SideBar />
        <MainContainer>{props.children}</MainContainer>
      </div>
      <Footer />
    </div>
  );
};
function SideBar() {
  const [openArticles, setOpenArticles] = useState(false);

  const [openAuthors, setOpenAuthors] = useState(false);

  return (
    <div className="">
      <Link
        to={"/"}
        className="relative flex items-center justify-between w-full space-x-2 border uppercase p-2 cursor-pointer hover:bg-gray-100 text-sm bg-white"
      >
        <span>Home</span>
        <div className="text-red-900">
          <FaHome />
        </div>
      </Link>
      <div
        className="relative flex items-center justify-between w-full space-x-2 border uppercase p-2 cursor-pointer hover:bg-gray-100 text-sm bg-white"
        onMouseEnter={() => setOpenArticles(true)}
        onMouseLeave={() => setOpenArticles(false)}
      >
        <span>Articles</span>
        <div className="text-red-900">
          <IoIosPaper />
        </div>
        {openArticles && (
          <div className=" absolute bottom-0 sm:top-0 right-0 transform translate-y-full sm:translate-y-0 w-full shadow-xl shad sm:translate-x-full bg-white z-50">
            <Link
              to={"/articles-sort?by=author"}
              className=" flex items-center justify-between w-full space-x-2 border uppercase p-2 cursor-pointer hover:bg-gray-100 text-sm bg-white"
            >
              <span>By Author</span>
            </Link>
            <Link
              to={"/articles-sort?by=title"}
              className=" flex items-center justify-between w-full space-x-2 border uppercase p-2 cursor-pointer hover:bg-gray-100 text-sm bg-white"
            >
              <span>By title</span>
            </Link>
          </div>
        )}
      </div>
      <Link
        to={"/editorial-team"}
        className=" flex items-center justify-between w-full space-x-2 border uppercase p-2 cursor-pointer hover:bg-gray-100 text-sm bg-white"
      >
        <span>Editorial Team</span>
        <div className="text-red-900">
          <MdGroups2 />
        </div>
      </Link>
      <div
        className=" relative flex items-center justify-between w-full space-x-2 border uppercase p-2 cursor-pointer hover:bg-gray-100 text-sm bg-white"
        onMouseEnter={() => setOpenAuthors(true)}
        onMouseLeave={() => setOpenAuthors(false)}
      >
        <span>Authors</span>
        <div className="text-red-900">
          <MdOutlineModeEdit />
        </div>
        {openAuthors && (
          <div className=" absolute bottom-0 sm:top-0 right-0 transform translate-y-full sm:translate-y-0 w-full shadow-xl sm:translate-x-full bg-white z-50">
            <Link
              to={"/author/submission-procedure"}
              className=" flex items-center justify-between w-full space-x-2 border uppercase p-2 cursor-pointer hover:bg-gray-100 text-sm bg-white"
            >
              <span>submission procedure</span>
            </Link>
            {/* <div className=" flex items-center justify-between w-full space-x-2 border uppercase p-2 cursor-pointer hover:bg-gray-100 text-sm bg-white">
              <span>submission guideline</span>
            </div>
            <div className=" flex items-center justify-between w-full space-x-2 border uppercase p-2 cursor-pointer hover:bg-gray-100 text-sm bg-white">
              <span>submit and track manuscript</span>
            </div>
            <div className=" flex items-center justify-between w-full space-x-2 border uppercase p-2 cursor-pointer hover:bg-gray-100 text-sm bg-white">
              <span>publication fee</span>
            </div>
            <div className=" flex items-center justify-between w-full space-x-2 border uppercase p-2 cursor-pointer hover:bg-gray-100 text-sm bg-white">
              <span>make a payment</span>
            </div> */}
          </div>
        )}
      </div>
    </div>
  );
}
export default Mainlayout;
