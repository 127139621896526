import React from 'react'
import { AgricDiv, AgricContainer } from './style'

function MedResearchPage() {
  return (
    <div>
      <AgricDiv>
      <AgricContainer>

      MedResearchPage
      </AgricContainer>
      </AgricDiv>
      </div>
  )
}

export default MedResearchPage