import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { ARTICLE_ENDPOINT } from "api/ACTION";
import { AuthButton } from "components/Auth";
import { ClipLoader } from "react-spinners";
import { VerifyCont } from "components/Auth/style";
import jmsApp from "api/jms";
import toast from "react-hot-toast";
import { useEffect } from "react";

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "white"
};

function VerifyView() {
  // let { id } = useParams();
  const navigate = useNavigate();

  // Get the query parameters from the URL
  const [searchParams] = useSearchParams();

  // Extract the value of the 'token' parameter
  const token = searchParams.get("token");
  // console.log("THis is the token : ", token);
  // id = id?.split("=")[1];
  const verifyInvite = async () => {
    localStorage.setItem("ViewId", JSON.stringify(token));
    try {
      const { data } = await toast.promise(
        jmsApp.get(ARTICLE_ENDPOINT.VERIFY_ARTICLE_VIEW(), {
          params: { token: token }
        }),
        {
          loading: "Verification in progress...",
          success: "Article verification successful.",
          error: (err) =>
            err.response.data.error || "Article verification failed"
        }
      );

      if (data.success) {
        navigate(`/articles/${token}`);
      }
    } catch (err) {
      if (err.response) {
        // toast.error(err.response.data.error);
      }
    }
  };

  useEffect(() => {
    verifyInvite();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <VerifyCont>
      <h1>Please verify view </h1>
      <ClipLoader
        color="#46555C"
        cssOverride={override}
        size={30}
        aria-label="Loading Spinner"
        data-testid="loader"
      />

      {/* <form>
        <AuthButton title="Verify View" />
      </form> */}
    </VerifyCont>
  );
}

export default VerifyView;
