import * as Yup from "yup";

import React, { useEffect, useRef, useState } from "react";

import { AiOutlineClose } from "react-icons/ai";
import { AuthButton } from "components/Auth";
import { CONSTANT } from "utils/validation";
import { COURSE } from "api/ACTION";
import Dropdown from "../sharedComponents/DropDown";
import { Modal } from "../Staff/style";
import TextInput from "components/TextInput";
import jmsApp from "api/jms";
import { toast } from "react-hot-toast";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";

function UpdateModal({ open, onClose, course, setUpdated }) {
  const navigate = useNavigate();
  const [category, setCategory] = useState(course.category);
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState(course.type);
  // eslint-disable-next-line no-unused-vars
  const [text, setText] = useState("");
  const [values, setValues] = useState(null);
  const JournalFileRef = useRef();
  const [journalFile, setJournalFile] = useState();

  useEffect(() => {
    setCategory(course.category);
    setType(course.type);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadFile = (file) => {
    if (file) {
      const type = file.type;
      // const type = values.type
      // setActive(true)
      if (!CONSTANT.FILE_TYPE.includes(type)) {
        toast.error("Invalid file format");
      } else {
        toast.success("file upload complete");
      }
      // values["format"] = file.type;
      const newValues = { ...values, format: type };
      setValues(newValues);

      // read the file
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setJournalFile(reader.result);
      };
      // setActive(false)
    }
  };
  const uploadFile = (base64EncodedImage) => {
    setValues(base64EncodedImage);
  };

  const handleInputChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      // User uploaded a file
      loadFile(event.target.files[0]);
      // setFile(event.target.files[0])
    } else {
      // User entered text
      setText(event.target.value);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: course.title,
      resourceUrl: course.resourceUrl
    },

    onSubmit: async (info, helpers) => {
      info.id = course.courseId;
      info.type = type;
      info.category = category;

      setLoading(true);
      try {
        if (journalFile) {
          //because the file required check if the use selected a file
          uploadFile(journalFile); // set the file data in the state varible
          info.fileData = values;
        }

        const { data } = await toast.promise(
          jmsApp.patch(COURSE.UPDATE_COURSE(), {
            ...info
          }),
          {
            loading: "Updating resource...",
            success: "Resource updated",
            error: ""
          }
        );
        if (data.success) {
          setLoading(false);
          helpers.resetForm();
          setUpdated(true);
          onClose();
        }
      } catch (err) {
        setLoading(false);
        if (err.response.status === 401) {
          navigate("/login");
        }
        if (err.response.data) {
          // toast.error(err.response.data.error);
        }
      }
    },

    validationSchema: Yup.object({
      title: Yup.string().max(255).required().label("Title"),
      resourceUrl: Yup.string().max(255).required().label("ResourceUrl")
    })
  });

  if (!open) return null;

  return (
    <Modal>
      <div className="container">
        <div className="relative flex items-center justify-center w-full">
          {/* <div className="header"> */}
          <h4 className="capitalize font-bold text-[#333333] text-[24px] ">
            Update Resource
          </h4>
          <span className=" absolute top-0 right-0 transform -translate-1/2 mx-6 cursor-pointer transition-colors hover:bg-black/50 duration-300 hover:text-white p-2 rounded-full ">
            <AiOutlineClose
              className="text-[#333333]"
              onClick={() => {
                setCategory(course.category);
                setType(course.type);
                onClose();
              }}
            />
          </span>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <TextInput
            label="Title"
            type="title"
            name="title"
            id="Title"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.title}
            error={formik.errors.title}
            visible={formik.touched.title}
            placeholder="Title "
            mt="0.5rem"
          />
          <Dropdown
            label="Select Type Option"
            options={["art", "science"]}
            lblList={["art", "science"]}
            selectedOption={type}
            onSelectOption={(option) => {
              setValues({ ...values, type: option.val });
              setType(option.val);
            }}
          />
          <Dropdown
            label="Select Category Option"
            options={["video", "book"]}
            lblList={["video", "book"]}
            selectedOption={category}
            onSelectOption={(option) => {
              setValues({ ...values, category: option.val });
              setCategory(option.val);
            }}
          />
          {category === "book" && (
            <>
              <input
                ref={JournalFileRef}
                name="type"
                type="file"
                onChange={handleInputChange}
                className=" mt-4"
              />
              <br />
            </>
          )}
          {category === "video" && (
            <TextInput
              label="ResourceUrl"
              name="resourceUrl"
              type="resourceUrl"
              id="ResourceUrl"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.resourceUrl}
              error={formik.errors.resourceUrl}
              visible={formik.touched.resourceUrl}
              placeholder="resourceUrl"
              mt="0.5rem"
            />
          )}
          <AuthButton loading={loading} type="submit" title="Update" />
        </form>
      </div>
    </Modal>
  );
}

export default UpdateModal;
