import LoginComponent from "components/Auth/Login"
import React from "react"

function LoginPage() {
	return (
		<div>
			<LoginComponent />
		</div>
	)
}

export default LoginPage
