import React, { useEffect, useMemo, useState } from "react";

import { AllArticles } from "../Articles/Articles.style";
import ClipLoader from "react-spinners/ClipLoader";
import { FcDeleteDatabase } from "react-icons/fc";
import { HiPlus } from "react-icons/hi";
import { LOCAL_STORAGE } from "api/LOCALSTORAGE";
import { PlanHeading } from "../sharedComponents/articleData";
import Search from "../sharedComponents/Search";
import { ShowAddPlan } from "./modals/ShowAddPlan";
import { ShowCourses } from "./modals/ShowCourses";
import { ShowUpdatePlan } from "./modals/ShowUpdatePlan";
import { StaffCont } from "../Staff/style";
import Table from "../sharedComponents/Table-v2";
import { USER_PLAN } from "api/ACTION";
import jmsApp from "api/jms";
import { override } from "utils/admin-roles";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

// import Table from "../Plan/Table";

const PlanComp = () => {
  const [plans, setPlans] = useState(useMemo(() => [], []));
  const columns = useMemo(() => PlanHeading, []);
  const [addPlan, setAddPlan] = useState(false);
  const [updatePlan, setUpdatePlan] = useState(false);
  const [updated, setUpdated] = useState(true);
  const [showCourses, setShowCourses] = useState(false);
  const [courses, setCourses] = useState(null);
  const [selPlan, setSelPlan] = useState(null);
  const [fetchingPlans, setFetchingPlans] = useState(false);
  const [isDeletingPlan, setIsDeletingPlan] = useState(null);
  const currentUser = LOCAL_STORAGE.user();
  const userRole = currentUser?.type ?? "";
  useEffect(() => {
    if (updated === true) {
      // setFetchingPlans(true);
      handleData();
      setUpdated(false);
      // setFetchingPlans(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updated]);

  const handleCourse = (plan) => {
    setShowCourses(true);
    setCourses(plan);
  };

  const handleEdit = (plan) => {
    setSelPlan(plan);
    setUpdatePlan(true);
  };

  const navigate = useNavigate();

  const handleData = async () => {
    setFetchingPlans(true);
    try {
      const { data } = await jmsApp.get(USER_PLAN.GET_PLAN());

      if (data.success) {
        setPlans(data.plans);
        setUpdated(false);
      }
    } catch (error) {
      if (error.response?.status === "401") {
        navigate("/login");
      } else return new Error(error);
    } finally {
      setFetchingPlans(false);
    }
  };

  const handleDelete = async (plan) => {
    const planId = plan.planId;
    try {
      const { data } = await toast.promise(
        jmsApp.delete(USER_PLAN.DELETE_PLAN(), {
          params: { planId }
        }),
        {
          loading: `${plan.type} deletion in progress...`,
          success: () => {
            handleData();
            return `${plan.type} deletion successful`;
          },
          error: `${plan.type} deletion failed...`
        }
      );
      if (data.success) {
        setIsDeletingPlan(null);
        // toast.success(data.msg);
        setUpdated(true);
        return true;
      }
    } catch (error) {
      if (error) return false;
    } finally {
      setIsDeletingPlan(null);
    }
  };

  const [value, setValue] = useState("");

  return (
    <>
      <StaffCont>
        <div className="heading">
          <div className=" w-full flex items-center justify-between py-4 px-6 ">
            <h1 className=" text-xl font-bold uppercase">All courses</h1>
            {currentUser.name && (
              <div className=" flex text-xs lg:text-xl font-bold uppercase space-x-4 items-center justify-end ">
                <span>Welcome</span>
                <span className=" ">{currentUser?.name}</span>
              </div>
            )}
          </div>
          {/* <h1>All Plans</h1> */}
          <button onClick={() => setAddPlan(true)}>
            <span>
              <HiPlus size={24} />
            </span>
            Add Courses
          </button>
        </div>
        <div className="search">
          <Search
            value={value}
            setValue={setValue}
            placeholder="Search Plan..."
          />
        </div>
        {plans.length > 0 ? (
          <AllArticles>
            <Table
              data={plans}
              columns={columns}
              color="grey"
              heading={PlanHeading}
              user={userRole}
              // handleDelete={(id) => handleDelete(id)}
              // viewCourses={(courses) => handleCourse(courses)}
              // handleEdit={(plan) => handleEdit(plan)}
              actions={[
                { name: "view", action: handleCourse },
                { name: "edit", action: handleEdit },
                // { name: "delete", action: handleDelete }
                { name: "delete", action: (plan) => setIsDeletingPlan(plan) }
              ]}
            />
          </AllArticles>
        ) : !fetchingPlans ? (
          <div className="bg-transparent capitalize font-bold text-xl font-primary text-center w-full py-4 text-[#46555C] flex flex-col space-y-4 items-center  justify-center">
            <span>
              <FcDeleteDatabase size={40} />
            </span>
            <p>No course found</p>
          </div>
        ) : (
          <div className=" w-full h-full flex  items-center justify-center">
            <ClipLoader
              color="#46555C"
              loading={fetchingPlans}
              cssOverride={override}
              size={30}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        )}
        {updatePlan && (
          <ShowUpdatePlan
            setUpdated={setUpdated}
            plan={selPlan}
            onClose={() => setUpdatePlan(false)}
          />
        )}
        {addPlan && (
          <ShowAddPlan
            setUpdated={setUpdated}
            onClose={() => {
              setAddPlan(false);
            }}
            plan={plans}
          />
        )}
        {showCourses && (
          <ShowCourses data={courses} onClose={() => setShowCourses(false)} />
        )}
        {isDeletingPlan && (
          <div className="min-h-screen bg-black/70 bg-opacity-50 backdrop-blur-sm fixed w-full h-full top-0 left-0 flex items-center justify-center">
            <div
              className="max-w-[400px] h-[250px] flex flex-col bg-white rounded-lg items-center justify-center gap-6
           p-6"
            >
              <p className=" font-medium text-center text-xl ">
                Do you want to remove{" "}
                <strong className="block text-red-600 italic underline">
                  {isDeletingPlan.description}
                </strong>{" "}
                <span className="block">from the courses list ?</span>
              </p>
              <div className=" w-full items-center justify-between flex gap-6">
                <button
                  onClick={() => setIsDeletingPlan(null)}
                  className=" w-full p-2 rounded-md border border-red-500 text-red-500 transition-all duration-300 ease-in-out hover:bg-red-500 hover:text-white capitalize font-bold text-lg "
                >
                  no
                </button>
                <button
                  onClick={() => handleDelete(isDeletingPlan)}
                  className=" w-full p-2 rounded-md border border-blue-500 text-blue-500 transition-all duration-300 ease-in-out hover:bg-blue-500 hover:text-white capitalize font-bold text-lg "
                >
                  yes
                </button>
              </div>
            </div>
          </div>
        )}
      </StaffCont>
    </>
  );
};

export default PlanComp;
