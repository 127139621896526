import { ErrorMsgSpan, InputText, TextInputContainer } from "./style";

import React from "react";

function TextInput({
  name,
  type = "text",
  id,
  onChange,
  label,
  mt = "",
  disabled,
  labelColor = "",
  width = "100%",
  error = "",
  visible,
  ErrorMt,
  min = 1,
  ...rest
}) {
  return (
    <TextInputContainer mt={mt} width={width} labelColor={labelColor}>
      <label htmlFor={name}>{label}</label>
      <InputText
        disabled={disabled}
        type={type}
        name={name}
        id={id}
        onChange={onChange}
        {...rest}
        min={min}
      />

      {visible && error ? (
        <ErrorMsgSpan ErrorMt={`${mt}`}>{error}</ErrorMsgSpan>
      ) : null}
    </TextInputContainer>
  );
}

export default TextInput;
