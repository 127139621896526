import { Navigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

import ClipLoader from "react-spinners/ClipLoader";
import { LOCAL_STORAGE } from "api/LOCALSTORAGE";
import authStore from "mobx/AuthStore";
import { checkUser } from "../../api/VerifyUser";
import { handleLogout } from "utils/UserLogout";
import { handleRefreshToken } from "utils/auth";
import { observer } from "mobx-react-lite";
import { override } from "utils/admin-roles";

export const ProtectedRoute = observer(({ children, allowedRoles }) => {
  let location = useLocation();
  const [checked, setChecked] = useState(false);
  const [authorizing, setAuthorizing] = useState(true);
  const TIMER = 1000;
  const [timer, setTimer] = useState(TIMER);
  useEffect(() => {
    if (timer === 0) {
      if (authStore.shouldRefresh) {
        handleRefreshToken();
        authStore.setShouldRefresh(false);
        setTimer(TIMER);
      } else {
        return;
      }
    }
    const interval = setInterval(() => {
      // Decrease timer by 1000 milliseconds (1 second) every second
      setTimer((prevTimer) => prevTimer - 1000);
    }, 1000); // Run the interval every second (1000 milliseconds)

    // Cleanup function to clear the interval when the component unmounts or timer reaches zero
    return () => clearInterval(interval);
  }, [timer]);

  // check which user is logged in and select page to display.
  const childrenType = () => {
    return allowedRoles.find((role) => LOCAL_STORAGE.user().type === role) ? (
      children
    ) : (
      <Navigate to="/unauthorized" state={{ from: location }} replace />
    );
  };

  useEffect(() => {
    if (LOCAL_STORAGE.user()) {
      checkUser().then((data) => {
        if (data?.success) {
          setChecked(true);
          setAuthorizing(false);
        }
        if (data.response && data.response?.status === 401) {
          setChecked(false);
          handleRefreshToken().then((data) => {
            if (data.success) {
              setChecked(true);
            } else {
              <Navigate to="/login" state={{ from: location }} replace />;
            }
          });
        }
        if (!data || data?.success === false) handleLogout();
      });
    } else handleLogout();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (authorizing) {
    return (
      <div className="fixed top-0 left-0 w-full h-screen flex items-center justify-center bg-gray-100 bg-opacity-75 !mt-0 backdrop:blur-md">
        <ClipLoader
          color="#333333"
          loading={authorizing}
          cssOverride={override}
          size={40}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    );
  }
  if (checked) {
    return childrenType();
  }
});
