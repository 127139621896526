import * as Yup from "yup";

import React, { useState } from "react";

import { AiOutlineClose } from "react-icons/ai";
import { CATEGORY_ENDPOINT } from "api/ACTION";
import { Modal } from "../Staff/style";
import TextInput from "components/TextInput";
import authStore from "mobx/AuthStore";
import jmsApp from "api/jms";
import { toast } from "react-hot-toast";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";

function UpdateModal({
  // open,
  onClose,
  categoryId,
  setUpdated,
  category: defaultValues
}) {
  // eslint-disable-next-line no-unused-vars
  // const [category, setCategory] = useState(defaultValues);
  const navigate = useNavigate();
  const [categoryFile, setCategoryFile] = useState({
    format: "",
    fileData: null,
    size: 0
  });
  const formik = useFormik({
    enableReinitialize: false,
    initialValues: {
      name: defaultValues?.name ?? "",
      format: "",
      fileData: null
    },

    onSubmit: async (info, helpers) => {
      info.id = categoryId;
      if (categoryFile.size > 5 * 1024 * 1024) {
        toast.error("Maximum upload size (5MB) exceeded!");
        return;
      }
      try {
        const { data } = await toast.promise(
          jmsApp.patch(CATEGORY_ENDPOINT.UPDATE_CATEGORY(), {
            ...info,
            format: categoryFile.format,
            fileData: categoryFile.fileData
          }),
          {
            loading: `Updating ${info.name} category....`,
            success: `${info.name} category successfully updated.`,
            error: `Failed to update ${info.name} category.`
          },
          {
            className: "text-[12px]"
          }
        );
        if (data.success) {
          helpers.resetForm();
          // handleCategory();
          setUpdated(true);
          onClose();
        }
      } catch (err) {
        if (err.response.status === 401) {
          navigate("/login");
        }
        if (err.response.data) {
          // toast.error(err.response.data.error);
        }
      }
    },

    validationSchema: Yup.object({
      name: Yup.string().max(255).required().label("name")
    })
  });

  // const handleCategory = async () => {
  //   try {
  //     const { data } = await jmsApp.get(CATEGORY_ENDPOINT.GET_CATEGORY());
  //     if (data.success) {
  //       setCategory(data.category);
  //     }
  //   } catch (error) {}
  // };
  function handleFile(e) {
    const reader = new FileReader();
    const fileData = e.target.files[0];
    // Set up the FileReader to convert the file to a data URI
    reader.onload = function (file) {
      const dataURI = file.target.result;

      const newCatFile = {
        size: fileData.size,
        format: `.${fileData.type.split("/")[1]}`,
        fileData: dataURI
      };
      setCategoryFile(newCatFile);

      // Now you have the data URI, which you can use in your application
      // For example, you can set it as the source of an image element
      // const imageElement = document.getElementById('yourImageElementId');
      // imageElement.src = dataURI;
    };

    // Read the file as a data URI
    reader.readAsDataURL(fileData);
  }
  // if (!open) return null;

  return (
    <Modal>
      <div className="container">
        <div className="relative flex items-center justify-center w-full">
          {/* <div className="header"> */}
          <h4 className="capitalize font-bold text-[#333333] text-[24px] ">
            Update categories
          </h4>
          <span
            onClick={onClose}
            className=" absolute top-0 right-0 transform -translate-1/2 mx-6 cursor-pointer transition-colors hover:bg-black/50 duration-300 hover:text-white p-2 rounded-full "
          >
            <AiOutlineClose className="currentColor" />
          </span>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <TextInput
            label="name "
            name="name"
            type="name"
            id="name"
            defaultValues={defaultValues?.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
            error={formik.errors.name}
            visible={formik.touched.name}
            placeholder="name "
            mt="0.5rem"
          />
          <div className=" my-4">
            <label className="block">
              <span className="sr-only">Choose category photo</span>
              <input
                type="file"
                className="block w-full text-sm text-gray-500
      file:me-4 file:py-2 file:px-4
      file:rounded-lg file:border-0
      file:text-sm file:font-semibold
      file:bg-blue-600 file:text-white
      hover:file:bg-blue-700
      file:disabled:opacity-50 file:disabled:pointer-events-none
     
    "
                onChange={handleFile}
              />
            </label>
          </div>
          <button
            className={
              "!bg-black text-white hover:scale-105 transform transition-all duration-200 ease-in-out px-2 py-1 rounded-md w-fit h-fit mt-4 cursor-pointer disabled:cursor-not-allowed disabled:opacity-50"
            }
            type="submit"
            disabled={authStore.isLoading ? true : false}
          >
            {authStore.isLoading ? "Loading..." : "Update"}
          </button>
        </form>
      </div>
    </Modal>
  );
}

export default UpdateModal;
