import React, { useEffect, useState } from "react";

import { AUTH_ENDPOINTS } from "api/ACTION";
import { ClipLoader } from "react-spinners";
import { Link } from "react-router-dom";
import { MdArrowRight } from "react-icons/md";
import jmsApp from "api/jms";
import { override } from "utils/admin-roles";

export default function EditorialTeam() {
  const [team, setTeam] = useState([]);
  const [gettingEditors, setGettingEditors] = useState(false);
  useEffect(() => {
    getEditorTeam();
  }, []);
  async function getEditorTeam() {
    try {
      setGettingEditors(true);
      const { data } = await jmsApp.get(AUTH_ENDPOINTS.GET_EDITORS());

      setTeam(data?.editors ?? []);
    } catch (err) {
      //   console.log(err);
    } finally {
      setGettingEditors(false);
    }
  }
  //   const team = [
  //     {
  //       name: "Aladelo Moses",
  //       phone: "+2348977837389",
  //       email: "almoses@gmail.com"
  //     },
  //     {
  //       name: "George Leigh",
  //       phone: "+1148957637280",
  //       email: "george@yahoo.com"
  //     },
  //     {
  //       name: "Ming Sung",
  //       phone: "+348934537389",
  //       email: "misung@committe.books.org"
  //     },
  //     {
  //       name: "Rayleigh Kingsley",
  //       phone: "+8889778987389",
  //       email: "rayking084@outlook.com"
  //     },
  //     {
  //       name: "Jennifer Komolafe",
  //       phone: "+2348979825738",
  //       email: "jksusan@pub.edu.ng"
  //     }
  //   ];
  if (gettingEditors) {
    return (
      <div className=" w-full h-full flex  items-center justify-center">
        <ClipLoader
          color="#46555C"
          loading={gettingEditors}
          cssOverride={override}
          size={30}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    );
  }
  if (team.length === 0) {
    return (
      <div className=" w-full h-full flex  items-center justify-center flex-col gap-6">
        <p className=" text-lg font-bold text-blue-800 uppercase ">
          No editor found
        </p>
        <button
          onClick={getEditorTeam}
          className=" p-2 capitalize text-xs font-bold border-blue-600 border border-solid rounded-lg text-blue-600 hover:text-white hover:bg-blue-600 transition-all duration-300 ease-in-out"
        >
          Retry
        </button>
      </div>
    );
  }
  return (
    <div className="w-full px-6 py-2 flex flex-col items-start justify-start  gap-4">
      <div className="w-full flex items-center justify-start gap-6 text-[#b97868] font-bold uppercase">
        <Link to={"/"}>Home</Link>
        <MdArrowRight size={40} />
        <span>MEMBERS OF THE EDITORIAL TEAM</span>
      </div>
      <div className="uppercase text-[#21046e] text-start w-full ">
        MEMBERS OF THE EDITORIAL TEAM
      </div>
      <div className="-m-1.5 overflow-auto w-full shadow-xl">
        <div className="p-1.5 min-w-full inline-block align-middle">
          <div className="overflow-hidden">
            <div className="table border-collapse table-auto w-full divide-y divide-gray-200 ">
              <div className="table-header-group">
                <div className="table-row">
                  <div className="table-cell px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase">
                    Name
                  </div>
                  <div className="table-cell px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase">
                    email
                  </div>
                  <div className="table-cell px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase">
                    phone number
                  </div>
                  <div className="table-cell px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase">
                    status
                  </div>
                </div>
              </div>
              <div className="table-row-group divide-y divide-gray-200 bg-white ">
                {team.map((member, idx) => (
                  <div key={idx} className="table-row">
                    <div className="table-cell px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 ">
                      {member.name}
                    </div>
                    <div className="table-cell px-6 py-4 whitespace-nowrap text-sm text-gray-800 ">
                      {member.email}
                    </div>
                    <div className="table-cell px-6 py-4 whitespace-nowrap text-sm text-gray-800 ">
                      {member.phone}
                    </div>
                    <div
                      className={`table-cell px-6 py-4 whitespace-nowrap text-sm `}
                    >
                      <span
                        className={`rounded-lg p-2  uppercase text-sm text-white ${String(member.status).toLowerCase() === "verified" ? "bg-green-600" : "bg-red-500"}`}
                      >
                        {member.status}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
