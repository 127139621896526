import tw from "twin.macro";
import styled from "styled-components";

export const ArticleCon = styled.div`
  margin-top: 2rem;
  /* display: flex; */
  flex-direction: column;
  color: gray;
  width: 100%;
  height: 100%;
`
export const Container = styled.div`
  width: 100%;
  margin: auto;
  // border: 2px solid red;
  // padding:0 8.5% 0 8.5%;
`
export const CategoryHeadBg = styled.div`
  width: 100%;
  min-height: 300px;
  margin-top : 20px;
  display: flex;
  border-radius : 5px;
  //  padding:0 8.5% 0 8.5%;

  ${tw`mx-auto`}
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`
export const CategoryLeft = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;
  // background-color: gray;
  ${tw`mx-auto`}
  @media (max-width: 768px) {
    width: 100%;
    // margin: auto;
  }
`
export const CategoryRight = styled.div`
  // width: 60%;
  // border: 2px solid gray;
  ${tw`mx-auto p-2 sm:p-4 w-full lg:w-3/5`}
`

export const ButtonComp = styled.div`
  width: 100%;
 background-color: none;
 padding: 2px 0;
 color: green;
 font-size: 14px;
 font-weight: 600;
`
export const Card = styled.div`
  display: block;
  // margin:20px 8.5% 0 8.5%;
  // gap: 2rem;
  // width: 100%;
  min-height: 180px;
  ${tw`mx-auto my-1.5 sm:my-2.5 sm:space-y-2  w-80 sm:w-[40rem] p-1 sm:p-2 h-40 sm:h-auto`}
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  .desc {
    color: brown;
    // font-size: 16px;

    ${tw`text-sm sm:text-base`}
  }
`

export const PdfCardCon = styled.div`
  width: 180px;
  height: 140px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border: 1px solid #4ab6ff;
  border-radius: 10px;
  font-size: 14px;
  margin: auto
`