import { MobileMenuBtn, MobileNavLink } from "./style";

import React from "react";

function NavButtonMobile({ onClick, title, mt, to }) {
  return (
    <MobileNavLink to={to}>
      <MobileMenuBtn mt={mt} onClick={onClick}>
        <span className="transition-all duration-300 ease-in-out border-b border-solid border-transparent hover:border-black">
          {title}
        </span>
      </MobileMenuBtn>
    </MobileNavLink>
  );
}

export default NavButtonMobile;
