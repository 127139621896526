import {
  Modal,
  Table,
  TableData,
  TableHeader
} from "components/Admin/course/style";

import { AiOutlineClose } from "react-icons/ai";
import Colors from "utils/colors";
import { IconContext } from "react-icons/lib";
import { MdNextPlan } from "react-icons/md";
import NoCoursesPage from "components/ELearn/NoCoursePg";
import React from "react";

export const ShowCourses = ({ data, onClose }) => {
  const { courses, description } = data;
  return (
    <Modal className=" !absolute">
      <div className="container">
        <div className="relative flex items-center justify-center w-full flex-col">
          {/* <div className="header"> */}
          <h4 className="capitalize font-bold text-[#333333] text-[20px] sm:text-[24px] ">
            View Courses
          </h4>
          <span className=" absolute top-0 right-0 transform -translate-1/2 cursor-pointer transition-colors hover:bg-black/50 duration-300 hover:text-white p-2 rounded-full ">
            <AiOutlineClose className="text-[#333333]" onClick={onClose} />
          </span>
          <div className=" w-4/5 mx-auto text-center text-sm sm:text-lg">
            <span>Description : </span>
            <span className=" ">{description}</span>
          </div>
        </div>
        <div className="w-4/5 mx-auto py-4">
          {/* <div className="courses"> */}
          {courses.length !== 0 ? (
            <>
              <div className="flex flex-col">
                <div className="-m-1.5 overflow-x-auto">
                  <div className="p-1.5 min-w-full inline-block align-middle">
                    <div className="overflow-hidden">
                      <table className="min-w-full divide-y divide-gray-200 ">
                        <thead>
                          <tr>
                            <th
                              scope="col"
                              className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase"
                            >
                              Category
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase"
                            >
                              Title
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase"
                            >
                              Module
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-3 text-end text-xs font-medium text-gray-500 uppercase"
                            >
                              &nbsp;
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 ">
                          {courses?.map((item, id) => (
                            <tr key={`&&-${id}`} id={item?.id}>
                              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 ">
                                {item?.category}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 ">
                                {item?.title}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 ">
                                {item?.module}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-end text-sm font-medium">
                                <button
                                  type="button"
                                  className="inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-blue-600 hover:text-blue-800 disabled:opacity-50 disabled:pointer-events-none "
                                >
                                  <IconContext.Provider
                                    value={{
                                      color: `${Colors.primary}`,
                                      size: "25px"
                                    }}
                                  >
                                    <div
                                      id={id}
                                      className=" flex w-full items-center justify-center"
                                    >
                                      <MdNextPlan />
                                    </div>
                                  </IconContext.Provider>
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              {/* <Table>
                <thead>
                  <tr>
                    <TableHeader>Category</TableHeader>
                    <TableHeader>Title</TableHeader>
                    <TableHeader>Module</TableHeader>
                    <TableHeader></TableHeader>
                  </tr>
                </thead> */}
              {/* <tbody>
                  {courses?.map((item, id) => (
                    <tr key={`&&-${id}`} id={item?.id}>
                      <TableData>{item?.category}</TableData>
                      <TableData>{item?.title}</TableData>
                      <TableData>{item?.module}</TableData>
                      <TableData>
                        <IconContext.Provider
                          value={{ color: `${Colors.primary}`, size: "25px" }}
                        >
                          <div
                            id={id}
                            className=" flex w-full items-center justify-center"
                          >
                            <MdNextPlan />
                          </div>
                        </IconContext.Provider>
                      </TableData>
                    </tr>
                  ))}
                </tbody>
              </Table> */}
            </>
          ) : (
            <NoCoursesPage />
          )}
        </div>
      </div>
    </Modal>
  );
};
