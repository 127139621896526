import { AppLink, Img } from "globalStyles";
import { AuthButtonDiv, MenuContainer, MenuOverlay } from "./style";
import { ELEARN_ROUTE, HOME_ROUTE, LOGIN_ROUTE, SUBMIT_ROUTE } from "routes";
import { Link, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";

import { AppLogo } from "utils/assets";
import { CATEGORY_ENDPOINT } from "api/ACTION";
import { CloseIcon } from "utils/assets";
import { HomeLogoContainer } from "components/Navbar/style";
import { MainBtn } from "components/Home/Hero Section/style";
import NavButtonMobile from "./NavButtonMobile";
import jmsApp from "api/jms";
import toast from "react-hot-toast";
import useMediaQuery from "hooks/use-media-query/use-media-query";

function MobileMenu({ isOpen, close }) {
  const [journalList, setJournalList] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [activeNav, setActiveNav] = useState(1);
  const [categories, setCategories] = useState([]);

  const journalHandler = () => {
    setJournalList(!journalList);
  };
  const toggleActiveNav = (index) => {
    setActiveNav(index);
  };

  const location = useLocation();
  const { pathname } = location;
  // eslint-disable-next-line no-unused-vars
  const path = pathname.slice(0);

  const handleCategories = async () => {
    try {
      const { data } = await jmsApp.get(
        CATEGORY_ENDPOINT.GET_ARTICLE_CATEGORY()
      );
      if (data.success) setCategories((categories) => [...data.category]);
    } catch (error) {
      setCategories([]);
      // if (error.response) toast.error(error.response.data.error);
      // else toast.error(error);
    }
  };
  const media800 = useMediaQuery({ screen: "800px", type: "min" });

  useEffect(() => {
    handleCategories();
  }, []);
  useEffect(() => {
    if (!media800) return;
    close();
  }, [close, media800]);

  return (
    <MenuOverlay open={isOpen}>
      <MenuContainer>
        {/* <CloseMenuContainer> */}
        <button
          onClick={close}
          className=" p-2 absolute top-0 right-0  transform -translate-1/2 transition-all  duration-300 ease-in-out hover:text-white  hover:bg-black/50 mr-5 mt-5 rounded-full  "
        >
          <Img src={CloseIcon} alt={"Close Menu"} />
        </button>
        {/* </CloseMenuContainer> */}
        <HomeLogoContainer>
          <Img src={AppLogo} alt="LP Logo" />
        </HomeLogoContainer>
        <NavButtonMobile to={HOME_ROUTE} title="Home" mt="20px" />

        <div className=" ">
          <div className=" transition-all duration-300 ease-in-out transform  font-bold">
            {/* <AppLink
              // className={path === "" ? "  scale-110 " : " scale-95"}
              onClick={() => {
                toggleActiveNav(2);
                journalHandler();
              }}
            > */}
            <span
              onClick={(e) => {
                e.stopPropagation();
                toggleActiveNav(2);
                journalHandler();
              }}
              // onMouseEnter={() => {
              //   toggleActiveNav(2);
              //   journalHandler();
              // }}
              className="text-lg cursor-pointer transition-all duration-300 ease-in-out transform  hover:font-bold  hover:scale-110 border-b border-solid border-transparent hover:border-black"
            >
              Journals
            </span>
            {/* </AppLink> */}
          </div>
          <div className="absolute divide-y-2 w-full left-0">
            {journalList && (
              // <JournalListDiv>
              <ul className=" divide-y-2 w-max   shadow-2xl rounded-lg  bg-white text-black text-sm font-medium capitalize mx-auto p-2 text-center">
                {categories?.map((category) => (
                  <li
                    key={category.id}
                    className=" p-2 rounded-lg  rounded-t-none hover:rounded-t-lg  transition-all duration-300 ease-in-out hover:bg-black/50 text-black"
                  >
                    <Link to={`/journals/${category.name.toLowerCase()}`}>
                      {category.name}
                    </Link>
                  </li>
                ))}
              </ul>
              // </JournalListDiv>
            )}
          </div>
        </div>

        <NavButtonMobile to={ELEARN_ROUTE} title="E-learning" />
        {/* {!media520 && ( */}
        <>
          <button
            disabled={path === SUBMIT_ROUTE}
            className={` disabled:cursor-progress disabled:hover:bg-transparent disabled:hover:text-[#46555c] border border-solid border-[#46555c] py-1 px-1.5 rounded-[5px]  transition-colors duration-100 ease-in-out hover:bg-[#46555c] hover:text-white hover:shadow-lg active:shadow-none`}
          >
            <AppLink
              className={`${
                path === HOME_ROUTE ? "  scale-120 " : " scale-90"
              } whitespace-nowrap transform transition-all duration-300 ease-in-out `}
              onClick={() => {
                toggleActiveNav(1);
              }}
              to={SUBMIT_ROUTE}
            >
              Submit Paper
            </AppLink>
          </button>

          <AuthButtonDiv>
            <Link
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center"
              }}
              to={LOGIN_ROUTE}
            >
              <MainBtn>Login</MainBtn>
            </Link>
          </AuthButtonDiv>
        </>
        {/* )} */}
      </MenuContainer>
    </MenuOverlay>
  );
}

export default MobileMenu;
