import React, { useRef, useState } from "react";

import { AiOutlineClose } from "react-icons/ai";
import { AuthButton } from "components/Auth";
import { CONSTANT } from "utils/validation";
import { COURSE } from "api/ACTION";
import Dropdown from "../sharedComponents/DropDown";
import { Modal } from "./style";
import TextInput from "components/TextInput";
import authStore from "mobx/AuthStore";
import jmsApp from "api/jms";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router";

function AddCourse({ open, onClose, setUpdated }) {
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [data, setData] = useState([]);
  const [category, setCategory] = useState("");
  const [type, setType] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [text, setText] = useState("");
  const JournalFileRef = useRef();

  const { navigate } = useNavigate();

  const [values, setValues] = useState({
    title: "",
    type: "",
    category: "",
    resourceUrl: ""
  });
  const [journalFile, setJournalFile] = useState();

  const handleInputChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      // User uploaded a file
      loadFile(event.target.files[0]);
      // setFile(event.target.files[0])
    } else {
      // User entered text
      setText(event.target.value);
    }
  };

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const loadFile = (file) => {
    if (file) {
      const type = file.type;
      if (!CONSTANT.FILE_TYPE.includes(type)) {
        toast.error("Invalid file format");
      } else toast.success("file upload complete");
      values.format = file.type;
      // read the file
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setJournalFile(reader.result);
      };
      // setActive(false)
    }
  };
  const uploadFile = (base64EncodedImage) => {
    values.fileData = base64EncodedImage;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (journalFile) {
        uploadFile(journalFile); // set the file data in the state varible
        // setActive(true)
      }
      const { data } = await toast.promise(
        jmsApp.post(COURSE.CREATE_COURSE(), values),
        {
          loading: "Adding resource...",
          success: "Resource successfully added.",
          error: "Resource addition failed, Please try again."
        }
      );
      if (data.success) {
        // toast.success(data.msg);
        setLoading(false);
        onClose();
        setUpdated(true);
      }
    } catch (error) {
      setLoading(false);
      if (error.response?.status === "401") {
        navigate("/login");
      } else {
        //toast.error(error.mssage)
      }
    }
  };

  if (!open) return null;

  return (
    <Modal>
      <div className="container">
        <div className="relative flex items-center justify-center w-full">
          {/* <div className="header"> */}
          <h4 className="capitalize font-bold text-[#333333] text-[24px] ">
            add resource
          </h4>
          <span className=" absolute top-0 right-0 transform -translate-1/2 mx-6 cursor-pointer transition-colors hover:bg-black/50 duration-300 hover:text-white p-2 rounded-full ">
            <AiOutlineClose className="text-[#333333]" onClick={onClose} />
          </span>
        </div>
        <form onSubmit={handleSubmit} className="w-[70%]">
          <TextInput
            label="Title"
            name="title"
            type="text"
            id="title"
            onChange={handleChange}
            placeholder="Title "
            mt="0.5rem"
          />

          <Dropdown
            label="Select Type Option"
            options={["art", "science"]}
            lblList={["art", "science"]}
            selectedOption={type}
            onSelectOption={(option) => {
              setValues({ ...values, type: option.val });
              setType(option.val);
            }}
          />

          <Dropdown
            label="Select Category Option"
            options={["video", "book"]}
            lblList={["video", "book"]}
            selectedOption={category}
            onSelectOption={(option) => {
              setValues({ ...values, category: option.val });
              setCategory(option.val);
            }}
          />

          {category === "book" && (
            <>
              <input
                ref={JournalFileRef}
                name="type"
                type="file"
                onChange={handleInputChange}
                className=" mt-6"
              />
              <br />
            </>
          )}
          {category === "video" && (
            <>
              <TextInput
                label="ResourceUrl"
                name="resourceUrl"
                type="text"
                id="resourceUrl"
                onChange={handleChange}
                placeholder="resourceUrl"
                mt="0.5rem"
              />
              <br />
            </>
          )}

          <div className=" mt-6">
            <AuthButton
              type="submit"
              disabled={authStore.isLoading ? true : false}
              title="Add"
              loading={loading}
            />
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default AddCourse;
