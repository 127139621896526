
import { GiChurch } from "react-icons/gi";
import { ImBook } from "react-icons/im";
import { RiEnglishInput, RiGovernmentLine } from "react-icons/ri";

export const courseInfoWaec = [
  { type: "art", subject: "Government", icon: <RiGovernmentLine /> },
  { type: "", subject: "English Lang", icon: <RiEnglishInput /> },
  { type: "art", subject: "C.R.K", icon: <GiChurch /> },
  { type: "art", subject: "History", icon: <ImBook /> },

];
export const courseInfoJamb = [
  { type: "art", subject: "Government", icon: <RiGovernmentLine /> },
  { type: "", subject: "English Lang", icon: <RiEnglishInput /> },
  { type: "art", subject: "C.R.K", icon: <GiChurch /> },
  { type: "art", subject: "History", icon: <ImBook /> },
];
