import Colors from "utils/colors";
import media from "utils/media";
import styled from "styled-components/macro";

export const LayoutCont = styled.div`
  display: flex;
  ${media.tablet`flex-direction:column;
	`};

  .kids {
    padding-left: 280px;
    width: 100%;
    min-height: 100vh;
    ${media.smallDesktopMinimum`
	padding-left:80px;
	`};
    ${media.tablet`
	padding-left:0px;
    padding-top:80px;
	`};
  }
`;

export const DashboardDiv = styled.div`
  width: 280px;
  height: 100vh;
  position: fixed;
  bottom: 0;
  background: ${Colors.primary};
  padding: 40px 10px;
  ${media.tablet`
	display:none;
	`};
  ${media.smallDesktopMinimum`
	width:80px;
	`};

  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    ${media.smallDesktopMinimum`
	display:none;
	`};
  }
`;
export const DashboardLinks = styled.div`
  display: flex;
  flex-direction: column;
  margin: 54px 0 0 20px;
  ${media.smallDesktopMinimum`
  margin: unset;
	`};
  .active {
    background: ${Colors.whiteGradient};
    border-radius: 8px;
  }
  .hidden {
    display: none;
  }
  div {
    display: flex;
    gap: 8px;
    justify-content: start;
    align-items: center;
    margin: 12px 12px;
    padding: 8px 6px;
    ${media.smallDesktopMinimum`
gap:unset;
	`};

    span {
      color: ${Colors.white};
      ${media.smallDesktopMinimum`
	display:flex;
  justify-content:center;
  align-items:center;
  color:red;
	`};
    }

    p {
      font-size: 16px;
      color: ${Colors.white};
      display: block;
      ${media.smallDesktopMinimum`
	display:none;

	`};
    }
    :hover {
      background: ${Colors.whiteGradient};
      border-radius: 8px;
      transition: background 0.2s ease-in-out;
    }
  }
`;
export const MobileDashboardDiv = styled.div`
  display: none;
  ${media.tablet`
	display:block;
	`};
`;
export const MobileDashboardCont = styled.div`
  z-index: 1000;
  width: 100%;
  height: 80px;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${Colors.primary};
  padding: 10px 26px;
  .menu {
    color: ${Colors.whiteGradient};
  }
  .links {
    height: 100vh;
    display: flex;
    flex-direction: column;
    padding: 30px 0 0 20px;
    position: fixed;
    top: 80px;
    left: 0;
    width: 280px;
    right: 0;
    background: ${Colors.primary};

    div {
      display: flex;
      gap: 8px;
      justify-content: start;
      align-items: center;
      margin: 12px 12px;
      padding: 8px 6px;
      transition: all 0.3s ease-in-out;

      span {
        color: ${Colors.white};
      }

      p {
        font-size: 16px;
        color: ${Colors.white};
        display: block;
      }
      :hover {
        background: ${Colors.whiteGradient};
        border-radius: 8px;
        transition: background 0.2s ease-in-out;
      }
    }
    .active {
      background: ${Colors.whiteGradient};
      border-radius: 8px;
    }
  }
`;
