import * as Yup from "yup";

import { AuthBottomLink, AuthButton, AuthHeader } from "..";
import {
  AuthCard,
  AuthContent,
  Authcontainer,
  EmailCreateText,
  LoginContainer,
  LoginDiv,
  LoginFormContainer
} from "../style";
import { LOGIN_ROUTE, VERIFY_OTP_ROUTE } from "routes";
import { Link, useNavigate } from "react-router-dom";

import { AUTH_ENDPOINTS } from "api/ACTION";
import { AppLogo } from "utils/assets";
import { Img } from "globalStyles";
import React from "react";
import TextInput from "components/TextInput";
import authStore from "mobx/AuthStore";
import bg from "../../../assets/images/banner2.jpg";
import jmsApp from "api/jms";
import { toast } from "react-hot-toast";
import { useFormik } from "formik";
import { useState } from "react";

function SignUpComp() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    enableReinitialize: false,
    initialValues: {
      name: "",
      username: "",
      email: "",
      password: "",
      confirm_password: ""
    },

    onSubmit: async (info, helpers) => {
      setLoading(true);

      try {
        const { data } = await toast.promise(
          jmsApp.post(AUTH_ENDPOINTS.REGISTER(), info),
          {
            loading: "Please wait while we get you registered...",
            success: "You have been successfully registered",
            error: (err) => `${err.response.data.error}`
          }
        );
        setLoading(false);
        if (data.success) {
          authStore.setCurrentStep("verify");

          authStore.setUserEmail(data.tempAccount.email);

          localStorage.setItem(
            "tempAccountEmail",
            JSON.stringify(data.tempAccount.email)
          );

          authStore.setInitialAuth(data.tempAccount.token);

          authStore.loading();
          helpers.resetForm();

          window.location.replace(`${VERIFY_OTP_ROUTE}`);
          // navigate(`${VERIFY_OTP_ROUTE}`);
        } else if (
          data.success === false &&
          data.msg.toLowerCase().includes("incomplete")
        ) {
          if (data.data) {
            toast.success("You are being redirected.");
            authStore.setUserEmail(data.data.email);
            localStorage.setItem(
              "tempAccountEmail",
              JSON.stringify(data.data.email)
            );
            authStore.setInitialAuth(data.data.token);
            helpers.resetForm();
            navigate(`${VERIFY_OTP_ROUTE}`);
          } else {
            throw new Error("Sign up failed");
          }
        } else {
          throw new Error("Sign up failed");
        }
      } catch (err) {
        setLoading(false);
        if (err.response.data) {
          // toast.error(err.response.data.error);
        }
      }
    },

    validationSchema: Yup.object({
      name: Yup.string().max(50).required().label("Full Name"),
      username: Yup.string().max(50).required().label("Username"),
      email: Yup.string().max(255).email().required().label("Email"),
      password: Yup.string()
        .min(8)
        .required("Password is required.")
        .label("Password"),
      confirm_password: Yup.string()
        .oneOf(
          [Yup.ref("password"), null],
          "Confirm Password must match Password"
        )
        .required("Confirm Password is required.")
    }).label("Confirm Password")
  });
  return (
    <Authcontainer style={{ backgroundImage: `url(${bg})` }}>
      <AuthContent>
        <Link to="/">
          <Img src={AppLogo} alt="LP" />
        </Link>
        <LoginContainer>
          <LoginDiv onSubmit={formik.handleSubmit}>
            <AuthCard>
              <LoginFormContainer>
                <AuthHeader text="Create an Account" />
                <EmailCreateText>
                  {" "}
                  We will send a one time password to this email address{" "}
                </EmailCreateText>
                <TextInput
                  label="Full Name"
                  name="name"
                  id="name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                  error={formik.errors.name}
                  visible={formik.touched.name}
                  placeholder="Enter your full name"
                />
                <TextInput
                  label="Username"
                  name="username"
                  id="username"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.username}
                  error={formik.errors.username}
                  visible={formik.touched.username}
                  placeholder="Proviade a username"
                  mt="0.5rem"
                />
                <TextInput
                  label="Email Address"
                  name="email"
                  type="email"
                  id="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  error={formik.errors.email}
                  visible={formik.touched.email}
                  placeholder="Email Address"
                  mt="0.5rem"
                />
                <TextInput
                  label="Password"
                  name="password"
                  type="password"
                  id="password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  error={formik.errors.password}
                  visible={formik.touched.password}
                  placeholder="Password"
                  mt="0.5rem"
                />
                <TextInput
                  label="Confirm Password"
                  name="confirm_password"
                  type="password"
                  id="confirm_password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.confirm_password}
                  error={formik.errors.confirm_password}
                  visible={formik.touched.confirm_password}
                  placeholder="Confirm Password"
                />
                <AuthButton
                  type="submit"
                  disabled={authStore.isLoading ? true : false}
                  title="Submit"
                  loading={loading}
                />
                <AuthBottomLink link={LOGIN_ROUTE} />
              </LoginFormContainer>
            </AuthCard>
          </LoginDiv>
        </LoginContainer>
      </AuthContent>
    </Authcontainer>
  );
}

export default SignUpComp;
