import { BiArrowBack, BiLandscape } from "react-icons/bi";
import { FaChartBar, FaNewspaper } from "react-icons/fa";
import React, { useEffect, useState } from "react";

import { ARTICLE_ENDPOINT } from "api/ACTION";
import ArticlesSort from "./side-bar/articles-sort";
import { ClipLoader } from "react-spinners";
import { IconSize } from "components/Admin/Articles/Articles.style";
import { Link } from "react-router-dom";
import { MdOutlineArticle } from "react-icons/md";
import ViewArticle from "./Article Section/view-article";
import { convertUTCToLocalTime } from "utils/utc-to-date";
import jmsApp from "api/jms";
import landingPageGg1 from "../../../src/assets/images/banner.jpg";
import landingPageGg2 from "../../../src/assets/images/banner2.jpg";
import { override } from "utils/admin-roles";
import styled from "styled-components";
import tw from "twin.macro";

// import BlogSection from "./Blog Section";

// import ArticleSection from "./Article Section";

// import HeroComp from './Hero Section'

const HomeComp = () => {
  const [isFetchingArticles, setIsFetchingArticles] = useState(false);
  const [articles, setArticles] = useState([]);
  const getAllArticles = async () => {
    try {
      setIsFetchingArticles(true);

      const { data } = await jmsApp.get(ARTICLE_ENDPOINT.ARTICLE_PUBLISHED());

      if (data.success) {
        // console.log(data);
        setArticles(data.article);
      }
    } catch (err) {
      // console.log(err);
    } finally {
      setIsFetchingArticles(false);
    }
  };

  useEffect(() => {
    getAllArticles();
  }, []);

  return (
    <>
      <HeroComp />
      <div className=" flex flex-col  gap-6 xl:gap-0 xl:grid grid-cols-2 place-items-start w-full">
        <ArticleSection
          isFetchingArticles={isFetchingArticles}
          articles={articles}
          getAllArticles={getAllArticles}
        />
        <BlogSection articles={articles} />
      </div>
    </>
  );
};

export default HomeComp;
function HeroComp() {
  const images = [landingPageGg1, landingPageGg2];
  const [activeImage, setActiveImage] = useState(0);
  const [countDown, setCountDown] = useState(10000);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCountDown((prevCount) => prevCount - 1000); // decrement countDown by 1000 milliseconds
    }, 1000);

    return () => clearInterval(intervalId); // cleanup function to clear the interval
  }, []);

  useEffect(() => {
    if (countDown === 0) {
      setActiveImage((prevImage) => (prevImage === 0 ? 1 : 0));
      setCountDown(10000);
    }
  }, [countDown]);

  return (
    <div className="mb-6 w-full flex flex-col items-center justify-start xl:grid  grid-cols-[40%_60%] xl:h-[300px]">
      <div className="w-full h-full bg-red-900 p-10 flex items-center flex-col justify-center gap-4 text-white">
        <div className="w-full text-start">ABOUT THE JOURNAL</div>
        <div className="w-full h-[2px] bg-white"></div>
        <div>
          The journal is the official journal of{" "}
          <strong>Learning Publics</strong>. It provides a platform for
          scholarly exchange between family medicine and primary health care
          researchers and practitioners across Africa. It provides a contextual
          and holistic view of family medicine and primary health care as
          practiced across the continent.
        </div>
      </div>
      <div className="w-full h-full  relative  items-center justify-center object-contain hidden xl:flex">
        <img
          src={images[0]}
          alt="Learning Publics"
          className={` absolute top-0 left-0 w-full h-full object-cover object-top ${activeImage === 0 ? "" : "hidden"}`}
        />
        <img
          src={images[1]}
          s
          alt="Learning Publics"
          className={`absolute top-0 left-0 w-full h-full object-cover object-top ${activeImage === 1 ? "" : "hidden"}`}
        />
        <div className=" absolute flex items-center justify-center gap-4 py-6 bottom-0 left-0 w-full z-20 ">
          {images.map((image, idx) => (
            <span
              key={idx}
              className={`w-[10px] h-[10px] cursor-pointer rounded-full  shadow-xl hover:bg-blue-600 ${activeImage === idx ? " bg-blue-600" : "bg-white"}`}
              onClick={() => setActiveImage(idx)}
            >
              &nbsp;
            </span>
          ))}
        </div>
      </div>
    </div>
  );
}
// export const testArticles = [
//   {
//     title:
//       "Understanding of ‘generalist medical practice’ in South African medical schools",
//     author: "Langalibalele H. Mabuza, Mosa Moshabela",
//     date: "08 March 2024",
//     type: "Original Research"
//   },
//   {
//     title:
//       "IDEAL: Maintaining PHC-focused training in a MBChB programme through a COVID-induced innovation",
//     author:
//       "Nondumiso B.Q. Ncube, Tawanda Chivese, Ferdinand C. Mukumbang, Hazel A. Bradley, Helen Schneider, Richard Laing",
//     date: "08 March 2024",
//     type: "Review Article"
//   },
//   {
//     title:
//       "Views of Nigerian civil servants about compulsory COVID-19 vaccination: A qualitative study",
//     author: "Ian Couper, Julia Blitz, Therese Fish",
//     date: "08 March 2024",
//     type: "Original Research"
//   },
//   {
//     title:
//       "Mental health impact of COVID-19 on healthcare workers versus adults in Africa",
//     author: "Thandi M. Dlamini, Siyabonga Dlamini",
//     date: "08 March 2024",
//     type: "Original Research"
//   }
// ];
function ArticleSection({ isFetchingArticles, articles = [],getAllArticles }) {
  const [viewArticle, setViewArticle] = useState(null);
  if (isFetchingArticles) {
    return (
      <div className=" w-full h-full flex  items-center justify-center">
        <ClipLoader
          color="#46555C"
          loading={isFetchingArticles}
          cssOverride={override}
          size={30}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    );
  }
  if (articles.length === 0) {
    return (
      <div className=" w-full h-full flex  items-center justify-center flex-col gap-6">
        <p className=" text-lg font-bold text-blue-800 uppercase ">
          No articles found
        </p>
        <button
          onClick={() => getAllArticles()}
          className=" p-2 capitalize text-xs font-bold border-blue-600 border border-solid rounded-lg text-blue-600 hover:text-white hover:bg-blue-600 transition-all duration-300 ease-in-out"
        >
          Retry
        </button>
      </div>
    );
  }
  // console.log(articles);
  return (
    <div className="p-4 w-full">
      <div className="w-full flex items-center justify-start gap-2 text-xl font-bold text-[#424242]">
        <MdOutlineArticle />
        <p>Latest articles</p>
      </div>
      <div className=" divide-y-2 w-full flex flex-col items-start justify-center gap-4">
        {articles.map((article, idx) => {
          return (
            <div
              key={idx}
              className=" flex flex-col items-start justify-start transition-all duration-300 ease-in-out hover:shadow-lg w-full px-2 rounded-md  pt-4 capitalize cursor-pointer"
              onClick={() =>
                setViewArticle({
                  url: article.articleUrl,
                  title: article.title,
                  author: article.author
                })
              }
            >
              <p className=" text-red-300 text-xs">
                {article.status} | {article.country}
              </p>
              <p className="text-[#21406e] hover:underline ">{article.title}</p>
              <p className="text-[#21406e] hover:underline  ">
                {article.description}
              </p>
              <p className=" text-[#6D6E71]">{article.author}</p>

              <p className=" text-[#424242]">
                {convertUTCToLocalTime(article.createdAt)}
              </p>
            </div>
          );
        })}
      </div>
      {viewArticle && (
        <div className="fixed z-[999] top-0 left-0 w-full h-screen overflow-y-auto bg-black/50 backdrop-blur-lg grid grid-rows-[max-content_1fr]">
          <div className=" text-black/80 flex w-full items-center justify-center mx-auto h-20 bg-white ">
            <IconSize>
              <div
                className=" cursor-pointer text-[12px]  sm:text-lg lg:text-xl border border-solid rounded-full transition-all duration-300 ease-in-out bg-black/70 text-white hover:shadow-sm absolute left-0 top-0 transform p-3 translate-x-1/2 translate-y-1/2"
                onClick={() => setViewArticle(null)}
              >
                <BiArrowBack />
              </div>
            </IconSize>
            <div className=" flex flex-col items-center w-full justify-center py-3">
              <p className=" font-bold text-xl text-center">
                Author: {viewArticle.author}
              </p>
              <p className=" font-bold text-xl text-center">
                Title: {viewArticle.title}
              </p>
            </div>
          </div>
          <ViewArticle data={viewArticle} close={() => setViewArticle(null)} />
        </div>
      )}
    </div>
  );
}
function BlogSection({ articles }) {
  return (
    <div className=" flex flex-col gap-6 items-start justify-start divide-y-2 pr-4">
      <div className=" flex flex-col items-start justify-start gap-2">
        <div className=" flex items-center justify-start gap-3 uppercase text-sm xxs:text-lg w-full ">
          <FaChartBar />
          <span>FAST FACTS 2018-2023 STATISTICS (UPDATED DAILY)</span>
        </div>
        <div className=" flex items-center flex-col xxs:flex-row justify-start gap-6 text-white w-full uppercase">
          <div className=" flex flex-col bg-red-900 py-[0.5px] w-[150px]  lg:w-[250px] rounded-lg items-center justify-center ">
            <span className=" text-4xl">{articles.length + 56}</span>
            <span className="text-sm">published</span>
            <span className="text-sm">content</span>
          </div>
          <div className=" flex flex-col bg-red-900 py-[0.5px]  w-[150px]  lg:w-[250px] rounded-lg items-center justify-center ">
            <h1 className=" text-4xl">{articles.length * 3 + 90}</h1>
            <span className="text-sm">total</span>
            <span className="text-sm">downloads</span>
          </div>
        </div>
      </div>
      <div className=" flex flex-col items-start justify-start gap-4 pt-6 bg-gray-200 p-2">
        <div className=" flex items-center justify-start uppercase font-bold text-xl gap-3">
          <FaNewspaper />
          <p>announcement</p>
        </div>
        <div className=" flex flex-col items-start justify-start gap-4">
          <p className=" text-start capitalize font-bold text-red-400">
            other programs
          </p>
          <p>
            Prepare for success in WAEC and JAMB with our tailored courses.
            Covering a range of subjects, our comprehensive platform offers
            interactive lessons, practice tests, and personalized feedback to
            build confidence and competence for both exams.
          </p>
          <p>Prepare for WAEC and JAMB by utilizing our courses</p>
          <BlogLink to="/elearn" className="w-fit whitespace-nowrap">
            Visit&nbsp;our&nbsp;e-learning&nbsp;portal
          </BlogLink>
        </div>
      </div>
    </div>
  );
}
const BlogLink = styled(Link)`
  display: inline-block;
  /* // padding: 10px 20px; */
  background-color: #3498db;
  /* // color: #fff; */
  text-decoration: none;
  border-radius: 5px;
  /* // font-weight: bold; */
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #297fb8;
  }

  ${tw`px-4 py-2 rounded-md text-white font-bold text-xs sm:text-sm`}
`;
