import VerifyRecovery from "components/Auth/VerifyPassword";
import React from "react";

function VerifyRecoveryPg() {
  return (
    <div>
      <VerifyRecovery />
    </div>
  );
}

export default VerifyRecoveryPg;
