import media from "utils/media";
import styled from "styled-components";
import tw from "twin.macro";

export const ArticleCon = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  ${tw`px-6 sm:px-12 my-4 gap-4 sm:gap-8 mx-auto flex-wrap items-center`}

  ${media.mobile`
  flex-direction: column;
  /* New style */
 
  `}
`;
export const ArtCardCon = styled.div`
  width: 240px;
  height: 160px;
  background: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  border-top: 3px solid red;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  /* New style */
  ${tw` transition duration-300 ease-in-out `}

  position: relative;

  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  }

  &:after {
    content: "";
    position: absolute;
    top: 80%;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 60%;
    height: 1px;
    background-color: #ff0000;
  }
`;

export const CardPara = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 200%;
  font-family: "Roboto Slab", sans-serif;
  text-align: center;
  color: #46555c;
`;
export const ArticleCardCon = styled.div`
  display: flex;
  width: 100%;
  // padding-top : 20px;
  /* padding: 25px 8.5% 40px 8.5%; */
  // padding: 0 30px;

  ${tw`whitespace-pre overflow-auto  p-4 sm:p-6 `}
  // align-items: center;
  // padding-left: 8.5%;
// border : 2px solid red;
  ${media.mobile`
  // flex-direction: column;
  // padding-left: 0%;
  // padding: 40px 8.5%;
  `}
`;

export const ArticleCardContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  // margin: 0 auto;
  overflow-x: hidden;
  ${tw`px-6 sm:px-12 py-4 sm:py-6  h-fit mx-auto self-center`}
`;
export const ArtCardLeft = styled.div`
  //
  // padding: 20px 20px;
  /* border : 2px solid #D86D5E; */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  // ${media.mobile`
  // width: 100%;
  // `}
  &:hover {
    // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Deep gray box shadow on hover */
  }
`;
export const ArtCardRight = styled.div`
  // width: 50%;
  // padding: 20px 0px;
  // box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  // ${media.mobile`
  // width: 100%;
  // `}
  //  &:hover {
  //   box-shadow: 0 4px 5px rgba(0, 0, 0, 0.2); /* Deep gray box shadow on hover */
  // }
`;

export const ArticlesSectionArticleCard = styled.div`
  ${tw`w-40 sm:w-60 h-60 sm:h-96  mx-0 shadow-md rounded-lg overflow-hidden flex flex-col justify-center items-center`}
`;

export const ArtHead = styled.h1`
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 50px;
  color: #46555c;

  ${tw`sm:px-4 text-center sm:text-center py-4`}
  ${media.mobile`
 
  font-size: 30px;
  line-height: 40px;
  `}
`;
export const ArtSubHead = styled.h1`
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  text-decoration: underline;
  color: #d86d5e;
  padding-top: 20px;
`;
export const ArtAuthor = styled.h1`
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #46555c;
`;
export const ArtBody = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #89939e;

  ${tw`sm:text-base text-sm leading-normal truncate`}
`;

export const ArtHeadCon = styled.div`
  // padding-left: 8.5%;
  // padding: 25px 0 25px 8.5%;

  ${tw`px-4 sm:px-12 sm:py-2 text-center `}
`;
export const ArtCardsDiv = styled.div`
  width: 100%;
  display: flex;
  padding: 10px;
  flex-direction: column;
`;
