import { AiOutlineClockCircle, AiOutlineNotification } from "react-icons/ai";

import { BsGraphUp } from "react-icons/bs";
import Colors from "utils/colors";
import { FaCalendarAlt } from "react-icons/fa";
import { FiHelpCircle } from "react-icons/fi";
import { HiOutlineClock } from "react-icons/hi";
import styled from "styled-components/macro";
import tw from "twin.macro";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  width: 100%;

  .flex {
    display: flex;
  }

  .progress {
    /* // background: red; */
    width: 100%;
    padding: 20px;
    margin: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
`;

export const CardContainer = styled.div`
  width: 100%;
  margin-bottom: 20px;
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  padding: 50px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  /* align-items: center; */

  .text {
    margin-bottom: 10px;
    font-weight: 500;
  }

  .innerCon {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    ${tw`gap-4 sm:gap-8`}

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
    }
  }

  button {
    margin-top: 20px;
    padding: 6px 10px;
    text-align: center;
    align-self: end;
    border: 1px solid #9c4df4;
    border-radius: 5px;
    width: fit-content;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    color: #9c4df4;
    cursor: pointer;
    background: unset;
    :hover {
      background: #9c4df4;
      color: #ffffff;
    }
  }
`;

export const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  padding: 20px;
  border-radius: 8px;
  /* width: 200px; */
  overflow: hidden;

  @media (max-width: 768px) {
    /* width: 100%; */
    margin-bottom: 20px;
  }
`;

export const Title = styled.h2`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
`;

export const Value = styled.p`
  font-size: 20px;
  width: 80%;
  font-weight: bold;
  margin-bottom: 10px;
`;

export const Subtitle = styled.p`
  font-size: 16px;
  color: #666;
`;

export const ProgressIcon = styled(AiOutlineClockCircle)`
  font-size: 50px;
  color: #0080ff;
  margin-bottom: 10px;
`;

export const NotificationIcon = styled(AiOutlineNotification)`
  font-size: 50px;
  color: #ffbf00;
  margin-bottom: 10px;
`;

export const SupportIcon = styled(FiHelpCircle)`
  font-size: 50px;
  color: #33cc33;
  margin-bottom: 10px;
`;

export const WelcomeCard = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 150px;
  margin-bottom: 20px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  /* // background-color: ${Colors.primary}; */
  color: white;
  border-radius: 10px;
`;

export const CourseCon = styled.div`
  margin: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const WelcomeMessage = styled.p`
  font-size: 20px;
  text-align: center;
`;

export const CourseList = styled.div`
  display: flex;
  gap: 20px;
`;

export const CourseCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100px;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
`;

export const CourseTitle = styled.p`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
`;

export const CourseDuration = styled.p`
  font-size: 14px;
  display: flex;
  align-items: center;
  color: #666;
`;

export const ClockIcon = styled(HiOutlineClock)`
  margin-right: 5px;
`;

export const Stats = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const Stat = styled.div`
  display: flex;
  align-items: center;
`;

export const StatIcon = styled(BsGraphUp)`
  margin-right: 5px;
`;

export const StatLabel = styled.p`
  font-size: 14px;
  margin-right: 10px;
`;

export const StatValue = styled.p`
  font-size: 16px;
  font-weight: bold;
`;

export const Container2 = styled.div`
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
`;

export const Calendar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

export const CalendarIcon = styled(FaCalendarAlt)`
  font-size: 48px;
  color: #666;
  margin-bottom: 20px;
`;

export const CalendarTitle = styled.p`
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
`;

export const CalendarItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

export const CalendarDate = styled.p`
  font-size: 16px;
  color: #666;
  margin-right: 20px;
`;

export const CalendarEvent = styled.p`
  font-size: 16px;
  color: #333;
`;
