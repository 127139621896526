import { FiDownload, FiEye } from "react-icons/fi";
import { Fragment, useEffect, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import {
  handleArticlePublished,
  handleArticleReview
} from "../Dashboard/admin-article-count";

import { ADMIN_ROLES } from "utils/admin-roles";
import { ARTICLE_ENDPOINT } from "api/ACTION";
import { AiFillDelete } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import Checkbox from "../sharedComponents/CheckBox";
import { IoClose } from "react-icons/io5";
import { LOCAL_STORAGE } from "api/LOCALSTORAGE";
import { MdAssignment } from "react-icons/md";
import { NO_OF_ITEMS_PER_PAGE } from "utils";
import { TextArea } from "components/TextArea/style";
import Tooltip from "utils/tooltip";
import { dateStringToSeconds } from "utils/date-string-to-seconds";
import jmsApp from "api/jms";
import styled from "styled-components";
import { toast } from "react-hot-toast";
import tw from "twin.macro";

// import toast from "react-hot-toast";
const Button = styled.button`
  padding: 10px 20px;
  background-color: ${(props) =>
    props.isSubmitComplete ? "green" : "blue"} !important;
  color: white;
  font-size: 16px;
  margin: 4px;
  border: none;
  cursor: pointer;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
  box-shadow: none;
`;

const StyledSpan = styled.span`
  background-color: ${(props) => props.color};
  ${tw`w-full text-center mx-auto  inline-block uppercase px-6 py-2 rounded-lg whitespace-nowrap text-sm font-medium text-white`}
`;

const activeStatus = [
  "review",
  "approved",
  "published",
  "rejected",
  "pending",
  "reviewed",
  "resubmit"
];

function ArticlesTable({
  data,

  columns,
  user,
  actions = [],
  hasStatus = false,
  handleUpdateData,
  isPagination = false
}) {
  const savedArticle = LOCAL_STORAGE.articleId() || null;
  // const savedArticle = localStorage.getItem("articleId") || null;
  const currentUser = LOCAL_STORAGE.user() || null;
  const [commentActive, setCommentActive] = useState(false);
  const [isSubmitComplete, setIsSubmitComplete] = useState(false);
  const [checked, setChecked] = useState(savedArticle);
  const [rows, setRows] = useState(2);
  const [adminComment, setAdminComment] = useState("");
  const [adminCommentWithStatusData, setAdminCommentWithStatusData] =
    useState(null);
  // PAGINATION
  const [currentPage, setCurrentPage] = useState(1);

  const paginatedData = isPagination
    ? [...data].slice(
        (currentPage - 1) * NO_OF_ITEMS_PER_PAGE,
        (currentPage - 1) * NO_OF_ITEMS_PER_PAGE + NO_OF_ITEMS_PER_PAGE
      )
    : [...data];
  // console.log(currentPage, data, paginatedData);
  useEffect(() => {
    if (savedArticle === null && checked !== null) {
      setChecked(null);
    }
  }, [savedArticle, checked]);
  const TableActions = {
    edit: ActionEdit,
    download: ActionDownload,
    delete: ActionDelete,
    view: ActionView,
    assign: ActionAssign
  };

  const handleStatusWithComment = async () => {
    if (!adminCommentWithStatusData) return;
    const { articleId, status, id } = adminCommentWithStatusData;
    try {
      const { data: updateData } = await jmsApp.patch(
        ARTICLE_ENDPOINT.ARTICLE_STATUS(),
        {
          articleId: articleId,
          status: status,
          comment: adminComment
        }
      );

      if (updateData.success) {
        const newDeets = [...data];
        const newDataIdx = newDeets.findIndex((item, idx) => idx === id);

        if (newDataIdx >= 0) {
          newDeets[newDataIdx].status = status;
        }

        handleUpdateData([...newDeets]);
        await handleArticlePublished();
        await handleArticleReview();
        setIsSubmitComplete(true);
        setCommentActive(false);
        // defaultStat.splice(id, 1, status);
        // setdefaultStat([...defaultStat]);
      }
    } catch (error) {
      if (error.response) toast.error(error.response.data.error);
    }
  };
  const handleStatus = async (articleId, currentStatus, status, id) => {
    if (currentStatus.toLowerCase() !== "approved" && status === "published") {
      toast.error("Article has not been approved yet");
      return;
    }
    if (
      status === "resubmit" &&
      currentUser?.type !== ADMIN_ROLES.SUPER_ADMIN
    ) {
      toast.error("You are not Authorized.");
      return;
    } else if (
      status === "resubmit" &&
      currentUser?.type === ADMIN_ROLES.SUPER_ADMIN
    ) {
      setCommentActive(true);
      setAdminCommentWithStatusData({ articleId, currentStatus, status, id });
      return;
    }

    try {
      const { data: updateData } = await toast.promise(
        jmsApp.patch(ARTICLE_ENDPOINT.ARTICLE_STATUS(), {
          articleId: articleId,
          status: status
        }),
        {
          loading: `Setting article status to ${status}`,
          success: `Article status set to ${status}`,
          error: (err) =>
            err.response.data.error ||
            "Error during status change, please try again later..."
        }
      );

      if (updateData.success) {
        const newDeets = [...data];
        const newDataIdx = newDeets.findIndex((item, idx) => idx === id);

        if (newDataIdx >= 0) {
          newDeets[newDataIdx].status = status;
        }

        handleUpdateData([...newDeets]);

        // defaultStat.splice(id, 1, status);
        // setdefaultStat([...defaultStat]);
      }
    } catch (error) {
      if (error.response) toast.error(error.response.data.error);
    }
  };

  function ActionView({ action, item }) {
    return (
      <Tooltip text="view">
        <button onClick={() => action(item)}>
          <FiEye size={25} />
        </button>
      </Tooltip>
    );
  }
  function ActionDownload({ action, item }) {
    return (
      <Tooltip text="download">
        <button
          onClick={() => action(item)}
          disabled={item.status === "pending" || item.status === "rejected"}
          className=" disabled:cursor-not-allowed"
        >
          <FiDownload size={25} />
        </button>
      </Tooltip>
    );
  }
  function ActionDelete({ action, item }) {
    return (
      <Tooltip text="delete">
        <button onClick={() => action(item)}>
          <AiFillDelete size={25} />
        </button>
      </Tooltip>
    );
  }
  function ActionEdit({ action, item }) {
    return (
      <Tooltip text="edit">
        <button onClick={() => action(item)}>
          <BiEdit size={25} />
        </button>
      </Tooltip>
    );
  }
  function ActionAssign({ action, item }) {
    if (user !== ADMIN_ROLES.SUPER_ADMIN) return;
    return (
      <Tooltip text="assign">
        <button
          className="hs-tooltip-toggle flex w-full items-center justify-center h-full"
          onClick={() => action(item)}
        >
          <MdAssignment size={25} />
        </button>
      </Tooltip>
    );
  }
  function RenderAction({ actionType, action, item, key }) {
    const ActionComponent = TableActions[actionType];

    if (ActionComponent) {
      return <ActionComponent action={action} item={item} key={key} />;
    } else {
      return null; // or render a default action or handle unknown action types
    }
  }
  // const statusColors = {
  //   default: "rgb(129 140 248)",
  //   pending: "rgb(167 139 250)",
  //   review: "rgb(254 240 138)",
  //   approved: "rgb(96 165 250)",
  //   published: "rgb(74 222 128)",
  //   rejected: "rgb(248 113 113)",
  //   assigned: "rgb(132 204 87)"
  // };
  const statusColors = {
    default: "rgb(158, 158, 158)", // Gray
    pending: "rgb(255, 193, 7)", // Yellow
    review: "rgb(33, 150, 243)", // Blue
    rejected: "rgb(244, 67, 54)", // Red
    approved: "rgb(76, 175, 80)", // Green
    published: "rgb(56, 142, 60)", // Dark Green
    assigned: "rgb(132, 204, 87)", // Light Green
    reviewed: "rgb(103, 58, 183)", // Purple
    formatted: "rgb(255, 87, 34)", // Orange
    resubmit: "rgb(233, 30, 99)", // Pink
    resubmitted: "rgb(133, 30, 99)" // Pink
  };

  //   ! Table functionalities
  function handleCheckVal(id) {
    if (checked === id) {
      setChecked(null);
      localStorage.setItem("articleId", JSON.stringify(null));
    } else {
      setChecked(id);
      localStorage.setItem("articleId", JSON.stringify(id));
    }
  }

  function statusPositionDown(idx) {
    if (paginatedData.length < 4) {
      return null;
    } else {
      const half = paginatedData.length / 2;
      return idx < half;
    }
  }
  return (
    <>
      <div className="flex flex-col">
        <div className="-m-1.5 overflow-x-auto w-full">
          <div className="p-1.5 sm:p-6 min-w-full inline-block align-middle">
            <div className="overflow-hidden">
              <table className="relative overflow-hidden min-w-full divide-y  divide-gray-600 ">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-end text-xs font-medium text-transparent uppercase"
                    >
                      checkbox
                    </th>
                    {columns.map((column) => {
                      if (
                        user !== ADMIN_ROLES.SUPER_ADMIN &&
                        column.accessor === "email"
                      ) {
                        return null;
                      }
                      return (
                        <th
                          key={column.accessor}
                          scope="col"
                          className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase"
                        >
                          {column.Header}
                        </th>
                      );
                    })}

                    {actions.length > 0 && (
                      <th
                        scope="col"
                        className="px-6 py-3 text-end text-xs font-medium text-gray-500 uppercase"
                      >
                        action
                      </th>
                    )}
                  </tr>
                </thead>

                <tbody>
                  {paginatedData
                    .sort(
                      (a, b) =>
                        dateStringToSeconds(a.createdAt) -
                        dateStringToSeconds(b.createdAt)
                    )
                    .map((item, dataIdx) => (
                      <tr
                        key={`${dataIdx}--%`}
                        // className="odd:bg-white even:bg-gray-100 hover:bg-gray-100 "
                        className="odd:bg-white even:bg-gray-100 hover:bg-gray-100 "
                      >
                        <td className="uppercase px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800   ">
                          <Checkbox
                            onClick={() => handleCheckVal(item.articleId)}
                            checked={checked === item.articleId}
                          />
                        </td>
                        {columns.map((col, idx) => {
                          if (
                            user !== ADMIN_ROLES.SUPER_ADMIN &&
                            col.accessor === "email"
                          ) {
                            return null;
                          } else if (item.status) {
                            return (
                              <td
                                key={`${idx}-${item.status || "nil"}`}
                                className="relative  px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800  "
                              >
                                {col.accessor === "status" ? (
                                  <Menu
                                    as="div"
                                    className="uppercase inline-block text-left  "
                                  >
                                    <div>
                                      <Menu.Button
                                        disabled={
                                          user !== ADMIN_ROLES.SUPER_ADMIN
                                        }
                                        className="inline-flex w-full justify-center rounded-md  px-4 py-2 text-sm font-medium text-white  focus:outline-none"
                                      >
                                        <StyledSpan
                                          color={
                                            statusColors[
                                              `${item.status}`.toLowerCase()
                                            ]
                                          }
                                        >
                                          {Array.isArray(item[col.accessor])
                                            ? item[col.accessor].length
                                            : item[col.accessor] || "Nil"}
                                        </StyledSpan>
                                      </Menu.Button>
                                    </div>

                                    <Transition
                                      as={Fragment}
                                      enter="transition ease-out duration-100"
                                      enterFrom="transform opacity-0 scale-95"
                                      enterTo="transform opacity-100 scale-100"
                                      leave="transition ease-in duration-75"
                                      leaveFrom="transform opacity-100 scale-100"
                                      leaveTo="transform opacity-0 scale-95"
                                    >
                                      <Menu.Items
                                        className={`absolute z-50 px-2 py-2 mt-2 origin-top-right divide-y divide-gray-100 min-h-[4rem] rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none transform ${
                                          statusPositionDown(dataIdx) === null
                                            ? "-translate-y-[100%]"
                                            : statusPositionDown(dataIdx)
                                              ? ""
                                              : "-translate-y-[140%]"
                                        } `}
                                      >
                                        <div
                                          className={`"px-1 py-1 flex flex-col items-center justify-start gap-2 overflow-y-scroll  ${paginatedData.length < 3 ? "h-[6rem]" : "h-[10rem]"}`}
                                        >
                                          {activeStatus.map((status, idx) => {
                                            // if (status === "reviewed")
                                            //   return null;
                                            return (
                                              <Menu.Item
                                                key={`${idx}--status`}
                                                id={status}
                                                onClick={() => {
                                                  handleStatus(
                                                    item.articleId,
                                                    item.status,
                                                    status,
                                                    dataIdx
                                                  );
                                                }}
                                              >
                                                {({ active }) => {
                                                  return (
                                                    <button className="bg-transparent">
                                                      {" "}
                                                      {active ? (
                                                        <StyledSpan
                                                          color={
                                                            statusColors[
                                                              `${status}`.toLowerCase()
                                                            ]
                                                          }
                                                        >
                                                          {/* {status ===
                                                          "published"
                                                            ? status
                                                            : "publish"} */}
                                                          {status ===
                                                          "published"
                                                            ? "publish"
                                                            : status ===
                                                                "approved"
                                                              ? "approve"
                                                              : status ===
                                                                  "rejected"
                                                                ? "reject"
                                                                : status}
                                                        </StyledSpan>
                                                      ) : (
                                                        <span
                                                          className="text-gray-900 block px-6 py-2 text-sm uppercase bg-clip-text text-transparent drop-shadow-sm"
                                                          style={{
                                                            backgroundColor:
                                                              statusColors[
                                                                `${status}`.toLowerCase()
                                                              ]
                                                          }}
                                                        >
                                                          {status ===
                                                          "published"
                                                            ? "publish"
                                                            : status ===
                                                                "approved"
                                                              ? "approve"
                                                              : status ===
                                                                  "rejected"
                                                                ? "reject"
                                                                : status}
                                                        </span>
                                                      )}
                                                    </button>
                                                  );
                                                }}
                                              </Menu.Item>
                                            );
                                          })}
                                        </div>
                                      </Menu.Items>
                                    </Transition>
                                  </Menu>
                                ) : Array.isArray(item[col.accessor]) ? (
                                  item[col.accessor].length
                                ) : (
                                  item[col.accessor] || "Nil"
                                )}
                              </td>
                            );
                          } else {
                            return (
                              <td
                                key={idx}
                                className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 "
                              >
                                {Array.isArray(item[col.accessor])
                                  ? item[col.accessor].length
                                  : item[col.accessor] || "Nil"}
                              </td>
                            );
                          }
                        })}
                        {actions.length > 0 && (
                          <td
                            className={`px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 grid grid-rows-1 grid-flow-col gap-4 place-items-end`}
                          >
                            {actions.map((action, idx) => (
                              <td key={idx} className=" py-4">
                                {RenderAction({
                                  key: idx,
                                  item: item,
                                  action: action.action,
                                  actionType: action.name
                                })}
                              </td>
                            ))}
                          </td>
                        )}
                      </tr>
                    ))}
                </tbody>
              </table>
              {isPagination && data.length > NO_OF_ITEMS_PER_PAGE && (
                <div className=" w-full flex items-center justify-start lg:justify-center">
                  <div className="py-1 px-4">
                    <nav className="flex items-center space-x-1">
                      <button
                        disabled={currentPage === 1}
                        type="button"
                        onClick={() => {
                          setCurrentPage((val) => val - 1);
                        }}
                        className="py-2 px-4  inline-flex items-center gap-x-2 text-sm rounded-full text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none "
                      >
                        <span aria-hidden="true">«</span>
                        <span className="sr-only">Previous</span>
                      </button>
                      {Array.from(
                        {
                          length: Math.ceil(data.length / NO_OF_ITEMS_PER_PAGE)
                        },
                        (val, idx) => (
                          <button
                            key={idx}
                            type="button"
                            className={`min-w-[40px] flex justify-center items-center text-gray-800 hover:bg-gray-100 py-2.5 text-sm rounded-full disabled:opacity-50 disabled:pointer-events-none disabled:cursor-default ${currentPage === idx + 1 ? "bg-gray-100" : ""}`}
                            aria-current="page"
                            onClick={() => setCurrentPage(idx + 1)}
                          >
                            {idx + 1}
                          </button>
                        )
                      )}
                      <button
                        disabled={
                          currentPage ===
                          Math.ceil(data.length / NO_OF_ITEMS_PER_PAGE)
                        }
                        type="button"
                        onClick={() => setCurrentPage((val) => val + 1)}
                        className="py-2 px-4 inline-flex items-center gap-x-2 text-sm rounded-full text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none  disabled:cursor-default"
                      >
                        <span className="sr-only">Next</span>
                        <span aria-hidden="true">»</span>
                      </button>
                    </nav>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {commentActive && (
        <div className=" fixed top-0 left-0 backdrop-blur-sm flex items-center justify-center h-screen w-full shadow-2xl bg-slate-400 bg-opacity-15">
          <div className="relative w-4/5 sm:w-3/5 flex flex-col bg-white rounded-lg p-4 space-y-4 ">
            <button
              className=" absolute top-0 right-0 mr-4 mt-4 p-2 rounded-full text-black hover:bg-black hover:text-white cursor-pointer "
              onClick={() => setCommentActive(false)}
            >
              <IoClose />
            </button>

            <h1 className=" font-bold text-2xl uppercase text-center">
              Add a comment on the article
            </h1>
            <div className=" w-4/5 mx-auto">
              <TextArea
                name="description"
                type="text"
                placeholder="Write a brief review for the article..."
                onChange={(e) => setAdminComment(e.target.value)}
                rows={rows}
                onClick={() => setRows((val) => (val === 5 ? 2 : 5))}
                onBlur={() => {
                  if (rows === 2) {
                    return;
                  }
                  setRows(2);
                }}
                required={true}
              />
            </div>
            <div className=" w-4/5 mx-auto flex items-end justify-end">
              <Button
                // className={` text-white p-2 rounded-lg uppercase  ${
                //   isSubmitComplete ? "bg-green-400" : "bg-blue-400"
                // }`}
                type="button"
                onClick={() => handleStatusWithComment()}
                isSubmitComplete={isSubmitComplete}
                disabled={adminComment === ""}
              >
                {isSubmitComplete ? "Submit Complete!" : "Submit"}
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ArticlesTable;
