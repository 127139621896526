import { Container, Title } from "components/courses/courseStyle";
import { Document, Page, pdfjs } from "react-pdf";
import React, { useState } from "react";

import { BiArrowBack } from "react-icons/bi";
import { IconSize } from "components/Admin/Articles/Articles.style";
import { IoReload } from "react-icons/io5";
import { useDebounce } from "hooks/use-debounce";
import { useNavigate } from "react-router-dom";
import useResizeObserver from "use-resize-observer";

// import { useScreen } from "hooks/use-window-size";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const ViewArticle = ({ data, close }) => {
  const { url, title } = data;
  const navigate = useNavigate();
  const { ref, width = 1 } = useResizeObserver();
  const debouncedWidth = useDebounce(width, 300);

  const [loadError, setLoadError] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [numPages, setNumPages] = useState(null);
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };
  const onDocumentLoadFailed = ({ err }) => {
    setLoadError(err);
    setNumPages(null);
  };
  if (loadError) {
    <div className=" flex flex-col items-center justify-center space-y-6 w-full">
      <p className=" font-bold text-xl text-center">Error loading file </p>
      <button
        className=" flex items-center justify-center border border-solid border-[#9C4DF4] rounded-lg "
        onClick={() => window.location.reload()}
      >
        <IoReload />
        reload
      </button>
    </div>;
  }
  return (
    <Container className=" relative !bg-white">
      <div className=" flex flex-col items-center space-y-4" ref={ref}>
        <Document
          file={url}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={onDocumentLoadFailed}
        >
          {/* <div className=" flex w-full items-center justify-center py-10"> */}
          <Page
            width={debouncedWidth || undefined}
            renderAnnotationLayer={false}
            renderTextLayer={false}
            // className={` w-full `}
            pageNumber={currentPage}
            error={
              <p className=" capitalize font-bold text-2xl">No file found</p>
            }
            loading={
              <p className=" capitalize font-bold text-2xl">Loading....</p>
            }
          />
          {/* </div> */}
        </Document>
        <div className="w-full flex items-center justify-center space-x-8 py-4">
          <button
            onClick={() => {
              if (currentPage - 1 === 0) return;
              setCurrentPage((page) => page - 1);
            }}
            className=" p-2 border border-solid border-blue-300 text-blue-400 rounded-lg transition-all duration-300 hover:bg-blue-400 hover:text-white disabled:cursor-not-allowed "
            disabled={currentPage - 1 === 0}
          >
            Previous
          </button>
          <p>
            Page {currentPage}&nbsp;of&nbsp;&nbsp;{numPages}
          </p>
          <button
            onClick={() => {
              if (currentPage + 1 > numPages) return;
              setCurrentPage((page) => page + 1);
            }}
            disabled={currentPage + 1 > numPages}
            className=" disabled:cursor-not-allowed p-2 border border-solid border-blue-300 text-blue-400 rounded-lg transition-all duration-300 hover:bg-blue-400 hover:text-white "
          >
            Next
          </button>
        </div>
      </div>
    </Container>
  );
};

export default ViewArticle;
