import { DashboardDiv, DashboardLinks, MobileDashboardDiv } from "./style";
import { Link, useLocation } from "react-router-dom";

import { ADMIN_ROLES } from "utils/admin-roles";
import { AppLogo } from "utils/assets";
import { LOCAL_STORAGE } from "api/LOCALSTORAGE";
import LogoutModal from "./LogoutModal";
import MobileDashboard from "./MobileDashboard";
import { useState } from "react";

function DashboardLayout({ links }) {
  const [openModal, setOpenModal] = useState(false);
  const currentUser = LOCAL_STORAGE.user();

  // filter content of side bar based on who is logged in
  const showNavLinks = () => {
    if (currentUser?.type === ADMIN_ROLES.USER) {
      return links;
    } else {
      return links.filter((link) => {
        return link.user.includes(currentUser?.type);
      });
    }
    // if (currentUser?.type === ADMIN_ROLES.USER) {
    //   return links;
    // } else if (currentUser?.type !== ADMIN_ROLES.SUPER_ADMIN) {
    //   const onlyELearn = links.filter(
    //     (link) => link.user !== ADMIN_ROLES.E_LEARNING
    //   );
    //   return onlyELearn;
    // } else if (currentUser?.type !== ADMIN_ROLES.SUPER_ADMIN) {
    //   const onlyAdmin = links.filter(
    //     (link) => link.user !== ADMIN_ROLES.SUPER_ADMIN
    //   );
    //   return onlyAdmin;
    // } else {
    //   return links;
    // }
    // return navLinks;
  };

  const location = useLocation().pathname;
  const activeNav = links.find((link) =>
    location.includes(link.route) ? link : null
  );
  const [active, setActive] = useState(activeNav.name);
  return (
    <>
      <MobileDashboardDiv>
        {<MobileDashboard links={links} />}
      </MobileDashboardDiv>
      {openModal && <LogoutModal setOpenModal={setOpenModal} />}
      <DashboardDiv>
        <div className="logo">
          <img src={AppLogo} alt="" />
        </div>
        <DashboardLinks>
          {showNavLinks()?.map((link, i) => (
            <Link
              to={link.route}
              key={i}
              onClick={() => {
                !link.action && setActive(link.name);
                // link.action && logout();
                link.action && setOpenModal(true);
              }}
            >
              <div
                className={`${
                  active.trim() === link.name.trim()
                    ? "text-white bg-black/50"
                    : "text-black/50 "
                }  transition-colors duration-300 ease-in-out rounded hover:bg-black/50 hover:text-white`}
              >
                <span>{link.icon}</span>
                <span className=" hidden abovelg items-center justify-center ">
                  {link.name}
                </span>
              </div>
            </Link>
          ))}
        </DashboardLinks>
      </DashboardDiv>
    </>
  );
}

export default DashboardLayout;
