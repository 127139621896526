import media from "utils/media";
import styled from "styled-components";

export const JournalCon = styled.div`
  width: 40%;
  padding-left: 8.5%;
  ${media.mobile`
  width: 100%;
  padding-left: 0%;
  padding: 0px 8.5%;
  `}
`;
export const JournalHead = styled.h1`
  /* font-family: 'Montserrat'; */
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  color: #46555c;
  line-height: 150.02%;
  text-align: center;

  ${media.mobile`
  font-size: 24px;
  line-height: 120%;
  
  `}
`;

export const JournalUploadDiv = styled.div``;

export const UploadSelect = styled.button`
  width: 50%;
  margin-top: 1rem;
  height: 35px;
  border: 2px solid #d7d7d7;
  border-radius: 15px;
  cursor: pointer;

  background-color: ${(props) =>
    props.isUploadComplete ? "green" : "#46555C"};
  transition: background-color 0.3s ease-in-out;
  color: #000;
  /* color: #fff; */
  font-size: 16px;
  cursor: pointer;
  border-radius: 10%;

  &:hover {
    background-color: ${(props) =>
      props.isUploadComplete ? "green" : "darkblue"};
  }
`;

export const TextArea = styled.textarea`
  width: 100%;
  /* height:  ${(props) => (props.height ? `${props.height}px` : "100px")}; */
  margin-top: 1rem;
  padding: 12px 20px;
  box-sizing: border-box;
  font-size: 16px;
  /* width: 519px; */
  border: 1px solid #46555c;
  border-radius: 10px;
  resize: none;

  ${media.mobile`
  height: 200px;
  `}
`;
