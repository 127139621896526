import HomeComp from "../components/Home";
import Mainlayout from "layout/MainLayout";
import { Outlet } from "react-router-dom";
import React from "react";

function HomePage() {
  return (
    <Mainlayout>
      <Outlet />
      {/* <HomeComp /> */}
    </Mainlayout>
  );
}

export default HomePage;
