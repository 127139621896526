import React from "react";
import Layout from "components/layout/Layout";
import { courseInfoJamb } from "../components/courses/courseInfo";
import CoursesInfo from "components/courses/CoursesInfo";

function JambCoursesPg() {
  return (
    <Layout>
      <CoursesInfo data={courseInfoJamb} />
    </Layout>
  );
}

export default JambCoursesPg;
