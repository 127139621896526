import React, { useState } from 'react'

import { AiOutlineClose } from 'react-icons/ai';
import { AuthButton } from 'components/Auth';
import { Modal } from 'components/Admin/course/style';
import { USER } from 'api/ACTION';
import jmsApp from 'api/jms';
import { useNavigate } from 'react-router'

const DeleteModal = ({onClose}) => {
    const {navigate} = useNavigate();
    const [loading, setLoading] = useState(false);

    const handleDeleteAccount = async () => {
      setLoading(true)
      try {
        const { data } = await jmsApp.delete(USER.DELETE_USER());
     
        if(data.success){
          setLoading(false)
          navigate("/")
        }
      } catch (error) {
        setLoading(false)
      
      }
      };

    return (
        <Modal>
          <div className="container">
            <div className="header">
              <h4 className="font-bold text-[#333333] text-[24px]">Delete Account</h4>
              <span>
                <AiOutlineClose className="text-[#333333]" onClick={onClose} />
              </span>
            </div>
            <p>You will loose all paid subscriptions made with this account.</p>
            <p>Are you sure you want to delete this account?</p>
            <AuthButton
              loading={loading}
                type="submit"
                onClick={handleDeleteAccount}
                // disabled={authStore.isLoading ? true : false}
                title={"Delete"}
              />
          </div>
        </Modal>
      );
}

export default DeleteModal