import { AUTH_ENDPOINTS } from "./ACTION";
import jmsApp from "./jms";
import { toast } from "react-hot-toast";

export const AuthenticateUser = async () => {
  // const navigate = useNavigate()
  // try {
  //   const { data } = await jmsApp.post(AUTH_ENDPOINTS.CHECK_USER(), {});
  //   console.log(data,'ff')
  //   // if (!data.success) window.location.replace("/login");
  // } catch (error) {
  //   console.error(error,"ERR IN AUTHcHEC")
  //   toast.error(error.response.data.error);
  //   // window.location.replace("/login");
  // }
};
