import { AiOutlineHome } from "react-icons/ai";
import { MdLogout, MdOutlinePayment,  } from "react-icons/md";
import { ImBook } from "react-icons/im";
import { RiAccountCircleLine,  } from "react-icons/ri";

export const dashboardLinks = [
  {
    name: 'Dashboard',
    icon: <AiOutlineHome size={24} />,
    route: '/dashboard',
  },
  {
    name: 'Courses',
    icon: <ImBook size={24} />,
    route: '/courses',
  },
  {
    name: 'Payment',
    icon: <MdOutlinePayment size={24} />,
    route: '/payment',
  },
  {
    name: 'Account',
    icon: <RiAccountCircleLine size={24} />,
    route: '/account',
  },
  { name: 'Logout', icon: <MdLogout size={24} />, route: '', action: 'logout' },
]
