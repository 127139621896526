export const USER_TYPE = {
  ADMIN: () => "admin",
  USER: () => "user",
  SUPER: () => "super"
};

export const ARTICLE_ENDPOINT = {
  SEND_ARTICLE: () => "/author/article",
  ASSIGN_ARTICLE: () => "/author/article/assign",
  REUPLOAD_ARTICLE: () => "/author/article/admin",
  UPDATE_ARTICLE: () => "/author/article",
  VERIFY_ARTICLE: () => "/author/article",
  GET_ARTICLE: () => "/author/article",
  GET_ARTICLE_BY_ID: () => "/author/article/admin/:?",
  // GET_ARTICLE_BY_ID: () => "/author/article/:?",
  GET_ALL_ARTICLE: () => "/author/articles/all",
  ARTICLE_STATUS: () => "/author/article",
  ARTICLE_IN_REVIEW: () => "/author/article/review",
  ARTICLE_PUBLISHED: () => "/author/article/published",
  GET_ARTICLE_BY_TITLE: (title) => `/author/articles/title/?title=${title}`,
  GET_ARTICLE_BY_CATEGORY: () => "/author/articles/category",
  VERIFY_ARTICLE_VIEW: () => "/author/article-verify",
  DELETE_ARTICLE: () => "/author/article",
  SEND_REVIEW: () => "/author/article/send-review"
};

export const DOWNLOAD_ENDPOINT = {
  DOWNLOADED_ARTICLE: () => "/download",
  GET_DOWNLOADED_ARTICLE_BY_ID: () => `/download/:`
  // GET_DOWNLOADED_ARTICLE_BY_ID: (articleId) =>
  //   `/download/:?articleId=${articleId}`
};

export const AUTH_ENDPOINTS = {
  GET_EDITORS: () => "/auth/editor",
  REGISTER: () => "/auth/register",
  LOGIN: () => "/auth/login",
  LOGOUT: () => "/auth/logout",
  LOGOUT_ALL: () => "/auth/logout/all",
  CHECK_USER: () => "/auth/check",
  VERIFY_OTP: () => "/auth/register-verify",
  SEND_OPT: () => "/auth/send-otp",
  REGISTER_ADMIN: () => "/auth/create",
  GET_USER_ACCOUNTS: () => "/auth/users",
  GET_ADMIN_ACCOUNTS: () => "/auth/admins",
  GET_ADMIN_ACCOUNTS_BY_ROLE: (role) => `/auth/admin/:`,
  DELETE_USER_ACCOUNT: () => "/auth/admin",
  SEND_INVITE: () => "/user/invite",
  VERIFY_INVITE: () => "/user/verify-invite",
  DEFAULT: () => "/auth/default",
  REFRESH_TOKEN: () => "/auth/refreshtoken",

  UPDATE_LOGIN: () => "/auth/reset-password",
  USER_INFO: () => "/user/info"
};

export const PASSWORD_RECOVERY_ENDPOINT = {
  FORGOT_PASSWORD: () => "/user/forgot-password",
  VERIFY_PASSWORD: () => "/user/verify-reset?",
  RECOVER_LINK: () => "/user/recovery-mail?",
  RESET_PASSWORD: () => "/user/reset-password"
};

export const ADMIN_ENDPOINTS = {
  ADD_ADMIN: () => "/user/invite",
  VERIFY_INVITE: () => `/user/verify-invite`,
  REGISTER_ADMIN: () => "/auth/create"
};

export const CATEGORY_ENDPOINT = {
  CREATE_CATEGORY: () => "/category",
  UPDATE_CATEGORY: () => "/category",
  DELETE_CATEGORY: (categoryId) => `/category?categoryId=${categoryId}`,
  GET_ARTICLE_CATEGORY: () => "/category",
  GET_CATEGORY: () => "/category/all"
  // GET_CATEGORY: () => "/category",
};

export const CHECK_STATUS = {
  ADD_ADMIN: () => "/status"
};

export const USER = {
  UPDATE_USER: () => "/user/update",
  VERYFY_USER: () => "/user/verify",
  DELETE_USER: () => "/user/account"
};

export const USER_PLAN = {
  GET_PLAN: () => "/plan",
  GET_PLAN_BY_ID: () => "/plan/:",
  GET_USER_PLANS: () => "/plan/userplan",
  CREATE_PLAN: () => "/plan",
  DELETE_PLAN: () => "/plan",
  UPDATE_PLAN_COURSE: () => `/plan/:?`,
  // UPDATE_PLAN_COURSE: () => "/plan/add-course/?",

  UPDATE_PLAN: () => "/plan/:",
  REMOVE_MODULE: ({ planId, module }) =>
    `/plan/remove-course?planId=${planId}&modules=${module}`
  // REMOVE_MODULE: () => "/plan/remove-course"
};

export const PAYMENT = {
  BUY_PLAN: () => "/plan/transaction",
  VERIFY_TRANSACTION: () => "/plan/transaction?"
};

export const COURSE = {
  CREATE_COURSE: () => "/course",
  UPDATE_COURSE: () => "/course",
  GET_COURSE: () => "/course",
  GET_COURSE_BY_ID: () => "/course/id",
  DELETE_COURSE: () => "/course",
  COMPLETE_COURSE: () => "/course/completed"
};
