import AccountComp from 'components/Account/Account'
import Layout from 'components/layout/Layout'
import React from 'react'

const AccountPg = () => {
  return (
    <Layout>
      <AccountComp />
    </Layout>
  )
}

export default AccountPg