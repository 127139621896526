import { FaSearch } from "react-icons/fa";
import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  align-self: start;
  margin: 0px 0px 10px 0px;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 8px;
`;

const Input = styled.input`
  border: none;
  outline: none;
  margin-left: 8px;
  font-size: 16px;
  width: 100%;
`;

const SearchIcon = styled(FaSearch)`
  margin-right: 8px;
  color: #999;
`;

const SearchBox = ({ value, onChange, title }) => {
  return (
    <div className=" w-full flex items-center !justify-between">
      <p className=" font-bold text-xl capitalize font-primary">{title}</p>
      <Container>
        <SearchIcon />
        <Input
          type="text"
          placeholder="Search for Courses"
          value={value}
          onChange={onChange}
        />
      </Container>
    </div>
  );
};

export default SearchBox;
