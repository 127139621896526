import {
  ArticleCon,
  ButtonComp,
  Card,
  CategoryHeadBg,
  CategoryLeft,
  CategoryRight,
  Container
} from "./style";
import { Link, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";

import { ARTICLE_ENDPOINT } from "api/ACTION";
import { ArtHead } from "components/Home/Article Section/style";
import { BlogHeadCon } from "components/Home/Blog Section/style";
import book from "../../assets/images/books.png";
import jmsApp from "api/jms";
import styled from "styled-components";
import textBanner from "../../assets/images/bgBanner.png";
import { toast } from "react-hot-toast";
import { useDebounce } from "hooks/use-debounce";

// import SupportPricing from 'components/SupportPricing';

const SearchContainer = styled.div`
  position: relative;
  width: 300px;
  padding: 20px;
  border: 1px solid #ccc;
`;

const SearchInput = styled.input`
  padding: 8px 55px;
  width: 100%;
  border: none;
  border-radius: 4px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  font-size: 16px;
`;

function JournalsComp({ ArtCategory = "" }) {
  const params = useParams();
  const { id } = params;

  const [articles, setArticles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [query, setQuery] = useState("");
  const debouncedQuery = useDebounce(query, 500);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const articlesPerPage = 5;

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const { data: articlesData } = await jmsApp.get(
          ARTICLE_ENDPOINT.GET_ARTICLE_BY_CATEGORY(),
          {
            params: {
              category: id,
              search: debouncedQuery,
              author: selectedOption,
              country: selectedCountry
            }
          }
        );

        setIsLoading(false);
        // Sort articles based on relevance to the search query
        const sortedArticles = articlesData.article.sort((a, b) => {
          const aRelevance = a.title
            .toLowerCase()
            .includes(debouncedQuery.toLowerCase());
          const bRelevance = b.title
            .toLowerCase()
            .includes(debouncedQuery.toLowerCase());

          if (aRelevance && !bRelevance) return -1;
          if (!aRelevance && bRelevance) return 1;

          // If both articles have relevance, sort based on other criteria (e.g., publication date)
          // You can customize this part based on your requirements
          return a.title.localeCompare(b.title);
        });

        setArticles(sortedArticles);
      } catch (error) {
        setIsLoading(false);
        toast.error("Failed to fetch data");
      }
    };

    fetchData();
  }, [id, debouncedQuery, selectedOption, selectedCountry]);

  const handleInputChange = (event) => {
    setQuery(event.target.value);
    setCurrentPage(1);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Automatically triggers useEffect with updated query
  };

  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handlESubmit = (event) => {
    event.preventDefault();
    // Automatically triggers useEffect with updated country or author
  };

  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
  const currentArticles = articles.slice(
    indexOfFirstArticle,
    indexOfLastArticle
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Extract countries and authors from articles
  const countries = Array.from(
    new Set(articles.map((article) => article.country))
  );
  const authors = Array.from(
    new Set(articles.map((article) => article.author))
  );

  return (
    <ArticleCon>
      <Container>
        <BlogHeadCon
          style={{ backgroundImage: `url(${textBanner})`, height: "100px" }}
        >
          <ArtHead style={{ color: "#ffffff" }}>Browse journals</ArtHead>
        </BlogHeadCon>
        <CategoryHeadBg>
          <CategoryLeft>
            <SearchContainer>
              <form onSubmit={handleSubmit}>
                <div
                  className="search-container"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "15px"
                  }}
                >
                  <SearchInput
                    type="text"
                    placeholder="Search for article..."
                    value={query}
                    onChange={handleInputChange}
                    className="search-input"
                    style={{
                      padding: "10px",
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                      marginRight: "5px",
                      width: "100%"
                    }}
                  />
                  <button
                    type="submit"
                    className="search-button"
                    onClick={handleSubmit}
                    style={{
                      padding: "10px",
                      color: "black",
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                      cursor: "pointer"
                    }}
                  >
                    Search
                  </button>
                </div>
              </form>
              <div style={{ paddingTop: "30px", paddingBottom: "30px" }}>
                <form onSubmit={handlESubmit}>
                  <label htmlFor="discipline">Choose Author:</label>
                  <br /> <br />
                  <select
                    id="discipline"
                    name="discipline"
                    value={selectedOption}
                    onChange={handleOptionChange}
                    style={{
                      padding: "10px",
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                      marginRight: "5px",
                      width: "100%"
                    }}
                  >
                    <option value="">Select Author</option>
                    {authors.map((author, index) => (
                      <option key={index} value={author}>
                        {author}
                      </option>
                    ))}
                  </select>
                </form>
              </div>

              <form onSubmit={handlESubmit}>
                <label htmlFor="subject">Choose Country:</label>
                <br /> <br />
                <select
                  id="country"
                  name="country"
                  value={selectedCountry}
                  onChange={handleCountryChange}
                  style={{
                    padding: "10px",
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                    marginRight: "5px",
                    width: "100%"
                  }}
                >
                  <option value="">Select Country</option>
                  {countries.map((country, index) => (
                    <option key={index} value={country}>
                      {country}
                    </option>
                  ))}
                </select>
              </form>
            </SearchContainer>
          </CategoryLeft>
          {isLoading === false && currentArticles.length > 0 && (
            <>
              {" "}
              <CategoryRight>
                {currentArticles.map((article) => (
                  <Card key={article.articleId}>
                    <div className=" flex  w-full h-full items-center gap-x-4 sm:gap-x-8 ">
                      <div className=" h-full min-w-[7rem] max-w-[7rem] w-28 sm:w-32 sm:max-w-[8rem] rounded-md overflow-hidden">
                        <img
                          src={book}
                          alt="img"
                          className=" !w-full !h-full object-cover"
                        />
                      </div>
                      <div className=" truncate">
                        <h2 className="truncate px-1 ">{article.title}</h2>
                        <p className="author truncate">{article.author}</p>
                        <p className="desc truncate">{article.description}</p>
                        <Link to={`${article.articleUrl}`} target="_blank">
                          <ButtonComp>Read paper</ButtonComp>
                        </Link>
                      </div>
                    </div>
                  </Card>
                ))}

                {/* Pagination */}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "20px"
                  }}
                >
                  {Array.from(
                    { length: Math.ceil(articles.length / articlesPerPage) },
                    (_, index) => (
                      <button
                        key={index + 1}
                        onClick={() => paginate(index + 1)}
                        style={{
                          padding: "10px 15px",
                          margin: "0 5px",
                          border: "1px solid #3498db",
                          borderRadius: "5px",
                          color:
                            index + 1 === currentPage ? "white" : "#3498db",
                          backgroundColor:
                            index + 1 === currentPage
                              ? "#3498db"
                              : "transparent",
                          cursor: "pointer"
                        }}
                      >
                        {index + 1}
                      </button>
                    )
                  )}
                </div>
              </CategoryRight>
            </>
          )}

          {isLoading === true && currentArticles.length <= 0 && (
            <>
              <CategoryRight>
                <div
                  role="status"
                  className="space-y-8 my-2 animate-pulse md:space-y-0 md:space-x-8 rtl:space-x-reverse md:flex md:items-center"
                >
                  <div className="flex items-center justify-center w-full h-48 bg-gray-300 rounded sm:w-96 ">
                    <svg
                      className="w-10 h-10 text-gray-200 "
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 20 18"
                    >
                      <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
                    </svg>
                  </div>
                  <div className="w-full">
                    <div className="h-2.5 bg-gray-200 rounded-full w-48 mb-4"></div>
                    <div className="h-2 bg-gray-200 rounded-full max-w-[480px] mb-2.5"></div>
                    <div className="h-2 bg-gray-200 rounded-full mb-2.5"></div>
                    <div className="h-2 bg-gray-200 rounded-full max-w-[440px] mb-2.5"></div>
                    <div className="h-2 bg-gray-200 rounded-full max-w-[460px] mb-2.5"></div>
                    <div className="h-2 bg-gray-200 rounded-full max-w-[360px]"></div>
                  </div>
                  <span className="sr-only">Loading...</span>
                </div>
                <div
                  role="status"
                  className="space-y-10 my-2 animate-pulse md:space-y-0 md:space-x-8 rtl:space-x-reverse md:flex md:items-center"
                >
                  <div className="flex items-center justify-center w-full h-48 bg-gray-300 rounded sm:w-96 ">
                    <svg
                      className="w-10 h-10 text-gray-200 "
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 20 18"
                    >
                      <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
                    </svg>
                  </div>
                  <div className="w-full">
                    <div className="h-2.5 bg-gray-200 rounded-full w-48 mb-4"></div>
                    <div className="h-2 bg-gray-200 rounded-full max-w-[480px] mb-2.5"></div>
                    <div className="h-2 bg-gray-200 rounded-full mb-2.5"></div>
                    <div className="h-2 bg-gray-200 rounded-full max-w-[440px] mb-2.5"></div>
                    <div className="h-2 bg-gray-200 rounded-full max-w-[460px] mb-2.5"></div>
                    <div className="h-2 bg-gray-200 rounded-full max-w-[360px]"></div>
                  </div>
                  <span className="sr-only">Loading...</span>
                </div>
                <div
                  role="status"
                  className="space-y-8 my-2 animate-pulse md:space-y-0 md:space-x-8 rtl:space-x-reverse md:flex md:items-center"
                >
                  <div className="flex items-center justify-center w-full h-48 bg-gray-300 rounded sm:w-96 ">
                    <svg
                      className="w-10 h-10 text-gray-200 "
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 20 18"
                    >
                      <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
                    </svg>
                  </div>
                  <div className="w-full">
                    <div className="h-2.5 bg-gray-200 rounded-full w-48 mb-4"></div>
                    <div className="h-2 bg-gray-200 rounded-full max-w-[480px] mb-2.5"></div>
                    <div className="h-2 bg-gray-200 rounded-full mb-2.5"></div>
                    <div className="h-2 bg-gray-200 rounded-full max-w-[440px] mb-2.5"></div>
                    <div className="h-2 bg-gray-200 rounded-full max-w-[460px] mb-2.5"></div>
                    <div className="h-2 bg-gray-200 rounded-full max-w-[360px]"></div>
                  </div>
                  <span className="sr-only">Loading...</span>
                </div>
              </CategoryRight>
            </>
          )}

          {isLoading === false && currentArticles.length <= 0 && (
            <CategoryRight>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%"
                }}
              >
                <div className="  w-full h-full bg-opacity-50 flex items-center justify-center ">
                  <h2 className=" text-lg  sm:text-2xl font-bold capitalize pt-8 sm:pt-0">
                    No articles found
                  </h2>
                </div>
              </div>
            </CategoryRight>
          )}
        </CategoryHeadBg>
      </Container>
    </ArticleCon>
  );
}

export default JournalsComp;
