import { Link, useLocation, useNavigate } from "react-router-dom";
import React, { useLayoutEffect, useState } from "react";

import { AppLogo } from "utils/assets";
import { GiHamburgerMenu } from "react-icons/gi";
import { GrClose } from "react-icons/gr";
import LogoutModal from "./LogoutModal";
import { MobileDashboardCont } from "./style";
import authStore from "mobx/AuthStore";
import { handleLogout } from "utils/UserLogout";

function MobileDashboard({ links }) {
  const navigate = useNavigate();

  const logout = () => {
    setLoading(true);
    setOpenModal(true);
    handleLogout()
      .then((data) => {
        if (data.success) {
          setOpenModal(false);
          authStore.logoutUser();
          navigate("/login");
          setOpenModal(false);
          setLoading(false);
        }
      })
      .catch((err) => {
        setOpenModal(false);

        setLoading(false);
        setOpenModal(false);
      });
  };
  const [openModal, setOpenModal] = useState(false);
  let [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [active, setActive] = useState("Dashboard");
  let location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location]);
  return (
    <MobileDashboardCont>
      <div className="logo">
        <img src={AppLogo} alt="" />
      </div>
      {openModal && <LogoutModal loading={loading} />}
      <div>
        <div className="menu" onClick={() => setOpen(!open)}>
          <div className=" cursor-pointer">
            {open ? <GrClose size={24} /> : <GiHamburgerMenu size={24} />}
          </div>
        </div>
        {open && (
          <div className="links">
            {links?.map((link, i) => (
              <Link
                to={link.route}
                key={i}
                onClick={() => {
                  setActive(link.name);
                  link.action && logout();
                  link.action && setOpenModal(true);
                }}
              >
                <div
                  className={`${
                    active.trim() === link.name.trim()
                      ? "text-white bg-black/50"
                      : "text-black/50 "
                  }  rounded hover:bg-black/50 hover:text-white`}
                >
                  <span>{link.icon}</span>
                  <span className="flex items-center justify-center ">
                    {link.name}
                  </span>
                </div>
              </Link>
            ))}
          </div>
        )}
      </div>
    </MobileDashboardCont>
  );
}

export default MobileDashboard;
