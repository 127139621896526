import {
  ADMIN_COURSE_ROUTE,
  ADMIN_DASHBOARD_ROUTE,
  ADMIN_PLAN_ROUTE,
  ADMIN_SETTINGS,
  ARTICLES_ROUTE,
  STAFF_ROUTE
} from "routes";
import { MdLogout, MdOutlinePayment, MdSettingsSuggest } from "react-icons/md";

import { AiOutlineHome } from "react-icons/ai";
import DashboardLayout from "components/shared/DashboardLayout";
import { ImBook } from "react-icons/im";
import { IoIosPeople } from "react-icons/io";
import { LayoutCont } from "./style";
import React from "react";
import { RiArticleFill } from "react-icons/ri";
import { useLocation } from "react-router-dom";

export const dashboardLinks = [
  {
    name: "Dashboard",
    icon: <AiOutlineHome size={24} />,
    route: "/admin/dashboard",
    user: ["editor", "super", "formatter", "sub", "user", "e-learning"]
  },
  {
    name: "Articles",
    icon: <RiArticleFill size={24} />,
    route: "/admin/articles",
    user: ["editor", "super", "formatter", "sub", "user"]
  },
  {
    name: "Staffs",
    icon: <IoIosPeople size={24} />,
    route: "/admin/staffs",
    user: ["super"]
  },
  {
    name: "Resources",
    icon: <ImBook size={24} />,
    route: "/admin/course",
    user: ["super", "e-learning"]
  },
  {
    name: "Courses",
    icon: <MdOutlinePayment size={24} />,
    route: "/admin/plan",
    user: ["super", "e-learning"]
  },
  {
    name: "Settings",
    icon: <MdSettingsSuggest size={24} />,
    route: "/admin/settings",
    user: ["editor", "super", "formatter", "sub", "user", "e-learning"]
  },

  {
    name: "Logout",
    icon: <MdLogout size={24} />,
    route: "",
    action: "logout",
    user: ["editor", "super", "formatter", "sub", "user", "e-learning"]
  }
];

function Layout({ children }) {
  let location = useLocation();
  const path = location.pathname;
  const displaypath =
    path === ADMIN_DASHBOARD_ROUTE ||
    path === ARTICLES_ROUTE ||
    path === STAFF_ROUTE ||
    path === ADMIN_COURSE_ROUTE ||
    path === ADMIN_PLAN_ROUTE ||
    path === ADMIN_SETTINGS;
  return (
    <LayoutCont>
      {/* <DashboardLayout links={dashboardLinks} /> */}
      {displaypath && <DashboardLayout links={dashboardLinks} />}
      <div className="kids">{children}</div>
    </LayoutCont>
  );
}

export default Layout;
