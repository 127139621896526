import Colors from "utils/colors";
import media from "utils/media";
import styled from "styled-components";
import tw from "twin.macro";

export const Header = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  border-bottom: 0.5px solid gray;
  padding-bottom: 10px;

  ${media.mobile`
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin: 10px 10px;
`};
`;

export const Title = styled.h1`
  font-size: 24px;
`;

export const UpdateCon = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  background: #eff1f3;
  margin: 30px 0px;
  border-radius: 20px;
  padding: 20px;
  align-items: center;
  gap: 2rem;

  ${media.mobile`
  width: 100%;
  margin: 0px;
`};

  .boxCon {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 20px;
  }

  .info {
    font-size: 24px;
  }
`;

export const TxtBoxCon = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 300px;
  margin-bottom: 10px;
  padding: 10px;
  height: 70px;
  background-color: white;
  border-radius: 5px;

  .lbl {
    font-size: 10px;
    color: gray;
    margin-right: 10px;
  }
`;

export const Icon = styled.div`
  margin-right: 10px;
  cursor: pointer;
`;

export const Input = styled.input`
  width: 100%;
  outline: none;
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  height: 100vh;
  align-items: center;
  padding: 20px 50px;
  background: white;

  ${media.mobile`
  padding: 0px;
  background: #eff1f3;
`};
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export const Button = styled.button`
  height: fit-content;
  width: ${(props) => {
    return props.name === "update" ? "300px" : "";
  }};
  padding: 10px 15px;
  border-radius: 50px;
  border: none;
  background: ${(props) => {
    return props.disabled === true
      ? "#e0e0e0"
      : props.name === "verify"
      ? "green"
      : props.name === "update"
      ? `${Colors.primary}`
      : "red";
  }};
  color: white;
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  transition: all 0.5s ease-in-out;
  &:disabled {
    cursor: not-allowed;
    opacity: 50;
    pointer-events: none;
  }

  &:hover {
    transform: scaleY(1.2);

    ${tw`shadow-xl animate-pulse`}
  }

  svg {
    margin-right: 10px;
  }
`;
