// import AgricComp from 'components/Articles/Agric'
import Mainlayout from 'layout/MainLayout'
import React from 'react'

function AgricPage() {
  return (
    <Mainlayout>
        {/* <AgricComp /> */}
    </Mainlayout>
  )
}

export default AgricPage