import AdminDashboard from "components/Admin/Dashboard/AdminDashboard";
import React from "react";
import Layout from "components/Admin/DashboardLayout/Layout";

function AdminDashboardPg() {
  return (
    <Layout>
      <AdminDashboard />
    </Layout>
  );
}

export default AdminDashboardPg;
