import { COURSE, USER_TYPE } from "api/ACTION";
import { useEffect, useMemo, useState } from "react";

import AddCourse from "./AddCourse";
import { AllArticles } from "../Articles/Articles.style";
import ClipLoader from "react-spinners/ClipLoader";
import { HiPlus } from "react-icons/hi";
import { LOCAL_STORAGE } from "api/LOCALSTORAGE";
import NoCoursesPage from "components/ELearn/NoCoursePg";
import Search from "../sharedComponents/Search";
import { StaffCont } from "./style";
import Table from "../sharedComponents/Table-v2";
import UpdateModal from "./UpdateModal";
import { courseHeading } from "../sharedComponents/articleData";
import jmsApp from "api/jms";
import { override } from "utils/admin-roles";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

// import CourseTable from "./Table";

function Courses() {
  const [openModal, setOpenModal] = useState(false);
  const [value, setValue] = useState("");
  const [courses, setCourses] = useState([]);
  const [course, setCourse] = useState(null);
  const columns = useMemo(
    () =>
      /* The `courseHeading` constant is being used to define the columns for
  the table in the Courses component. It is used as the heading for
  the table columns to display information about each course. The
  `courseHeading` constant is defined outside the Courses component
  and is imported into the component. It is used to specify the
  structure of the table columns, such as the column names and their
  corresponding data keys. This helps in organizing and displaying the
  course data in a structured manner within the table. */
      courseHeading,
    []
  );
  const [openModal2, setOpenModal2] = useState(false);
  const [updated, setUpdated] = useState(true);
  const [fetchingCourse, setFetchingCourses] = useState(false);
  const [isDeletingCourse, setIsDeletingCourse] = useState(null);

  const navigate = useNavigate();
  const userRole = LOCAL_STORAGE.user()?.type ?? "";

  const handleData = async () => {
    setFetchingCourses(true);
    try {
      if (USER_TYPE.SUPER() === "super") {
        const { data } = await jmsApp.get(COURSE.GET_COURSE());
        if (data.success) {
          setCourses(data.course);
          setUpdated(false);
        }
      }
      setFetchingCourses(false);
    } catch (error) {
      // toast.error("error");
    } finally {
      setFetchingCourses(false);
    }
  };

  const handleDelete = async (course) => {
    // console.log(course);
    const courseId = course.courseId;
    try {
      const { data } = await toast.promise(
        jmsApp.delete(COURSE.DELETE_COURSE(), {
          params: { courseId }
        }),
        {
          loading: `Deleting ${course.title}`,
          success: `${course.title} successfully deleted.`,
          error: `${course.title} deletion failed.`
        }
      );
      if (data.success) {
        setIsDeletingCourse(null);
        setUpdated(true);
        return true;
      }
    } catch (error) {
      if (error.response?.status === "401") {
        navigate("/login");
      } else return new Error(error);
    }
  };

  const handleUpdate = (course) => {
    setCourse(course);
    setOpenModal2(true);
  };

  useEffect(() => {
    if (updated === true) {
      handleData();
    }
  }, [updated]);

  return (
    <StaffCont>
      <div className="heading">
        <h1 className=" text-xl font-bold uppercase">All Resources</h1>

        <button onClick={() => setOpenModal(true)} type="button">
          <span>
            <HiPlus size={24} />
          </span>
          Add Resources
        </button>
      </div>
      <div className="search">
        <Search
          value={value}
          setValue={setValue}
          placeholder="Search Course..."
        />
      </div>
      <div className="courses">
        {courses.length !== 0 ? (
          <AllArticles>
            <Table
              data={courses}
              columns={columns}
              color="grey"
              heading={courseHeading}
              user={userRole}
              // handleDelete={(id) => handleDelete(id)}
              // handleEdit={(course) => handleUpdate(course)}
              actions={[
                { name: "edit", action: handleUpdate },
                // { name: "delete", action: handleDelete }
                {
                  name: "delete",
                  action: (course) => setIsDeletingCourse(course)
                }
              ]}
            />
            {/* <CourseTable
            data={courses}
            columns={columns}
            color="grey"
            heading={courseHeading}
            user="course"
            handleDelete={(id) => handleDelete(id)}
            handleEdit={(course) => handleUpdate(course)}
          /> */}
          </AllArticles>
        ) : !fetchingCourse ? (
          <NoCoursesPage />
        ) : (
          <div className=" w-full h-full flex  items-center justify-center  py-10">
            <ClipLoader
              color="#46555C"
              loading={fetchingCourse}
              cssOverride={override}
              size={30}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        )}
      </div>
      {openModal2 && (
        <UpdateModal
          open={openModal2}
          course={course}
          setUpdated={setUpdated}
          onClose={() => setOpenModal2(false)}
        />
      )}
      <AddCourse
        open={openModal}
        onClose={() => setOpenModal(false)}
        setUpdated={setUpdated}
      />
      {isDeletingCourse && (
        <div className="min-h-screen  bg-black/70 bg-opacity-50 backdrop-blur-sm fixed w-full h-full top-0 left-0 flex items-center justify-center">
          <div
            className="min-w-[280px] max-w-[400px] h-[250px] flex flex-col bg-white rounded-lg items-center justify-center gap-6
           p-6"
          >
            <p className=" font-medium text-center text-xl ">
              Do you want to remove{" "}
              <strong className="block text-red-600 italic underline">
                {isDeletingCourse.title}
              </strong>{" "}
              <span className="block">from list of resources ?</span>
            </p>
            <div className=" w-full items-center justify-between flex gap-6">
              <button
                onClick={() => setIsDeletingCourse(null)}
                className=" w-full p-2 rounded-md border border-red-500 text-red-500 transition-all duration-300 ease-in-out hover:bg-red-500 hover:text-white capitalize font-bold text-lg "
              >
                no
              </button>
              <button
                onClick={() => handleDelete(isDeletingCourse)}
                className=" w-full p-2 rounded-md border border-blue-500 text-blue-500 transition-all duration-300 ease-in-out hover:bg-blue-500 hover:text-white capitalize font-bold text-lg "
              >
                yes
              </button>
            </div>
          </div>
        </div>
      )}
    </StaffCont>
  );
}

export default Courses;
