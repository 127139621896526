import styled from 'styled-components'
import media from 'utils/media'

export const TextArea = styled.textarea`
  width: 100%;
  height: 300px;
  margin-top: 1rem;
  padding: 12px 20px;
  box-sizing: border-box;
  font-size: 16px;
  /* width: 519px; */
  border: 1px solid #46555c;
  border-radius: 10px;
  resize: none;

  ${media.mobile`
  height: 200px;
  `}
`