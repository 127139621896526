import * as Yup from "yup";

import React, { useState } from "react";

import { AiOutlineClose } from "react-icons/ai";
import { CATEGORY_ENDPOINT } from "api/ACTION";
import { Modal } from "../Staff/style";
import TextInput from "components/TextInput";
import jmsApp from "api/jms";
import { toast } from "react-hot-toast";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";

function AddModal({ open, onClose }) {
  // eslint-disable-next-line no-unused-vars
  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [category, setCategory] = useState([]);
  const [categoryFile, setCategoryFile] = useState({
    format: "",
    fileData: null,
    size: 0
  });
  const navigate = useNavigate();

  const formik = useFormik({
    enableReinitialize: false,
    initialValues: {
      name: "",
      format: "",
      fileData: null
    },

    onSubmit: async (info, helpers) => {
      if (categoryFile.size > 5 * 1024 * 1024) {
        toast.error("Maximum upload size (5MB) exceeded!");
        return;
      }
      try {
        setIsLoading(true);
        const { data } = await toast.promise(
          jmsApp.post(CATEGORY_ENDPOINT.CREATE_CATEGORY(), {
            ...info,
            format: categoryFile.format,
            fileData: categoryFile.fileData
          }),
          {
            loading: `Adding ${info.name} to the category list...`,
            success: `${info.name} successfully added to the category list`,
            error: `Failed to add ${info.name} to category list.`
          },
          {
            className: "text-[12px]"
          }
        );

        onClose();
        if (data.success) {
          // authStore.loading()
          handleCategory();
          helpers.resetForm();
        }
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        if (err.response.status === 401) {
          navigate("/login");
        }
        if (err.response.data) {
          // toast.error(err.response.data.error);
        }
      }
    },

    validationSchema: Yup.object({
      name: Yup.string().max(255).required().label("name")
    })
  });

  const handleCategory = async () => {
    try {
      const { data } = await jmsApp.get(CATEGORY_ENDPOINT.GET_CATEGORY());

      if (data.success) {
        setCategory(data.category);
      }
    } catch (error) {
      // toast.error(error.response.data.error);
    }
  };
  function handleFile(e) {
    const reader = new FileReader();
    const fileData = e.target.files[0];
    // Set up the FileReader to convert the file to a data URI
    reader.onload = function (file) {
      const dataURI = file.target.result;

      const newCatFile = {
        size: fileData.size,
        format: `.${fileData.type.split("/")[1]}`,
        fileData: dataURI
      };
      setCategoryFile(newCatFile);

      // Now you have the data URI, which you can use in your application
      // For example, you can set it as the source of an image element
      // const imageElement = document.getElementById('yourImageElementId');
      // imageElement.src = dataURI;
    };

    // Read the file as a data URI
    reader.readAsDataURL(fileData);
  }

  if (!open) return null;

  return (
    <Modal>
      <div className="container">
        <div className="header">
          <h4 className="font-bold text-[#333333] text-[24px]">
            Add Categories
          </h4>
          <span>
            <AiOutlineClose className="text-[#333333]" onClick={onClose} />
          </span>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <TextInput
            label="name "
            name="name"
            type="name"
            id="name"
            required={true}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
            error={formik.errors.name}
            visible={formik.touched.name}
            placeholder="name "
            mt="0.5rem"
          />
          <div className=" my-4">
            <label className="block">
              <span className=" font-bold text-sm text-black/50">
                Choose category photo:
              </span>
              <input
                type="file"
                className="block w-full text-sm text-gray-500
      file:me-4 file:py-2 file:px-4
      file:rounded-lg file:border-0
      file:text-sm file:font-semibold
      file:bg-blue-600 file:text-white
      hover:file:bg-blue-700
      file:disabled:opacity-50 file:disabled:pointer-events-none
   
    "
                onChange={handleFile}
              />
            </label>
          </div>
          <div
            className={
              "!bg-black text-white hover:scale-105 transform transition-all duration-200 ease-in-out px-2 py-1 rounded-md w-fit h-fit mt-4 cursor-pointer disabled:cursor-not-allowed disabled:opacity-50"
            }
            onClick={formik.handleSubmit}
            type="submit"
            disabled={isLoading}
          >
            {isLoading ? "Loading..." : "Add"}
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default AddModal;
