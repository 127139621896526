import "./App.css";

import {
  ABOUT_ROUTE,
  ACCOUNT_ROUTE,
  ADMIN_COURSE_ROUTE,
  ADMIN_DASHBOARD_ROUTE,
  ADMIN_PLAN_ROUTE,
  ADMIN_SETTINGS,
  ADMIN_SIGNUP_ROUTE,
  AGRIC_ROUTE,
  ARTICLES_ROUTE,
  ARTICLE_ROUTE,
  BLOG_ROUTE,
  CHECKOUT_ROUTE,
  COURSES_ROUTE,
  COURSE_ROUTE,
  CREATE_ACCOUNT_ROUTE,
  DASHBOARD_ROUTE,
  ELEARN_ROUTE,
  ENGINE_ROUTE,
  FORGOT_PASSWORD_ROUTE,
  HOME_ROUTE,
  HSS_ROUTE,
  JAMB_ROUTE,
  JOURNAL_ROUTE,
  LOGIN_ADMIN_ROUTE,
  LOGIN_ROUTE,
  MED_ROUTE,
  MGT_ROUTE,
  PAYMENT_ROUTE,
  RESET_PASSWORD_ROUTE,
  SFR_ROUTE,
  SIDEBAR_ARTICLE_ROUTE,
  SIDEBAR_AUTHOR_ROUTE,
  SIDEBAR_EDITORIAL_ROUTE,
  STAFF_ROUTE,
  SUBMIT_ROUTE,
  SUPPORT_ROUTE,
  UNAUTHORIZED_ROUTE,
  VERIFY_ARTICLE_ROUTE,
  VERIFY_INVITE_ROUTE,
  VERIFY_OTP_ROUTE,
  VERIFY_RECOVERY_ROUTE,
  WAEC_ROUTE
} from "routes";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import AboutPage from "pages/AboutPage";
import AccountPg from "pages/AccountPg";
import AdminCoursePg from "pages/AdminCoursePg";
import AdminDashboardPg from "pages/AdminDashboardPg";
import AdminRegisterPg from "pages/AdminRegister";
import AdminSettings from "components/Admin/settings";
import AgricPage from "pages/Articles/AgricPage";
import ArticlePg from "pages/ArticlePg";
import ArticlesSort from "components/Home/side-bar/articles-sort";
import ArticlesPg from "pages/ArticlesPg";
import ArticlesViewPage from "pages/ArticlesViewPage";
import Author from "components/Home/side-bar/author";
import BlogPage from "./pages/BlogPage";
import BuyCourseProvider from "components/context/epaymentContext";
import CheckoutPg from "pages/checkoutPg";
import CoursePg from "pages/CoursePg";
import CoursesPg from "pages/CoursesPg";
import DashboardPg from "pages/DashboardPg";
import EditorialTeam from "components/Home/side-bar/editorial-team";
import Elearn from "components/e-learn/Elearn";
import EnginePage from "pages/EnginePage";
import ForgotPasswordPg from "pages/ForgotPasswordPg";
import { GlobalStyle } from "globalStyles";
import HomeComp from "components/Home";
import HomePage from "./pages";
import HumanSocialPage from "pages/HumanSocialPage";
import JambCoursesPg from "pages/JambCoursesPg";
import JournalPage from "./pages/JournalPage";
import JournalsViewPage from "pages/JournalsViewPage";
import LoginPage from "pages/Login";
import ManagementPage from "pages/ManagementPage";
import MedResearchPage from "pages/MedResearchPage";
import PageNotFound from "pages/Pg404";
import PaymentPg from "pages/PaymentPg";
import PlanPg from "pages/PlanPg";
import { ProtectedRoute } from "components/protectedRoutes/ProtectedRoute";
import ResetPg from "pages/ResetPg";
import ScienceFrontierPage from "pages/ScienceFrontierPage";
import SignupPage from "pages/signup";
import StaffsPg from "pages/StaffsPg";
import SubmitPage from "pages/submit";
import SupportPage from "./pages/SupportPage";
import { Toaster } from "react-hot-toast";
import Unauthorized from "pages/Unauthorized";
import VerifyInvitePg from "pages/VerifyInvite";
import VerifyOtp from "components/Auth/CreateAccount/VerifyOtp";
import VerifyRecoveryPg from "pages/VerifyRecoveryPg";
import VerifyView from "components/Articles/VerifyView";
import WaecCorsesPg from "pages/WaecCorsesPg";

// import { useState, useEffect } from "react";

// import ELearningPage from "./pages/ELearningPage";

// import { AuthRoutes } from "components/protectedRoutes/UserAuthRoute";

// import LoginAdmin from "components/Auth/LoginAdmin";

// import ArticlesComp from "components/Articles/ArticlesComp";

function App() {
  return (
    <div
      style={{
        background: "linear-gradient(to bottom right, #ffffff, #f4f4f4)"
      }}
    >
      <Toaster />

      <GlobalStyle />
      <BuyCourseProvider>
        <BrowserRouter>
          <Routes>
            <Route
              path={HOME_ROUTE}
              element={<HomePage />}
              errorElement={
                <div className="w-full flex item-center justify-center font-bold text-2xl">
                  <p>Something went wrong!!</p>
                </div>
              }
            >
              <Route index element={<HomeComp />} />
              <Route path={SIDEBAR_AUTHOR_ROUTE} element={<Author />} />
              <Route path={SIDEBAR_ARTICLE_ROUTE} element={<ArticlesSort />} />
              <Route
                path={SIDEBAR_EDITORIAL_ROUTE}
                element={<EditorialTeam />}
              />
            </Route>
            <Route path={JOURNAL_ROUTE} element={<JournalPage />} />
            <Route path={VERIFY_ARTICLE_ROUTE} element={<VerifyView />} />
            <Route path={SUBMIT_ROUTE} element={<SubmitPage />} />
            <Route path={SUPPORT_ROUTE} element={<SupportPage />} />
            <Route path={ELEARN_ROUTE} element={<Elearn />} />
            <Route path={BLOG_ROUTE} element={<BlogPage />} />
            <Route path={ABOUT_ROUTE} element={<AboutPage />} />

            <Route path="journals/:id" element={<JournalsViewPage />} />
            <Route path="articles/:id" element={<ArticlesViewPage />} />

            <Route path={SFR_ROUTE} element={<ScienceFrontierPage />} />

            <Route path={MGT_ROUTE} element={<ManagementPage />} />
            <Route path={ENGINE_ROUTE} element={<EnginePage />} />
            <Route path={AGRIC_ROUTE} element={<AgricPage />} />
            <Route path={MED_ROUTE} element={<MedResearchPage />} />
            <Route path={HSS_ROUTE} element={<HumanSocialPage />} />
            <Route
              path={CREATE_ACCOUNT_ROUTE}
              element={
                // <AuthRoutes>
                <SignupPage />
                /* </AuthRoutes> */
              }
            />
            <Route
              path={VERIFY_OTP_ROUTE}
              element={
                // <AuthRoutes>
                <VerifyOtp />
                /* </AuthRoutes> */
              }
            />
            <Route
              path={LOGIN_ROUTE}
              element={
                // <AuthRoutes>
                <LoginPage />
                /* </AuthRoutes> */
              }
            />
            <Route
              path={LOGIN_ADMIN_ROUTE}
              element={
                // <AuthRoutes>
                <LoginPage />
                /* </AuthRoutes> */
              }
            />
            <Route
              path={ADMIN_SIGNUP_ROUTE}
              element={
                // <AuthRoutes>
                <AdminRegisterPg />
                /* </AuthRoutes> */
              }
            />
            <Route
              path={DASHBOARD_ROUTE}
              element={
                <ProtectedRoute
                  allowedRoles={["user", "editor", "sub", "super", "formatter"]}
                >
                  <DashboardPg />
                </ProtectedRoute>
              }
            />
            <Route
              path={FORGOT_PASSWORD_ROUTE}
              element={
                // <AuthRoutes>
                <ForgotPasswordPg />
                /* </AuthRoutes> */
              }
            />
            <Route
              path={VERIFY_RECOVERY_ROUTE}
              element={
                // <AuthRoutes>
                <VerifyRecoveryPg />
                /* </AuthRoutes> */
              }
            />
            <Route
              path={RESET_PASSWORD_ROUTE}
              element={
                // <AuthRoutes>
                <ResetPg />
                /* </AuthRoutes> */
              }
            />
            <Route
              path={COURSES_ROUTE}
              element={
                <ProtectedRoute
                  allowedRoles={[
                    "user",
                    "editor",
                    "sub",
                    "super",
                    "formatter",
                    "e-learning"
                  ]}
                >
                  <CoursesPg />
                </ProtectedRoute>
              }
            />
            <Route
              path={JAMB_ROUTE}
              element={
                <ProtectedRoute
                  allowedRoles={[
                    "user",
                    "editor",
                    "sub",
                    "super",
                    "formatter",
                    "e-learning"
                  ]}
                >
                  <JambCoursesPg />
                </ProtectedRoute>
              }
            />
            <Route
              path={WAEC_ROUTE}
              element={
                <ProtectedRoute
                  allowedRoles={[
                    "user",
                    "editor",
                    "sub",
                    "super",
                    "formatter",
                    "e-learning"
                  ]}
                >
                  <WaecCorsesPg />
                </ProtectedRoute>
              }
            />
            <Route
              path={PAYMENT_ROUTE}
              element={
                <ProtectedRoute
                  allowedRoles={[
                    "user",
                    "editor",
                    "sub",
                    "super",
                    "formatter",
                    "e-learning"
                  ]}
                >
                  <PaymentPg />
                </ProtectedRoute>
              }
            />
            <Route
              path={ACCOUNT_ROUTE}
              element={
                <ProtectedRoute
                  allowedRoles={[
                    "user",
                    "editor",
                    "sub",
                    "super",
                    "formatter",
                    "e-learning"
                  ]}
                >
                  <AccountPg />
                </ProtectedRoute>
              }
            />
            <Route
              path={CHECKOUT_ROUTE}
              element={
                <ProtectedRoute
                  allowedRoles={[
                    "user",
                    "editor",
                    "sub",
                    "super",
                    "formatter",
                    "e-learning"
                  ]}
                >
                  <CheckoutPg />
                </ProtectedRoute>
              }
            />
            <Route
              path={ADMIN_DASHBOARD_ROUTE}
              element={
                // <ProtectedRoute allowedRoles={["editor","sub","super","formatter"]}>
                <AdminDashboardPg />
                // </ProtectedRoute>
              }
            />
            <Route
              path={ARTICLES_ROUTE}
              element={
                <ProtectedRoute
                  allowedRoles={[
                    "editor",
                    "sub",
                    "super",
                    "formatter",
                    "e-learning"
                  ]}
                >
                  <ArticlesPg />
                </ProtectedRoute>
              }
            />
            <Route
              path={ADMIN_PLAN_ROUTE}
              element={
                <ProtectedRoute
                  allowedRoles={[
                    "editor",
                    "sub",
                    "super",
                    "formatter",
                    "e-learning"
                  ]}
                >
                  <PlanPg />
                </ProtectedRoute>
              }
            />
            <Route
              path={ADMIN_SETTINGS}
              element={
                <ProtectedRoute
                  allowedRoles={["editor", "sub", "super", "formatter"]}
                >
                  <AdminSettings />
                </ProtectedRoute>
              }
            />
            <Route
              path={COURSE_ROUTE}
              element={
                <ProtectedRoute
                  allowedRoles={["user", "editor", "sub", "super", "formatter"]}
                >
                  <CoursePg />
                </ProtectedRoute>
              }
            />
            {/* <Route
              path={ACCOUNT_ROUTE}
              element={
                <ProtectedRoute allowedRoles={['user',"editor","sub","super","formatter"]}>
                  <Account />
                </ProtectedRoute>
              }
            /> */}

            <Route
              path={ARTICLE_ROUTE}
              element={
                <ProtectedRoute
                  allowedRoles={["editor", "sub", "super", "formatter"]}
                >
                  <ArticlePg />
                </ProtectedRoute>
              }
            />
            <Route
              path={ADMIN_COURSE_ROUTE}
              element={
                // <ProtectedRoute allowedRoles={["editor","sub","super","formatter"]}>
                <AdminCoursePg />
                // </ProtectedRoute>
              }
            />
            <Route
              path={STAFF_ROUTE}
              element={
                // <ProtectedRoute allowedRoles={["super"]}>
                <StaffsPg />
                // </ProtectedRoute>
              }
            />
            <Route
              path={VERIFY_INVITE_ROUTE}
              element={
                // <ProtectedRoute allowedRoles={["user", "editor","sub","super","formatter"]}>
                <VerifyInvitePg />
                //  </ProtectedRoute>
              }
            />
            <Route path={UNAUTHORIZED_ROUTE} element={<Unauthorized />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </BrowserRouter>
      </BuyCourseProvider>
    </div>
  );
}

export default App;
