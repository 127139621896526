import Mainlayout from 'layout/MainLayout'
import React from 'react'
import BlogComp from '../components/BlogComp'

const BlogPage = () => {
  return (
    <Mainlayout>
      <BlogComp />
    </Mainlayout>
  )
}

export default BlogPage
