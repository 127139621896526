import React from "react";
import styled from "styled-components";

// Styled components
const SupportSection = styled.section`
  padding: 40px;
  background-color: #f2f2f2;
  padding-top: 200px;

  @media (max-width: 768px) {
    padding-top: 130px;
  }
`;

const Title = styled.h1`
  font-size: 32px;
  color: #333333;
  margin-bottom: 20px;
`;

const Description = styled.p`
  font-size: 18px;
  // color: #333333;
  line-height: 1.5;
  /* font-size: 16px; */
  color: #ffffff;
`;

const DonationContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 40px;
`;

const DonationOption = styled.div`
  flex: 1;
  text-align: center;
  padding: 20px;
  background-color: #ffffff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  margin: 10px;
  border-radius: 20px;

  &:hover {
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  }
`;

const Amount = styled.h3`
  font-size: 24px;
  color: #ffffff;
`;

const DonateButton = styled.button`
  background-color: #dc143c;
  color: #ffffff;
  font-size: 16px;
  padding: 10px 20px;
  border: 2px solid #dc143c;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px;
  hieght : 70px;

  // &:hover {
  //   background-color: transparent;
  //   // border: 2px solid #dc143c;
  //   color: #dc143c;
  //   // font-weight: bold;
  //   // font-size: 18px;
  }
`;

// Support Us page component
const SupportComp = () => {
  return (
    <SupportSection>
      <Title>Support Us</Title>
      <Description style={{ color: "#333333" }}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod
        ipsum a quam ultrices consectetur. Proin eget lacinia ex. Nulla quis
        tempus tellus, eget maximus metus. Integer vel mauris ut erat tincidunt
        malesuada. Vivamus non ultricies turpis, eget aliquet mi.
      </Description>
      <DonationContainer>
        <DonationOption style={{ backgroundColor: " #4682B4" }}>
          <Amount>$10</Amount>
          <Description>Lorem ipsum dolor sit amet</Description>
          <DonateButton>Donate</DonateButton>
        </DonationOption>
        <DonationOption style={{ backgroundColor: "#87CEEB  " }}>
          <Amount>$25</Amount>
          <Description>Lorem ipsum dolor sit amet</Description>
          <DonateButton>Donate</DonateButton>
        </DonationOption>
        <DonationOption style={{ backgroundColor: "#1E90FF" }}>
          <Amount>$50</Amount>
          <Description>Lorem ipsum dolor sit amet</Description>
          <DonateButton>Donate</DonateButton>
        </DonationOption>
        <DonationOption style={{ backgroundColor: "#4169E1" }}>
          <Amount>$100</Amount>
          <Description>Lorem ipsum dolor sit amet</Description>
          <DonateButton>Donate</DonateButton>
        </DonationOption>
      </DonationContainer>
    </SupportSection>
  );
};

export default SupportComp;
