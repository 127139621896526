

import React from 'react'
import styled from 'styled-components'

// Styled components
const BlogSection = styled.section`
  padding: 40px;
  background-color: #f2f2f2;
  padding-top:120px;
`

const BlogContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
`

const BlogCard = styled.div`
  background-color: #ffffff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 4px;
`

const BlogImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 4px;
  margin-bottom: 10px;
`

const BlogTitle = styled.h2`
  font-size: 20px;
  color: #333333;
  margin-bottom: 10px;
`

const BlogAuthor = styled.p`
  font-size: 14px;
  color: #666666;
  margin-bottom: 10px;
`

const BlogContent = styled.p`
  font-size: 16px;
  color: #666666;
`

// Blog page component
const BlogComp = () => {
  const blogPosts = [
    {
      id: 1,
      image: '/images/blog1.jpg',
      title: 'Blog Post 1',
      author: 'John Doe',
      content:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod ipsum a quam ultrices consectetur.',
    },
    {
      id: 2,
      image: '/images/blog2.jpg',
      title: 'Blog Post 2',
      author: 'Jane Smith',
      content:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod ipsum a quam ultrices consectetur.',
    },
    {
      id: 3,
      image: '/images/blog3.jpg',
      title: 'Blog Post 3',
      author: 'Mike Johnson',
      content:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod ipsum a quam ultrices consectetur.',
    },
    {
      id: 4,
      image: '/images/blog3.jpg',
      title: 'Blog Post 4',
      author: 'Mike Johnson',
      content:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod ipsum a quam ultrices consectetur.',
    },
    {
      id: 5,
      image: '/images/blog3.jpg',
      title: 'Blog Post 5',
      author: 'Mike Johnson',
      content:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod ipsum a quam ultrices consectetur.',
    },
    {
      id: 6,
      image: '/images/blog3.jpg',
      title: 'Blog Post 6',
      author: 'Mike Johnson',
      content:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod ipsum a quam ultrices consectetur.',
    },
  ]

  return (
    <BlogSection>
      <BlogContainer>
        {blogPosts.map((post) => (
          <BlogCard key={post.id}>
            <BlogImage src={post.image} alt={post.title} />
            <BlogTitle>{post.title}</BlogTitle>
            <BlogAuthor>By {post.author}</BlogAuthor>
            <BlogContent>{post.content}</BlogContent>
          </BlogCard>
        ))}
      </BlogContainer>
    </BlogSection>
  )
}

export default BlogComp
